import { ApiUrl } from "API/config";
import axios from "axios";
import { useEffect, useState } from "react";
import {  useForm } from "react-hook-form";

function NewUser(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
const [allCenters,setAllCenters]=useState([])

useEffect(()=>{
  getAllCenter()
},[])



const getAllCenter = ()=>{
  axios.get(`${ApiUrl}center/all?page=-1`).then((data)=>{
    console.log("centerdata",data.data)
    setAllCenters(data.data)
  })
}

const isValidPhoneNumber = (val) => {
 return val.match(/^\d{10}$/);
}
  const element = (
    <form onSubmit={handleSubmit((data)=>props.props.onNewUserSubmit(data))}>


      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Full Name
          </label>
          <input
            type="text"
            id="nameBackdrop"
             className={`form-control ${errors.name ? "errorBorder" : ""}`}
            placeholder="Enter Full Name"
            {...register("name", { required: true })}
          />
        {errors.name && <span className="textRed" style={{color: "red"}}>*Full Name is required</span>}
        </div>
      </div>


      <div className="row">
        <div className="col mb-3">
          <label htmlFor="emailBackdrop" className="form-label required">
            Email ID
          </label>
          <input
            type="text"
            id="emailBackdrop"
             className={`form-control ${errors.email ? "errorBorder" : ""}`}
            placeholder="Enter Email Id"
            {...register("email", { required: true })}
          />
        {errors.email && <span className="textRed">*Email ID is required</span>}
        </div>
      </div>
    
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Mobile No
          </label>
          <input
            type="number"
            id="nameBackdrop"
            numformat="numberLeft"
             className={`form-control ${errors.mobile ? "errorBorder" : ""}`}
            onInput={(e) => {e.target.value = Math.max( 0,parseInt(e.target.value)).toString().slice(0, 10);}}
             pattern="/^-?\d+\.?\d*$/" 
            placeholder="xxxx-xxxx-xx"
            {...register("mobile", { required: true, maxLength: 10, minLength: 10 })}
            
          />
          {errors.mobile && errors.mobile.type == "required" && (
                        <span className="textRed" style={{color: "red"}}>
                          *Mobile Number is required{" "}
                        </span>
                      )}
          {errors.mobile && errors.mobile.type === "maxLength" && <span className="textRed">Mobile No should be of 10 digits</span>}
          {errors.mobile && errors.mobile.type === "minLength" && <span className="textRed">Mobile No should be of 10 digits</span>}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Roles
          </label>
          <select
             className={`form-control ${errors.role ? "errorBorder" : ""}`}
            id="nameBackdrop"
            aria-label="Default select example"
            {...register("role", { required: true })}
          >
            <option value="">Select Role</option>
            <option value="admin">Admin</option>
            <option value="excecutive">Executive Assistant</option>
            <option value="mentor">Mentor</option>
            <option value="supervisor">Supervisor</option>
          </select>
        {errors.role && <span className="textRed">*Roles is required</span>}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Centre
          </label>
          <select
             className={`form-select form-control ${errors.center ? "errorBorder" : ""}`}
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            {...register("center", { required: true })}
          >
            <option Value="">Select Centre</option>
            {allCenters.filter(x=>x.status=='active').map((x)=>{
              return(
                <option value={x._id}>{x.name}</option>
              )
            })

            }

          </select>
          
        {errors.center && <span className="textRed">*Centre is required</span>}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Status
          </label>
          <select
          {...register("status", { required: true })}
           className={`form-select ${errors.status ? "errorBorder" : ""}`}
            id="exampleFormControlSelect1"
            aria-label="Default select example"
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        {errors.status && <span className="textRed">*Status is required</span>}
        </div>
      </div>
     
      <div className="row">
         <div className="mt-3"><span className="asterisk">*</span> Required Fields</div>
      <div className="col-sm-12 text-center">
      <button type="submit" className="btn btn-md btn-primary me-2">Save</button>
      <button onClick={ ()=> props.props.handleClose()} className="btn btn-md btn-secondary btn-outline-secondary">Cancel</button>
      </div>
      </div>
     

    </form>
  );

  return element;
}

export default NewUser;
