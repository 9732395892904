import React, {useEffect, useState} from "react";
import {MdDelete} from "react-icons/md";
import {useFieldArray} from "react-hook-form";

function SkuModel(props) {
  console.log(props)
  let control = props.control;
  const {
    fields: fieldsSkuPacking,
    append: appendSkuPacking,
    remove: removeSkuPacking,
    update: updateSkuPacking,
    setValue: setValueSkuPacking,
  } = useFieldArray({
    control,
    name: `skuData.${props.index}.packing`,
  });

  const triggerCalculation = () => {
    props.value.packing.map((packingItem, packingIndex) => {
      calculateTotal(packingItem.quantity, "packing", "quantity", props.index, props.value, true, packingItem, packingIndex)
    });
  }
  useEffect(() => {
    if (props.trigger) {
      triggerCalculation();
    };
  }, [props.trigger]);

  useEffect(() => {
    if (props.trigger) {
      triggerCalculation();
    };
    
  }, []);
  const calculateTotal = (value, field, subField, index, item, isNumber, packingItem, packingIndex) => {
    var pObj = packingItem;
    var obj = item;
    obj.sku_rmValue = props.totalRawMaterialCostPerUnit * parseFloat(obj.sku_size) * parseFloat(obj.sku_quantity);
    const raw_material = props.fieldsRawMaterial.filter((x) => x.raw_material_description == pObj.level)[0];
    if (raw_material) {
      obj[field][packingIndex].unit_cost = parseFloat(raw_material.per_unit_cost_of_raw_material);
      obj[field][packingIndex].gst_in_total = parseFloat(raw_material.gst_in_total);
    }
    value = isNumber ? parseFloat(value) : value;
    obj[field][packingIndex][subField] = value ? value : 0;
    if (isNumber) {

      let newPackinCost = parseFloat(obj[field][packingIndex].quantity) * parseFloat(obj[field][packingIndex].unit_cost);
      obj[field][packingIndex].total_packing_cost = parseFloat(newPackinCost);

      let skuTotalCost = obj.packing.map((skuItem, index) => parseFloat(skuItem.total_packing_cost)).reduce((prev, next) => prev + next, 0);
      let skuTotalGst = obj.packing.map((skuItem, index) => parseFloat(skuItem.gst_in_total) / parseFloat(skuItem.unit_cost)).reduce((prev, next) => prev + next, 0);
      let skuTotalGstAmount = obj.packing
        .map(skuItem => {
        const totalPackingCost = parseFloat(skuItem.total_packing_cost);
        const gstInTotal = parseFloat(skuItem.gst_in_total);
        const unitCost = parseFloat(skuItem.unit_cost);
        const result = totalPackingCost * (gstInTotal / unitCost);
        return result?result:0;
    })
    .reduce((prev, next) => prev + next, 0);
      obj.sku_total = parseFloat(skuTotalCost);
      
      let basicVariableCostGtsAmt = parseFloat(obj.sku_rmValue) * props.gstPercOfTotalUnitCost;
      let skuGstAmtOnVariableCost = parseFloat(basicVariableCostGtsAmt) + parseFloat(skuTotalGstAmount);
      let skuTotalVariableCostExcludingGST = parseFloat(skuTotalCost) + parseFloat(obj.sku_rmValue) - skuGstAmtOnVariableCost;
      obj.skuTotalVariableCostExcludingGST = skuTotalVariableCostExcludingGST;
      obj.skuGstAmtOnVariableCost = skuGstAmtOnVariableCost;
    }

    props.updateSku(index, obj);
    obj.sku_commission = (parseFloat(obj.sku_commission_percent) * parseFloat(obj.sku_sales_price)) / 100;
    obj.sku_gst_amount = (parseFloat(obj.sku_gst_percent) * parseFloat(obj.sku_sales_price)) / 100;
    let gross_amount = parseFloat(obj.sku_sales_price) - parseFloat(obj.sku_rmValue) - parseFloat(obj.sku_total) - parseFloat(obj.sku_commission) - parseFloat(obj.sku_transport);
    obj.sku_gross_profit = gross_amount;
    props.updateSku(index, obj);
  };

  const calculateGrossProfit = (value, field, index, item, isNumber) => {
    var obj = item;

    let targetValue = isNumber ? parseFloat(value.replace(/,/g, "")) : value;
    obj[field] = targetValue;
    let rmValue = obj.sku_rmValue ? obj.sku_rmValue : 0;
    obj.sku_commission = (obj.sku_commission_percent * parseFloat(obj.sku_sales_price)) / 100;
    obj.sku_gst_amount = (obj.sku_gst_percent * parseFloat(obj.sku_sales_price)) / 100;
    obj.sku_gross_profit = parseFloat(obj.sku_sales_price) - rmValue - obj.sku_total - obj.sku_commission - obj.sku_transport;
    props.updateSku(index, obj);
  };

  const handleAddPacking = (e) => {
    e.preventDefault();
    let obj = props.value;
    obj.packing.push({
      level: "",
      quantity: "0",
      total_packing_cost: "0",
      unit_cost: "0",
      gst_in_total: "0",
    });

    props.updateSku(props.index, obj);
  };
  const handleRemovePacking = (value, field, subField, index, item, isNumber, packingItem, packingIndex) => {
    var obj = item;

    removeSkuPacking(packingIndex);
    obj.packing.splice(packingIndex, 1);

    obj.sku_total = obj.packing.map((skuItem, skuIndex) => skuItem.total_packing_cost).reduce((prev, next) => prev + next, 0);
    props.updateSku(index, obj);
  };

  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,  });
    return num ? numberFormatter.format(num) : 0;
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-6">
        <div className="col-md-6 mb-6">
          <strong>Unit Economics of SKU</strong>
        </div>
        <div className="col-md-12 mb-3 mt-4">
          <button onClick={(e) => handleAddPacking(e)} className="btn btn-primary me-2 mb-2 float-end">
            Add
          </button>
        </div>
        <br></br>
        <br></br>
        <h6>Packing Unit Cost</h6>
        <table className="table table-bordered">
          <thead className="table-light text-center">
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit Cost</th>
              <th>Total Cost</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {props.value.packing &&
              props.value.packing.map((packingItem, packingIndex) => {
                return (
                  <tr key={packingIndex}>
                    <td>
                      <select
                        className="form-control sl-width"
                        id="exampleFormControlSelect1"
                        aria-label="Default select example"
                        {...props.register(`skuData.${props.index}.packing.${packingIndex}.level`, {
                          onChange: (e) => calculateTotal(e.target.value, "packing", "level", props.index, props.value, true, packingItem, packingIndex),
                        })}
                      >
                        <option defaultValue="">Select</option>
                        {props.fieldsRawMaterial
                          .filter((x) => x.cost_per_batch == 0)
                          .map((y) => {
                            
                            return <option value={y.raw_material_description}>{y.raw_material_description}</option>;
                          })}
                      </select>
                    </td>
                    <td className="text-center cursor-pointer">
                      <input
                        type="number"
                        step="any"
                        numformat="numberRight"
                        className="form-control sl-width"
                        id="exampleFormControlInput1"
                        {...props.register(`skuData.${props.index}.packing.${packingIndex}.quantity`, {
                          onChange: (e) => calculateTotal(e.target.value, "packing", "quantity", props.index, props.value, true, packingItem, packingIndex),
                        })}
                      />
                    </td>
                    <td className="text-center cursor-pointer">
                      <input numformat="numberRight" type="text" className="form-control sl-width" id="exampleFormControlInput1" disabled value={props.value.packing[packingIndex] ? getNumericFormat(props.value.packing[packingIndex].unit_cost) : 0} {...props.register(`skuData.${props.index}.packing.${packingIndex}.unit_cost`)} />
                    </td>

                    <td className="text-center cursor-pointer">
                      <input type="text" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" {...props.register(`skuData.${props.index}.packing.${packingIndex}.total_packing_cost`)} disabled value={props.value.packing[packingIndex] ? getNumericFormat(props.value.packing[packingIndex].total_packing_cost) : 0} />
                    </td>
                    <td style={{textAlign: "center"}} colSpan="1">
                      <MdDelete onClick={(e) => handleRemovePacking(e.target.value, "packing", "unit_cost", props.index, props.value, true, packingItem, packingIndex)} size={20} color="red" />
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td>Total</td>
              <td colSpan="2"></td>
              <td>
                <input type="text" numformat="numberRight" className="form-control" disabled value={props.value.sku_total ? getNumericFormat(props.value.sku_total) : 0}></input>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <br></br>
        <h6>Unit Gross Profit</h6>
        <table className="table table-bordered">
          <tbody className="table-border-bottom-0">
            <tr>
              <td>Sales Price</td>
              <td colSpan="3">
                <input
                  type="number"
                  step="any"
                  numformat="numberRight"
                  className="form-control"
                  value={parseFloat(props.data[props.index].sku_sales_price)  ? parseFloat(props.data[props.index].sku_sales_price) : 0}
                  {...props.register(`skuData.${props.index}.sku_sales_price`, {
                    onChange: (e) => calculateGrossProfit(e.target.value, "sku_sales_price", props.index, props.value, true),
                  })}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Basic Variable Cost</td>
              <td colSpan="3">
                <input type="text" numformat="numberRight" className="form-control" value={props.data[props.index].sku_rmValue ? getNumericFormat(props.data[props.index].sku_rmValue) : 0} disabled></input>
              </td>
            </tr>
            <tr>
              <td>Packing</td>
              <td colSpan="3">
                <input type="text" numformat="numberRight" className="form-control" id={"sku_total" + props.index} value={props.data[props.index].sku_total ? getNumericFormat(props.data[props.index].sku_total) : 0} disabled></input>
              </td>
            </tr>
            <tr>
              
              <td>Commission %</td>
              <td>
                <input
                  type="number"
                  step="any"
                  numformat="numberRight"
                  className="form-control"
                  {...props.register(`skuData.${props.index}.sku_commission_percent`, {
                    onChange: (e) => calculateGrossProfit(e.target.value, "sku_commission_percent", props.index, props.value, true),
                  })}
                ></input>
              </td>
              <td>Commission Amount</td>
              <td>
                <input type="text" numformat="numberRight" className="form-control" {...props.register(getNumericFormat(`skuData.${props.index}.sku_commission`))} disabled value={getNumericFormat(props.value.sku_commission)}></input>
              </td>
            </tr>

            <tr>
              <td>Transport</td>
              <td colSpan="3">
                <input
                  type="number"
                  step="any"
                  numformat="numberRight"
                  className="form-control"
                  {...props.register(`skuData.${props.index}.sku_transport`, {
                    onChange: (e) => calculateGrossProfit(e.target.value, "sku_transport", props.index, props.value, true),
                  })}
                ></input>
              </td>
            </tr>

            <tr>
              <td>Gross Profit</td>
              <td colSpan="3">
                <input type="text" numformat="numberRight" className="form-control" value={props.data[props.index].sku_gross_profit ? getNumericFormat(props.data[props.index].sku_gross_profit) : 0} disabled></input>
              </td>
            </tr>
            <tr>
            <td>GST % on Sale</td>
              <td>
                <input
                  type="number"
                  step="any"
                  numformat="numberRight"
                  className="form-control"
                  {...props.register(`skuData.${props.index}.sku_gst_percent`, {
                    onChange: (e) => calculateGrossProfit(e.target.value, "sku_gst_percent", props.index, props.value, true),
                  })}
                ></input>
              </td>
              <td>GST Amount on Sale</td>
              <td>
                <input type="text" numformat="numberRight" className="form-control" {...props.register(`skuData.${props.index}.sku_gst_amount`, {})} disabled value={props.value.sku_gst_amount ? getNumericFormat(props.value.sku_gst_amount) : 0}></input>
              </td>
              
            </tr>
            <tr>
              <td>Total Variable Cost Excluding GST</td>
              <td>
                <input type="text" numformat="numberRight" className="form-control" disabled value={props.data[props.index].skuTotalVariableCostExcludingGST ? getNumericFormat(props.data[props.index].skuTotalVariableCostExcludingGST) : 0}></input>
              </td>
              <td>GST Amount on Variable Cost</td>
              <td>
                <input type="text" numformat="numberRight" className="form-control" disabled value={props.data[props.index].skuGstAmtOnVariableCost ? getNumericFormat(props.data[props.index].skuGstAmtOnVariableCost) : 0}></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end mt-3 mb-3">
        <MdDelete size={20} color="red" onClick={() => props.removeSku(props.index)} style={{cursor: "pointer"}} />
      </div>
    </div>
  );
}

export default SkuModel;
