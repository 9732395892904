import Layout from "common/components/layout";
import { useParams, useNavigate, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { set, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";

function OrderingFrequency() {
  const { register, control, handleSubmit, reset, setValue, formState } = useForm();
  const [isLoader, setIsLoader] = useState(false);
  const { fields: fieldOrderingFrequency, append: appendOrderingFrequency, update: updateOrderingFrequency, setValue: setValueOrderingFrequency } = useFieldArray({ name: "fieldOrderingFrequencyName", control });
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const [routePath, setRoutePath] = useState(location.state.data.businessType == "manufacturing" ? "orderingFrequency" : location.state.data.businessType == "animalhusbandry" ? "orderingFrequency/animalhusbandry" : location.state.data.businessType == "agro" ? "orderingFrequency/agro" : location.state.data.businessType == "trading" ? "orderingFrequency/trading" : location.state.data.businessType == "service" ? "orderingFrequency/service" : "");
  const onSubmit = (data) => {
    setIsLoader(true);
    axios
      .post(`${ApiUrl}${routePath}/orderingFrequency/create`, {
        ...data,
        businessId: location.state.data.businessId,
        protegeId: location.state.data.protegeId,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onUpdate = (data) => {
    setIsLoader(true);
    axios
      .put(`${ApiUrl}${routePath}/orderingFrequency/update`, {
        ...data,
        _id: orderingFrequencyId,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  let orderingFrequencyId = location.state.data.orderingFrequencyId && location.state.data.orderingFrequencyId;
  useEffect(() => {
    getOrderingFrequency();
  }, []);

  const getOrderingFrequency = () => {
    setIsLoader(true);
    axios.get(`${ApiUrl}protege/${location.state.data.protegeId}`).then(async (result) => {
      if (location.state.data.businessType == "manufacturing" || location.state.data.businessType == "service") {
        let businessData = location.state.data.businessType == "manufacturing" ? result.data[0].manufacturingIds : result.data[0].service;
        let consolidatedRMConsumption = consolidatedRM(businessData);
        let saleProjection = businessData.productList.length && businessData.salesProjection && businessData.salesProjection.fieldProductSkuName && consolidatedRMConsumption.length ? businessData.salesProjection : [];
        let fieldProductRMTotalConsumptionName = getConsumptionQuantity(consolidatedRMConsumption, saleProjection)
        let requiredData = fieldProductRMTotalConsumptionName.map((x, i) => {
          return {
            Raw_Material: x.name,
            item: i + 1,
            Annual_Usage: x.aggregate["1"],
            Cum_Annual_Usage: 0,
            Percentage: 0,
            Cum_Percentage: 0,
            Ordering_Frequency: 0,
          };
        });
        setOrderingFrequency(businessData, requiredData)

      } else if (location.state.data.businessType == "animalhusbandry" || location.state.data.businessType == "agro") {
        let businessData = location.state.data.businessType == "animalhusbandry" ? result.data[0].animalhusbandry : location.state.data.businessType == "agro" ? result.data[0].agro : [];
        let consumptionQuantity = [];
        let consolidatedRMData = businessData.productList.map((x, i) =>
          x.rawMaterialData.rawMaterial.map((y) => {
            let annual_usage = 0;
            for (const month in x.basicInformation[0].details) {
              annual_usage += x.basicInformation[0].details[month].qty_to_be_fed * y.cost_per_batch;
            }
            y.annual_usage = annual_usage;
            return y;
          })
        )
          .flat();

        consolidatedRMData.forEach((x) => {
          let obj = consumptionQuantity.find((o) => o.raw_material_description === x.raw_material_description);
          if (obj) {
            obj.annual_usage = parseFloat(obj.annual_usage) + parseFloat(x.annual_usage);
          } else {
            consumptionQuantity.push(x);
          }
        });

        let requiredData = consumptionQuantity.map((y, index) => {
          return {
            Raw_Material: y.raw_material_description,
            item: index + 1,
            Annual_Usage: y.annual_usage,
            Cum_Annual_Usage: 0,
            Percentage: 0,
            Cum_Percentage: 0,
            Ordering_Frequency: 0
          }
        }).flat();

        setOrderingFrequency(businessData, requiredData)

      } else if (location.state.data.businessType == "trading") {
        let businessData = result.data[0].trading;
        let consumptionQuantity = [];

        let saleProjection = businessData.productList.length && businessData.salesProjection && businessData.salesProjection.fieldProductSkuName ? businessData.salesProjection.fieldProductSkuName
          : [];



        let consolidatedRMData = businessData.productList.map((x, i) =>
          x.rawMaterialData.rawMaterial.map((y) => {
            let obj = saleProjection.find((o) => o.name === y.raw_material_description);
            let annual_usage = 0;
            if (x.numberOfItemsLessThan) {
              for (const month in obj.details) {
                annual_usage += parseFloat(obj.details[month].prod_quantity) * parseFloat(y.cost_per_batch);
              }
            } else {
              annual_usage = parseFloat(obj.aggregate[1].prod_quantity);
            }
            y.annual_usage = annual_usage;
            return y;
          })
        )
          .flat();

        consolidatedRMData.forEach((x) => {
          let obj = consumptionQuantity.find((o) => o.raw_material_description === x.raw_material_description);
          if (obj) {
            obj.annual_usage = parseFloat(obj.annual_usage) + parseFloat(x.annual_usage);
          } else {
            consumptionQuantity.push(x);
          }
        });

        let requiredData = consumptionQuantity.map((y, index) => {
          return {
            Raw_Material: y.raw_material_description,
            item: index + 1,
            Annual_Usage: y.annual_usage,
            Cum_Annual_Usage: 0,
            Percentage: 0,
            Cum_Percentage: 0,
            Ordering_Frequency: 0
          }
        }).flat();

        setOrderingFrequency(businessData, requiredData)

      } else {

      }

      setTimeout(() => setIsLoader(false), 400);

    })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });

  };

  const setOrderingFrequency = (businessData, requiredData) => {
    let fieldOrderingFrequencyName = businessData.orderingFrequency ? businessData.orderingFrequency.fieldOrderingFrequencyName : [];
    requiredData.sort((a, b) => b.Annual_Usage - a.Annual_Usage);
    let sum = 0;
    for (let i = 0; i < requiredData.length; i++) {
      sum += parseFloat(requiredData[i].Annual_Usage);
      requiredData[i].Cum_Annual_Usage = parseFloat(sum);
    }
    for (let i = 0; i < requiredData.length; i++) {
      let calculatedPercentage = (parseFloat(requiredData[i].Annual_Usage) / parseFloat(requiredData[requiredData.length - 1].Cum_Annual_Usage)) * 100;
      requiredData[i].Percentage = parseFloat(calculatedPercentage);
    }
    let sumPercent = 0;
    for (let i = 0; i < requiredData.length; i++) {
      sumPercent += parseFloat(requiredData[i].Percentage);
      requiredData[i].Cum_Percentage = parseFloat(sumPercent);
    }
    for (let i = 0; i < requiredData.length; i++) {
      requiredData[i].Ordering_Frequency = fieldOrderingFrequencyName.length && fieldOrderingFrequencyName[i] && fieldOrderingFrequencyName[i].Ordering_Frequency ? fieldOrderingFrequencyName[i].Ordering_Frequency : 0;

      // if (requiredData[i] && fieldOrderingFrequencyName.length && fieldOrderingFrequencyName[i].Ordering_Frequency) {
      // } else {
      //   requiredData[i].Ordering_Frequency = 0;
      // }
    }
    setValue("fieldOrderingFrequencyName", requiredData);
  }
  const consolidatedRM = (result) => {
    console.log(result)
    let eachSkuPacking = result.productList.map((x, i) => x.skuData.map((y) => y.packing).flat());
    let consolidatedRM = result.productList
      .map((x, i) => {
        return x.skuData
          .map((z, sIndex) => {
            return [
              ...x.rawMaterialData.rawMaterial.filter((rm) => {
                return !eachSkuPacking[i].map((mr) => mr.level).includes(rm.raw_material_description);
              }),
              ...x.skuData[sIndex].packing.map((pk, pi) => {
                return {
                  raw_material_description: pk.level,
                  sku_quantity: parseFloat(pk.sku_quantity),
                  unit_cost: parseFloat(pk.unit_cost),
                };
              }),
            ].map((y) => {
              return getConsolidatedRawMaterial(x, y, z);
            });
          })
          .flat();
      })
      .flat();
    return consolidatedRM;
  };
  const getConsumptionQuantity = (requiredFieldProductRMName, productionSku) => {
    let consumptionQuantity = [];
    requiredFieldProductRMName.map((a, i) => {
      let detailValue = productionSku ? productionSku.fieldProductSkuName.filter((x) => x.name == a.sku_description) : [];
      let productionQuantity = 0;
      let quantityRequired = a.quantity ? parseFloat(a.quantity) : 0;
      let requiredCalculatedData = 0;
      for (const item in a.details) {
        productionQuantity = detailValue.length && detailValue[0].details[item] ? detailValue[0].details[item].prod_quantity : 0;
        if (quantityRequired) {
          requiredCalculatedData = ((productionQuantity * parseFloat(a.sku_quantity)) / (parseFloat(a.batch_size) / parseFloat(a.sku_size))) * quantityRequired;
          a.details[item] = parseFloat(requiredCalculatedData);
        } else {
          a.details[item] = productionQuantity;
        }
      }
      let requiredAgg = 0;
      let productionQuantityYear = 0;
      for (const year in a.aggregate) {
        productionQuantityYear = detailValue.length && detailValue[0].aggregate[year] ? parseFloat(detailValue[0].aggregate[year].prod_quantity) : 0;
        if (quantityRequired) {
          requiredAgg = ((productionQuantityYear * parseFloat(a.sku_quantity)) / (parseFloat(a.batch_size) / parseFloat(a.sku_size))) * quantityRequired;
          a.aggregate[year] = parseFloat(requiredAgg);
        } else {
          a.aggregate[year] = productionQuantityYear;
        }
      }
      return a;
    });

    // console.log(requiredFieldProductRMName)

    requiredFieldProductRMName.forEach((x) => {
      let obj = consumptionQuantity.find((o) => o.name === x.name);
      if (obj) {
        let requiredMonth = 0;
        for (const item in x.details) {
          requiredMonth = parseFloat(obj.details[item]) + parseFloat(x.details[item]);
          obj.details[item] = parseFloat(requiredMonth);
        }
        let requiredAggregate = 0;
        for (const item in x.aggregate) {
          requiredAggregate = parseFloat(obj.aggregate[item]) + parseFloat(x.aggregate[item]);
          obj.aggregate[item] = parseFloat(requiredAggregate);
        }
      } else {
        consumptionQuantity.push(x);
      }
    });
    let requiredTotalMaterialArray = consumptionQuantity.map((item, index) => {
      let requiredData = 0;
      for (const month in item.details) {
        if (item.per_unit_cost_of_raw_material) {
          requiredData = parseFloat(item.details[month]) * parseFloat(item.per_unit_cost_of_raw_material);
          item.details[month] = parseFloat(requiredData);
        } else {
          item.details[month] = parseFloat(item.details[month]) * parseFloat(item.unit_cost);
        }
      }
      let requiredAggregateData = 0;
      for (const year in item.aggregate) {
        if (item.per_unit_cost_of_raw_material) {
          requiredAggregateData = parseFloat(item.aggregate[year]) * parseFloat(item.per_unit_cost_of_raw_material);
          item.aggregate[year] = parseFloat(requiredAggregateData);
        } else {
          item.aggregate[year] = parseFloat(item.aggregate[year]) * parseFloat(item.unit_cost);
        }
      }
      return item;
    });
    return requiredTotalMaterialArray;
  };
  const getConsolidatedRawMaterial = (x, y, z) => {
    // console.log(x, "------", y );
    return {
      sku_description: z ? z.sku_description : "",
      productId: x.product_name,
      product_id: x._id,
      per_unit_cost_of_raw_material: y.per_unit_cost_of_raw_material,
      sku_quantity: z.sku_quantity,
      sku_size: z.sku_size,
      batch_size: x.batch_size,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
      name: y.raw_material_description,
      unit_of_measure: y.unit_of_measure,
      quantity: y.quantity_required_for_batch_size,
      unit_cost: y.unit_cost,
    };
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Ordering Frequency
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="mb-4">
            <div className="card-body">
              {!fieldOrderingFrequency.length && <div className="row">No data found!</div>}
              {fieldOrderingFrequency.length != 0 && (
                <form id="formAccountSettings" onSubmit={handleSubmit(orderingFrequencyId ? onUpdate : onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-3 mt-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>SL. No.</th>
                              <th>Input</th>
                              <th>Annual Usuage in Rs</th>
                              <th>Cum Annual Usuage in Rs</th>
                              <th>Percentage</th>
                              <th>Cum Percentage</th>
                              <th>Ordering Frequency in months</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldOrderingFrequency.map((x, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <input disabled numformat="numberRight" type="text" className="form-control sl-width" value={i + 1} {...register(`fieldOrderingFrequencyName.${i}.Item`)} />
                                  </td>
                                  <td>
                                    <input disabled type="text" className="form-control lg-width" id="exampleFormControlInput1" {...register(`fieldOrderingFrequencyName.${i}.Raw_Material`)} />
                                  </td>
                                  <td>
                                    <input disabled type="text" numformat="numberRight" className="form-control sl-width" value={getNumericFormat(x.Annual_Usage)} id="exampleFormControlInput1" {...register(getNumericFormat(`fieldOrderingFrequencyName.${i}.Annual_Usage`))} />
                                  </td>
                                  <td>
                                    <input disabled type="text" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" value={getNumericFormat(x.Cum_Annual_Usage)} {...register(getNumericFormat(`fieldOrderingFrequencyName.${i}.Cum_Annual_Usage`))} />
                                  </td>
                                  <td>
                                    <input disabled type="text" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" value={getNumericFormat(x.Percentage)} {...register(getNumericFormat(`fieldOrderingFrequencyName.${i}.Percentage`))} />
                                  </td>
                                  <td>
                                    <input disabled type="text" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" value={getNumericFormat(x.Cum_Percentage)} {...register(getNumericFormat(`fieldOrderingFrequencyName.${i}.Cum_Percentage`))} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" {...register(`fieldOrderingFrequencyName.${i}.Ordering_Frequency`)} />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                    <button
                      type="reset"
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
export default OrderingFrequency;
