import {
  Tab,
  Nav
} from "react-bootstrap";
import IncomeFixedCost from "./IncomeFixedCost";
import IncomeSalesRevenue from "./IncomeSalesRevenue";
import IncomeStatement from "./IncomeStatement";
import { useState } from "react";
import IncomeUnitEconomics from "./incomeUnitEconomics";
import IncomeProdPlan from "./incomeProdPlan";
function IncomeStatementPage() {
  const [active, setActive] = useState('incomestatement');
  return (
    <>
      {/* <h4 className="fw-bold py-3">Cash flow</h4> */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="incomestatement" onSelect={(index) => setActive(index)}>
        <div className="nav-align-top mb-4">
          <Nav variant="pills" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="incomestatement">
                <span className="bx-profile">Income Statement</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="salesrevenue">
                <span className="bx-profile">Income Sales Revenue</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fixedcost">
                <span className="bx-profile">Income Fixed Cost</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="unitEconomics">
                <span className="bx-profile">Income Unit Economics</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="capacity">
                <span className="bx-profile">Income Prod Plan and Capacity</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="incomestatement">
              {active === "incomestatement" ? <IncomeStatement /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="salesrevenue">
              {active === "salesrevenue" ? <IncomeSalesRevenue /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="fixedcost">
              {active === "fixedcost" ? <IncomeFixedCost /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="unitEconomics">
              {active === "unitEconomics" ? <IncomeUnitEconomics /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="capacity">
              {active === "capacity" ? <IncomeProdPlan /> : ""}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
      <hr className="my-5" />
      <div className="content-backdrop fade"></div>
    </>
  );
}
export default IncomeStatementPage;
