import Layout from "common/components/layout";
import React, { useEffect, useState } from "react";
import Loader from "common/components/loader";
import { useParams, useNavigate, useLocation } from "react-router";
import { MdDelete } from "react-icons/md";
import { set, useFieldArray, useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
import "screens/protégé/style/manufacturing.css";
function AgroProductDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const op = useParams().op;
  const productId = useParams().id;
  const { register, control, handleSubmit, getValues, setValue, setError, reset, formState, watch, setFocus } = useForm();
  const { errors } = formState;
  const { fields: fieldsBasicInformation, append: appendBasicInformation, remove: removeBasicInformation, update: updateBasicInformation } = useFieldArray({ name: "basicInformation", control });
  const { fields: fieldsRawMaterial, append: appendFeedInformation, remove: removeFeedInformation, update: updateFeedInformation } = useFieldArray({ name: "rawMaterial", control });
  const { fields: fieldsSku, append: appendSku, remove: removeSku, update: updateSku, setValue: setValueSku } = useFieldArray({ name: "skuData", control });
  const { fields: fieldsAnimalProduce, append: appendAnimalProduce, remove: removeAnimalProduce, update: updateAnimalProduce, setValue: setValueAnimalProduce } = useFieldArray({ name: "produceAnimal", control });
  const [isLoader, setIsLoader] = useState(false);
  const [rawMaterialName, setRawMaterialName] = useState([]);
  const [errorRawMaterial, setErrorRawMaterial] = useState([]);
  const [errorGrowAndSale, setErrorGrowAndSale] = useState({
    details: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
    },
    aggregate: {
      1: false,
      2: false,
      3: false,
    },
  });
  const [totalSaleRevenu, settotalSaleRevenu] = useState({
    animalProduce: {
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
    byeProduct: {
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [totalFeedCostPerUnit, setTotalFeedCostPerUnit] = useState(0);
  const [natureofBusiness, setNatureofBusiness] = useState("gsa_byeProduct");
  const [animalName, setAnimalName] = useState("");
  const [compositionOfSales, setCompositionOfSales] = useState({
    Cash: {
      value: 0,
    },
    Credit: {
      value: 0,
    },
    Card: {
      value: 0,
    },
    Advance: {
      value: 0,
    },
  });
  const [totalPercent, setTotalPercent] = useState(0);
  const [errorInTotal, setErrorInTotal] = useState(false);
  const inputRef = React.useRef();
  // console.log("location", location);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    console.log(location.state.agroId)
    if (location.state.agroId) {
      getEditProductData();
    }
  }, []);
  const getEditProductData = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}agro/getagro/${location.state.agroId}`, {
        _id: location.state.agroId,
      })
      .then(async (result) => {
        console.log(result)
        let rawMaterialData = result.data.data.length && result.data.data[0] && result.data.data[0].rawMaterialConsumption ? await getRawMaterialData(result.data.data[0].rawMaterialConsumption) : [];
        setRawMaterialName(rawMaterialData);
        if (result.data.data.length && result.data.data[0] && result.data.data[0].productList && productId) {
          let productData = result.data.data[0].productList.find((product) => product._id === productId);
          reset({
            rawMaterial: productData.rawMaterialData ? productData.rawMaterialData.rawMaterial : [],
            skuData: productData.skuData,
            basicInformation: productData.basicInformation,
            produceAnimal: productData.produceAnimal,
            noOfMonths_cash: productData.noOfMonths_cash,
            cash_details: productData.cash_details,
            noOfMonths_Advance: productData.noOfMonths_Advance,
            noOfMonths_Card: productData.noOfMonths_Card,
            noOfMonths_Credit: productData.noOfMonths_Credit,
            percent_Advance: productData.percent_Advance,
            percent_card: productData.percent_card,
            percent_credit: productData.percent_credit,
            natureofBusiness: productData.natureofBusiness,
            product_name: productData.product_name,
            brief_manufacture_product: productData.brief_manufacture_product,
            physical_nature_of_product: productData.physical_nature_of_product,
            machine_used_in_measure_process: productData.machine_used_in_measure_process,
            batch_size: productData.batch_size,
            unit_of_measure: productData.unit_of_measure
          });
          let rawMaterial = getValues("rawMaterial");
          rawMaterial.filter((x, i) => setRawMaterialValue(x.raw_material_description, i, x, rawMaterialData));
          setAnimalName(productData.animalName);
          setNatureofBusiness(productData.natureofBusiness);
          calcuateTotalFeedCostPerAnimal();
          updateErrorRawMaterial();
          saleRevenueCalculation1()
          // saleRevenueCalculation()
        }
        setTimeout(() => setIsLoader(false), 400);
      }).catch((error) => {
        // console.log(error);
        setIsLoader(false);
      });
  };
  const getRawMaterialData = (data) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${ApiUrl}rawMaterialConsumption/agro/rawMaterialConsumption/${data}`)
        .then((result) => {
          resolve(result.data.rawMaterial);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const setRawMaterialValue = (value, index, x, rawMaterial) => {
    rawMaterial.filter((item) => {
      if (item.raw_material_description === value) {
        x.raw_material_description = item.raw_material_description;
        x.unit_of_measure = item.unit_of_measure;
        x.unit_rate = item.unit_rate;
        x.gst_on_raw_material = item.gst_on_raw_material;
        x.transpost_cost_on_raw_material = item.transpost_cost_on_raw_material;
        x.gst_transport = item.gst_transport;
        x.gst_in_total = item.gst_in_total;
        x.per_unit_cost_of_raw_material = item.per_unit_cost_of_raw_material;
        x.cost_per_batch = parseFloat(x.per_unit_cost_of_raw_material) * parseFloat(x.quantity_required_for_batch_size);
        x.gst_amount_batch = parseFloat(x.cost_per_batch) * parseFloat(parseFloat(x.gst_in_total) / parseFloat(x.per_unit_cost_of_raw_material));
        updateFeedInformation(index, x);
      }
    });
  };
  useEffect(() => {
    console.log("inputRef", inputRef);
    if (op == "Add") {
      // inputRef.current.blur();
      appendSku({
        sku_description: "",
        sku_quantity: 0,
        sku_unit_of_measure: "",
        sku_unit_of_time: "",
        price_per_unit: 0,
        packing: [
          {
            level: "",
            quantity: "0",
            total_packing_cost: "0",
            unit_cost: "0",
            gst_in_total: "0",
          }
        ],
        sale_quantity: {
          details: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          aggregate: {
            1: 0,
            2: 0,
            3: 0,
          },
        },
        sale_revenu: {
          details: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          aggregate: {
            1: 0,
            2: 0,
            3: 0,
          },
        },
      });
    }
    appendBasicInformation({
      bi_description: "",
      unit_of_measure: "",
      unit_price_purchase: 0,
      growth_period: 0,
      yield_description: "",
      quantity_of_yield: 0,
      unit_of_measure_yield: "",
      sale_price: 0,
      unit_of_measure_sales_price: "",
      mortality_rate: 0,
      minimum_order_qty: 0,
      lead_time_in_month: 0,
      payment_terms: "",
      no_of_months: 0,
      safety_stock_in_month: 0,
      totalFeedCostPerUnit: 0,
      details: {
        1: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        2: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        3: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        4: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        5: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        6: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        7: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        8: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        9: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        10: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        11: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        12: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
      },
      aggregate: {
        1: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        2: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
        3: { unit_price: 0, qty_to_be_purchased: 0, cumulative_purchase: 0, sale_possible: 0, cumulative_sales: 0, animals_sold: 0, qty_to_be_fed: 0, sales_revenue: 0, acquistion_cost: 0, feed_cost: 0 },
      },
    });
    appendAnimalProduce({
      name_of_the_produce: "",
      quantity_per_land: 0,
      unit_of_measure: "",
      price_per_unit: 0,
      unit_of_time: "",
      shelf_life: 0,
      unit_of_time_shelf_life: "",
      inactive_period: 0,
      unit_of_time_inactive_period: "",
      minimum_order_qty: 0,
      lead_time_in_month: 0,
      payment_terms: "",
      no_of_months: 0,
      safety_stock_in_month: 0,
      sale_quantity: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
      sale_revenu: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
    });
    appendFeedInformation({
      raw_material_description: "",
      unit_of_measure: "",
      unit_rate: 0,
      gst_on_raw_material: 0,
      transpost_cost_on_raw_material: 0,
      gst_transport: 0,
      gst_in_total: 0,
      per_unit_cost_of_raw_material: 0,
      quantity_required_for_batch_size: 0,
      cost_per_batch: 0,
    });
  }, []);
  // const saleRevenueCalculation = () => {
  //   let skuData = getValues("skuData");
  //   skuData.filter((obj, index) => {
  //     obj.sale_quantity.aggregate[1] = 0;
  //     obj.sale_revenu.aggregate[1] = 0;
  //     for (const item in obj.sale_quantity.details) {
  //       obj.sale_revenu.details[item] = parseFloat(obj.sale_quantity.details[item]) * parseFloat(obj.price_per_unit);
  //       obj.sale_quantity.aggregate[1] += parseFloat(obj.sale_quantity.details[item]);
  //       obj.sale_revenu.aggregate[1] += parseFloat(obj.sale_revenu.details[item]);
  //       obj.sale_revenu.aggregate[2] = parseFloat(obj.sale_quantity.aggregate[2]) * parseFloat(obj.price_per_unit);
  //       obj.sale_revenu.aggregate[3] = parseFloat(obj.sale_quantity.aggregate[3]) * parseFloat(obj.price_per_unit);
  //       totalSaleRevenuCalculation(skuData, item, "byeProduct");
  //     }
  //   })
  //   setValue("skuData", skuData);
  // };
  const calculateAnimalProduce = (value, field, index, item, isNumber) => {
      var obj = item;
    // console.log(obj)
    value = isNumber ? (value.target ? parseFloat(value.target.value.replace(/,/g, "")) : parseFloat(value.replace(/,/g, ""))) : value;
    obj[field] = value
    updateAnimalProduce(index, obj);
    saleRevenueCalculation1()
  };
  const saleRevenueCalculation1 = () => {
    let produceAnimalData = getValues("produceAnimal");
    let basicInformationData = getValues("basicInformation");
    console.log(produceAnimalData)
    produceAnimalData.filter((obj, index) => {
      for (const month in obj.sale_quantity.details) {
        if (month) {
          console.log(obj);
          obj.sale_revenu.details[month] = parseFloat(obj.sale_quantity.details[month]) * parseFloat(obj.price_per_unit);
          console.log(obj.sale_quantity.details[month],obj.price_per_unit )
          obj.sale_quantity.aggregate[1] = 0;
          obj.sale_revenu.aggregate[1] = 0;
          for (const month in obj.sale_quantity.details) {
            obj.sale_quantity.aggregate[1] += parseFloat(obj.sale_quantity.details[month]);
            obj.sale_revenu.aggregate[1] += parseFloat(obj.sale_revenu.details[month]);
          }
        }
        obj.sale_revenu.aggregate[2] = parseFloat(obj.sale_quantity.aggregate[2]) * parseFloat(obj.price_per_unit);
        obj.sale_revenu.aggregate[3] = parseFloat(obj.sale_quantity.aggregate[3]) * parseFloat(obj.price_per_unit);
        totalSaleRevenuCalculation(produceAnimalData, month, "animalProduce");
        basicInformationData[0].details[month].sales_revenue = totalSaleRevenu["animalProduce"].details[month];
      }
    });
    basicInformationData[0].aggregate[1].sales_revenue = totalSaleRevenu["animalProduce"].aggregate[1];
    basicInformationData[0].aggregate[2].sales_revenue = totalSaleRevenu["animalProduce"].aggregate[2];
    basicInformationData[0].aggregate[3].sales_revenue = totalSaleRevenu["animalProduce"].aggregate[3];
    setValue("produceAnimal", produceAnimalData);
    setValue("basicInformation", basicInformationData);
  };
  const totalSaleRevenuCalculation = (obj, month, fieldName) => {
    totalSaleRevenu[fieldName].details[month] = 0;
    totalSaleRevenu[fieldName].aggregate[1] = 0;
    totalSaleRevenu[fieldName].aggregate[2] = 0;
    totalSaleRevenu[fieldName].aggregate[3] = 0;
    obj.filter((item, index) => {
      totalSaleRevenu[fieldName].details[month] += item.sale_revenu.details[month];
      totalSaleRevenu[fieldName].aggregate[1] += item.sale_revenu.aggregate[1];
      totalSaleRevenu[fieldName].aggregate[2] += item.sale_revenu.aggregate[2];
      totalSaleRevenu[fieldName].aggregate[3] += item.sale_revenu.aggregate[3];
    });
  };
  const calculateGrowAndSaleAnimals = () => {
    let obj = getValues("basicInformation");
    let total_cost_per_batch_value = getValues("rawMaterial")
      .map((x, i) => x.cost_per_batch)
      .reduce((a, b) => a + b, 0);
    let growth_period = obj[0].growth_period ? parseFloat(obj[0].growth_period) : 0;
    let mortality_rate = obj[0].mortality_rate ? parseFloat(obj[0].mortality_rate) / 100 : 0;
    for (let i = 1; i <= growth_period; i++) {
      obj[0].details[i].animals_sold = 0;
    }
    let yearly_qty_to_be_purchased = 0;
    let yearly_animals_sold = 0;
    let yearly_unit_price = 0;
    let yearly_qty_to_be_fed = 0;
    let yearly_acquistion_cost = 0;
    for (const month in obj[0].details) {
      const qty_to_be_purchased = parseFloat(obj[0].details[month].qty_to_be_purchased ? obj[0].details[month].qty_to_be_purchased : 0);
      const cumulative_purchase = month == 1 ? 0 : parseFloat(obj[0].details[month - 1].cumulative_purchase);
      obj[0].details[month].cumulative_purchase = qty_to_be_purchased + cumulative_purchase;
      let mortality_value = Math.round(obj[0].details[month].cumulative_purchase * mortality_rate);
      obj[0].details[month].sale_possible = parseFloat(growth_period) < month ? obj[0].details[month - parseFloat(growth_period)].cumulative_purchase - mortality_value : 0;
      const cumulative_sales = month == 1 ? 0 : parseFloat(obj[0].details[month - 1].cumulative_sales);
      const animals_sold = parseFloat(obj[0].details[month].animals_sold ? obj[0].details[month].animals_sold : 0);
      obj[0].details[month].cumulative_sales = (animals_sold + cumulative_sales);
      if (getValues("natureofBusiness") === "gsa_byeProduct") {
        obj[0].details[month].sales_revenu = animals_sold  * parseFloat(obj[0].sale_price);
        obj[0].details[month].sales_revenue = animals_sold  * parseFloat(obj[0].sale_price);
        console.log(animals_sold,obj[0].quantity_of_yield, obj[0].sale_price )
        console.log(obj);
        obj[0].details[month].qty_to_be_fed = parseFloat(obj[0].details[month].cumulative_purchase) - parseFloat(obj[0].details[month].cumulative_sales) - mortality_value;
        obj[0].details[month].acquistion_cost = qty_to_be_purchased * parseFloat(obj[0].unit_price_purchase);
        obj[0].details[month].feed_cost = parseFloat(obj[0].details[month].qty_to_be_fed) * parseFloat(total_cost_per_batch_value);
      } else {
        obj[0].details[month].qty_to_be_fed = parseFloat(obj[0].details[month].cumulative_purchase);
        obj[0].details[month].acquistion_cost = qty_to_be_purchased * parseFloat(obj[0].details[month].unit_price);
        obj[0].details[month].feed_cost = Math.round(parseFloat(obj[0].details[month].cumulative_purchase) * parseFloat(total_cost_per_batch_value));
      }
      errorGrowAndSale.details[month] = obj[0].details[month].sale_possible - obj[0].details[month].cumulative_sales < 0 ? true : false;
      yearly_qty_to_be_purchased += qty_to_be_purchased;
      yearly_animals_sold += animals_sold;
      yearly_unit_price += parseFloat(obj[0].details[month].unit_price);
      yearly_qty_to_be_fed += parseFloat(obj[0].details[month].qty_to_be_fed);
      yearly_acquistion_cost += parseFloat(obj[0].details[month].acquistion_cost);
    }
    for (const year in obj[0].aggregate) {
      const year_qty_to_be_purchased = parseFloat(obj[0].aggregate[year].qty_to_be_purchased ? obj[0].aggregate[year].qty_to_be_purchased : 0);
      if (year == 1) {
        obj[0].aggregate[year].qty_to_be_purchased = yearly_qty_to_be_purchased;
        obj[0].aggregate[year].cumulative_purchase = yearly_qty_to_be_purchased;
        obj[0].aggregate[year].sale_possible = obj[0].details[12].sale_possible;
        obj[0].aggregate[year].animals_sold = yearly_animals_sold;
        obj[0].aggregate[year].cumulative_sales = yearly_animals_sold;
        obj[0].aggregate[year].qty_to_be_fed = Math.round(yearly_qty_to_be_fed / 12);
        obj[0].aggregate[year].acquistion_cost = yearly_acquistion_cost;
        obj[0].aggregate[year].unit_price = Math.round(yearly_acquistion_cost / yearly_qty_to_be_purchased);
      } else {
        obj[0].aggregate[year].cumulative_purchase = parseFloat(obj[0].aggregate[year - 1].cumulative_purchase) + year_qty_to_be_purchased;
        if (getValues("natureofBusiness") === "gsa_byeProduct") {
          obj[0].aggregate[year].acquistion_cost = Math.round(year_qty_to_be_purchased * parseFloat(obj[0].unit_price_purchase));
        } else {
          obj[0].aggregate[year].acquistion_cost = Math.round(year_qty_to_be_purchased * parseFloat(obj[0].aggregate[year].unit_price));
        }
      }
      obj[0].aggregate[year].feed_cost = Math.round(parseFloat(obj[0].aggregate[year].qty_to_be_fed) * 12 * parseFloat(total_cost_per_batch_value));
      obj[0].aggregate[year].sales_revenu = parseFloat(obj[0].aggregate[year].animals_sold)  * parseFloat(obj[0].sale_price);
      obj[0].aggregate[year].sales_revenue = parseFloat(obj[0].aggregate[year].animals_sold)  * parseFloat(obj[0].sale_price);
      errorGrowAndSale.aggregate[year] = parseFloat(obj[0].aggregate[year].sale_possible) - parseFloat(obj[0].aggregate[year].cumulative_sales) < 0 ? true : false;
    }
    setErrorGrowAndSale(errorGrowAndSale);
    setValue("basicInformation", obj);
  };
  const setFieldArrayValue = (value, field, index, item, isNumber) => {
      var obj = item;
    // console.log(obj)
    value = isNumber ? (value.target ? parseFloat(value.target.value.replace(/,/g, "")) : parseFloat(value.replace(/,/g, ""))) : value;
    obj[field] = value
    if (natureofBusiness === "gsa_byeProduct") {
      updateBasicInformation(index, obj);
    }
    calculateGrowAndSaleAnimals();
  };
  const setSkuFieldArrayValue = (value, field, index, item, isNumber) => {
    var obj = item;
    obj[field] = isNumber ? parseInt(value) : value;
    updateSku(index, obj);
  };
  const handleAddProduce = (e) => {
    e.preventDefault();
    const animalItem = {
      name_of_produce: "",
      quantity_per_land: 0,
      unit_of_measure: "",
      unit_sale_price: 0,
      unit_of_time: "",
      shelf_life: 0,
      unit_of_time_shelf_life: "",
      inactive_period: 0,
      unit_of_time_inactive_period: "",
      minimum_order_qty: 0,
      lead_time_in_month: "",
      payment_terms: "",
      no_of_months: 0,
      safety_stock_in_month: 0,
      sale_quantity: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
      sale_revenu: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
    };
    appendAnimalProduce(animalItem);
  };
  const handleAddFeedInformation = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      raw_material_description: "",
      unit_of_measure: "",
      unit_rate: 0,
      gst_on_raw_material: 0,
      transpost_cost_on_raw_material: 0,
      gst_transport: 0,
      gst_in_total: 0,
      per_unit_cost_of_raw_material: 0,
      quantity_required_for_batch_size: 0,
      cost_per_batch: 0,
    };
    appendFeedInformation(rawMaterialItem);
  };
  const handleAddSku = (e) => {
    const skuItem = {
      sku_description: "",
      sku_quantity: 0,
      sku_unit_of_measure: "",
      sku_unit_of_time: "",
      price_per_unit: 0,
      packing: [
        {
          level: "",
          quantity: "0",
          total_packing_cost: "0",
          unit_cost: "0",
          gst_in_total: "0",
        }
      ],
      sale_quantity: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
      sale_revenu: {
        details: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
    };
    appendSku(skuItem);
  };
  const onRemoveFeedInformation = (i) => {
    removeFeedInformation(i);
    calcuateTotalFeedCostPerAnimal();
  };
  const onRemoveAnimalProduce = (i) => {
    removeAnimalProduce(i);
    calcuateTotalFeedCostPerAnimal();
  };
  const updateErrorRawMaterial = () => {
    let rawMaterial = [];
    var valueArr = getValues("rawMaterial").map(function (item) {
      return item.raw_material_description;
    });
    valueArr.filter((item, idx) => {
      if (valueArr.indexOf(item) != idx) {
        rawMaterial[idx] = true;
      } else {
        rawMaterial[idx] = false;
      }
    });
    setErrorRawMaterial(rawMaterial);
  };
  const setSalesCompositionPercent = (e, field) => {
    setErrorInTotal(false);
    var obj = compositionOfSales;
    let prev = obj[field].value;
    obj[field].value = e.target.value ? parseFloat(e.target.value) : 0;
    let total = parseFloat(obj.Cash.value) + parseFloat(obj.Card.value) + parseFloat(obj.Credit.value) + parseFloat(obj.Advance.value);
    if (total != 100) {
      setErrorInTotal(true);
    }
    if (total > 100) {
      e.target.value = prev;
      obj[field].value = prev;
      return;
    }
    updateCompositionTotals(obj, total, field);
  };
  const updateCompositionTotals = (obj, total, field) => {
    setCompositionOfSales(obj);
    setTotalPercent(total);
  };
  const calculateFeedInformation = (field, value, index, item, isNumber) => {
    var obj = item;
    value = isNumber ? parseFloat(value) : value;
    obj[field] = value;
    obj.per_unit_cost_of_raw_material = parseFloat(obj.unit_rate) + parseFloat(obj.transpost_cost_on_raw_material) + (parseFloat(obj.gst_on_raw_material) * parseFloat(obj.unit_rate)) / 100 + (parseFloat(obj.gst_transport) * parseFloat(obj.transpost_cost_on_raw_material)) / 100;
    obj.gst_in_total = (parseFloat(obj.gst_on_raw_material) * parseFloat(obj.unit_rate)) / 100 + (parseFloat(obj.gst_transport) * parseFloat(obj.transpost_cost_on_raw_material)) / 100;
    obj.cost_per_batch = parseFloat(obj.per_unit_cost_of_raw_material) * parseFloat(obj.quantity_required_for_batch_size);
    updateFeedInformation(index, obj);
    calcuateTotalFeedCostPerAnimal();
  };
  const calcuateTotalFeedCostPerAnimal = () => {
    let total_cost_per_batch_value = getValues("rawMaterial")
      .map((x, i) => {
        return x.cost_per_batch;
      })
      .reduce((a, b) => a + b, 0);
    setTotalFeedCostPerUnit(total_cost_per_batch_value ? total_cost_per_batch_value : 0);
    calculateGrowAndSaleAnimals();
  };
  const onSubmit = (data) => {
    setIsLoader(true);
    if (location.state.agroId) {
      axios
        .post(`${ApiUrl}agro/create`, {
          protegeId: location.state.id,
          agroId: location.state.agroId,
          productList: [
            {
              ...data,
              rawMaterialData: {
                rawMaterial: data.rawMaterial
              },
              compositionOfSales,
              animalName: animalName,
              natureofBusiness: natureofBusiness,             
            },
          ],
        })
        .then((response) => {
          console.log(response.data);
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      axios
        .post(`${ApiUrl}agro/create`, {
          protegeId: location.state.id,
          productList: [
            {
              ...data,
              rawMaterialData: {
                rawMaterial: data.rawMaterial
              },
              compositionOfSales,
              animalName: animalName,
              natureofBusiness: natureofBusiness,
            },
          ],
        })
        .then((response) => {
          console.log(response.data);
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const onUpdateSubmit = (data) => {
    console.log("update data", data);
    setIsLoader(true);
    // console.log(growAndShellArray);
    axios
      .put(`${ApiUrl}agro/update/${productId}/${location.state.agroId}`, {
        // protegeId: location.state.id,
        ...data,
        rawMaterialData: {
          rawMaterial: data.rawMaterial
        },
        compositionOfSales,
        animalName: animalName,
        natureofBusiness: natureofBusiness,
      })
      .then((response) => {
        console.log(response.data);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Agro
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form id="productOne" onSubmit={handleSubmit(op == "Edit" ? onUpdateSubmit : onSubmit)}>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Nature of Business
                  </label>
                  <select className={`form-select ${errors.natureofbusiness ? "errorBorder" : ""}`} aria-label="Default select example" {...register("natureofBusiness")} onChange={(e) => setNatureofBusiness(e.target.value)} value={natureofBusiness}>
                    <option value="gsa_byeProduct">Grow & Sell</option>
                    <option value="sap_byeProduct">Sell Agro Produce</option>
                  </select>
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Crop Name
                  </label>
                  <input type="text" className="form-control"  required {...register("product_name", {
                                      onChange: (e) => {
                                        setAnimalName(e.target.value)
                                      },
                                      required: true
                  })
                                    } />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                  Describe crop relationship and Bye Product
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("brief_manufacture_product")}
                  ></textarea>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Explain the process for each crop (Gestation period, Cycle, Yield, Mortality of Crop)
                  </label>
                  <textarea
                    className={`autoResize form-control ${errors.physical_nature_of_product ? "errorBorder" : ""
                      }`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("physical_nature_of_product")}
                  ></textarea>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    List the Machineries/Tools/Equipments needed
                  </label>
                  <textarea
                    className={` autoResize form-control ${errors.machine_used_in_measure_process ? "errorBorder" : ""
                      }`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("machine_used_in_measure_process")}
                  ></textarea>
                </div>
                <div className="col-md-3 mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Size of Land
                  </label>
                  <input
                    type="number" step="any"
                    className={`form-control ${errors.batch_size
                      ? "errorBorder"
                      : ""
                      }`}
                    id="exampleFormControlInput1"
                    {...register(`batch_size`)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Unit of Measure
                  </label>
                  <select
                    className={`form-select ${errors.unit_of_measure
                      ? "errorBorder"
                      : ""
                      }`}
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                    {...register(
                      `unit_of_measure`,
                    )}
                  >
                    <option value="">Select</option>
                    <option value="Feet">Sq. Feet</option>
                    <option value="Meter">Sq. Meter</option>
                    <option value="Gunta">Gunta</option>
                    <option value="Acre">Acre</option>
                    <option value="Hectare">Cm</option>
                  </select>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-12 mb-3">
                  <strong>Composition of sale</strong>
                </div>
                <div className="col-md-12 bgwhite">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>Terms of Payment</th>
                          <th>% age</th>
                          <th>No Of Months</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                        <tr>
                          <td>Cash</td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="percent_cash" {...register("cash_details")} onChange={(e) => setSalesCompositionPercent(e, "Cash")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="noOfMonths_cash" {...register("noOfMonths_cash")} readOnly />
                          </td>
                        </tr>
                        <tr>
                          <td>Credit</td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="percent_credit" {...register("percent_credit")} onChange={(e) => setSalesCompositionPercent(e, "Credit")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="noOfMonths_Credit" {...register("noOfMonths_Credit")} />
                          </td>
                        </tr>
                        <tr>
                          <td>Credit Card</td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="percent_card" {...register("percent_card")} onChange={(e) => setSalesCompositionPercent(e, "Card")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="noOfMonths_Card" {...register("noOfMonths_Card")} />
                          </td>
                        </tr>
                        <tr>
                          <td>Advance</td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="percent_Advance" {...register("percent_Advance")} onChange={(e) => setSalesCompositionPercent(e, "Advance")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="noOfMonths_Advance" {...register("noOfMonths_Advance")} />
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <input type="number" step="any" className="form-control md-width" id="totalpercent" value={totalPercent} disabled readOnly />
                            {errorInTotal && <span className="text-danger">Total Should Not Exceed 100%</span>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
              {(natureofBusiness === "gsa_byeProduct" || natureofBusiness === "gsa") && (
                <div className="row">
                  <div className="col-md-12 mb-3 mt-4">
                    <strong>Basic Information - Seed & Produce</strong>
                  </div>
                  <div className="table-responsive">
                    {fieldsBasicInformation && fieldsBasicInformation.map((x, i) => {
                      return (
                        <>
                          <table className="table table-bordered">
                            <thead className="table-light">
                              <tr>
                                <th>Description</th>
                                <th>Unit of Measure</th>
                                <th>Unit Purchase Price</th>
                                <th>Growth Period in Month</th>
                                <th>Produce Description</th>
                                <th>Total Produce per crop cycle</th>
                                <th>Unit of Measure (Produce)</th>
                                <th>Sale Price</th>
                                <th>Unit of Measure (Sales Price)</th>
                                <th>Harvesting Period</th>
                                <th>Unit of Measure time</th>
                                <th>Mortality Rate %</th>
                                <th>Minimum Order Qty</th>
                                <th>Lead Time in Month</th>
                                <th>Payment Terms</th>
                                <th>No. of Months</th>
                                <th>Safety Stock in Month</th>
                              </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                              <tr key={i}>
                              <td>
                                  <input
                                    type="text"
                                    className={`form-control lg-width`}
                                    {...register(`basicInformation.${i}.bi_description`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "bi_description", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <select
                                    className={`form-select ${errors.unit_of_measure ? "errorBorder" : ""}`}
                                    aria-label="Default select example"
                                    {...register(`basicInformation.${i}.unit_of_measure`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "unit_of_measure", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="kg">Kg</option>
                                    <option value="gm">Gms</option>
                                    <option value="lt">Litre</option>
                                    <option value="ml">Millilitre</option>
                                    <option value="Millilitre">Cm</option>
                                    <option value="Metre">Metre</option>
                                    <option value="Lot">Lot</option>
                                    <option value="Set">Set</option>
                                    <option value="Batch">Batch</option>
                                    <option value="Month">Month</option>
                                    <option value="Dozen">Dozen</option>
                                    <option value="Person">Person</option>
                                    <option value="Piece">Piece</option>
                                    <option value="kWh">kWh</option>
                                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                    <option value="event">Event</option>
<option value="service">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.unit_price_purchase`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "unit_price_purchase", i, x, true);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.growth_period`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "growth_period", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={`form-control lg-width`}
                                    {...register(`basicInformation.${i}.yield_description`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "yield_description", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.quantity_of_yield`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "quantity_of_yield", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <select
                                    className={`form-select ${errors.unit_of_measure_yield ? "errorBorder" : ""}`}
                                    aria-label="Default select example"
                                    {...register(`basicInformation.${i}.unit_of_measure_yield`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "unit_of_measure_yield", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="kg">Kg</option>
                                    <option value="gm">Gms</option>
                                    <option value="lt">Litre</option>
                                    <option value="ml">Millilitre</option>
                                    <option value="Millilitre">Cm</option>
                                    <option value="Metre">Metre</option>
                                    <option value="Lot">Lot</option>
                                    <option value="Set">Set</option>
                                    <option value="Batch">Batch</option>
                                    <option value="Month">Month</option>
                                    <option value="Dozen">Dozen</option>
                                    <option value="Person">Person</option>
                                    <option value="Piece">Piece</option>
                                    <option value="kWh">kWh</option>
                                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                    <option value="event">Event</option>
<option value="service">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.sale_price`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sale_price", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <select
                                    className={`form-select ${errors.unit_of_measure_sales_price ? "errorBorder" : ""}`}
                                    aria-label="Default select example"
                                    {...register(`basicInformation.${i}.unit_of_measure_sales_price`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "unit_of_measure_sales_price", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="kg">Kg</option>
                                    <option value="gm">Gms</option>
                                    <option value="lt">Litre</option>
                                    <option value="ml">Millilitre</option>
                                    <option value="Millilitre">Cm</option>
                                    <option value="Metre">Metre</option>
                                    <option value="Lot">Lot</option>
                                    <option value="Set">Set</option>
                                    <option value="Batch">Batch</option>
                                    <option value="Month">Month</option>
                                    <option value="Dozen">Dozen</option>
                                    <option value="Person">Person</option>
                                    <option value="Piece">Piece</option>
                                    <option value="kWh">kWh</option>
                                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                    <option value="event">Event</option>
<option value="service">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.harvesting_period`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "harvesting_period", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                    <select
                                      className={`form-select ${errors.unit_of_time_harvesting_period ? "errorBorder" : ""}`}
                                      aria-label="Default select example"
                                      {...register(`basicInformation.${i}.unit_of_time_harvesting_period`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_time_harvesting_period", i, x, false);
                                        },
                                      })}
                                    >
                                      <option defaultValue="">Select</option>
                                      <option value="week">Week</option>
                                      <option value="month">Month</option>
                                      <option value="year">Year</option>
                                    </select>
                                  </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.mortality_rate`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "mortality_rate", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.minimum_order_qty`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "minimum_order_qty", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.lead_time_in_month`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "lead_time_in_month", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <select
                                    className={`form-select`}
                                    aria-label="Default select example"
                                    {...register(`basicInformation.${i}.payment_terms`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "payment_terms", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="cash">Cash</option>
                                    <option value="credit">Credit</option>
                                    <option value="creditCard">Credit Card</option>
                                    <option value="advance">advance</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    disabled={x.payment_terms == "credit" ? false : true}
                                    numformat="numberRight"
                                    className="form-control sl-width"
                                    {...register(`basicInformation.${i}.no_of_cash_in_months`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "no_of_cash_in_months", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    step="any"
                                    className={`form-control md-width`}
                                    {...register(`basicInformation.${i}.safety_stock_in_month`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "unit_of_measure_salesafety_stock_in_months_price", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
              {(natureofBusiness === "sap_byeProduct") && (
                <div className="row">
                  <div className="col-md-6 mb-3 mt-4">
                    <strong>Basic Information</strong>
                  </div>
                  <div className="col-md-6 mb-3 mt-4">
                    <button type="button" className="btn btn-primary me-2 mb-2 float-end" onClick={(e) => handleAddProduce(e)}>
                      Add Produce
                    </button>
                  </div>
                  <div className="table-responsive">
                    <>
                      <table className="table table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th>Name of the produce</th>
                            <th>Total Produce per crop cycle</th>
                            <th>Produce Unit of Measure</th>
                            <th>Unit Sale Price </th>
                            <th>Crop Cycle in Months</th>
                            <th>Shelf Life</th>
                            <th>Unit of time (Shelf Life)</th>
                            <th>Harvesting Period</th>
                            <th>Unit of time (Harvesting Period)</th>
                            <th>Minimum Order Qty</th>
                            <th>Lead Time in Month</th>
                            <th>Payment Terms</th>
                            <th>No. of Months</th>
                            <th>Safety Stock in Month</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {fieldsAnimalProduce &&
                            fieldsAnimalProduce.map((x, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <input
                                      type="text"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].name_of_the_produce ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.name_of_the_produce`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "name_of_the_produce", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].quantity_per_land ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.quantity_per_land`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "quantity_per_land", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className={`form-select ${errors.unit_of_measure ? "errorBorder" : ""}`}
                                      aria-label="Default select example"
                                      {...register(`produceAnimal.${i}.unit_of_measure`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_measure", i, x, false);
                                        },
                                      })}
                                    >
                                      <option value="">Select</option>
                                      <option value="kg">Kg</option>
                                      <option value="gm">Gms</option>
                                      <option value="lt">Litre</option>
                                      <option value="ml">Millilitre</option>
                                      <option value="Millilitre">Cm</option>
                                      <option value="Metre">Metre</option>
                                      <option value="Lot">Lot</option>
                                      <option value="Set">Set</option>
                                      <option value="Batch">Batch</option>
                                      <option value="Month">Month</option>
                                      <option value="Dozen">Dozen</option>
                                      <option value="Person">Person</option>
                                      <option value="Piece">Piece</option>
                                      <option value="kWh">kWh</option>
                                      <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                      

                                      <option value="event">Event</option>
<option value="service">Service</option>

                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].unit_sale_price ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.price_per_unit`, {
                                        onChange: (e) => {
                                          calculateAnimalProduce(e.target.value, "price_per_unit", i, x, true);
                                        },
                                      })}
                                    />
                                  </td>
                                  {/* <td>
                                    <select
                                      className={`form-select ${errors.unit_of_time ? "errorBorder" : ""}`}
                                      aria-label="Default select example"
                                      {...register(`produceAnimal.${i}.unit_of_time`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_time", i, x, false);
                                        },
                                      })}
                                    >
                                      <option defaultValue="">Select</option>
                                      <option value="1">Hour</option>
                                      <option value="2">Day</option>
                                      <option value="3">Shift (8 hours)</option>
                                      <option value="4">Month</option>
                                    </select>
                                  </td> */}
                                  <td>
                                  <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].unit_of_time ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.unit_of_time`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_time", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].shelf_life ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.shelf_life`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "shelf_life", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className={`form-select ${errors.unit_of_time_shelf_life ? "errorBorder" : ""}`}
                                      aria-label="Default select example"
                                      {...register(`produceAnimal.${i}.unit_of_time_shelf_life`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_time_shelf_life", i, x, false);
                                        },
                                      })}
                                    >
                                      <option defaultValue="">Select</option>
                                      <option value="1">Hour</option>
                                      <option value="2">Day</option>
                                      <option value="3">Shift (8 hours)</option>
                                      <option value="4">Month</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].inactive_period ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.inactive_period`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "inactive_period", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className={`form-select ${errors.unit_of_time_inactive_period ? "errorBorder" : ""}`}
                                      aria-label="Default select example"
                                      {...register(`produceAnimal.${i}.unit_of_time_inactive_period`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "unit_of_time_inactive_period", i, x, false);
                                        },
                                      })}
                                    >
                                      <option defaultValue="">Select</option>
                                      <option value="1">Hour</option>
                                      <option value="2">Day</option>
                                      <option value="3">Shift (8 hours)</option>
                                      <option value="4">Month</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].minimum_order_qty ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.minimum_order_qty`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "minimum_order_qty", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].lead_time_in_month ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.lead_time_in_month`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "lead_time_in_month", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className={`form-select`}
                                      aria-label="Default select example"
                                      {...register(`produceAnimal.${i}.payment_terms`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "payment_terms", i, x, false);
                                        },
                                      })}
                                    >
                                      <option value="">Select</option>
                                      <option value="cash">Cash</option>
                                      <option value="credit">Credit</option>
                                      <option value="creditCard">Credit Card</option>
                                      <option value="advance">advance</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      disabled={x.payment_terms == "credit" ? false : true}
                                      numformat="numberRight"
                                      className="form-control sl-width"
                                      {...register(`produceAnimal.${i}.no_of_months`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "no_of_months", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className={`form-control md-width ${errors.produceAnimal && errors.produceAnimal[i] && errors.produceAnimal[i].safety_stock_in_month ? "errorBorder" : ""}`}
                                      {...register(`produceAnimal.${i}.safety_stock_in_month`, {
                                        onChange: (e) => {
                                          setFieldArrayValue(e.target.value, "safety_stock_in_month", i, x, false);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td className="text-center cursor-pointer">
                                    <MdDelete
                                      size={20}
                                      color="red"
                                      onClick={() => {
                                        onRemoveAnimalProduce(i, x);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 mb-3 mt-4">
                  <strong>Input Information</strong>
                </div>
                <div className="col-md-6 mb-3 mt-4">
                  <button type="button" className="btn btn-primary me-2 mb-2 float-end" onClick={(e) => handleAddFeedInformation(e)}>
                  Add Input
                  </button>
                </div>
                <div className="col-md-12">
                  <div className="table-responsive">
                    <>
                      <table className="table table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th>S No</th>
                            <th>Material</th>
                            <th>Total Unit Cost</th>
                            <th>Unit of Measure</th>
                            <th>{natureofBusiness === "gsa_byeProduct" ? "Qty required per piece per month" : "Qty per unit land per crop cycle"}</th>
                            <th>{natureofBusiness === "gsa_byeProduct" ? "Value per piece per month" : "Value per unit land per crop cycle"}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {fieldsRawMaterial &&
                            fieldsRawMaterial.map((x, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <select
                                      className={`form-select lg-width col-md-2 ${errorRawMaterial[i] ? "errorBorder" : ""}`}
                                      id="exampleFormControlSelect1"
                                      aria-label="Default select example"
                                      value={fieldsRawMaterial[i].raw_material_description}
                                      required
                                      {...register(`rawMaterial.${i}.raw_material_description`, {
                                        onChange: (e) => {
                                          setRawMaterialValue(e.target.value, i, x, rawMaterialName);
                                          calculateFeedInformation("raw_material_description", e.target.value, i, x, false);
                                          updateErrorRawMaterial();
                                        }
                                      })}
                                    >
                                      <option value="">Select</option>
                                      {rawMaterialName.map((y) => {
                                        return <option value={y.raw_material_description}>{y.raw_material_description}</option>;
                                      })}
                                    </select>
                                    {errorRawMaterial[i] && (
                                      <span className="textRed" style={{ color: "red" }}>
                                        *Already the material is added.{" "}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className={`form-control`}
                                      disabled
                                      value={rawMaterialName.filter((y) => y.raw_material_description === x.raw_material_description).map((item) => getNumericFormat(item.per_unit_cost_of_raw_material))}
                                      id="exampleFormControlInput1"
                                    />
                                  </td>
                                  <td>
                                    <select className="form-select md-width" id="exampleFormControlSelect1" disabled value={fieldsRawMaterial[i].unit_of_measure} aria-label="Default select example" {...register(`rawMaterial.${i}.unit_of_measure`)}>
                                      <option value="">Select</option>
                                      <option value="kg">Kg</option>
                                      <option value="gm">Gms</option>
                                      <option value="lt">Litre</option>
                                      <option value="ml">Millilitre</option>
                                      <option value="Millilitre">Cm</option>
                                      <option value="Metre">Metre</option>
                                      <option value="Lot">Lot</option>
                                      <option value="Set">Set</option>
                                      <option value="Batch">Batch</option>
                                      <option value="Month">Month</option>
                                      <option value="Dozen">Dozen</option>
                                      <option value="Person">Person</option>
                                      <option value="Piece">Piece</option>
                                      <option value="kWh">kWh</option>
                                      <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                      <option value="event">Event</option>
<option value="service">Service</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      numformat="numberRight"
                                      className={`form-control ${errors.rawMaterial && errors.rawMaterial[i] && errors.rawMaterial[i].quantity_required_for_batch_size ? "errorBorder" : ""}`}
                                      value={fieldsRawMaterial[i].quantity_required_for_batch_size}
                                      id="exampleFormControlInput1"
                                      {...register(`rawMaterial.${i}.quantity_required_for_batch_size`, {
                                        onChange: (e) => {
                                          calculateFeedInformation("quantity_required_for_batch_size", e.target.value, i, x, true);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td style={{ textAlign: "right" }}>{getNumericFormat(x.cost_per_batch)}</td>
                                  <td className="text-center cursor-pointer">
                                    <MdDelete
                                      size={20}
                                      color="red"
                                      onClick={() => {
                                        onRemoveFeedInformation(i, x);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan="5">
                              <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                              {natureofBusiness === "gsa_byeProduct" ? "Total Feed Cost Per piece per month" : "Total Feed Cost Per Crop Cycle"}
                              </label>
                            </td>
                            <td colSpan="1">
                              <input type="text" disabled className="form-control md-width" value={totalFeedCostPerUnit ? getNumericFormat(totalFeedCostPerUnit) : 0} {...register(getNumericFormat(`total_feed_cost_per_unit`))} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  </div>
                </div>
              </div>
              {(natureofBusiness === "gsa_byeProduct" || natureofBusiness === "gsa") && (
                <div className="row">
                  <div className="col-md-6 mb-3 mt-4">
                    <strong>Acquisition, Growth & Sales</strong>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      {fieldsBasicInformation.map((x, i) => {
                        return (
                          <>
                            <table className="table table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th className='freez-column'>Months</th>
                                  <th>1</th>
                                  <th>2</th>
                                  <th>3</th>
                                  <th>4</th>
                                  <th>5</th>
                                  <th>6</th>
                                  <th>7</th>
                                  <th>8</th>
                                  <th>9</th>
                                  <th>10</th>
                                  <th>11</th>
                                  <th>12</th>
                                  <th>Year 1</th>
                                  <th>Year 2</th>
                                  <th>Year 3</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                <tr>
                                  <td className='md-width freez-column'>Seed Qty Purchased</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[1].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[2].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[3].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[4].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[5].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[6].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[7].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[8].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[9].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[10].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[11].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[12].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].qty_to_be_purchased)} {...register(`basicInformation.${i}.aggregate[1].qty_to_be_purchased`)} />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[2].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[3].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Cumulative Purchase</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].cumulative_purchase)} {...register(`basicInformation.${i}.details[1].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].cumulative_purchase)} {...register(`basicInformation.${i}.details[2].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].cumulative_purchase)} {...register(`basicInformation.${i}.details[3].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].cumulative_purchase)} {...register(`basicInformation.${i}.details[4].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].cumulative_purchase)} {...register(`basicInformation.${i}.details[5].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].cumulative_purchase)} {...register(`basicInformation.${i}.details[6].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].cumulative_purchase)} {...register(`basicInformation.${i}.details[7].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].cumulative_purchase)} {...register(`basicInformation.${i}.details[8].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].cumulative_purchase)} {...register(`basicInformation.${i}.details[9].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].cumulative_purchase)} {...register(`basicInformation.${i}.details[10].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].cumulative_purchase)} {...register(`basicInformation.${i}.details[11].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].cumulative_purchase)} {...register(`basicInformation.${i}.details[12].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[1].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[2].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[3].cumulative_purchase`)} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Maximum Sale Possible Cum</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].sale_possible)} {...register(`basicInformation.${i}.details[1].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].sale_possible)} {...register(`basicInformation.${i}.details[2].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].sale_possible)} {...register(`basicInformation.${i}.details[3].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].sale_possible)} {...register(`basicInformation.${i}.details[4].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].sale_possible)} {...register(`basicInformation.${i}.details[5].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].sale_possible)} {...register(`basicInformation.${i}.details[6].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].sale_possible)} {...register(`basicInformation.${i}.details[7].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].sale_possible)} {...register(`basicInformation.${i}.details[8].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].sale_possible)} {...register(`basicInformation.${i}.details[9].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].sale_possible)} {...register(`basicInformation.${i}.details[10].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].sale_possible)} {...register(`basicInformation.${i}.details[11].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].sale_possible)} {...register(`basicInformation.${i}.details[12].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].sale_possible)} {...register(`basicInformation.${i}.aggregate[1].sale_possible`)} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[2].sale_possible} {...register(`basicInformation.${i}.aggregate[2].sale_possible`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[3].sale_possible} {...register(`basicInformation.${i}.aggregate[3].sale_possible`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Seed & Sapling Sold</td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[1] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 1}
                                      value={fieldsBasicInformation[i].details[1].animals_sold}
                                      {...register(`basicInformation.${i}.details[1].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[2] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 2}
                                      value={fieldsBasicInformation[i].details[2].animals_sold}
                                      {...register(`basicInformation.${i}.details[2].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[3] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 3}
                                      value={fieldsBasicInformation[i].details[3].animals_sold}
                                      {...register(`basicInformation.${i}.details[3].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[4] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 4}
                                      value={fieldsBasicInformation[i].details[4].animals_sold}
                                      {...register(`basicInformation.${i}.details[4].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[5] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 5}
                                      value={fieldsBasicInformation[i].details[5].animals_sold}
                                      {...register(`basicInformation.${i}.details[5].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[6] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 6}
                                      value={fieldsBasicInformation[i].details[6].animals_sold}
                                      {...register(`basicInformation.${i}.details[6].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[7] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 7}
                                      value={fieldsBasicInformation[i].details[7].animals_sold}
                                      {...register(`basicInformation.${i}.details[7].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[8] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 8}
                                      value={fieldsBasicInformation[i].details[8].animals_sold}
                                      {...register(`basicInformation.${i}.details[8].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[9] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 9}
                                      value={fieldsBasicInformation[i].details[9].animals_sold}
                                      {...register(`basicInformation.${i}.details[9].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[10] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 10}
                                      value={fieldsBasicInformation[i].details[10].animals_sold}
                                      {...register(`basicInformation.${i}.details[10].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[11] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 11}
                                      value={fieldsBasicInformation[i].details[11].animals_sold}
                                      {...register(`basicInformation.${i}.details[11].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.details[12] ? "errorBorder" : ""}`}
                                      disabled={fieldsBasicInformation.length && fieldsBasicInformation[0].growth_period >= 12}
                                      value={fieldsBasicInformation[i].details[12].animals_sold}
                                      {...register(`basicInformation.${i}.details[12].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].animals_sold)} {...register(`basicInformation.${i}.aggregate[1].animals_sold`)} />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.aggregate[2] ? "errorBorder" : ""}`}
                                      {...register(`basicInformation.${i}.aggregate[2].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className={`form-control md-width ${errorGrowAndSale.aggregate[3] ? "errorBorder" : ""}`}
                                      {...register(`basicInformation.${i}.aggregate[3].animals_sold`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Cumulative Sales</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[1].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[2].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[3].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[4].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[5].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[6].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[7].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[8].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[9].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[10].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[11].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.details[12].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].cumulative_sales)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].cumulative_sales`))} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[2].cumulative_sales} {...register(`basicInformation.${i}.aggregate[2].cumulative_sales`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[3].cumulative_sales} {...register(`basicInformation.${i}.aggregate[3].cumulative_sales`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Seed & Sapling to be Fed</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[1].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[2].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[3].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[4].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[5].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[6].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[7].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[8].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[9].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[10].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[11].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[12].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[2].qty_to_be_fed} {...register(`basicInformation.${i}.aggregate[2].qty_to_be_fed`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[3].qty_to_be_fed} {...register(`basicInformation.${i}.aggregate[3].qty_to_be_fed`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Sales Revenue</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[1].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[2].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[3].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[4].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[5].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[6].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[7].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[8].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[9].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[10].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[11].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.details[12].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[2].sales_revenu`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].sales_revenu)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[3].sales_revenu`))} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Acquistion Cost</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[1].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[2].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[3].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[4].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[5].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[6].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[7].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[8].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[9].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[10].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[11].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[12].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[2].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[3].acquistion_cost`))} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Feed Cost</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[1].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[2].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[3].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[4].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[5].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[6].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[7].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[8].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[9].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[10].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[11].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[12].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[2].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[3].feed_cost`))} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {(natureofBusiness === "sap_byeProduct") && (
                <div className="row">
                  <div className="col-md-6 mb-3 mt-4">
                    <strong>Acquisition & Production</strong>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      {fieldsBasicInformation.map((x, i) => {
                        return (
                          <>
                            <table className="table table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th className='freez-column'>Months</th>
                                  <th>1</th>
                                  <th>2</th>
                                  <th>3</th>
                                  <th>4</th>
                                  <th>5</th>
                                  <th>6</th>
                                  <th>7</th>
                                  <th>8</th>
                                  <th>9</th>
                                  <th>10</th>
                                  <th>11</th>
                                  <th>12</th>
                                  <th>Year 1</th>
                                  <th>Year 2</th>
                                  <th>Year 3</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                <tr>
                                  <td className='md-width freez-column'>Unit Price</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[1].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[2].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[3].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[4].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[5].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[6].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[7].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[8].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[9].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[10].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[11].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[12].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].unit_price)} {...register(`basicInformation.${i}.aggregate[1].unit_price`)} />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[2].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[3].unit_price`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='md-width freez-column'>Seed & Sapling to be Purchased</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[1].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[2].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[3].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[4].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[5].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[6].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[7].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[8].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[9].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[10].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[11].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.details[12].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].qty_to_be_purchased)} {...register(`basicInformation.${i}.aggregate[1].qty_to_be_purchased`)} />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[2].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`basicInformation.${i}.aggregate[3].qty_to_be_purchased`, {
                                        onChange: (e) => {
                                          calculateGrowAndSaleAnimals();
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Cumulative Purchase</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].cumulative_purchase)} {...register(`basicInformation.${i}.details[1].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].cumulative_purchase)} {...register(`basicInformation.${i}.details[2].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].cumulative_purchase)} {...register(`basicInformation.${i}.details[3].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].cumulative_purchase)} {...register(`basicInformation.${i}.details[4].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].cumulative_purchase)} {...register(`basicInformation.${i}.details[5].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].cumulative_purchase)} {...register(`basicInformation.${i}.details[6].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].cumulative_purchase)} {...register(`basicInformation.${i}.details[7].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].cumulative_purchase)} {...register(`basicInformation.${i}.details[8].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].cumulative_purchase)} {...register(`basicInformation.${i}.details[9].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].cumulative_purchase)} {...register(`basicInformation.${i}.details[10].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].cumulative_purchase)} {...register(`basicInformation.${i}.details[11].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].cumulative_purchase)} {...register(`basicInformation.${i}.details[12].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[1].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[2].cumulative_purchase`)} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].cumulative_purchase)} {...register(`basicInformation.${i}.aggregate[3].cumulative_purchase`)} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Seed & Sapling to be Fed</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[1].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[2].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[3].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[4].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[5].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[6].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[7].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[8].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[9].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[10].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[11].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.details[12].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].qty_to_be_fed)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].qty_to_be_fed`))} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[2].qty_to_be_fed} {...register(`basicInformation.${i}.aggregate[2].qty_to_be_fed`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                  <td>
                                    <input type="number" step="any" className="form-control md-width" value={fieldsBasicInformation[i].aggregate[3].qty_to_be_fed} {...register(`basicInformation.${i}.aggregate[3].qty_to_be_fed`, {
                                      onChange: (e) => {
                                        calculateGrowAndSaleAnimals();
                                      },
                                    })} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Acquistion Cost</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[1].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[2].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[3].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[4].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[5].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[6].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[7].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[8].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[9].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[10].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[11].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[12].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[2].acquistion_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].acquistion_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[3].acquistion_cost`))} />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='freez-column'>Feed Cost</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[1].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[1].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[2].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[2].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[3].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[3].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[4].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[4].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[5].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[5].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[6].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[6].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[7].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[7].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[8].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[8].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[9].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[9].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[10].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[10].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[11].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[11].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].details[12].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.details[12].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[1].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[1].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[2].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[2].feed_cost`))} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={getNumericFormat(fieldsBasicInformation[i].aggregate[3].feed_cost)} {...register(getNumericFormat(`basicInformation.${i}.aggregate[3].feed_cost`))} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 mt-4">
                    <strong>Sales Qty</strong>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th className='freez-column'>Months</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>Year 1</th>
                            <th>Year 2</th>
                            <th>Year 3</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {fieldsAnimalProduce &&
                            fieldsAnimalProduce.map((x, index) => {
                              return (
                                <tr key={index}>
                                  <td className='md-width freez-column'>{x.name_of_the_produce}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[1]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 1, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[2]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 2, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[3]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 3, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[4]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 4, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[5]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 5, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[6]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 6, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[7]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 7, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[8]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 8, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[9]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 9, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[10]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 10, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[11]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 11, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.details[12]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, 12, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_quantity.aggregate[1]} {...register(`produceAnimal.${index}.sale_quantity.aggregate[1]`)} />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.aggregate[2]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, null, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control md-width"
                                      {...register(`produceAnimal.${index}.sale_quantity.aggregate[3]`, {
                                        onChange: (e) => {
                                          saleRevenueCalculation1(x, null, index, e);
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 mt-4">
                    <strong>Sales Revenue</strong>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th className='md-width freez-column'>Months</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>Year 1</th>
                            <th>Year 2</th>
                            <th>Year 3</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {fieldsAnimalProduce &&
                            fieldsAnimalProduce.map((x, index) => {
                              return (
                                <tr key={index}>
                                  <td className='md-width freez-column'>{x.name_of_the_produce}</td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[1]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[2]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[3]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[4]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[5]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[6]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[7]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[8]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[9]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[10]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[11]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[12]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[1]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[2]} />
                                  </td>
                                  <td>
                                    <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[3]} />
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td className='md-width freez-column'>Total</td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[1]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[2]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[3]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[4]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[5]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[6]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[7]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[8]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[9]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[10]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[11]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.details[12]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.aggregate[1]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.aggregate[2]} />
                            </td>
                            <td>
                              <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.animalProduce.aggregate[3]} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {(
                <>
                  <div className="row">
                    <div className="col-md-6 mb-3 mt-4">
                      <strong>Bye Product</strong>
                    </div>
                    <div className="col-md-6 mb-3 mt-4">
                      <button type="button" className="btn btn-primary me-2 mb-2 float-end" onClick={(e) => handleAddSku(e)}>
                        Add Bye Product
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Description</th>
                              <th>Quantity</th>
                              <th>Unit of Measure</th>
                              <th>Unit of Time</th>
                              <th>Price Per Unit</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldsSku &&
                              fieldsSku.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sku_description`, {
                                          onChange: (e) => {
                                            setSkuFieldArrayValue(e.target.value, "sku_description", index, x, false);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sku_quantity`, {
                                          onChange: (e) => {
                                            setSkuFieldArrayValue(e.target.value, "sku_quantity", index, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        className={`form-select ${errors.sku_unit_of_measure ? "errorBorder" : ""}`}
                                        aria-label="Default select example"
                                        {...register(`skuData.${index}.sku_unit_of_measure`, {
                                          onChange: (e) => {
                                            setSkuFieldArrayValue(e.target.value, "sku_unit_of_measure", index, x, false);
                                          },
                                        })}
                                      >
                                        <option value="">Select</option>
                                        <option value="kg">Kg</option>
                                        <option value="gm">Gms</option>
                                        <option value="lt">Litre</option>
                                        <option value="ml">Millilitre</option>
                                        <option value="Millilitre">Cm</option>
                                        <option value="Metre">Metre</option>
                                        <option value="Lot">Lot</option>
                                        <option value="Set">Set</option>
                                        <option value="Batch">Batch</option>
                                        <option value="Month">Month</option>
                                        <option value="Dozen">Dozen</option>
                                        <option value="Person">Person</option>
                                        <option value="Piece">Piece</option>
                                        <option value="kWh">kWh</option>
                                        <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                        <option value="event">Event</option>
<option value="service">Service</option>
                                      </select>
                                    </td>
                                    <td>
                                      <select
                                        className={`form-select ${errors.sku_unit_of_time ? "errorBorder" : ""}`}
                                        aria-label="Default select example"
                                        {...register(`skuData.${index}.sku_unit_of_time`, {
                                          onChange: (e) => {
                                            setSkuFieldArrayValue(e.target.value, "sku_unit_of_time", index, x, false);
                                          },
                                        })}
                                      >
                                        <option defaultValue="">Select</option>
                                        <option value="1">Week</option>
                                        <option value="2">Month</option>
                                        <option value="3">Year</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.price_per_unit`, {
                                          onChange: (e) => {
                                            setSkuFieldArrayValue(e.target.value, "price_per_unit", index, x, false);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td className="text-center cursor-pointer">
                                      <MdDelete
                                        size={20}
                                        color="red"
                                        onClick={() => {
                                          removeSku(index, x);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-6 mb-3 mt-4">
                      <strong>Bye Product Sales Qty</strong>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th className='md-width freez-column'>Months</th>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                              <th>5</th>
                              <th>6</th>
                              <th>7</th>
                              <th>8</th>
                              <th>9</th>
                              <th>10</th>
                              <th>11</th>
                              <th>12</th>
                              <th>Year 1</th>
                              <th>Year 2</th>
                              <th>Year 3</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldsSku &&
                              fieldsSku.map((x, index) => {
                                return (
                                  <tr>
                                    <td className='md-width freez-column'>{x.sku_description}</td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[1]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[2]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[3]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[4]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[5]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[6]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[7]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[8]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[9]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[10]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[11]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.details[12]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_quantity && x.sale_quantity.aggregate[1]} {...register(`skuData.${index}.sale_quantity.aggregate[1]`)} />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.aggregate[2]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control md-width"
                                        {...register(`skuData.${index}.sale_quantity.aggregate[3]`, {
                                          onChange: (e) => {
                                            saleRevenueCalculation();
                                          },
                                        })}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3 mt-4">
                      <strong>Bye Product Sales Revenue</strong>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th className='md-width freez-column'>Months</th>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                              <th>5</th>
                              <th>6</th>
                              <th>7</th>
                              <th>8</th>
                              <th>9</th>
                              <th>10</th>
                              <th>11</th>
                              <th>12</th>
                              <th>Year 1</th>
                              <th>Year 2</th>
                              <th>Year 3</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldsSku &&
                              fieldsSku.map((x, index) => {
                                return (
                                  <tr>
                                    <td className='md-width freez-column'>{x.sku_description}</td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[1]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[2]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[3]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[4]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[5]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[6]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[7]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[8]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[9]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[10]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[11]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.details[12]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[1]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[2]} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={x.sale_revenu && x.sale_revenu.aggregate[3]} />
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr>
                              <td className='md-width freez-column'>Total</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[1]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[2]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[3]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[4]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[5]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[6]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[7]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[8]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[9]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[10]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[11]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.details[12]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.aggregate[1]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.aggregate[2]} />
                              </td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control md-width" disabled readOnly value={totalSaleRevenu.byeProduct.aggregate[3]} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                </>
              )}
              {/* sku Accordion end */}
              <hr />
              <div className="mt-2">
                <button type="submit" className="btn btn-primary me-2">
                  {op == "Edit" ? "Update Changes" : "Save changes"}
                </button>
                <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
}
export default AgroProductDetails;
