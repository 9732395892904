import {ApiUrl} from "API/config";
import axios from "axios";
import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FcAlphabeticalSortingZa, FcAlphabeticalSortingAz} from "react-icons/fc";
import Loader from "common/components/loader";
function ProtégéList() {
  const [allData, setALLData] = useState([]);
  const [allProtege, setProtegeData] = useState([]);
  const [allProtegeData, setAllProtegeData] = useState([]);
  const [pager, setPager] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [sortedFieldName, setSortedFieldName] = useState("");
  const [selectedSortType, setSelectedSortType] = useState("");
  useEffect(() => {
    getAllcenter();
    getAllCentersForSearch();
  }, []);
  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  let page = 1;
  let currentPage = 0;

  const onProtégéSearch = (e) => {
    if (!e.target.value) {
      setALLData(allProtegeData);
    } else {
      setALLData(allProtege.filter((x) => x.personalInfoId.name.toLowerCase().includes(e.target.value.toLowerCase())));
    }
  };

  const onSort = (name, fieldName) => {
    let data;
    switch (name) {
      case "ascending":
        data = allProtege.sort((a, b) => a.personalInfoId[fieldName] - b.personalInfoId[fieldName]);
        break;
      case "descending":
        data = allProtege.sort((a, b) => b.personalInfoId[fieldName] - a.personalInfoId[fieldName]);
        break;
    }
    return data;
  };
  const onSortAlphaBetically = (name, fieldName) => {
    let data;
    switch (name) {
      case "ascending":
        data = allProtege.sort((a, b) => ("" + a.personalInfoId[fieldName]).localeCompare(b.personalInfoId[fieldName], undefined, {numeric: true}));
        break;
      case "descending":
        data = allProtege.sort((a, b) => ("" + b.personalInfoId[fieldName]).localeCompare(a.personalInfoId[fieldName], undefined, {numeric: true}));
        break;
    }
    return data;
  };
  const onSortAlphaBeticallyB = (name, fieldName) => {
    var collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "accent",
    });
    let data;
    switch (name) {
      case "ascending":
        data = allProtege
          .filter((a) => a.businessInfoid.businessIdea != undefined || a.businessInfoid.businessIdea != null)
          .sort((a, b) => {
            return collator.compare(a.businessInfoid.businessIdea, b.businessInfoid.businessIdea);
          });
        break;
      case "descending":
        data = allProtege
          .filter((a) => a.businessInfoid.businessIdea != undefined || a.businessInfoid.businessIdea != null)
          .sort((a, b) => {
            return collator.compare(b.businessInfoid.businessIdea, a.businessInfoid.businessIdea);
          });
        break;
    }
    return data;
  };

  const onSortTable = (name, sort) => {
    setSortedFieldName(name);
    setSelectedSortType(sort);
    switch (name) {
      case "name":
        setALLData(onSortAlphaBetically(sort, name));
        break;
      case "email":
        setALLData(onSortAlphaBetically(sort, name));
        break;
      case "phone":
        setALLData(onSort(sort, name));
        break;
      case "business":
        setALLData(onSortAlphaBeticallyB(sort, name));
        break;
    }
  };
  const getAllCentersForSearch = () => {
    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}personalInfo/all?page=all`)
        .then((data) => {
          console.log(data)
          if (userObj.role == "mentor") {
            setProtegeData(data.data.pageOfItems.filter((x) => x.personalInfoId.mentor == userObj._id));
            setPager(data.data.pager);
          } else {
            setProtegeData(data.data.pageOfItems);
            setPager(data.data.pager);
          }
          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 400);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };
  const getAllcenter = () => {
    setIsLoader(true);
    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}personalInfo/all?page=${page}`)
        .then((data) => {
          if (userObj.role == "mentor") {
            setAllProtegeData(data.data.pageOfItems.filter((x) => x.personalInfoId.mentor == userObj._id));
            setPager(data.data.pager);
            setALLData(data.data.pageOfItems.filter((x) => x.personalInfoId.mentor == userObj._id));
          } else {
            setPager(data.data.pager);
            setALLData(data.data.pageOfItems);
            setAllProtegeData(data.data.pageOfItems);
          }
          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 400);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };

  const onChangePage = (data) => {
    page = data.page;
    getAllcenter();
  };
  const fontStyle = {fontSize: "0.9rem"};
  return (
    <Layout>
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3">Protégé</h4>
        </div>
        <div className="col-6">
          <Link to={"/ManageProtege/Add"}>
            <button type="button" className="btn btn-primary float-end">
              {" "}
              Add Protégé{" "}
            </button>
          </Link>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="user-search">
            <div className="col-sm-3">
              <input type="text" className="form-control" onChange={onProtégéSearch} placeholder="Search by name" aria-describedby="defaultFormControlHelp" />
            </div>
          </div>
          <div className="table-responsive text-wrap">
            <table className="table">
              <thead className="table-light">
                <tr>
                  <th style={{width: "300px"}}>
                    Name <span style={{cursor: "pointer"}}> {selectedSortType == "ascending" ? <FcAlphabeticalSortingAz style={fontStyle} onClick={() => onSortTable("name", "descending")} /> : <FcAlphabeticalSortingZa style={fontStyle} onClick={() => onSortTable("name", "ascending")} />}</span>
                  </th>
                  <th style={{width: "300px"}}>
                    Business Idea
                    <span style={{cursor: "pointer"}}>{selectedSortType == "ascending" ? <FcAlphabeticalSortingAz style={fontStyle} onClick={() => onSortTable("business", "descending")} /> : <FcAlphabeticalSortingZa style={fontStyle} onClick={() => onSortTable("business", "ascending")} />}</span>
                  </th>
                  <th>
                    Phone
                    <span style={{cursor: "pointer"}}>{selectedSortType == "ascending" ? <FcAlphabeticalSortingAz style={fontStyle} onClick={() => onSortTable("phone", "descending")} /> : <FcAlphabeticalSortingZa style={fontStyle} onClick={() => onSortTable("phone", "ascending")} />}</span>
                  </th>
                  <th>
                    Email ID
                    <span style={{cursor: "pointer"}}>{selectedSortType == "ascending" ? <FcAlphabeticalSortingAz style={fontStyle} onClick={() => onSortTable("email", "descending")} /> : <FcAlphabeticalSortingZa style={fontStyle} onClick={() => onSortTable("email", "ascending")} />}</span>
                  </th>
                  <th>Report</th>
                  <th>Actions </th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {allData &&
                  allData.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td width="300px">{x.personalInfoId && x.personalInfoId.name ? x.personalInfoId && x.personalInfoId.name : "NA"}</td>
                        <td>{x.businessInfoid && x.businessInfoid.businessIdea ? x.businessInfoid && x.businessInfoid.businessIdea : "NA"}</td>
                        <td>{x.personalInfoId && x.personalInfoId.phone ? x.personalInfoId && x.personalInfoId.phone : "NA"}</td>
                        <td>{x.personalInfoId && x.personalInfoId.email ? x.personalInfoId && x.personalInfoId.email : "NA"}</td>
                        <td className="text-center">
                          <Link to={`/ProtegeReports/Edit/${x._id}`} state={{id: x._id}} className="menu-link">
                            <i className="bx bxs-report me-1"></i>
                          </Link>
                          {/* <Link to={`/CashflowPage/Edit/${x._id}`} state={{id: x._id}} className="menu-link">
                            <i className="bx bxs-report me-1"></i>
                          </Link> */}
                        </td>
                        <td className="text-center">
                          <Link to={`/ManageProtege/Edit/${x._id}`} state={{id: x._id}} className="menu-link">
                            <i className="bx bx-edit-alt me-1"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <nav aria-label="Page navigation" className="paginationSpacing">
              <ul className="pagination justify-content-end">
                {pager && pager.pages && pager.pages.length && (
                  <ul className="pagination">
                    <li style={{cursor: "pointer"}} className={`page-item first-item ${pager.currentPage === 1 ? "disabled" : ""}`}>
                      <div
                        onClick={() => {
                          onChangePage({page: 1});
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </div>
                    </li>

                    {pager.pages.map((page) => (
                      <li key={page} style={{cursor: "pointer"}} className={`page-item number-item ${pager.currentPage === page ? "active" : ""}`}>
                        <div
                          onClick={() => {
                            onChangePage({page: page});
                          }}
                          className="page-link"
                        >
                          {page}
                        </div>
                      </li>
                    ))}
                    <li style={{cursor: "pointer"}} className={`page-item previous-item ${pager.currentPage === pager.endPage ? "disabled" : ""}`}>
                      <div
                        onClick={() => {
                          onChangePage({page: pager.endPage});
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </div>
                    </li>
                  </ul>
                )}
              </ul>
            </nav>
          </div>
        </div>
      )}
      {/* <hr className="my-5" /> */}

      <div className="content-backdrop fade"></div>
    </Layout>
  );
}

export default ProtégéList;
