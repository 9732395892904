import {useParams, useNavigate, useLocation} from "react-router";
import * as ReactDOM from "react-dom";
import {Link} from "react-router-dom";
import ProductItem from "./productItem";
import CustomModal from "common/components/CustomModal";
import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import axios from "axios";
import {AnimationType, DialogType, OutAnimationType, PopupProvider, usePopup, ToastPosition} from "react-custom-popup";
import {ApiUrl} from "../../../../API/config";
// import AgroProductView from "../ProtégéForms/agroProductView";
// import HusbandryProductView from "../ProtégéForms/husbandryProductView";
// import ManufacturingProductView from "../ProtégéForms/maufacturingProductView";
// import ServicesProductView from "../ProtégéForms/servicesProductView";
// import TradingProductView from "../ProtégéForms/tradingProductView";
// import AnimalHusbandryProductView from "../ProtégéForms/animalHusbandryProductView";
function ProductList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const op = useParams().op;
  const productName = useParams().product;

  const GetBusinessModel = () => {
    switch (op) {
      case "Trading":
        break;

      default:
        break;
    }
  };

  const Components = {
    // ManufacturingProductView: ManufacturingProductView,
    // AgroProductView: AgroProductView,
    // HusbandaryProductView: HusbandryProductView,
    // AnimalHusbandryProductView: AnimalHusbandryProductView,
    // ServicesProductView: ServicesProductView,
    // TradingProductView: TradingProductView,
  };

  const ProductViewComponent = Components[productName + "ProductView"];
  const [show, setShow] = useState(false);
  const [manufacturingData, setManufacturingData] = useState([]);
  const [tradingData, setTradingData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [agroProducts, setAgroProducts] = useState([]);
  const [animalHusbandryProducts, setAnimalHusbandryProducts] = useState([]);
  const [index, setIndex] = useState(0);
  const [productId, setProductId] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [tradingProducts, setTradingProducts] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);
  const [agroData, setAgroData] = useState([]);
  const [animalHusbandryData, setAnimalHusbandryData] = useState([]);
  const handleClose = () => {
    setShow(false);
  };
  const {showOptionDialog, showAlert} = usePopup();
  const handleDelete = (name, id) => {
    let url;
    switch (name) {
      case "Trading":
        url = `${ApiUrl}trading/product/delete/${id}/${tradingData && tradingData._id}`;
        break;
      case "Manufacturing":
        url = `${ApiUrl}manufacturing/product/delete/${id}/${manufacturingData && manufacturingData._id}`;
        break;
      case "Service":
        url = `${ApiUrl}service/product/delete/${id}/${serviceData && serviceData._id}`;
        break;
      case "Agro":
        url = `${ApiUrl}agro/product/delete/${id}/${agroData && agroData._id}`;
        break;
      case "AnimalHusbandry":
        url = `${ApiUrl}animalhusbandry/product/delete/${id}/${animalHusbandryData && animalHusbandryData._id}`;
        break;

      default:
        break;
    }

    showOptionDialog({
      containerStyle: {width: 350},
      text: "Are you sure you want to delete this? You won't be able to revert that action.",
      title: "Delete Product?",
      options: [
        {
          name: "Cancel",
          type: "cancel",
        },
        {
          name: "Delete",
          type: "confirm",
          style: {background: "lightcoral"},
        },
      ],
      onConfirm: () =>
        axios
          .delete(url)
          .then((response) => {
            getData();
            showAlert({
              title: "Success",
              type: DialogType.SUCCESS,
              text: "DELETED SUCCESSFULLY",
            });
          })
          .catch((error) => {
            console.log(error);
          }),
    });
  };

  const showProduct = () => {
    setShow(true);
    setIndex(index);
  };

  useEffect(() => {
    if (op == "Edit") {
      getData();
    }
  }, []);

  const getData = () => {
    axios
      .get(`${ApiUrl}protege/${location.state.id}`)
      .then((data) => {
        console.log(data);
        setTradingProducts(
          data.data[0].trading &&
            data.data[0].trading.productList &&
            data.data[0].trading.productList.map((y) => {
              return {title: y.product_name, id: y._id};
            })
        );
        setServiceProducts(
          data.data[0].service &&
            data.data[0].service.productList &&
            data.data[0].service.productList.map((y) => {
              return {title: y.product_name, id: y._id};
            })
        );
        setProducts(
          data.data[0].manufacturingIds &&
            data.data[0].manufacturingIds.productList.map((y) => {
              return {title: y.product_name, id: y._id};
            })
        );
        setAgroProducts(
          data.data[0].agro &&
            data.data[0].agro.productList.map((y) => {
              return {title: y.product_name, id: y._id};
            })
        );
        setAnimalHusbandryProducts(
          data.data[0].animalhusbandry &&
            data.data[0].animalhusbandry.productList.map((y) => {
              return {title: y.animalName, id: y._id};
            })
        );
        setManufacturingData(data.data[0].manufacturingIds);
        setAnimalHusbandryData(data.data[0].animalhusbandry);
        setAgroData(data.data[0].agro);
        setTradingData(data.data[0].trading);
        setServiceData(data.data[0].service);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const element = (
    <>
      <Layout>
        <div className="row">
          <div className="col-6">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-2)}>Protégé</a> /
              </span>
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-1)}>{op} Protégé</a> /
              </span>{" "}
              {productName}
            </h4>
          </div>

          <div className="col-6">
            <Link
              to={`/Protege/${productName}Details/Add`}
              state={{
                id: location && location.state && location.state.data && location.state.data.id,
                manufacturingId: manufacturingData && manufacturingData._id,
                protegeId: location && location.state && location.state.data && location.state.id,
                tradingId: tradingData && tradingData._id,
                serviceId: serviceData && serviceData._id,
                agroId: agroData && agroData._id,
                animalHusbandryId: animalHusbandryData && animalHusbandryData._id,
              }}
            >
              <button type="button" className="btn btn-primary float-end">
                {" "}
                {/* {op} */}
                Add {productName == "Trading" ? "Item/Category" : productName == "Manufacturing" ? "Product" : productName == "Agro" ? "Crop" : productName == "AnimalHusbandry" ? "Animal" : productName}
              </button>
            </Link>
          </div>
        </div>
        {productName === "Trading" &&
          (tradingProducts && tradingProducts.length ? (
            <div>
              {tradingProducts.map((product, i) => {
                return <ProductItem key={i} product={product} productName={productName} protegeId={location.state.id} tradingId={tradingData && tradingData._id} showProduct={showProduct} setProductId={setProductId} handleDelete={handleDelete}></ProductItem>;
              })}
            </div>
          ) : (
            <div>
              <h3>Please Add a {productName == "Trading" ? "Item/Category" : "Product"}</h3>
            </div>
          ))}

        {productName === "Service" &&
          (serviceProducts && serviceProducts.length ? (
            <div>
              {serviceProducts.map((product, i) => {
                return <ProductItem key={i} product={product} productName={productName} protegeId={location.state.id} serviceId={serviceData && serviceData._id} showProduct={showProduct} setProductId={setProductId} handleDelete={handleDelete}></ProductItem>;
              })}
            </div>
          ) : (
            <div>
              <h3>Please Add a {productName == "Service" ? "Service" : "Product"}</h3>
            </div>
          ))}

        {productName === "Manufacturing" &&
          (products && products.length ? (
            <div>
              {products.map((product, i) => (
                <ProductItem key={i} product={product} productName={productName} protegeId={location.state.id} manufacturingId={manufacturingData && manufacturingData._id} showProduct={showProduct} setProductId={setProductId} handleDelete={handleDelete}></ProductItem>
              ))}
            </div>
          ) : (
            <div>
              <h3>Please Add a Product</h3>
            </div>
          ))}
        {productName === "Agro" &&
          (agroProducts && agroProducts.length ? (
            <div>
              {agroProducts.map((product, i) => (
                <ProductItem key={i} product={product} productName={productName} protegeId={location.state.id} agroId={agroData && agroData._id} showProduct={showProduct} setProductId={setProductId} handleDelete={handleDelete}></ProductItem>
              ))}
            </div>
          ) : (
            <div>
              <h3>Please Add a Crop</h3>
            </div>
          ))}
        {productName === "AnimalHusbandry" &&
          (animalHusbandryProducts && animalHusbandryProducts.length ? (
            <div>
              {animalHusbandryProducts.map((product, i) => (
                <ProductItem key={i} product={product} productName={productName} animalHusbandryId={animalHusbandryData && animalHusbandryData._id} showProduct={showProduct} setProductId={setProductId} handleDelete={handleDelete}></ProductItem>
              ))}
            </div>
          ) : (
            <div>
              <h3>Please Add a Animal</h3>
            </div>
          ))}

        <CustomModal show={show} handleClose={handleClose} title="Product Details" productId={productId} Body={ProductViewComponent} isModalXl={true}></CustomModal>
        <hr className="my-5" />
        <div className="content-backdrop fade"></div>
      </Layout>
    </>
  );

  return element;
}

export default ProductList;
