import {ApiUrl} from "API/config";
import axios from "axios";
import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import {useForm, useFieldArray} from "react-hook-form";
import {useParams, useNavigate, useLocation} from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { toast, ToastContainer } from "react-toastify";
import Loader from "common/components/loader";
import {
  AnimationType,
  DialogType,
  OutAnimationType,
  PopupProvider,
  usePopup,
  ToastPosition
} from "react-custom-popup";
function BusinessIdeaModel() {
  const { showOptionDialog, showAlert } = usePopup();
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const [data, setData] = useState();
  const [competitors, setCompetitors] = useState([]);
  const [strengths, setStrengths] = useState([]);
  const [shortcomes, setShortcomes] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: {errors},
  } = useForm();

  const updateCompetitor = (c, v) => {
    let newCom = [...competitors];
    newCom[c] = v;
    let nData = {...data};
    nData.competitor = newCom;
    setCompetitors(newCom);
  };

  const updateStrength = (c, v) => {
    let newCom = [...strengths];
    newCom[c] = v;
    let nData = {...data};
    nData.strengths = newCom;
    setStrengths(newCom);
  };

  const updateShortcome = (c, v) => {
    let newCom = [...shortcomes];
    newCom[c] = v;
    let nData = {...data};
    nData.shortcome = newCom;
    setShortcomes(newCom);
  };

  const [noOfCompetitors, setNoOfCompetitors] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [typeOfBusiness, setTypeOfBusiness] = useState([]);
  const [IsThisAnExistingBusinessIdea, setIsThisAnExistingBusinessIdea] = useState(false);
  const [IsGestationPeriodOnceInstalled, setIsGestationPeriodOnceInstalled] = useState(false);
  const [gstImplementation, setgstImplementation] = useState("");

  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;

  useEffect(() => {
     setIsLoader(true);
    if (op == "Edit") {
      axios
        .get(`${ApiUrl}protege/${location.state.id}`)
        .then((data) => {
          setData(data.data[0].businessInfoid);
          setSelectedValue(data.data[0].businessInfoid.typeOfBusiness);
          setTypeOfBusiness(data.data[0].businessInfoid.typeOfBusiness);
          setNoOfCompetitors(data.data[0].businessInfoid.competitorInLocality);
          setCompetitors(data.data[0].businessInfoid.competitor);
          setStrengths(data.data[0].businessInfoid.strengths);
          setShortcomes(data.data[0].businessInfoid.shortcome);
          setIsGestationPeriodOnceInstalled(data.data[0].businessInfoid.gestationPeriodOnceInstalled === "true" ? true : false);
          setIsThisAnExistingBusinessIdea(data.data[0].businessInfoid.isThisAnExistingBusinessIdea === "yes" ? true : false);
          setgstImplementation(data.data[0].businessInfoid.gstImplementation);
          setTimeout(() => setIsLoader(false), 400);
        })
        .catch((err) => {
          // console.log(err);
           setIsLoader(false);
        });
    }
  }, []);
  useEffect(() => {
    if (op == "Edit") {
      reset({
        address: data && data.address,
        businessIdea: data && data.businessIdea,
        businessModelBriefly: data && data.businessModelBriefly,
        competitor: data && data.competitor,
        competitorInLocality: data && data.competitorInLocality,
        customersCharacteristics: data && data.customersCharacteristics,
        gestationPeriodOnceInstalled: data && data.gestationPeriodOnceInstalled,
        ifExistingHowManyYears: data && data.ifExistingHowManyYears,
        ifYesHowLong: data && data.ifYesHowLong,
        isThisAnExistingBusinessIdea: data && data.isThisAnExistingBusinessIdea,
        likelyAggregateDemand: data && data.likelyAggregateDemand,
        noOfCustomers: data && data.noOfCustomers,
        placeWhereBusinessWillBeEstablished: data && data.placeWhereBusinessWillBeEstablished,
        relaventExperienceEntrepreneurSkills: data && data.relaventExperienceEntrepreneurSkills,
        shortcome: data && data.shortcome,
        strengths: data && data.strengths,
        typeOfBusinessModel: data && data.typeOfBusinessModel,
        whyThisIdea: data && data.whyThisIdea,
        whyUspWhyCustomerComeToYou: data && data.whyUspWhyCustomerComeToYou,
        gstImplementation: data && data.gstImplementation,
        organizationStructure: data && data.organizationStructure,
        taxRateYear1: data && data.taxRateYear1,
        taxRateYear2: data && data.taxRateYear2,
        taxRateYear3: data && data.taxRateYear3
      });
    }
  }, [data]);
  const onSelect = (selectedList, selectedItem) => {
    setTypeOfBusiness(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    showOptionDialog({
      containerStyle: { width: 350 },
      text: "Are you sure you want to delete this? You will lose all the data.",
      title: "Delete Business?",
      options: [
        {
          name: "Cancel",
          type: "cancel"
        },
        {
          name: "Delete",
          type: "confirm",
          style: { background: "lightcoral" }
        }
      ],
      onConfirm: () =>
      setTypeOfBusiness(selectedList),
      onCancel: () => 
        setTypeOfBusiness(selectedList.push(removedItem)),
    });
  };

  const handleCompetitiorsChange = (e) => {
    setNoOfCompetitors(parseInt(e.target.value));
  };
  const handleGSTImplementationChange = (e) => {
    setgstImplementation(e.target.value);
  };

  const autoResize = function (e) {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const onCreateBusinessIdeaInfo = (data) => {
    setIsLoader(true);
    let dataObj = {
      ...data,
      typeOfBusiness: typeOfBusiness.length ? typeOfBusiness : undefined,
      shortcome: shortcomes.length ? shortcomes : data.shortcome,
      competitor: competitors.length ? competitors : data.competitor,
      strengths: strengths.length ? strengths : data.strengths,
    };
    let totalNonumberOfField = 25;
    if (IsThisAnExistingBusinessIdea == false) totalNonumberOfField = totalNonumberOfField - 1;
    if (IsGestationPeriodOnceInstalled == false) totalNonumberOfField = totalNonumberOfField - 1;
    let existingFields = Object.keys({
      ...data,
      typeOfBusiness: typeOfBusiness,
      shortcome: shortcomes.length ? shortcomes : data.shortcome,
      competitor: competitors.length ? competitors : data.competitor,
      strengths: strengths.length ? strengths : data.strengths,
    }).filter((x) => dataObj[x]);

    let existingCount = existingFields ? existingFields.length : 0;
   
    let progressScore = (existingCount / totalNonumberOfField) * 100;
    if (op == "Edit") {
      axios
        .post(`${ApiUrl}businessInfo/updateBusinessInfo/${userObj._id}`, {
          ...data,
          userId: userObj._id,
          progressScore: progressScore > 100 ? 100 : progressScore,
          businessInfoid: location.state.id,
          typeOfBusiness: typeOfBusiness,
          shortcome: shortcomes.length ? shortcomes : data.shortcome,
          competitor: competitors.length ? competitors : data.competitor,
          strengths: strengths.length ? strengths : data.strengths,
        })
        .then((resoponse) => {
          toast("Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoader(false)
        });
    } else {
      axios
        .post(`${ApiUrl}businessInfo/createBusinessInfo`, {
          ...data,
          userId: userObj._id,
          businessInfoid: location.state.id,
          progressScore: progressScore,
          shortcome: shortcomes,
          competitor: competitors,
          strengths: strengths,
        })

        .then((resoponse) => {
          toast("Created Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoader(false)
        });
    }
  };

  let isFormRendered = op == "Edit" ? true : true;

  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Business Idea and Model
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
      <div className="card">
        <div className="mb-4">
          <div className="card-body">
            {isFormRendered && (
              <form id="formAccountSettings" onSubmit={handleSubmit(onCreateBusinessIdeaInfo)} method="POST">
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Business Idea
                    </label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" autoFocus="" defaultValue={data && data.businessIdea} {...register("businessIdea")} />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Business Name
                    </label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" autoFocus="" defaultValue={data && data.BusinessName} {...register("BusinessName")} />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="nameBackdrop" className="form-label">
                      Nature of Business
                    </label>
                    <br />
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="B2B"
                        defaultValue={data && data.typeOfBusinessModel}
                        {...register("typeOfBusinessModel")}

                        //defaultChecked={data && data.typeOfBusinessModel=="B2B"}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        B2B
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="B2C"
                        // defaultChecked ={data && data.typeOfBusinessModel=="B2C"}
                        defaultValue={data && data.typeOfBusinessModel}
                        {...register("typeOfBusinessModel")}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        B2C
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="Both"
                        // defaultChecked ={data && data.typeOfBusinessModel=="Both"}
                        defaultValue={data && data.typeOfBusinessModel}
                        {...register("typeOfBusinessModel")}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        Both
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Briefly describe the process, equipment needed, raw materials and how Idea will be Implemented
                    </label>
                    <textarea
                      type="text"
                      style={{overflow: "hidden"}}
                      className="form-control"
                      id="exampleFormControlInput1"
                      defaultValue={data && data.businessModelBriefly}
                      {...register("businessModelBriefly", {
                        onChange: (e) => {
                          autoResize(e);
                        },
                      })}
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="nameBackdrop" className="form-label">
                      Type of Business
                    </label>
                    <Multiselect
                      options={[{name: "Agro"}, {name: "Animal Husbandry"}, {name: "Manufacturing"}, {name: "Service"}, {name: "Trading"}]} // Options to display in the dropdown
                      selectedValues={selectedValue} // Preselected value to persist in dropdown
                      onSelect={onSelect} // Function will trigger on select event
                      onRemove={onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="nameBackdrop" className="form-label">
                      Is this an existing business?
                    </label>
                    <br />
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions1"
                        id="inlineRadio1"
                        value={"yes"}
                        onClick={() => setIsThisAnExistingBusinessIdea(true)}
                        // checked={
                        //   data && data.isThisAnExistingBusinessIdea == true
                        // }
                        defaultValue={data && data.isThisAnExistingBusinessIdea}
                        {...register("isThisAnExistingBusinessIdea")}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions1"
                        id="inlineRadio2"
                        value={"no"}
                        checked={!IsThisAnExistingBusinessIdea}
                        onClick={() => {
                          setIsThisAnExistingBusinessIdea(false);
                          reset({ ifExistingHowManyYears: "" });
                        }}
                        // checked={
                        //   data && data.isThisAnExistingBusinessIdea == false
                        // }
                        defaultValue={data && data.isThisAnExistingBusinessIdea}
                        {...register("isThisAnExistingBusinessIdea")}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                  {/* {IsThisAnExistingBusinessIdea&& */}
                  {IsThisAnExistingBusinessIdea && (
                    <div className="mb-3 col-md-6">
                      <label htmlFor="nameBackdrop" className="form-label">
                        If existing, how many years?
                      </label>
                      <select disabled={!IsThisAnExistingBusinessIdea} className="form-select" id="exampleFormControlSelect1" {...register("ifExistingHowManyYears")} defaultValue={data && data.ifExistingHowManyYears} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  )}
                  {/* } */}
                  {!IsThisAnExistingBusinessIdea && (
                    <div className="mb-3 col-md-6">
                      <label htmlFor="nameBackdrop" className="form-label">
                        Is there a Gestation Period ?
                      </label>
                      <br />
                      <div className="form-check form-check-inline mt-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          //  defaultValue={data && data.gestationPeriodOnceInstalled}
                          value="true"
                          // defaultChecked={
                          //   data && data.gestationPeriodOnceInstalled
                          // }
                          onClick={() => setIsGestationPeriodOnceInstalled(true)}
                          {...register("gestationPeriodOnceInstalled")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="false"
                          checked={!IsGestationPeriodOnceInstalled}
                          onClick={() => {
                            setIsGestationPeriodOnceInstalled(false);
                            reset({ ifYesHowLong: "" });
                          }}
                          //  defaultValue={data && data.gestationPeriodOnceInstalled}
                          // defaultChecked={
                          //   data && data.gestationPeriodOnceInstalled
                          // }
                          {...register("gestationPeriodOnceInstalled")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  )}
                  {/* {IsGestationPeriodOnceInstalled&& */}
                  {IsGestationPeriodOnceInstalled && (
                    <div className="mb-3 col-md-6">
                      <label htmlFor="nameBackdrop" className="form-label">
                        If yes, so how long (in months)?
                      </label>
                      <select disabled={!IsGestationPeriodOnceInstalled} className="form-select" id="exampleFormControlSelect1" {...register("ifYesHowLong")} defaultValue={data && data.ifYesHowLong} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  )}
                  {/* } */}

                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">
                      Why this idea? (Explain The Knowledge, Experience, Skill Use full in the Business)
                    </label>
                    <textarea
                      className="form-control"
                      style={{overflow: "hidden"}}
                      id="exampleFormControlTextarea1"
                      rows="3"
                      defaultValue={data && data.whyThisIdea}
                      {...register("whyThisIdea", {
                        onChange: (e) => autoResize(e),
                      })}
                    ></textarea>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Experience relevant for idea
                    </label>
                    <input type="text" className="form-control" defaultValue={data && data.relaventExperienceEntrepreneurSkills} {...register("relaventExperienceEntrepreneurSkills")} id="exampleFormControlInput1" />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Place of Business (City / Town)
                    </label>
                    <input type="text" className="form-control" placeholder='e.g. Bangalore' defaultValue={data && data.placeWhereBusinessWillBeEstablished} {...register("placeWhereBusinessWillBeEstablished")} id="exampleFormControlInput1" />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Address (If finalized)
                    </label>
                    <textarea
                      type="text"
                      placeholder='Address'
                      style={{overflow: "hidden"}}
                      className="form-control"
                      {...register("address", {
                        onChange: (e) => autoResize(e),
                      })}
                      defaultValue={data && data.address}
                      id="exampleFormControlInput1"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="nameBackdrop" className="form-label">
                      How many competitors in the this locality ?
                    </label>
                    <select className="form-select" id="exampleFormControlSelect1" aria-label="Default select example" defaultValue={data && data.competitorInLocality} {...register("competitorInLocality")} onChange={handleCompetitiorsChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="row">
                    {noOfCompetitors > 0 && (
                      <>
                        <h6>Competitor Analysis</h6>{" "}
                        <div className="col-md-4">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Competitor
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Strengths
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Shortcome
                          </label>
                        </div>
                      </>
                    )}
                    {[...Array(noOfCompetitors)]
                      .filter((y, index) => index < 5)
                      .map((i, c) => (
                        <>
                          <div className="mb-3 col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={competitors[c]}
                              // {...register("competitor")}
                              onChange={(e) => updateCompetitor(c, e.target.value)}
                              id="exampleFormControlInput1"
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              defaultValue={strengths[c]}
                              onChange={(e) => updateStrength(c, e.target.value)}
                              // {...register("strengths")}
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              defaultValue={shortcomes[c]}
                              onChange={(e) => updateShortcome(c, e.target.value)}
                              // {...register("shortcome")}
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="row">
                    <h6>Market Analysis</h6>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Customer Profile
                      </label>
                      <textarea
                        style={{overflow: "hidden"}}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        defaultValue={data && data.customersCharacteristics}
                        {...register("customersCharacteristics", {
                          onChange: (e) => autoResize(e),
                        })}
                      ></textarea>
                    </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                          Potential Customers in Target Area
                        </label>
                        <input type="number" step="any" placeholder='Enter number - e.g. 1' numformat="numberLeft"  className="form-control" id="exampleFormControlInput1" defaultValue={data && data.noOfCustomers} {...register("noOfCustomers")} />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                          Likely Customers For My Business
                        </label>
                        <input type="text" className="form-control" defaultValue={data && data.likelyAggregateDemand} id="exampleFormControlInput1" {...register("likelyAggregateDemand")} />
                      </div>

                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                      USP (Describe Specific Uniqueness of the Idea that will attract customers. Avoid General terms like Quality, Price etc)
                      </label>
                      <textarea
                        style={{overflow: "hidden"}}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        defaultValue={data && data.whyUspWhyCustomerComeToYou}
                        {...register("whyUspWhyCustomerComeToYou", {
                          onChange: (e) => autoResize(e),
                        })}
                      ></textarea>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                      Type of GST Implementation
                      </label>
                      <select className="form-select" id="exampleFormControlSelect1" aria-label="Default select example" defaultValue={data && data.gstImplementation} {...register("gstImplementation", { onChange: (e) => handleGSTImplementationChange(e) })}>
                      <option value="">Select</option>
                      <option value="NoRegistration">No Registration</option>
                      {/* <option value="CompositeRate">Composite Rate</option> */}
                      <option value="InputCredit">Input Credit</option>
                    </select>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Organization Structure
                      </label>
                      <select className="form-select" id="exampleFormControlSelect1" aria-label="Default select example" defaultValue={data && data.organizationStructure} {...register("organizationStructure")}>
                      <option value="">Select</option>
                      <option value="OnePersonCompany">One Person Company</option>
                      <option value="SoleProprietorship">Sole Proprietorship</option>
                      <option value="Partnership">Partnership</option>
                      <option value="PrivateLtd">Private Ltd</option>
                      <option value="PublicLimited">Public Limited</option>
                    </select>
                    </div>
                    
                  </div>
                  <div className='row'>
                    <h6>Income Tax Rate (%)</h6>
                       <div className="mb-3 col-md-2">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Year 1</label>
                        <input type="number" step="any"  numformat="numberLeft" className="form-control" defaultValue={data && data.taxRateYear1} id="exampleFormControlInput1" {...register("taxRateYear1")} />
                      </div>
                       <div className="mb-3 col-md-2">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Year 2</label>
                        <input type="number" step="any"  numformat="numberLeft" className="form-control" defaultValue={data && data.taxRateYear2} id="exampleFormControlInput1" {...register("taxRateYear2")} />
                      </div>
                       <div className="mb-3 col-md-2">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Year 3</label>
                        <input type="number" step="any"  numformat="numberLeft" className="form-control" defaultValue={data && data.taxRateYear3} id="exampleFormControlInput1" {...register("taxRateYear3")} />
                      </div>
                  </div>
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      {" "}
                      {op == "Edit" ? "Save Changes" : " Save Changes"}
                    </button>
                    <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    )}
      <div className="content-backdrop fade"></div>
    </Layout>
  );
}

export default BusinessIdeaModel;
