import { useEffect, useState } from "react";
import React from "react";

import NewUser from "./newUser";
import CustomModal from "../../../common/components/CustomModal";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { ApiUrl } from "API/config";
import UpdateUser from "./updateUser";
import Layout from "common/components/layout";
import {  useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "common/components/loader";
function UserList() {
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [pager, setPager] = useState({});
  const [allData, setALLData] = useState([]);
  const [allUsers, setSearchUserData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [userData, setUserData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  let userDetails = localStorage.getItem("user_data");
  let userObj = userDetails
    ? JSON.parse(localStorage.getItem("user_data")).user
    : undefined;

  const adduserHandler = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseUpdateUser = () => {
    setShowUpdate(false);
  };
  useEffect(() => {
    getAllUsers();
    getAllUserData();
  }, []);
  let page = 1;

  const getAllUserData = () => {
    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}user/all?page=${-1}`)
        .then((data) => {

          setSearchUserData(data.data);

          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 500);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          setIsLoader(false);
        });
      }
  }
  const getAllUsers = () => {
    setIsLoader(true);

    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}user/all?page=${page}`)
        .then((data) => {
          setAllUserData(data.data.pageOfItems);
          setALLData(data.data.pageOfItems);
          setPager(data.data.pager);
          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 500);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    } 
  };

  const onNewUserSubmit = (data) => {
    axios
      .post(`${ApiUrl}user/create`, data)
      .then((result) => {
        toast("Created Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleClose();
        getAllUsers();
      })
      .catch((err) => {
        toast(err.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleClose();
      });
  };

  const onUserSearch = (e) => {
     if (!e.target.value) {
      setALLData(allUserData);
    } else {
      setALLData(
      allUsers.filter((x) =>
        x.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    }
  };
  const onUserUpdate = (data) => {
    if (userObj._id == userData._id && data.status == "inactive") {
      // localStorage.clear()
      // navigate('/login')
      let isConfirmed = false;
      handleCloseUpdateUser();
      confirmAlert({
        title: "Confirm to Deactivate ?",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              axios
              .post(`${ApiUrl}user/${userData._id}`, data)
              .then((response) => {
                toast("Updated Successfully", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
                // getAllUsers();
                // handleCloseUpdateUser();
                localStorage.clear();
                navigate("/login");
              })
              .catch((error) => {
                toast("Error While Updating", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
                handleCloseUpdateUser();
              });
            
            }
          },
          {
            label: "No",
            onClick: () => (isConfirmed = true)
          }
        ]
      });
      return isConfirmed;
    }
    axios
      .post(`${ApiUrl}user/${userData._id}`, data)
      .then((response) => {
        toast("Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        getAllUsers();
        handleCloseUpdateUser();
      })
      .catch((error) => {
        toast("Error While Updating", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleCloseUpdateUser();
      });
  };

  const onChangePage = (data) => {
    page = data.page;
    getAllUsers();
  };

  const element = (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 cursor-pointer">Users</h4>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={adduserHandler}
          >
            {" "}
            Add User{" "}
          </button>
        </div>
      </div>
      {isLoader ? (
    <Loader></Loader>
      ) : (
        <div className="card">
          <div className="user-search">
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                onChange={onUserSearch}
                aria-describedby="defaultFormControlHelp"
              />
            </div>
          </div>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead className="table-light">
                <tr>
                  <th>Full Name</th>
                  <th>Email ID</th>
                  <th>Role</th>
                  <th>Center</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody className="table-border-bottom-0">
                {allData &&
                  allData.map((x, key) => {
                    return (
                      <tr key={key}>
                        <td>{x.name}</td>
                        <td>{x.email}</td>
                        <td>{x.role}</td>
                        <td>{x.center && x.center.name}</td>

                        <td>
                          {x.status == "active" ? (
                            <span className="badge bg-label-success me-1">
                              Active
                            </span>
                          ) : (
                            <span className="badge bg-label-warning me-1">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setUserData(x);
                              setShowUpdate(true);
                            }}
                          >
                            <i className="bx bx-edit-alt me-1"></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <nav aria-label="Page navigation" className="paginationSpacing">
              <ul className="pagination justify-content-end">
                {pager.pages && pager.pages.length && (
                  <ul className="pagination">
                    {/* <li
                    className={`page-item first-item ${
                      pager.currentPage === 1 ? "disabled" : ""
                    }`}
                  ></li> */}
                    <li
                      style={{ cursor: "pointer" }}
                      className={`page-item first-item ${
                        pager.currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <div
                        // to={{ search: `?page=1` }}
                        onClick={() => {
                          onChangePage({ page: 1 });
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </div>
                    </li>

                    {pager.pages.map((page) => (
                      <li
                        key={page}
                        style={{ cursor: "pointer" }}
                        className={`page-item number-item ${
                          pager.currentPage === page ? "active" : ""
                        }`}
                      >
                        <div
                          /// to={{ search: `?page=${page}` }}
                          onClick={() => {
                            onChangePage({ page: page });
                          }}
                          className="page-link"
                        >
                          {page}
                        </div>
                      </li>
                    ))}
                    <li
                      style={{ cursor: "pointer" }}
                      className={`page-item previous-item ${
                        pager.currentPage === pager.endPage ? "disabled" : ""
                      }`}
                    >
                      <div
                        // to={{ search: `?page=${pager.currentPage - 1}` }}
                        onClick={() => {
                          onChangePage({ page: pager.endPage });
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </div>
                    </li>
                  </ul>
                )}
                {/* <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li> */}
                {/* <li className="page-item next">
                <div className="page-link">
                 
                </div>
              </li> */}
              </ul>
            </nav>

            <div className="card-footer pb-0 pt-3"></div>
          </div>
        </div>
      )}

      {/* <hr className="my-5" /> */}
      <div className="content-backdrop fade"></div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        title="New User"
        onNewUserSubmit={onNewUserSubmit}
        submitBtnTitle="Save"
        Body={NewUser}
      ></CustomModal>
      <CustomModal
        show={showUpdate}
        handleClose={handleCloseUpdateUser}
        title="Update User"
        data={userData}
        onUserUpdate={onUserUpdate}
        submitBtnTitle="Save"
        cancelBtnTitle="Close"
        Body={UpdateUser}
      ></CustomModal>
    </Layout>
  );

  return element;
}

export default UserList;
