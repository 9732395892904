  import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router";
import { set, useFieldArray, useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
import CustomModal from "common/components/CustomModal";
import SalesRevenewReadOnly from "./salesRevenewReadOnly";
import Loader from "common/components/loader";
function SalesProjectionAgroDetails() {
  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
 
  const [totalPercent, setTotalPercent] = useState(0);

  const [totalMonthlyValues, setTotalMonthlyValues] = useState({
    details: {
      1: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      2: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      3: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      4: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      5: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      6: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      7: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      8: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      9: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      10: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      11: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      12: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
    },
    aggregate: {
      1: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      2: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
      3: {salesRevenue: 0, gst_collected_on_sales: 0, commission: 0, transport: 0, variableCostB4GST: 0, variableCostGST: 0},
    }
  });
  const [totalMonthlySalesRevenue, setTotalMonthlySalesRevenue] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });
  const [totalMonthlyGSTCollectOnSale, setTotalMonthlyGSTCollectOnSale] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });
  const [totalYearlyGSTCollectOnSale, setTotalYearlyGSTCollectOnSale] = useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const [totalMonthlyCashInflowOfSales, setTotalMonthlyCashInflowOfSales] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });

  const [totalMonthlyCashInflowOfSalesGST, setTotalMonthlyCashInflowOfSalesGST] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });

  const [totalYearlySalesRevenue, setTotalYearlySalesRevenue] = useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const [totalYearlyCashInFlowOfSales, setTotalYearlyCashInFlowOfSales] =
  useState({
    1: 0,
    2: 0,
    3: 0,
  });
const [totalYearlyCashInFlowOfSalesGST, setTotalYearlyCashInFlowOfSalesGST] =
  useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const [show, setShow] = useState(false);
  const [errorInTotal, setErrorInTotal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const op = useParams().op;
  const { register, control, handleSubmit, getValues, setValue, setError, reset, formState, watch } = useForm();
  const handleClose = () => {
    setShow(false);
  };
  const [compositionOfSales, setCompositionOfSales] = useState({
    Cash: {
      value: 0,
      noOfMonths: 0,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
    Credit: {
      value: 0,
      noOfMonths: 0,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
    Card: {
      value: 0,
      noOfMonths: 0,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
    Advance: {
      value: 0,
      noOfMonths: 0,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [creditCardFee, setCreditCardFee] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
}
)
const [creditCardFeeCashInFlow, setCreditCardFeeCashInFlow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
}
)

  const { fields: fieldProducts, append: appendProduct, update: updateProduct, setValue: setValueProduct } = useFieldArray({ name: "fieldProductsName", control });

  const { fields: fieldProductSku, append: appendProductSku, update: updateProductSku, setValue: setValueSku } = useFieldArray({ name: "fieldProductSkuName", control });
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,    });
    return num ? numberFormatter.format(num) : 0;
  };
  const setSalesCompositionPercent = (e, field) => {
    setErrorInTotal(false);
    var obj = compositionOfSales;
    let prev = obj[field].value;
    obj[field].value = e.target.value ? parseFloat(e.target.value) : 0;
    let total = parseFloat(obj.Cash.value) + parseFloat(obj.Card.value) + parseFloat(obj.Credit.value) + parseFloat(obj.Advance.value);
    if (total != 100) {
      setErrorInTotal(true);
    }
    if (total > 100) {
      e.target.value = prev;
      obj[field].value = prev;
      return;
    }
    setCompositionOfSales(obj);
    setTotalPercent(total);
    triggerMasterSalesCalcuation("month");
  };
  const setSalesCompositionMonth = (e, field) => {
    var obj = compositionOfSales;
    obj[field].noOfMonths = e.target.value ? parseFloat(e.target.value) : 0;
    setCompositionOfSales(obj);
    triggerMasterSalesCalcuation("month");
  }
  let salesProjectionId = location.state.data.salesProjectionId && location.state.data.salesProjectionId;

  const setSalesCompositionPay = (e, field) => {
    var obj = getValues("compositionOfSales")||compositionOfSales;
    obj[field].pay ={value: e.target.value ? parseFloat(e.target.value) : 0};
    setCompositionOfSales(obj);
    triggerMasterSalesCalcuation("month");
  };

  useEffect(() => {

    async function fetchData() {
      if (salesProjectionId) {
        await getSalesProjection();

      } else {
        await getManuFactingById();
      }
    }
    fetchData();


  }, []);

  const calcMonthlyAggregateCashInFlow = () => {
    const cash =
      compositionOfSales.Cash.aggregate[1] -
      ((Object.values(totalMonthlySalesRevenue)
        .map((value) => parseFloat(value))
        ?.slice(0, 12 - compositionOfSales.Cash.noOfMonths)
        ?.reduce((sum, value) => sum + value, 0) || 0) *
        compositionOfSales.Cash.value) /
        100;
    const card =
      compositionOfSales.Card.aggregate[1] -
      ((Object.values(totalMonthlySalesRevenue)
        .map((value) => parseFloat(value))
        ?.slice(0, 12 - compositionOfSales.Card.noOfMonths)
        ?.reduce((sum, value) => sum + value, 0) || 0) *
        compositionOfSales.Card.value) /
        100;
    const credit =
      compositionOfSales.Credit.aggregate[1] -
      ((Object.values(totalMonthlySalesRevenue)
        .map((value) => parseFloat(value))
        ?.slice(0, 12 - compositionOfSales.Credit.noOfMonths)
        ?.reduce((sum, value) => sum + value, 0) || 0) *
        compositionOfSales.Credit.value) /
        100;
    const advance =
      compositionOfSales.Advance.aggregate[1] -
      ((Object.values(totalMonthlySalesRevenue)
        .map((value) => parseFloat(value))
        ?.slice(0, 12 - compositionOfSales.Advance.noOfMonths)
        ?.reduce((sum, value) => sum + value, 0) || 0) *
        compositionOfSales.Advance.value) /
        100;
    const totalY1Forward = cash + card + credit + advance;

    return totalY1Forward;
  };

  const calcMonthlyAggregateCashInFlowGst = () => {
    const totalY1ForwardGst =
      Object.values(totalMonthlyCashInflowOfSalesGST).reduce(
        (sum, value) => sum + value,
        0
      ) || 0;
    return totalY1ForwardGst;
  };
  const getSalesProjection = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}agro/getagro/${location.state.data.businessId}`, {
        _id: location.state.data.businessId,
      })
      .then((result) => {
        axios.get(`${ApiUrl}salesprojection/agro/get/${salesProjectionId}`).then((resultSku) => {

          let requriedFieldProducts = result.data.data[0].productList.map((x, i) => {
            return {
              product_id: x._id,
              productId: x.product_name,
              details: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              sales: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0
              },
              gst_collected_on_sales: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0
                }
              },
              commission: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },
              transport: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },  
              variableCostB4GST: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },            
              variableCostGST: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0
                }
              }, 
              aggregate: {
                1: 0,
                2: 0,
                3: 0,
              },
            };
          });

          let requiredProductSku = result.data.data[0].productList
            .map((x, i) =>
              x.skuData.map((y) => {
                return {
                  productId: x.product_name,
                  product_id: x._id,
                  details: {
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    4: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    5: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    6: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    7: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    8: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    9: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    10: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    11: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    12: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  },
                  aggregate: {
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  },
                  name: y.sku_description,
                  gst_percentage: y.sku_gst_percent ? y.sku_gst_percent : 0,
                  sales_price: y.price_per_unit ? y.price_per_unit : 0,
                  commission: y.sku_commission ? y.sku_commission : 0,
                  transport: y.sku_transport ? y.sku_transport : 0,
                  variableCostB4GST: y.skuTotalVariableCostExcludingGST ? y.skuTotalVariableCostExcludingGST : 0,
                  variableCostGST: y.skuGstAmtOnVariableCost ? y.skuGstAmtOnVariableCost : 0,
                  basicInformation: x.basicInformation,
                  produceAnimal: x.produceAnimal
                };
              })
            )
            .flat();
          for (let i = 0; i < requriedFieldProducts.length; i++) {
            if (requiredProductSku[i] && requiredProductSku[i].product_id && resultSku.data.fieldProductsName[i] && requriedFieldProducts[i].product_id === resultSku.data.fieldProductsName[i].product_id) {
              requriedFieldProducts[i].details = resultSku.data.fieldProductsName[i].details;
              requriedFieldProducts[i].aggregate = resultSku.data.fieldProductsName[i].aggregate;
              requriedFieldProducts[i].productId = resultSku.data.fieldProductsName[i].productId;
              requriedFieldProducts[i].product_id = resultSku.data.fieldProductsName[i].product_id;
              requriedFieldProducts[i].sales = resultSku.data.fieldProductsName[i].sales;
              
            }
          }
          for (let i = 0; i < requiredProductSku.length; i++) {
            if (resultSku.data.fieldProductSkuName[i] && resultSku.data.fieldProductSkuName[i].product_id && requiredProductSku[i].product_id === resultSku.data.fieldProductSkuName[i].product_id) {
              requiredProductSku[i].details = resultSku.data.fieldProductSkuName[i].details;
              requiredProductSku[i].aggregate = resultSku.data.fieldProductSkuName[i].aggregate;
            }
          }


          let cashDetails = resultSku.data.cash_details ? parseFloat(resultSku.data.cash_details) : 0;
          let percentCard = resultSku.data.percent_card ? parseFloat(resultSku.data.percent_card) : 0;
          let percentCredit = resultSku.data.percent_credit ? parseFloat(resultSku.data.percent_credit) : 0;
          let percentAdvance = resultSku.data.percent_Advance ? parseFloat(resultSku.data.percent_Advance) : 0;

          let total = cashDetails + percentCard + percentCredit + percentAdvance;
          setTotalPercent(total);
          reset({
            fieldProductsName: requriedFieldProducts,
            fieldProductSkuName: requiredProductSku,
            noOfMonths_cash: resultSku.data.noOfMonths_cash,
            cash_details: resultSku.data.cash_details,
            noOfMonths_Advance: resultSku.data.noOfMonths_Advance,
            noOfMonths_Card: resultSku.data.noOfMonths_Card,
            noOfMonths_Credit: resultSku.data.noOfMonths_Credit,
            percent_Advance: resultSku.data.percent_Advance,
            percent_card: resultSku.data.percent_card,
            percent_credit: resultSku.data.percent_credit,
            compositionOfSales: resultSku.data.compositionOfSales,
            pay:resultSku.data.pay
          });

          for (let pIndex = 0; pIndex < requriedFieldProducts.length; pIndex++) {
            for (let x = 0; x < requiredProductSku.length; x++) {
              triggerMasterCalculation("gst_percentage", 1, x, requiredProductSku[x], "", requriedFieldProducts[pIndex], pIndex);
            }
          }
          setCompositionOfSales(resultSku.data.compositionOfSales);
          setTotalMonthlySalesRevenue(
            resultSku.data.totalMonthlySalesRevenue
              ? resultSku.data.totalMonthlySalesRevenue
              : {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              }
          );
          setTotalYearlySalesRevenue(
            resultSku.data.totalYearlySalesRevenue
              ? resultSku.data.totalYearlySalesRevenue
              : {
                1: 0,
                2: 0,
                3: 0,
              }
          );
          setTotalYearlyCashInFlowOfSalesGST(
            resultSku.data.totalYearlyCashInFlowOfSalesGST
              ? resultSku.data.totalYearlyCashInFlowOfSalesGST
              : {
                  1: 0,
                  2: 0,
                  3: 0,
                }
          );

          setTotalYearlyCashInFlowOfSales(
            resultSku.data.totalYearlyCashInFlowOfSales
              ? resultSku.data.totalYearlyCashInFlowOfSales
              : {
                  1: 0,
                  2: 0,
                  3: 0,
                }
          );

          setTimeout(() => setIsLoader(false), 400);

        })
          .catch((error) => {
            console.log(error);
            setIsLoader(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };

  const getManuFactingById = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}agro/getagro/${location.state.data.businessId}`, {
        _id: location.state.data.businessId,
      })
      .then((result) => {

        let requriedFieldProducts = result.data.data[0].productList.map((x, i) => {
            return {
              product_id: x._id,
              productId: x.animalName,
              details: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              sales: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0
            },
            gst_collected_on_sales: {
              details: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              aggregate: {
                1: 0,
                2: 0,
                3: 0
              }
            },
              commission: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },
              transport: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },            
              variableCostB4GST: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },            
              variableCostGST: {
                details: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                }
              },            
              aggregate: {
                1: 0,
                2: 0,
                3: 0,
              },
            };
          });

          let requiredProductSku =  result.data.data[0].productList
            .map((x, i) =>
              x.skuData.map((y) => {
                return {
                  productId: x.sku_description,
                  product_id: x._id,
                  details: {
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    4: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    5: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    6: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    7: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    8: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    9: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    10: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    11: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    12: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  },
                  aggregate: {
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesrevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  },
                  name: y.sku_description,
                  gst_percentage: y.sku_gst_percent ? y.sku_gst_percent : 0,
                  sales_price: y.price_per_unit ? y.price_per_unit : 0,
                  commission: y.sku_commission ? y.sku_commission : 0,
                  transport: y.sku_transport ? y.sku_transport : 0,
                  variableCostB4GST: y.skuTotalVariableCostExcludingGST ? y.skuTotalVariableCostExcludingGST : 0,
                  variableCostGST: y.skuGstAmtOnVariableCost ? y.skuGstAmtOnVariableCost : 0,
                  basicInformation: x.basicInformation,
                  produceAnimal: x.produceAnimal
                };
              })
            )
            .flat();
        reset({
          fieldProductsName: requriedFieldProducts,
          fieldProductSkuName: requiredProductSku
        })
        
        for (let pIndex = 0; pIndex < requriedFieldProducts.length; pIndex++) {
          for (let x = 0; x < requiredProductSku.length; x++) {
            triggerMasterCalculation("quantity", 1, x, requiredProductSku[x], "", requriedFieldProducts[pIndex], pIndex);
          }
        }
        setTimeout(() => setIsLoader(false), 400);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };

  const triggerMasterCalculation = (field, month, index, obj, e, product, pIndex) => {
    if (e && e.target.value) {
      let v = parseFloat(e.target.value);
      obj.details[month][field] = v;
    }
    calculateDetails(obj);
    triggerAggregateCalculation(obj, index, product, pIndex);
    updateProductSku(index, obj);
    triggerSalesRevenueCalculation(product);
    updateProduct(pIndex, product);
    calculateTotalSalesRevenue();
    triggerMasterSalesCalcuation("month");
  };
  const calculateDetails = (item) => {
    console.log(item);
    for (const monthData in item.details) {
      const prod_quantity = parseFloat(item.details[monthData].prod_quantity ? item.details[monthData].prod_quantity : 0);
      const sale_quantity = parseFloat(item.details[monthData].quantity ? item.details[monthData].quantity : 0);
      const closing_stock = monthData == 1 ? 0 : parseFloat(item.details[monthData - 1].closing_stock);
      item.details[monthData].closing_stock = parseFloat(closing_stock + prod_quantity - sale_quantity);
      item.details[monthData].salesrevenue = parseFloat(item.details[monthData].quantity) * (parseFloat(item.sales_price) ? parseFloat(item.sales_price) : 0);
      console.log(monthData , parseFloat(item.details[monthData].sales_revenue) , parseFloat(item.sales_price) , parseFloat(item.sales_price)  );
      item.details[monthData].commission = parseFloat(item.details[monthData].quantity) * (parseFloat(item.commission) ? parseFloat(item.commission) : 0);
      item.details[monthData].transport = parseFloat(item.details[monthData].quantity) * (parseFloat(item.transport) ? parseFloat(item.transport) : 0);
      item.details[monthData].variableCostB4GST = parseFloat(item.details[monthData].quantity) * (parseFloat(item.variableCostB4GST) ? parseFloat(item.variableCostB4GST) : 0);
      item.details[monthData].variableCostGST = parseFloat(item.details[monthData].quantity) * (parseFloat(item.variableCostGST) ? parseFloat(item.variableCostGST) : 0);
      item.details[monthData].gst_collected_on_sales = parseFloat((parseFloat(item.gst_percentage) * parseFloat(item.details[monthData].quantity) * parseFloat(item.sales_price)) / 100);
    }
  };

  const triggerAggregateCalculation = (item, index, product, pIndex) => {

    let qsum = 0;
    let psum = 0;
    for (const month in item.details) {
      qsum += parseFloat(item.details[month].quantity);
      psum += parseFloat(item.details[month].prod_quantity);
    }

    for (const year in item.aggregate) {
      if (year == 1) {
        item.aggregate[year].quantity = qsum;
        item.aggregate[year].prod_quantity = psum;
        item.aggregate[year].closing_stock = psum - qsum;
        item.aggregate[year].salesrevenue = qsum * (parseFloat(item.sales_price) ? parseFloat(item.sales_price) : 0);
        item.aggregate[year].commission = qsum * (parseFloat(item.commission) ? parseFloat(item.commission) : 0);
        item.aggregate[year].variableCostGST = qsum * (parseFloat(item.variableCostGST) ? parseFloat(item.variableCostGST) : 0);
        item.aggregate[year].variableCostB4GST = qsum * (parseFloat(item.variableCostB4GST) ? parseFloat(item.variableCostB4GST) : 0);
        item.aggregate[year].transport = qsum * (parseFloat(item.transport) ? parseFloat(item.transport) : 0);
        item.aggregate[year].gst_collected_on_sales = qsum * parseFloat((item.sales_price * item.gst_percentage) / 100);
      } else {
        item.aggregate[year].closing_stock = parseFloat(item.aggregate[year - 1].closing_stock) + parseFloat(item.aggregate[year].prod_quantity) - parseFloat(item.aggregate[year].quantity);
        item.aggregate[year].salesrevenue = parseFloat(item.aggregate[year].quantity) * (parseFloat(item.sales_price) ? parseFloat(item.sales_price) : 0);
        item.aggregate[year].commission = parseFloat(item.aggregate[year].quantity) * (parseFloat(item.commission) ? parseFloat(item.commission) : 0);
        item.aggregate[year].variableCostB4GST = parseFloat(item.aggregate[year].quantity) * (parseFloat(item.variableCostB4GST) ? parseFloat(item.variableCostB4GST) : 0);
        item.aggregate[year].variableCostGST = parseFloat(item.aggregate[year].quantity) * (parseFloat(item.variableCostGST) ? parseFloat(item.variableCostGST) : 0);
        item.aggregate[year].transport = parseFloat(item.aggregate[year].quantity) * (parseFloat(item.transport) ? parseFloat(item.transport) : 0);
        item.aggregate[year].gst_collected_on_sales = (parseFloat(item.aggregate[year].salesrevenue) * parseFloat(item.gst_percentage)) / 100;
      }
      const closing_stock = year == 1 ? 0 : parseFloat(item.aggregate[year - 1].closing_stock);
      item.aggregate[year].closing_stock = closing_stock + parseFloat(item.aggregate[year].prod_quantity) - parseFloat(item.aggregate[year].quantity);
    }
    updateProductSku(index, item);
  };

  const tirggerSubsequentYearCalculation = (e, field, year, item, index, product, pIndex) => {
    item.aggregate[year][field] = parseFloat(e.target.value);
    triggerAggregateCalculation(item, index, product, pIndex);
    updateProductSku(index, item);
    triggerSalesRevenueCalculation(product);
    updateProduct(pIndex, product);
    calculateTotalSalesRevenue();
    triggerMasterSalesCalcuation("year");
  };

  const triggerSalesRevenueCalculation = (product) => {
    var skuProduct = getValues("fieldProductSkuName");
    var fieldProductsValue = getValues("fieldProductsName");
    console.log(product)
    if (skuProduct.length && fieldProductsValue.length) {
      for (const monthData in product.details) {
        product.details[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData].salesrevenue) + parseFloat(obj.basicInformation[0].details[monthData].sales_revenue), 0);
        product.sales[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData].gst_collected_on_sales), 0);
        product.gst_collected_on_sales.details[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData].gst_collected_on_sales), 0);
        product.commission.details[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData].commission), 0);
        product.transport.details[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData].transport), 0);
        product.variableCostB4GST.details[monthData] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.basicInformation[0].details[monthData].feed_cost), 0);

        product.variableCostGST.details[monthData] = 0;
        console.log(fieldProductsValue)
        let totalMonthlySalesRevenueValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData]), 0);
        let objM = totalMonthlySalesRevenue;
        objM[monthData] = totalMonthlySalesRevenueValue;

        setTotalMonthlySalesRevenue(objM);
        
        let totalMonthlyGSTCollectOnSaleValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.gst_collected_on_sales.details[monthData]), 0);
        let objGST = totalMonthlyGSTCollectOnSale;
        objGST[monthData] = totalMonthlyGSTCollectOnSaleValue;
        setTotalMonthlyGSTCollectOnSale(objGST);
        
        let totalMonthlyCommission = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.commission.details[monthData]), 0);
        let totalMonthlyTransport = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.transport.details[monthData]), 0);
        let totalMonthlyVariableCostB4GST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostB4GST.details[monthData]), 0);
        let totalMonthlyVariableCostGST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostGST.details[monthData]), 0);
        let obj = totalMonthlyValues;
        obj.details[monthData].salesRevenue = totalMonthlySalesRevenueValue;
        obj.details[monthData].gst_collected_on_sales = totalMonthlyGSTCollectOnSaleValue;
        obj.details[monthData].commission = totalMonthlyCommission;
        obj.details[monthData].transport = totalMonthlyTransport;
        obj.details[monthData].variableCostB4GST = totalMonthlyVariableCostB4GST;
        obj.details[monthData].variableCostGST = totalMonthlyVariableCostGST;

        setTotalMonthlyValues(obj);

      }

      for (const year in product.aggregate) { 
        product.aggregate[year] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year].salesrevenue) + parseFloat(obj.basicInformation[0].aggregate[year].sales_revenue), 0);
        product.gst_collected_on_sales.aggregate[year] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year].gst_collected_on_sales), 0);
        product.commission.aggregate[year] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year].commission), 0)
        product.transport.aggregate[year] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year].transport), 0)

        product.variableCostB4GST.aggregate[year] = skuProduct.filter((x, i) => x.product_id === product.product_id).reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.basicInformation[0].aggregate[year].feed_cost), 0)
        product.variableCostGST.aggregate[year] = 0;

        let totalYearlySalesRevenueValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year]), 0);
        let objY = totalYearlySalesRevenue;
        objY[year] = totalYearlySalesRevenueValue;
        setTotalYearlySalesRevenue(objY);
    
        let totalYearlyGSTCollectOnSaleValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.gst_collected_on_sales.aggregate[year]), 0);
        let objGSTY = totalYearlyGSTCollectOnSale;
        objGSTY[year] = totalYearlyGSTCollectOnSaleValue; 
        setTotalYearlyGSTCollectOnSale(objGSTY);

        let totalYearlyCommission = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.commission.aggregate[year]), 0);
        let totalYearlyTransport = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.transport.aggregate[year]), 0);
        let totalYearlyVariableCostB4GST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostB4GST.aggregate[year]), 0);
        let totalYearlyVariableCostGST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostGST.aggregate[year]), 0);
        let obj = totalMonthlyValues;
        obj.aggregate[year].salesRevenue = totalYearlySalesRevenueValue;
        obj.aggregate[year].gst_collected_on_sales = totalYearlyGSTCollectOnSaleValue;
        obj.aggregate[year].commission = totalYearlyCommission;
        obj.aggregate[year].transport = totalYearlyTransport;
        obj.aggregate[year].variableCostB4GST = totalYearlyVariableCostB4GST;
        obj.aggregate[year].variableCostGST = totalYearlyVariableCostGST;
        setTotalMonthlyValues(obj);
      }

    }
  };
  const calculateTotalSalesRevenue= () => {
  //   var fieldProductsValue = getValues("fieldProductsName");
  //   if (fieldProductsValue.length) {
  //     for (const monthData in fieldProductsValue.details) {
  //     let totalMonthlySalesRevenueValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.details[monthData]), 0);
  //     let objM = totalMonthlySalesRevenue;
  //     objM[monthData] = totalMonthlySalesRevenueValue;

  //     setTotalMonthlySalesRevenue(objM);
      
  //     let totalMonthlyGSTCollectOnSaleValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.gst_collected_on_sales.details[monthData]), 0);
  //     let objGST = totalMonthlyGSTCollectOnSale;
  //     objGST[monthData] = totalMonthlyGSTCollectOnSaleValue;
  //     setTotalMonthlyGSTCollectOnSale(objGST);
      
  //     let totalMonthlyCommission = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.commission.details[monthData]), 0);
  //     let totalMonthlyTransport = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.transport.details[monthData]), 0);
  //     let totalMonthlyVariableCostB4GST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostB4GST.details[monthData]), 0);
  //     let totalMonthlyVariableCostGST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostGST.details[monthData]), 0);
  //     let obj = totalMonthlyValues;
  //     obj.details[monthData].salesRevenue = totalMonthlySalesRevenueValue;
  //     obj.details[monthData].gst_collected_on_sales = totalMonthlyGSTCollectOnSaleValue;
  //     obj.details[monthData].commission = totalMonthlyCommission;
  //     obj.details[monthData].transport = totalMonthlyTransport;
  //     obj.details[monthData].variableCostB4GST = totalMonthlyVariableCostB4GST;
  //     obj.details[monthData].variableCostGST = totalMonthlyVariableCostGST;
  //     }
  //     for (const year in fieldProductsValue.aggregate) {
  //     let totalYearlySalesRevenueValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.aggregate[year]), 0);
  //       let objY = totalYearlySalesRevenue;
  //       objY[year] = totalYearlySalesRevenueValue;
  //       setTotalYearlySalesRevenue(objY);
    
  //       let totalYearlyGSTCollectOnSaleValue = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.gst_collected_on_sales.aggregate[year]), 0);
  //       let objGSTY = totalYearlyGSTCollectOnSale;
  //       objGSTY[year] = totalYearlyGSTCollectOnSaleValue; 
  //       setTotalYearlyGSTCollectOnSale(objGSTY);

  //       let totalYearlyCommission = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.commission.aggregate[year]), 0);
  //       let totalYearlyTransport = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.transport.aggregate[year]), 0);
  //       let totalYearlyVariableCostB4GST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostB4GST.aggregate[year]), 0);
  //       let totalYearlyVariableCostGST = fieldProductsValue.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.variableCostGST.aggregate[year]), 0);
  //       let obj = totalMonthlyValues;
  //       obj.aggregate[year].salesRevenue = totalYearlySalesRevenueValue;
  //       obj.aggregate[year].gst_collected_on_sales = totalYearlyGSTCollectOnSaleValue;
  //       obj.aggregate[year].commission = totalYearlyCommission;
  //       obj.aggregate[year].transport = totalYearlyTransport;
  //       obj.aggregate[year].variableCostB4GST = totalYearlyVariableCostB4GST;
  //       obj.aggregate[year].variableCostGST = totalYearlyVariableCostGST;
  //       setTotalMonthlyValues(obj);
  //   }
  // }
  };

  //CompositionOfSalesCalculation
  const triggerMasterSalesCalcuation = (period) => {
    // if (period === "month") {
  
      for (var i = 0; i < 12; i++) {
        triggerCompositionOfSalesCalculation(i + 1, "Cash");
        triggerCompositionOfSalesCalculation(i + 1, "Credit");
        triggerCompositionOfSalesCalculation(i + 1, "Card");
        triggerCompositionOfSalesCalculation(i + 1, "Advance");
        triggerCashInflowCalculation(i + 1);
      }
    // } else {
      for (var i = 0; i < 3; i++) {
        triggerCompositionOfSalesCalculationAggregate(i + 1, "Cash");
        triggerCompositionOfSalesCalculationAggregate(i + 1, "Credit");
        triggerCompositionOfSalesCalculationAggregate(i + 1, "Card");
        triggerCompositionOfSalesCalculationAggregate(i + 1, "Advance");
      }
    // }
  };
  const triggerCompositionOfSalesCalculation = (month, field) => {
    var obj = compositionOfSales;
    obj[field].details[month] = ((parseFloat(obj[field].value).toFixed(2) * parseFloat(totalMonthlySalesRevenue[month])).toFixed(2) / 100).toFixed(2);
    setCompositionOfSales(obj);
    var obj1 = compositionOfSales;
   
    var noOfMonths_Cash = obj1["Cash"].noOfMonths ? month - parseFloat(obj1["Cash"].noOfMonths) : 0;
    var noOfMonths_Credit = obj1["Credit"].noOfMonths ? month - parseFloat(obj1["Credit"].noOfMonths) : 0;
    var noOfMonths_Card = obj1["Card"].noOfMonths ? month - parseFloat(obj1["Card"].noOfMonths) : 0;
    var noOfMonths_Advance = obj1["Advance"].noOfMonths ? month - parseFloat(obj1["Advance"].noOfMonths) : 0;

    var per_Cash = obj1["Cash"].value ? parseFloat(obj1["Cash"].value) : 0;
    var per_Credit = obj1["Credit"].value ? parseFloat(obj1["Credit"].value) : 0;
    var per_Card = obj1["Card"].value ? parseFloat(obj1["Card"].value) : 0;
    var per_Advance = obj1["Advance"].value ? parseFloat(obj1["Advance"].value) : 0;

    var Cash = (per_Cash * parseFloat(totalMonthlySalesRevenue[month]) / 100);
    var Credit = (noOfMonths_Credit > 0 ? ((per_Credit * parseFloat(totalMonthlySalesRevenue[noOfMonths_Credit])) / 100) : 0);
    var Card = (noOfMonths_Card > 0 ? ((per_Card * parseFloat(totalMonthlySalesRevenue[noOfMonths_Card])) / 100) : 0);
    var Advance = (noOfMonths_Advance > 0 ? ((per_Advance * parseFloat(totalMonthlySalesRevenue[noOfMonths_Advance])) / 100) : 0);

    var Cash_GST = (per_Cash * parseFloat(totalMonthlyGSTCollectOnSale[month]) / 100);
    var Credit_GST = (noOfMonths_Credit > 0 ? ((per_Credit * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Credit])) / 100) : 0);
    var Card_GST = (noOfMonths_Card > 0 ? ((per_Card * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Card])) / 100) : 0);
    var Advance_GST = (noOfMonths_Advance > 0 ? ((per_Advance * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Advance])) / 100) : 0);

    obj[month] = Cash + Credit + Card + Advance;
    // objGST[month] = Cash_GST + Credit_GST + Card_GST + Advance_GST;

    let objCashY=totalYearlyCashInFlowOfSales;
    objCashY[1] = Object.values(totalMonthlyCashInflowOfSales).reduce(
      (sum, value) => sum + value,
      0
    );
  
      objCashY[2]= (parseFloat(compositionOfSales.Card.aggregate[1])+parseFloat(compositionOfSales.Credit.aggregate[1])+parseFloat(compositionOfSales.Advance.aggregate[1])+parseFloat(compositionOfSales.Cash.aggregate[1]))-Object.values(totalMonthlyCashInflowOfSales).reduce(
      (sum, value) => sum + value,
      0
    )
    + parseFloat(compositionOfSales.Card.aggregate[2])+parseFloat(compositionOfSales.Credit.aggregate[2])+parseFloat(compositionOfSales.Advance.aggregate[2])+parseFloat(compositionOfSales.Cash.aggregate[2])
    -
    (
    (parseFloat(compositionOfSales.Card.aggregate[2]) * parseFloat(compositionOfSales.Card.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Cash.aggregate[2]) * parseFloat(compositionOfSales.Cash.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Credit.aggregate[2])  * parseFloat(compositionOfSales.Credit.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Advance.aggregate[2])  * parseFloat(compositionOfSales.Advance.noOfMonths))/12
    )
  
  
  
      objCashY[3]= (parseFloat(compositionOfSales.Card.aggregate[3])+parseFloat(compositionOfSales.Credit.aggregate[3])+parseFloat(compositionOfSales.Advance.aggregate[3])+parseFloat(compositionOfSales.Cash.aggregate[3]))+
      (
        (parseFloat(compositionOfSales.Card.aggregate[2]) / 12 * parseFloat(compositionOfSales.Card.noOfMonths)) +
        (parseFloat(compositionOfSales.Cash.aggregate[2]) / 12 * parseFloat(compositionOfSales.Cash.noOfMonths)) +
        (parseFloat(compositionOfSales.Credit.aggregate[2]) / 12 * parseFloat(compositionOfSales.Credit.noOfMonths))+
       (parseFloat(compositionOfSales.Advance.aggregate[2]) / 12 * parseFloat(compositionOfSales.Advance.noOfMonths))
      ) -
      (
        (parseFloat(compositionOfSales.Advance.aggregate[3]) / 12 * parseFloat(compositionOfSales.Advance.noOfMonths)) +
        (parseFloat(compositionOfSales.Card.aggregate[3]) / 12 * parseFloat(compositionOfSales.Card.noOfMonths)) +
        (parseFloat(compositionOfSales.Cash.aggregate[3]) / 12 * parseFloat(compositionOfSales.Cash.noOfMonths))+
        (parseFloat(compositionOfSales.Credit.aggregate[3]) / 12 * parseFloat(compositionOfSales.Credit.noOfMonths))
  
      );
    setTotalYearlyCashInFlowOfSales(objCashY);
    let objCashGstY= totalYearlyCashInFlowOfSalesGST;
    objCashGstY[1] = Object.values(totalMonthlyCashInflowOfSalesGST).reduce(
      (sum, value) => sum + value,
      0
    );
    objCashGstY[2]= 
    totalYearlyGSTCollectOnSale[1] -
    objCashGstY[1] +
    (totalYearlyGSTCollectOnSale[2] -
      (((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Cash.value) / 100) *
        ((compositionOfSales.Cash.noOfMonths) / 12) +
        ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Card.value) /
          100) *
          ((compositionOfSales.Card.noOfMonths) / 12) +
        ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Credit.value) /
          100) *
          ((compositionOfSales.Credit.noOfMonths) / 12) +
          ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Advance.value) /
            100) *
            ((compositionOfSales.Advance.noOfMonths) / 12)));
  
  
    objCashGstY[3]= totalYearlyGSTCollectOnSale[3] +
    (((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Cash.value) / 100) *
      (( compositionOfSales.Cash.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Card.value) / 100) *
        ((compositionOfSales.Card.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Advance.value) / 100) *
        ((compositionOfSales.Advance.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Credit.value) /
        100) *
        ((compositionOfSales.Credit.noOfMonths) / 12)) -
    (((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Cash.value) / 100) *
      (( compositionOfSales.Cash.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Card.value) / 100) *
        (( compositionOfSales.Card.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Advance.value) / 100) *
        (( compositionOfSales.Advance.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Credit.value) /
        100) *
        (( compositionOfSales.Credit.noOfMonths) / 12));
    setTotalYearlyCashInFlowOfSalesGST(objCashGstY);
  };
  const triggerCashInflowCalculation = (month) => {
    var obj = totalMonthlyCashInflowOfSales;
    var objGST = totalMonthlyCashInflowOfSalesGST;
    var obj1 = compositionOfSales;
   
    var noOfMonths_Cash = obj1["Cash"].noOfMonths ? month - parseFloat(obj1["Cash"].noOfMonths) : 0;
    var noOfMonths_Credit = obj1["Credit"].noOfMonths ? month - parseFloat(obj1["Credit"].noOfMonths) : 0;
    var noOfMonths_Card = obj1["Card"].noOfMonths ? month - parseFloat(obj1["Card"].noOfMonths) : 0;
    var noOfMonths_Advance = obj1["Advance"].noOfMonths ? month - parseFloat(obj1["Advance"].noOfMonths) : 0;

    var per_Cash = obj1["Cash"].value ? parseFloat(obj1["Cash"].value) : 0;
    var per_Credit = obj1["Credit"].value ? parseFloat(obj1["Credit"].value) : 0;
    var per_Card = obj1["Card"].value ? parseFloat(obj1["Card"].value) : 0;
    var per_Advance = obj1["Advance"].value ? parseFloat(obj1["Advance"].value) : 0;

    var Cash = (per_Cash * parseFloat(totalMonthlySalesRevenue[month]) / 100);
    var Credit = (noOfMonths_Credit > 0 ? ((per_Credit * parseFloat(totalMonthlySalesRevenue[noOfMonths_Credit])) / 100) : 0);
    var Card = (noOfMonths_Card > 0 ? ((per_Card * parseFloat(totalMonthlySalesRevenue[noOfMonths_Card])) / 100) : 0);
    var Advance = (noOfMonths_Advance > 0 ? ((per_Advance * parseFloat(totalMonthlySalesRevenue[noOfMonths_Advance])) / 100) : 0);

    var Cash_GST = (per_Cash * parseFloat(totalMonthlyGSTCollectOnSale[month]) / 100);
    var Credit_GST = (noOfMonths_Credit > 0 ? ((per_Credit * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Credit])) / 100) : 0);
    var Card_GST = (noOfMonths_Card > 0 ? ((per_Card * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Card])) / 100) : 0);
    var Advance_GST = (noOfMonths_Advance > 0 ? ((per_Advance * parseFloat(totalMonthlyGSTCollectOnSale[noOfMonths_Advance])) / 100) : 0);

    obj[month] = Cash + Credit + Card + Advance;
    objGST[month] = Cash_GST + Credit_GST + Card_GST + Advance_GST;

   setTotalMonthlyCashInflowOfSales(obj);
   setTotalMonthlyCashInflowOfSalesGST(objGST);
 

  {
    var prices = obj1.Card;
              var percentage = getValues('pay');
              var noOfMonths = parseFloat(prices.noOfMonths);

var creditCardFeeCashInFlow = {
details: {
1: 0,
2: 0,
3: 0,
4: 0,
5: 0,
6: 0,
7: 0,
8: 0,
9: 0,
10: 0,
11: 0,
12: 0,
},
aggregate: {
1: 0,
2: 0,
3: 0,
}
};
var creditCardFee = {
details: {
1: 0,
2: 0,
3: 0,
4: 0,
5: 0,
6: 0,
7: 0,
8: 0,
9: 0,
10: 0,
11: 0,
12: 0,
},
aggregate: {
1: 0,
2: 0,
3: 0,
}
};

for(let month=1; month<=15;month++){

if(month<13){
if(month<=noOfMonths){

creditCardFeeCashInFlow.details[month]=0;
}else{
creditCardFeeCashInFlow.details[month] = parseFloat(prices.details[month-noOfMonths])*percentage/100;
}

}
else if(month===13){
var preceedingAmt = Object.values(prices.details).slice(0,-noOfMonths).reduce((sum, total )=>sum+total , 0)
var preceedingAmtToInt = Object.values(prices.details).map(item=>parseFloat(item)*percentage/100)
creditCardFeeCashInFlow.aggregate[1] = preceedingAmtToInt.slice(0, 12-noOfMonths).reduce((sum, total)=>sum+total,0)
}
else if(month === 14){
var secondYearPreceedingAmt = ((preceedingAmtToInt.slice(-noOfMonths).reduce((sum, total)=>sum+total,0)))
creditCardFeeCashInFlow.aggregate[2] = secondYearPreceedingAmt + (parseFloat(prices.aggregate[2]) - parseFloat(prices.aggregate[2])*parseFloat(noOfMonths)/12)*percentage/100
}
else{
var thirdYearPreceedingAmt =  (parseFloat(prices.aggregate[2])*(parseFloat(noOfMonths))/12) *percentage/100;
creditCardFeeCashInFlow.aggregate[3] =  (parseFloat(prices.aggregate[3]*percentage/100)-parseFloat(prices.aggregate[3])*parseFloat(noOfMonths)/12*percentage/100) +thirdYearPreceedingAmt;
}

}

for(let month=0; month<=14;month++){
  
if(month<12){

  creditCardFee.details[month+1] = prices.details[month+1]*percentage/100;


}
else if(month===12){
var preceedingAmtToInt = Object.values(prices.details).map(item=>parseFloat(item)*percentage/100)
creditCardFee.aggregate[1] = preceedingAmtToInt.reduce((sum, total)=>sum+total,0)
}
else if(month === 13){
creditCardFee.aggregate[2] = (parseFloat(prices.aggregate[2])*percentage/100)
}
else{
creditCardFee.aggregate[3] =  (parseFloat(prices.aggregate[3])*percentage/100)
}

}
setCreditCardFee(creditCardFee)
setCreditCardFeeCashInFlow(creditCardFeeCashInFlow);

}
  };

  const triggerCompositionOfSalesCalculationAggregate = (year, field) => {
    var obj = compositionOfSales;
    obj[field].aggregate[year] = ((parseFloat(obj[field].value).toFixed(2) * parseFloat(totalYearlySalesRevenue[year])).toFixed(2) / 100).toFixed(2);
    setCompositionOfSales(obj);
    let objCashY=totalYearlyCashInFlowOfSales;
    objCashY[1] = Object.values(totalMonthlyCashInflowOfSales).reduce(
      (sum, value) => sum + value,
      0
    );
  
      objCashY[2]= (parseFloat(compositionOfSales.Card.aggregate[1])+parseFloat(compositionOfSales.Credit.aggregate[1])+parseFloat(compositionOfSales.Advance.aggregate[1])+parseFloat(compositionOfSales.Cash.aggregate[1]))-Object.values(totalMonthlyCashInflowOfSales).reduce(
      (sum, value) => sum + value,
      0
    )
    + parseFloat(compositionOfSales.Card.aggregate[2])+parseFloat(compositionOfSales.Credit.aggregate[2])+parseFloat(compositionOfSales.Advance.aggregate[2])+parseFloat(compositionOfSales.Cash.aggregate[2])
    -
    (
    (parseFloat(compositionOfSales.Card.aggregate[2]) * parseFloat(compositionOfSales.Card.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Cash.aggregate[2]) * parseFloat(compositionOfSales.Cash.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Credit.aggregate[2])  * parseFloat(compositionOfSales.Credit.noOfMonths))/12 +
    (parseFloat(compositionOfSales.Advance.aggregate[2])  * parseFloat(compositionOfSales.Advance.noOfMonths))/12
    )
  
  
  
      objCashY[3]= (parseFloat(compositionOfSales.Card.aggregate[3])+parseFloat(compositionOfSales.Credit.aggregate[3])+parseFloat(compositionOfSales.Advance.aggregate[3])+parseFloat(compositionOfSales.Cash.aggregate[3]))+
      (
        (parseFloat(compositionOfSales.Card.aggregate[2]) / 12 * parseFloat(compositionOfSales.Card.noOfMonths)) +
        (parseFloat(compositionOfSales.Cash.aggregate[2]) / 12 * parseFloat(compositionOfSales.Cash.noOfMonths)) +
        (parseFloat(compositionOfSales.Credit.aggregate[2]) / 12 * parseFloat(compositionOfSales.Credit.noOfMonths))+
       (parseFloat(compositionOfSales.Advance.aggregate[2]) / 12 * parseFloat(compositionOfSales.Advance.noOfMonths))
      ) -
      (
        (parseFloat(compositionOfSales.Advance.aggregate[3]) / 12 * parseFloat(compositionOfSales.Advance.noOfMonths)) +
        (parseFloat(compositionOfSales.Card.aggregate[3]) / 12 * parseFloat(compositionOfSales.Card.noOfMonths)) +
        (parseFloat(compositionOfSales.Cash.aggregate[3]) / 12 * parseFloat(compositionOfSales.Cash.noOfMonths))+
        (parseFloat(compositionOfSales.Credit.aggregate[3]) / 12 * parseFloat(compositionOfSales.Credit.noOfMonths))
  
      );
    setTotalYearlyCashInFlowOfSales(objCashY);
    let objCashGstY= totalYearlyCashInFlowOfSalesGST;
    objCashGstY[1] = Object.values(totalMonthlyCashInflowOfSalesGST).reduce(
      (sum, value) => sum + value,
      0
    );
    objCashGstY[2]= 
    totalYearlyGSTCollectOnSale[1] -
    objCashGstY[1] +
    (totalYearlyGSTCollectOnSale[2] -
      (((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Cash.value) / 100) *
        ((compositionOfSales.Cash.noOfMonths) / 12) +
        ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Card.value) /
          100) *
          ((compositionOfSales.Card.noOfMonths) / 12) +
        ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Credit.value) /
          100) *
          ((compositionOfSales.Credit.noOfMonths) / 12) +
          ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Advance.value) /
            100) *
            ((compositionOfSales.Advance.noOfMonths) / 12)));
  
  
    objCashGstY[3]= totalYearlyGSTCollectOnSale[3] +
    (((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Cash.value) / 100) *
      (( compositionOfSales.Cash.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Card.value) / 100) *
        ((compositionOfSales.Card.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Advance.value) / 100) *
        ((compositionOfSales.Advance.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[2] * compositionOfSales.Credit.value) /
        100) *
        ((compositionOfSales.Credit.noOfMonths) / 12)) -
    (((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Cash.value) / 100) *
      (( compositionOfSales.Cash.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Card.value) / 100) *
        (( compositionOfSales.Card.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Advance.value) / 100) *
        (( compositionOfSales.Advance.noOfMonths) / 12) +
      ((totalYearlyGSTCollectOnSale[3] * compositionOfSales.Credit.value) /
        100) *
        (( compositionOfSales.Credit.noOfMonths) / 12));
    setTotalYearlyCashInFlowOfSalesGST(objCashGstY);
  };

  const onSubmit = (data) => {
    setIsLoader(true);
    axios
      .post(`${ApiUrl}salesprojection/agro/create`, {
        ...data,
        compositionOfSales,
        totalYearlySalesRevenue: totalYearlySalesRevenue,
        totalMonthlySalesRevenue:totalMonthlySalesRevenue,
        totalMonthlyGSTCollectOnSale:totalMonthlyGSTCollectOnSale,
        totalYearlyGSTCollectOnSale:totalYearlyGSTCollectOnSale,
        totalMonthlyCashInflowOfSales:totalMonthlyCashInflowOfSales,
        totalMonthlyCashInflowOfSalesGST :totalMonthlyCashInflowOfSalesGST,
        totalMonthlyValues: totalMonthlyValues,
        totalYearlyCashInFlowOfSales: totalYearlyCashInFlowOfSales,
        totalYearlyCashInFlowOfSalesGST: totalYearlyCashInFlowOfSalesGST,
        creditCardFee: creditCardFee,
        creditCardFeeCashInFlow: creditCardFeeCashInFlow,
        manufacturId: location.state.data.businessId,
        editedBy: userObj._id,
      })
      .then((result) => {
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onUpdate = (data) => {
    setIsLoader(true);
    axios
      .put(`${ApiUrl}salesprojection/agro/update`, {
        ...data,
        compositionOfSales,
        _id: salesProjectionId,
        totalYearlySalesRevenue: totalYearlySalesRevenue,
        totalMonthlySalesRevenue:totalMonthlySalesRevenue,
        totalMonthlyGSTCollectOnSale:totalMonthlyGSTCollectOnSale,
        totalYearlyGSTCollectOnSale:totalYearlyGSTCollectOnSale,
        totalMonthlyCashInflowOfSales:totalMonthlyCashInflowOfSales,
        totalMonthlyCashInflowOfSalesGST :totalMonthlyCashInflowOfSalesGST,
        totalMonthlyValues: totalMonthlyValues,
        totalYearlyCashInFlowOfSales: totalYearlyCashInFlowOfSales,
        totalYearlyCashInFlowOfSalesGST: totalYearlyCashInFlowOfSalesGST,
        creditCardFee: creditCardFee,
        creditCardFeeCashInFlow: creditCardFeeCashInFlow,
        editedBy: userObj._id,
      })
      .then((result) => {
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light cursor-pointer">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light cursor-pointer">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Sales Plan
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form id="productOne" onSubmit={handleSubmit(salesProjectionId ? onUpdate : onSubmit)}>
              <div className="row">
                <div className="col-md-12 mb-3 mt-4">Composition of Sale Revenue</div>
                <div className="col-md-12 bgwhite">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>Terms of Payment</th>
                          <th>% age</th>
                          <th>No of Months</th>
                          <th>Pay %age</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                        <tr>
                          <td>Cash</td>
                          <td>
                            <input type="number" step="any" className="form-control" id="percent_cash" {...register("cash_details")} onChange={(e) => setSalesCompositionPercent(e, "Cash")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control" id="noOfMonths_cash" disabled {...register("noOfMonths_cash")} />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="any"
                              className="form-control"
                              id="pay_Credit"
                              {...register("pay_Credit")}
                              // onChange={(e) =>
                              //   setSalesCompositionPay(e, "Credit")
                              // }
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Credit</td>
                          <td>
                            <input type="number" step="any" className="form-control" id="percent_credit" {...register("percent_credit")} onChange={(e) => setSalesCompositionPercent(e, "Credit")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control" id="noOfMonths_Credit" {...register("noOfMonths_Credit")} onChange={(e) => setSalesCompositionMonth(e, "Credit")} />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="any"
                              className="form-control"
                              id="pay_Credit"
                              {...register("pay_Credit")}
                              // onChange={(e) =>
                              //   setSalesCompositionPay(e, "Credit")
                              // }
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Credit Card</td>
                          <td>
                            <input type="number" step="any" className="form-control" id="percent_card" {...register("percent_card")} onChange={(e) => setSalesCompositionPercent(e, "Card")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control" id="noOfMonths_Card" {...register("noOfMonths_Card")} onChange={(e) => setSalesCompositionMonth(e, "Card")}/>
                          </td>
                         
                          <td>
                            <input
                              type="number"
                              step="any"
                              className="form-control"
                              id="pay"
                              {...register("pay")}
                              onChange={(e) =>
                                setSalesCompositionPay(e, "Card")
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Advance</td>
                          <td>
                            <input type="number" step="any" className="form-control" id="percent_Advance" {...register("percent_Advance")} onChange={(e) => setSalesCompositionPercent(e, "Advance")} />
                          </td>
                          <td>
                            <input type="number" step="any" className="form-control" id="noOfMonths_Advance" {...register("noOfMonths_Advance")} onChange={(e) => setSalesCompositionMonth(e, "Advance")}/>
                          </td>
                          <td>
                            <input
                              type="number"
                              step="any"
                              className="form-control"
                              id="pay_Credit"
                              {...register("pay_Credit")}
                              // onChange={(e) =>
                              //   setSalesCompositionPay(e, "Credit")
                              // }
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <input type="number" step="any" className="form-control" id="totalpercent" value={totalPercent} disabled readOnly />
                            {errorInTotal && <span className="text-danger">Total Should be 100%</span>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 col-xl-12">
                    {fieldProducts.map((product, pIndex) => {
                      
                      return (
                        <div className="card shadow-none bg-not-started border mb-3">
                          <div className="card-body">
                            <div className="row">
                              <h5 className="card-title">{product?.productId}</h5>
                              <Accordion>
                                {fieldProductSku.map((x, index) => {
                                  return (
                                    x.product_id == product.product_id && (
                                      <Card key={index}>
                                        <CustomAccordian eventKey={index}>{x.name}</CustomAccordian>
                                        <Accordion.Collapse eventKey={index}>
                                          <Card.Body>
                                            <div className="row">
                                              <div className="col-md-3 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                                  GST %
                                                </label>
                                                <input type="number" className="form-control" id="exampleFormControlInput1" disabled value={x.gst_percentage} {...register(`fieldProductSkuName.${index}.gst_percentage`)} />
                                              </div>
                                              <div className="col-md-3 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                                  Sales Price
                                                </label>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" disabled value={getNumericFormat(x.sales_price)} />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="table-responsive">
                                                  <table className="table table-bordered">
                                                    <thead className="table-light">
                                                      <tr>
                                                        <th>Months</th>
                                                        <th>1</th>
                                                        <th>2</th>
                                                        <th>3</th>
                                                        <th>4</th>
                                                        <th>5</th>
                                                        <th>6</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                      <tr>
                                                        <td>Sales Quantity</td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_1" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[1].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 1, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_2" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[2].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 2, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_3" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[3].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 3, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_4" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[4].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 4, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_5" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[5].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 5, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_6" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[6].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 6, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                      </tr>
                                                      {/* <tr>
                                                        <td>Production Quantity</td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"production_quantity" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[1].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 1, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_2" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[2].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 2, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_3" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[3].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 3, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_4" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[4].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 4, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_5" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[5].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 5, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_6" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[6].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 6, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                      </tr> */}
                                                      {/* <tr >
                                                        <td>Closing Stock</td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[1].closing_stock)} id={"closing_stock_1" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[2].closing_stock)} id={"closing_stock_2" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[3].closing_stock)} id={"closing_stock_3" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[4].closing_stock)} id={"closing_stock_4" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[5].closing_stock)} id={"closing_stock_5" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[6].closing_stock)} id={"closing_stock_6" + index} disabled readOnly />
                                                        </td>
                                                      </tr> */}
                                                      <tr>
                                                        <td>Sales Revenue Before GST</td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[1].salesrevenue)} id={"salesrevenue_1" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[2].salesrevenue)} id={"salesrevenue_2" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[3].salesrevenue)} id={"salesrevenue_3" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[4].salesrevenue)} id={"salesrevenue_4" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[5].salesrevenue)} id={"salesrevenue_5" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[6].salesrevenue)} id={"salesrevenue_6" + index} disabled readOnly />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>GST Collected on Sales</td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[1].gst_collected_on_sales)} id={"salesrevenue_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[2].gst_collected_on_sales)} id={"salesrevenue_2" + index} />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[3].gst_collected_on_sales)} id={"salesrevenue_3" + index} />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[4].gst_collected_on_sales)} id={"salesrevenue_4" + index} />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[5].gst_collected_on_sales)} id={"salesrevenue_5" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[6].gst_collected_on_sales)} id={"salesrevenue_6" + index} />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="table-responsive mt-4">
                                                  <table className="table table-bordered">
                                                    <thead className="table-light">
                                                      <tr>
                                                        <th>Months</th>
                                                        <th>7</th>
                                                        <th>8</th>
                                                        <th>9</th>
                                                        <th>10</th>
                                                        <th>11</th>
                                                        <th>12</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                      <tr>
                                                        <td>Sales Quantity</td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_7" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[7].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 7, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_8" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[8].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 8, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_9" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[9].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 9, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_10" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[10].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 10, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_11" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[11].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 11, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"quantity_12" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[12].quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("quantity", 12, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                      </tr>
                                                      {/* <tr>
                                                        <td>Production Quantity</td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_7" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[7].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 7, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_8" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[8].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 8, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_9" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[9].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 9, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_10" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[10].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 10, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_11" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[11].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 11, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"prod_quantity_12" + index}
                                                            {...register(`fieldProductSkuName.${index}.details[12].prod_quantity`, {
                                                              onChange: (e) => {
                                                                triggerMasterCalculation("prod_quantity", 12, index, x, e, product, pIndex);
                                                              },
                                                            })}
                                                          />
                                                        </td>
                                                      </tr> */}

                                                      {/* <tr>
                                                        <td>Closing Stock</td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[7].closing_stock)} id={"closing_stock_7" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[8].closing_stock)} id={"closing_stock_8" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[9].closing_stock)} id={"closing_stock_9" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[10].closing_stock)} id={"closing_stock_10" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[11].closing_stock)} id={"closing_stock_11" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[12].closing_stock)} id={"closing_stock_12" + index} disabled readOnly />
                                                        </td>
                                                      </tr> */}
                                                      <tr>
                                                        <td>Sales Revenue Before GST</td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[7].salesrevenue)} id={"salesrevenue_7" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[8].salesrevenue)} id={"salesrevenue_8" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[9].salesrevenue)} id={"salesrevenue_9" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[10].salesrevenue)} id={"salesrevenue_10" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[11].salesrevenue)} id={"salesrevenue_11" + index} disabled readOnly />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[12].salesrevenue)} id={"salesrevenue_12" + index} disabled readOnly />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>GST Collected on Sales</td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[7].gst_collected_on_sales)} id={"salesrevenue_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[8].gst_collected_on_sales)} id={"salesrevenue_2" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[9].gst_collected_on_sales)} id={"salesrevenue_3" + index} />
                                                        </td>
                                                        <td>
                                                          <input numformat="numberRight" type="text" disabled className="form-control" value={getNumericFormat(x.details[10].gst_collected_on_sales)} id={"salesrevenue_4" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[11].gst_collected_on_sales)} id={"salesrevenue_5" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.details[12].gst_collected_on_sales)} id={"salesrevenue_6" + index} />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="table-responsive mt-4">
                                                  <table className="table table-bordered">
                                                    <thead className="table-light">
                                                      <tr>
                                                        <th>Years</th>
                                                        <th>Year 1</th>
                                                        <th>Year 2</th>
                                                        <th>Year 3</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                      <tr>
                                                        <td>Sales Quantity</td>
                                                        <td>
                                                          <input disabled value={getNumericFormat(x.aggregate[1].quantity)} numformat="numberRight" type="text" className="form-control" id={"aggregate_quantity_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input
                                                            {...register(`fieldProductSkuName.${index}.aggregate[2].quantity`, {
                                                              onChange: (e) => {
                                                                tirggerSubsequentYearCalculation(e, "quantity", 2, x, index, product, pIndex);
                                                              },
                                                            })}
                                                            value={x.aggregate[2].quantity}
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"aggregate_quantity_2" + index}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            {...register(`fieldProductSkuName.${index}.aggregate[3].quantity`, {
                                                              onChange: (e) => {
                                                                tirggerSubsequentYearCalculation(e, "quantity", 3, x, index, product, pIndex);
                                                              },
                                                            })}
                                                            value={x.aggregate[3].quantity}
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"aggregate_quantity_3" + index}
                                                          />
                                                        </td>
                                                      </tr>
                                                      {/* <tr>
                                                        <td>Production Quantity</td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[1].prod_quantity)} numformat="numberRight" type="text" className="form-control" id={"aggregate_prod_quantity_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input
                                                            {...register(`fieldProductSkuName.${index}.aggregate[2].prod_quantity`, {
                                                              onChange: (e) => {
                                                                tirggerSubsequentYearCalculation(e, "prod_quantity", 2, x, index, product, pIndex);
                                                              },
                                                            })}
                                                            value={x.aggregate[2].prod_quantity}
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"aggregate_prod_quantity_2" + index}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            {...register(`fieldProductSkuName.${index}.aggregate[3].prod_quantity`, {
                                                              onChange: (e) => {
                                                                tirggerSubsequentYearCalculation(e, "prod_quantity", 3, x, index, product, pIndex);
                                                              },
                                                            })}
                                                            value={x.aggregate[3].prod_quantity}
                                                            type="number" step="any"
                                                            className="form-control"
                                                            id={"aggregate_prod_quantity_3" + index}
                                                          />
                                                        </td>
                                                      </tr> */}
                                                      {/* <tr>
                                                        <td>Closing Stock</td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[1].closing_stock)} numformat="numberRight" type="text" className="form-control" id={"aggregate_closing_stock_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[2].closing_stock)} numformat="numberRight" type="text" className="form-control" id={"aggregate_closing_stock_2" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[3].closing_stock)} numformat="numberRight" type="text" className="form-control" id={"aggregate_closing_stock_3" + index} />
                                                        </td>
                                                      </tr> */}
                                                      <tr>
                                                        <td>Sales Revenue Before GST</td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[1].salesrevenue)} numformat="numberRight" type="text" className="form-control" id={"aggregate_salesrevenue_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[2].salesrevenue)} numformat="numberRight" type="text" className="form-control" id={"aggregate_salesrevenue_2" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled readOnly value={getNumericFormat(x.aggregate[3].salesrevenue)} numformat="numberRight" type="text" className="form-control" id={"aggregate_salesrevenue_3" + index} />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>GST Collected on Sales</td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.aggregate[1].gst_collected_on_sales)} id={"salesrevenue_1" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.aggregate[2].gst_collected_on_sales)} id={"salesrevenue_2" + index} />
                                                        </td>
                                                        <td>
                                                          <input disabled numformat="numberRight" type="text" className="form-control" value={getNumericFormat(x.aggregate[3].gst_collected_on_sales)} id={"salesrevenue_3" + index} />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    )
                                  );
                                })}
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-4">
                  <button type="button" onClick={() => { setShow(true) }} className="btn btn-primary me-2 float-end">
                    Sales Revenue Summary
                  </button>
                </div>
              </div>

              <hr />
              <div className="mt-2">
                <button type="submit" className="btn btn-primary me-2">
                  {salesProjectionId ? "Update Changes" : "Save changes"}
                </button>
                <button type="reset" onClick={() => navigate(-1)} className="btn btn-outline-secondary">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <CustomModal show={show} handleClose={handleClose} title="Sales Revenue Summary" fieldProducts={fieldProducts} fieldProductSku={fieldProductSku} totalMonthlySalesRevenue={totalMonthlySalesRevenue} totalMonthlyGSTCollectOnSale={totalMonthlyGSTCollectOnSale} totalYearlyGSTCollectOnSale={totalYearlyGSTCollectOnSale} totalYearlySalesRevenue={totalYearlySalesRevenue} compositionOfSales={compositionOfSales} totalMonthlyCashInflowOfSales={totalMonthlyCashInflowOfSales} totalYearlyCashInFlowOfSales={totalYearlyCashInFlowOfSales} totalYearlyCashInFlowOfSalesGST={totalYearlyCashInFlowOfSalesGST} totalMonthlyCashInflowOfSalesGST ={totalMonthlyCashInflowOfSalesGST} Body={SalesRevenewReadOnly} isModalXl={true}></CustomModal>
    </Layout>
  );
}

export default SalesProjectionAgroDetails;
