import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./screens/login/components/login";
import ForgotPassword from "./screens/login/components/forgotPassword";
import Dashboard from "./screens/dashboard/components/dashboard";
import UserList from "./screens/users/components/userList";
import CenterList from "./screens/centers/components/centerList";
import ProfilePage from "./screens/profile/components/profilePage";
import ProtégéDetailsRouter from "screens/protégé/components/ProtégéDetailsRouter";
import PersonalInfo from "screens/protégé/components/ProtégéForms/personalInformation";
import BusinessIdeaModel from "screens/protégé/components/ProtégéForms/businessIdeaModel";
import { Navigate } from "react-router";
import ProtégéList from "screens/protégé/components/protégéList";
import ManageProtégé from "screens/protégé/components/ManageProtégé";
import ProtegeReports from "screens/protégé/components/ProtegeReports";
import CashFlowPage from "screens/protégé/components/CashFlowPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/fonts/boxicons.css";
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/css/demo.css";
import "./assets/css/custom.css";
import "./assets/vendor/css/pages/page-auth.css";
import ActiveAccount from "screens/login/components/activeAccount";
import ManufacturingProductDetails from "screens/protégé/components/ProtégéForms/manufacturingProductDetails";
import ServicesProductDetails from "screens/protégé/components/ProtégéForms/serviceProductDetails";
import TradingProductDetails from "screens/protégé/components/ProtégéForms/tradingProductDetails";
import ProductList from "screens/protégé/components/common/productList";
import AgroProductDetails from "screens/protégé/components/ProtégéForms/agroProductDetails";
import HusbandryProductDetails from "screens/protégé/components/ProtégéForms/husbandryProductDetails";
import SalesProjectionDetails from "screens/protégé/components/ProtégéForms/salesProjectionDetials";
import CapacityProductDetails from "screens/protégé/components/ProtégéForms/capacityProductDetails";
import RawMaterialConsumption from "screens/protégé/components/ProtégéForms/rawMaterialConsumption";
import StartUpCost from "screens/protégé/components/ProtégéForms/startupCost";
import Manpower from "screens/protégé/components/ProtégéForms/manpower";
import FixedCost from "screens/protégé/components/ProtégéForms/fixedCost";
import SourcesOfFund from "screens/protégé/components/ProtégéForms/sourcesOfFund";
import OrderingFrequency from "screens/protégé/components/ProtégéForms/orderingFrequency";
import RawMaterialProcurement from "screens/protégé/components/ProtégéForms/rawMaterialProcurement";
import SalesProjectionAgroDetails from "screens/protégé/components/ProtégéForms/salesProjectionAgroDetails";
import SalesProjectionAnimalHusbandryDetails from "screens/protégé/components/ProtégéForms/salesProjectionAnimalHusbandryDetails";
import SalesProjectionTradingDetails from "screens/protégé/components/ProtégéForms/salesProjectionTradingDetails";
import SalesProjectionServicesDetails from "screens/protégé/components/ProtégéForms/salesProjectionServicesDetails";
import AnimalHusbandryProductDetails from "screens/protégé/components/ProtégéForms/animalHusbandryProductDetails";
import ResetPassword from "screens/login/components/resetPassword";
import EditNarrative from "screens/protégé/components/narrative/editNarrative";
function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute redirectTo="/login">
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/forgotPassword"
        element={
          <PublicRoute redirectTo="/forgotPassword">
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <PublicRoute redirectTo="/reset-password/:token">
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/activate-account/:token"
        element={
          <PublicRoute redirectTo="/activate-account">
            <ActiveAccount />
          </PublicRoute>
        }
      />
      {/* <Route
        path="/"
        element={
          <RequireAuth redirectTo="/login">
            <Dashboard />
          </RequireAuth>
        }
      /> */}
      {/* <Route
        path="/dashboard"
        element={
          <RequireAuth redirectTo="/login">
            <Dashboard />
          </RequireAuth>
        }
      /> */}
        <Route
        path="/"
        element={
          <RequireAuth redirectTo="/login">
            <ProtégéList />
          </RequireAuth>
        }
      />
        <Route
        path="/Protege"
        element={
          <RequireAuth redirectTo="/login">
            <ProtégéList />
          </RequireAuth>
        }
      />

      <Route
        path="/users"
        element={
          <RequireAuth redirectTo="/login">
            <UserList />
          </RequireAuth>
        }
      />
      <Route
        path="/centres"
        element={
          <RequireAuth redirectTo="/login">
            <CenterList />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth redirectTo="/login">
            <ProfilePage />
          </RequireAuth>
        }
      />
    
      <Route
        path="/ManageProtege/:op"
        element={
          <RequireAuth redirectTo="/login">
            <ManageProtégé />
          </RequireAuth>
        }
      />
      <Route
        path="/ManageProtege/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <ManageProtégé />
          </RequireAuth>
        }
      />
      <Route
        path="/CashFlowPage/:op"
        element={
          <RequireAuth redirectTo="/login">
            <CashFlowPage />
          </RequireAuth>
        }
      />
      <Route
        path="/CashFlowPage/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <CashFlowPage />
          </RequireAuth>
        }
      />
      <Route
        path="/ProtegeReports/:op"
        element={
          <RequireAuth redirectTo="/login">
            <ProtegeReports />
          </RequireAuth>
        }
      />
      <Route
        path="/ProtegeReports/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <ProtegeReports />
          </RequireAuth>
        }
      />
      <Route
        path="/ProtegeReports/EditNarrative/:id"
        element={
          <RequireAuth redirectTo="/login">
            <EditNarrative />
          </RequireAuth>
        }
      />
      <Route
        path="/ProtegeDetailsRouter/:op/:route/"
        element={
          <RequireAuth redirectTo="/login">
            <ProtégéDetailsRouter />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/PersonalInformation/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <PersonalInfo />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/BusinessIdeaModel/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <BusinessIdeaModel />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/SalesProjection/AnimalHusbandry/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SalesProjectionAnimalHusbandryDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/SalesProjection/Agro/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SalesProjectionAgroDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/SalesProjection/Trading/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SalesProjectionTradingDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/SalesProjection/Services/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SalesProjectionServicesDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/SalesProjection/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SalesProjectionDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/Capacity/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <CapacityProductDetails />
          </RequireAuth>
        }
      />
     
      <Route
        path="/Protege/RawMaterialConsumption/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialConsumption />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialConsumption/Trading/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialConsumption/>
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialConsumption/AnimalHusbandry/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialConsumption />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/RawMaterialConsumption/Services/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialConsumption />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialConsumption/Agro/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialConsumption />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/StartupCost/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <StartUpCost />
          </RequireAuth>
        }
      />
      
      <Route
        path="/Protege/Manpower/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <Manpower />
          </RequireAuth>
        }
      />
     1
      <Route
        path="/Protege/FixedCost/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <FixedCost />
          </RequireAuth>
        }
      />
    
      <Route
        path="/Protege/SourcesOfFund/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <SourcesOfFund />
          </RequireAuth>
        }
      />
      
      <Route
        path="/Protege/OrderingFrequency/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <OrderingFrequency />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/OrderingFrequency/Trading/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <OrderingFrequency />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/OrderingFrequency/AnimalHusbandry/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <OrderingFrequency />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/OrderingFrequency/Services/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <OrderingFrequency />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/OrderingFrequency/Agro/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <OrderingFrequency />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/RawMaterialProcurement/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialProcurement />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialProcurement/Trading/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialProcurement />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialProcurement/AnimalHusbandry/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialProcurement />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialProcurement/Services/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialProcurement />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/RawMaterialProcurement/Agro/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <RawMaterialProcurement />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/ManufacturingDetails/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <ManufacturingProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/ManufacturingDetails/:op"
        element={
          <RequireAuth redirectTo="/login">
            <ManufacturingProductDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/AgroDetails/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <AgroProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/AgroDetails/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <AgroProductDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/HusbandaryDetails/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <HusbandryProductDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/:product/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <ProductList />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/TradingDetails/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <TradingProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/TradingDetails/:op"
        element={
          <RequireAuth redirectTo="/login">
            <TradingProductDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/Protege/ServiceDetails/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <ServicesProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/ServiceDetails/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <ServicesProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/AnimalHusbandryDetails/:op/"
        element={
          <RequireAuth redirectTo="/login">
            <AnimalHusbandryProductDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/Protege/AnimalHusbandryDetails/:op/:id"
        element={
          <RequireAuth redirectTo="/login">
            <AnimalHusbandryProductDetails />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = localStorage.getItem("auth_token");
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
function PublicRoute({ children, redirectTo }) {
  let isAuthenticated = localStorage.getItem("auth_token");
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

export default App;
