import { ApiUrl } from "API/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
function UpdateCenter(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  return (
    <>
        <ToastContainer />
   
    <form
     onSubmit={handleSubmit((data)=>props.props.udapteCenter(data))}
     >
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Centre Name
          </label>
          <input
            type="text"
            id="nameBackdrop"
            className={`form-control ${errors.name ? "errorBorder" : ""}`}
            placeholder="Enter Centre Name"
           defaultValue={props.props.data.name}
           disabled
           // {...register("name", { required: true })}
           />
        </div>
           {errors.name && <span className="textRed">*Centre name is required</span>}
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="emailBackdrop" className="form-label required">
            Centre Code
          </label>
          <input
            type="text"
            id="emailBackdrop"
            className={`form-control ${errors.code ? "errorBorder" : ""}`}
            placeholder="Enter Centre Code"
            disabled
            
            defaultValue={props.props.data.code}
            // {...register("code", { required: true })}
          />
           {errors.code && <span className="textRed">*Centre code is required</span>}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Status
          </label>
          <select
           className={`form-select ${errors.status ? "errorBorder" : ""}`}
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            defaultValue={props.props.data.status}
            {...register("status", { required: true })}
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          {errors.status && <span className="textRed">*Centre status is required</span>}
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Save</button>
    </form>
    </>
  );
}

export default UpdateCenter;
