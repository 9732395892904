import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {useParams, useNavigate, useLocation} from "react-router";
import {set, useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import {ApiUrl} from "API/config";
import {toast, ToastContainer} from "react-toastify";
import {MdDelete} from "react-icons/md";
import Loader from "common/components/loader";
function StartUpCost() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const {register, control, handleSubmit, reset, formState, setValue} = useForm();
 const [isLoader, setIsLoader] = useState(false);
  const {fields: fieldNonDepreciables, append: appendNonDepreciables, update: updateNonDepreciables, remove: removeNonDepreciables, setValue: setValueNonDepreciables} = useFieldArray({name: "fieldNonDepreciablesName", control});

  const {fields: fieldDepreciables, append: appendDepreciables, update: updateDepreciables, remove: removeDepreciables, setValue: setValueDepreciables} = useFieldArray({name: "fieldDepreciablesName", control});

  const {fields: fieldIntangibles, append: appendIntangibles, update: updateIntangibles, remove: removeIntangibles, setValue: setValueIntangibles} = useFieldArray({name: "fieldIntangiblesName", control});

  const {fields: fieldDepositsAndAdvances, append: appendDepositsAndAdvances, update: updateDepositsAndAdvances, remove: removeDepositsAndAdvances, setValue: setValueDepositsAndAdvances} = useFieldArray({name: "fieldDepositsAndAdvancesName", control});

  const [totalDepreciableAssets, setTotalDepreciableAssets] = useState(0);
  const [totalNonDepreciableAssets, setTotalNonDepreciableAssets] = useState(0);
  const [totalIntangiblesAssets, setTotalIntangiblesAssets] = useState(0);
  const [isAddingAdditionalMachine, setIsAddingAdditionalMachine] = useState(false);
  const [totalDepositsAndAdvancesAssets, setTotalDepositsAndAdvancesAssets] = useState(0);
  const [initialRawMaterialPurchase, setInitialRawMaterialPurchase] = useState(0);
  const [initialCash, setInitialCash] = useState(0);
  const [totalFundsRequired, setTotalFundsRequired] = useState(0);
  const [fixedCostDuringGestPeriod, setFixedCostDuringGestPeriod] = useState(0);

  let startupCostId = location.state.data.startupCostId && location.state.data.startupCostId;
  // console.log("location", location);
  useEffect(() => {
    getFixedCostData();
  }, []);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,  });
    return num ? numberFormatter.format(num) : 0;
  };
  const getNumericFormatNodecimal = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,
  });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    console.log("navigator", navigator);

    if (!startupCostId) {
      appendNonDepreciables({
        Sl_No: 1,
        Description: "",
        Unit_of_Measure: "",
        Rate_per_unit_Rupees: 0,
        Quantity: 0,
        GST_percent_for_Product: 0,
        Transport_Installation: 0,
        GST_for_Transport_Installation: 0,
        Total: 0,
        Total_GST: 0,
        Total_Without_GST: 0
      });

      appendDepreciables({
        Sl_No: 1,
        Description: "",
        Unit_of_Measure: "",
        Rate_per_unit_Rupees: 0,  
        Quantity: 0,
        GST_percent_for_Product: 0,
        Transport_Installation: 0,
        GST_for_Transport_Installation: 0,
        Total: 0,
        Total_GST: 0,
        Total_Without_GST: 0,
        Rated_Capacity: 0,
        Depriciable_Life: 0,
        Lead_Time: 0,
        Installation_Time: 0,
      });

      appendIntangibles({
        Sl_No: 1,
        Description: "",
        Unit_of_Measure: "",
        Rate_per_unit_Rupees: 0,
        Quantity: 0,
        GST_percent_for_Product: 0,
        Transport_Installation: 0,
        GST_for_Transport_Installation: 0,
        Total: 0,
        Total_Without_GST: 0,
        Total_GST: 0,
        Amortization_Life: 0,
      });

      appendDepositsAndAdvances({
        Sl_No: 1,
        Description: "",
        Unit_of_Measure: "",
        Rate_per_unit_Rupees: 0,
        Quantity: 0,
        Total: 0,
      });
    } else {
      getStartupCost();
    }
  }, []);

  const getFixedCostData = (data) => {
    setIsLoader(true);
    axios.get(`${ApiUrl}protege/${location.state.data.protegeId}`).then((result) => {
     
        if (result.data[0].fixedCost && result.data[0].fixedCost._id) {
          let requiredItems = result.data[0].fixedCost.fieldLineItemsName.filter((x) => (x.details && x.details.gestPeriod ? x.details.gestPeriod : 0));
              let requiredFixedCostGest = requiredItems.reduce((prev, curr) => prev + curr.details.gestPeriod, 0);
              setFixedCostDuringGestPeriod(requiredFixedCostGest);
              if (data) {
                let requiredValue = parseFloat(data.totalDepreciableAssets) + parseFloat(data.totalIntangiblesAssets) + parseFloat(data.totalDepositsAndAdvancesAssets) + parseFloat(data.totalNonDepreciableAssets) + parseFloat(data.initialRawMaterialPurchase) + parseFloat(requiredFixedCostGest) + parseFloat(data.initialCash);
                setTotalFundsRequired(requiredValue);
              }
               setTimeout(() => setIsLoader(false), 400);
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {setIsLoader(false);});
  };
  useEffect(() => {
    let requiredValue = parseFloat(totalDepreciableAssets) + parseFloat(totalNonDepreciableAssets) + parseFloat(totalDepositsAndAdvancesAssets) + parseFloat(totalIntangiblesAssets) + parseFloat(initialRawMaterialPurchase) + parseFloat(fixedCostDuringGestPeriod) + parseFloat(initialCash);
    setTotalFundsRequired(requiredValue);
  }, [totalDepreciableAssets, totalNonDepreciableAssets, totalDepositsAndAdvancesAssets, totalIntangiblesAssets, initialRawMaterialPurchase, fixedCostDuringGestPeriod, initialCash]);

  const onChangeOfValue = (InitialRawMaterialPurchase, FixedCostDuringGest, InitialCash) => {
    // let inputValue = value ? parseFloat(value) : 0;
    setInitialRawMaterialPurchase(InitialRawMaterialPurchase);
    setFixedCostDuringGestPeriod(FixedCostDuringGest);
    setInitialCash(InitialCash);
    let requiredValue = parseFloat(totalDepreciableAssets) + parseFloat(totalNonDepreciableAssets) + parseFloat(totalDepositsAndAdvancesAssets) + parseFloat(totalIntangiblesAssets) + parseFloat(initialRawMaterialPurchase) + parseFloat(fixedCostDuringGestPeriod) + parseFloat(initialCash);

    setTotalFundsRequired(requiredValue);
  };

  const getStartupCost = () => {
     setIsLoader(true);
    axios
      .get(`${ApiUrl}startupCost/startupCost/${startupCostId}`)
      .then((res) => {
        reset(res.data);
        setTotalDepreciableAssets(res.data.totalDepreciableAssets);
        setTotalIntangiblesAssets(res.data.totalIntangiblesAssets);
        setTotalDepositsAndAdvancesAssets(res.data.totalDepositsAndAdvancesAssets);
        setTotalNonDepreciableAssets(res.data.totalNonDepreciableAssets);
        setIsAddingAdditionalMachine(res.data.isAddingAdditionalMachine);
        setInitialRawMaterialPurchase(res.data.initialRawMaterialPurchase);
        setInitialCash(res.data.initialCash);
        getFixedCostData(res.data);
        onChangeOfValue(res.data.initialRawMaterialPurchase, fixedCostDuringGestPeriod, res.data.initialCash);
        // onChangeOfValue(res.data.initialRawMaterialPurchase,fixedCostDuringGestPeriod)
        //   let requiredValue =
        //   parseFloat(res.data.totalDepreciableAssets) +
        //   parseFloat(res.data.totalIntangiblesAssets) +
        //   parseFloat(res.data.totalDepositsAndAdvancesAssets) +
        //   parseFloat(res.data.totalNonDepreciableAssets)+
        //   res.data.initialRawMaterialPurchase +
        //   parseFloat(fixedCostDuringGestPeriod);
        // console.log(requiredValue);
        setTotalFundsRequired(res.data.totalFundsRequired);
         setTimeout(() => setIsLoader(false), 400);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const triggerNonDepreciableChange = (e, i, x, field, type) => {
    let value = e.target.value;

    if (type === "number") {
      value = parseFloat(value);
    }
    x[field] = value;
    let requiredTotal = x.Rate_per_unit_Rupees * x.Quantity + (x.Rate_per_unit_Rupees * x.Quantity * x.GST_percent_for_Product) / 100 + x.Transport_Installation + (x.Transport_Installation * x.GST_for_Transport_Installation) / 100;

    x.Total = parseFloat(requiredTotal);

    setValue("fieldDepreciablesName", fieldDepreciables);
    setTotalDepreciableAssets(fieldDepreciables.reduce((acc, x) => acc + parseFloat(x.Total), 0));

    setValue("fieldNonDepreciablesName", fieldNonDepreciables);
    setTotalNonDepreciableAssets(fieldNonDepreciables.reduce((acc, x) => acc + parseFloat(x.Total), 0));
  };

  const triggerDepreciableChange = (e, i, x, field, type) => {
    let value = e.target.value;

    if (type === "number") {
      value = parseFloat(value);
    }
    x[field] = value;
    let requiredTotal = x.Rate_per_unit_Rupees * x.Quantity + (x.Rate_per_unit_Rupees * x.Quantity * x.GST_percent_for_Product) / 100 + x.Transport_Installation + (x.Transport_Installation * x.GST_for_Transport_Installation) / 100;
    x.Total = parseFloat(requiredTotal);

    if (x.Depriciable_Life) {
      x.Monthly_Depreciation = parseFloat(x.Total / (x.Depriciable_Life * 12));
    } else {
      x.Monthly_Depreciation = 0;
      // (x.Total / (1 * 12));
    }

    setValue("fieldDepreciablesName", fieldDepreciables);
    setTotalDepreciableAssets(fieldDepreciables.reduce((acc, x) => acc + parseFloat(x.Total), 0));
  };

  const triggerIntangiblesChange = (e, i, x, field, type) => {
    let value = e.target.value;

    if (type === "number") {
      value = parseFloat(value);
    }
    x[field] = value;

    if (x.Amortization_Life) {
      x.Monthly_Amortization = parseFloat(x.Total / (x.Amortization_Life * 12));
    } else {
      x.Monthly_Amortization = 0;
      // (x.Total / (1 * 12));
    }
    let requiredTotal = x.Rate_per_unit_Rupees * x.Quantity + (x.Rate_per_unit_Rupees * x.Quantity * x.GST_percent_for_Product) / 100 + x.Transport_Installation + (x.Transport_Installation * x.GST_for_Transport_Installation) / 100;
    x.Total = parseFloat(requiredTotal);
    setValue("fieldIntangiblesName", fieldIntangibles);
    setTotalIntangiblesAssets(fieldIntangibles.reduce((acc, x) => acc + parseFloat(x.Total), 0));
  };

  const triggerDepositsAndAdvancesChange = (e, i, x, field, type) => {
    let value = e.target.value;
    if (type === "number") {
      value = parseFloat(value);
    }
    x[field] = value;
    if (field === "Rate_per_unit_Rupees" || field === "Quantity") {
      x.Total = x.Rate_per_unit_Rupees * x.Quantity;
    }
    //setValue("fieldIntangiblesName", fieldDepositsAndAdvances);
    updateDepositsAndAdvances(i, x);
    setTotalDepositsAndAdvancesAssets(fieldDepositsAndAdvances.reduce((acc, x) => acc + parseFloat(x.Total), 0));
  };
  const handleAddNonDepreciable = () => {
    let sno = fieldNonDepreciables.length > 0 ? fieldNonDepreciables.length + 1 : 1;

    appendNonDepreciables({
      Sl_No: sno,
      Description: "",
      Unit_of_Measure: "",
      Rate_per_unit_Rupees: 0,
      Quantity: 0,
      GST_percent_for_Product: 0,
      Transport_Installation: 0,
      GST_for_Transport_Installation: 0,
      Total: 0, 
      Total_Without_GST: 0,
      Total_GST: 0,
    });
  };
  const handleRemoveNonDepreciable = (i) => {
    removeNonDepreciables(i);
  };

  const handleAddDepreciable = () => {
    let sno = fieldDepreciables.length > 0 ? fieldDepreciables.length + 1 : 1;

    appendDepreciables({
      Sl_No: sno,
      Description: "",
      Unit_of_Measure: "",
      Rate_per_unit_Rupees: 0,
      Quantity: 0,
      GST_percent_for_Product: 0,
      Transport_Installation: 0,
      GST_for_Transport_Installation: 0,
      Total: 0,
      Total_GST: 0,
      Total_Without_GST: 0,
      Rated_Capacity: 0,
      Depriciable_Life: 0,
      Lead_Time: 0,
      Installation_Time: 0,
    });
  };
  const handleRemoveDepreciable = (i) => {
    removeDepreciables(i);
  };

  const handleAddIntangibles = () => {
    let sno = fieldIntangibles.length > 0 ? fieldIntangibles.length + 1 : 1;

    appendIntangibles({
      Sl_No: sno,
      Description: "",
      Unit_of_Measure: "",
      Rate_per_unit_Rupees: 0,
      Quantity: 0,
      GST_percent_for_Product: 0,
      Transport_Installation: 0,
      GST_for_Transport_Installation: 0,
      Total: 0,
      Total_GST: 0,
      Total_Without_GST: 0,
      Amortization_Life: 0,
    });
  };
  const handleRemoveIntangibles = (i) => {
    removeIntangibles(i);
  };

  const handleAddDepositsAndAdvances = () => {
    let sno = fieldDepositsAndAdvances.length > 0 ? fieldDepositsAndAdvances.length + 1 : 1;

    appendDepositsAndAdvances({
      Sl_No: sno,
      Description: "",
      Unit_of_Measure: "",
      Rate_per_unit_Rupees: 0,
      Quantity: 0,
      Total: 0,
    });
  };
  const handleRemoveDepositsAndAdvances = (i) => {
    removeDepositsAndAdvances(i);
  };

  const onSubmit = (data) => {
    setIsLoader(true);
    data.fieldDepreciablesName = fieldDepreciables;
    axios
      .post(`${ApiUrl}startupCost/startupCost/create`, {
        ...data,
        protegeId: location.state.data.protegeId,
        totalDepreciableAssets: totalDepreciableAssets,
        totalDepreciableAssetsWithoutGst: fieldDepreciables ? fieldDepreciables.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalDepreciableAssetsGst: fieldDepreciables?fieldDepreciables.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        totalIntangiblesAssets: totalIntangiblesAssets,
        totalIntangiblesAssetsWithoutGst: fieldIntangibles ? fieldIntangibles.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalIntangiblesAssetsGst:  fieldIntangibles?fieldIntangibles.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        totalDepositsAndAdvancesAssets: totalDepositsAndAdvancesAssets,
        totalNonDepreciableAssets: totalNonDepreciableAssets,
        totalNonDepreciableAssetsWithoutGst: fieldNonDepreciables ? fieldNonDepreciables.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalNonDepreciableAssetsGst:  fieldNonDepreciables?fieldNonDepreciables.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        isAddingAdditionalMachine: isAddingAdditionalMachine,
        initialRawMaterialPurchase: initialRawMaterialPurchase,
        initialCash: initialCash,
        totalFundsRequired: totalFundsRequired,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onUpdate = (data) => {
    setIsLoader(true);
    data.fieldDepreciablesName = fieldDepreciables;

    axios
      .put(`${ApiUrl}startupCost/startupCost/update`, {
        ...data,
        _id: startupCostId,
        protegeId: location.state.data.protegeId,
        totalDepreciableAssets: totalDepreciableAssets,
        totalDepreciableAssetsWithoutGst: fieldDepreciables ? fieldDepreciables.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalDepreciableAssetsGst: fieldDepreciables?fieldDepreciables.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        totalIntangiblesAssets: totalIntangiblesAssets,
        totalIntangiblesAssetsWithoutGst: fieldIntangibles ? fieldIntangibles.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalIntangiblesAssetsGst:  fieldIntangibles?fieldIntangibles.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        totalDepositsAndAdvancesAssets: totalDepositsAndAdvancesAssets,
        totalNonDepreciableAssets: totalNonDepreciableAssets,
        totalNonDepreciableAssetsWithoutGst: fieldNonDepreciables ? fieldNonDepreciables.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0) : 0,
        totalNonDepreciableAssetsGst:  fieldNonDepreciables?fieldNonDepreciables.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0) : 0,
        isAddingAdditionalMachine: isAddingAdditionalMachine,
        initialRawMaterialPurchase: initialRawMaterialPurchase,
        initialCash: initialCash,
        totalFundsRequired: totalFundsRequired,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Layout>
        <ToastContainer />
        <div className="row">
          <div className="col-6">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-2)}>Protégé</a> /
              </span>
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-1)}>{op} Protégé</a> /
              </span>{" "}
              Startup Cost
            </h4>
          </div>
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="mb-4">
              <div className="card-body">
                <form id="formAccountSettings" onSubmit={handleSubmit(startupCostId ? onUpdate : onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <h5>Tangible Assets (A)</h5>
                    </div>
                    <Accordion>
                      <Card key={1}>
                        <CustomAccordian eventKey={1}>Non Depreciable</CustomAccordian>
                        <Accordion.Collapse eventKey={1}>
                          <Card.Body>
                            <div className="row">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 float-end"
                                  onClick={() => {
                                    handleAddNonDepreciable();
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                      <tr>
                                        <td>Sl No</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td className="text-center" key={i}>
                                              <label>{i + 1}</label>
                                              {i != 0 && <MdDelete style={{ cursor: "pointer", float: "right" }} color="red" size={25} onClick={() => handleRemoveNonDepreciable(i)}></MdDelete>}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Description</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.Description`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "Description");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Unit of Measure</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <select
                                                className={`form-select`}
                                                id={"unit_of_measure" + x.id}
                                                aria-label="Default select example"
                                                {...register(`fieldNonDepreciablesName.${i}.Unit_of_Measure`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "Unit_of_Measure");
                                                  },
                                                })}
                                              >
                                                <option value="">Select</option>
                                                <option value="kg">Kg</option>
                                                <option value="gm">Gms</option>
                                                <option value="lt">Litre</option>
                                                <option value="ml">Millilitre</option>
                                                <option value="Millilitre">Cm</option>
                                                <option value="Metre">Metre</option>
                                                <option value="Lot">Lot</option>
                                                <option value="Set">Set</option>
                                                <option value="Batch">Batch</option>
                                                <option value="Month">Month</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Person">Person</option>
                                                <option value="Piece">Piece</option>
                                                <option value="kWh">kWh</option>
<option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
<option value="event">Event</option>
<option value="service">Service</option>
                                              </select>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Rate per unit ₹</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.Rate_per_unit_Rupees`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "Rate_per_unit_Rupees", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Quantity</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.Quantity`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "Quantity", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>GST % for Item</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.GST_percent_for_Product`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "GST_percent_for_Product", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Transport & Installation</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.Transport_Installation`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "Transport_Installation", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>GST % for Transport & Installation</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td key={i}>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldNonDepreciablesName.${i}.GST_for_Transport_Installation`, {
                                                  onChange: (e) => {
                                                    triggerNonDepreciableChange(e, i, x, "GST_for_Transport_Installation", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Total</td>
                                        {fieldNonDepreciables.map((x, i) => {
                                          return (
                                            <td>
                                              <input tabIndex={i} type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={x.Total ? getNumericFormat(x.Total) : 0} readOnly disabled {...register(getNumericFormat(`fieldNonDepreciablesName.${i}.Total`))} />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>

                                        </td>
                                        <td colSpan="2">
                                          <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            Total Non Depreciable Assets
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldNonDepreciables ? getNumericFormat(fieldNonDepreciables.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation), 0)) : 0} />

                                        </td>
                                        
                                        <td colspan="2">
                                        <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            Total GST on Non Depreciable Assets
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldNonDepreciables  ? getNumericFormat(fieldNonDepreciables.reduce((sum, item) => sum + (item.Total - (item.Rate_per_unit_Rupees * item.Quantity+item.Transport_Installation)), 0)) : 0} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion>
                      <Card key={2}>
                        <CustomAccordian eventKey={2}>Depreciable</CustomAccordian>
                        <Accordion.Collapse eventKey={2}>
                          <Card.Body>
                            <div className="row">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 float-end"
                                  onClick={() => {
                                    handleAddDepreciable();
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered">
                                  <tbody className="table-border-bottom-0">
                                    <tr>
                                      <td>Sl No</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td className="text-center">
                                            <label>{i + 1}</label>
                                            {i != 0 && <MdDelete style={{ cursor: "pointer", float: "right" }} color="red" size={25} onClick={() => handleRemoveDepreciable(i)}></MdDelete>}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Description</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="text"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Description`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Description");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Unit of Measure</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <select
                                              tabIndex={i}
                                              className={`form-select`}
                                              id={"unit_of_measure" + x.id}
                                              aria-label="Default select example"
                                              {...register(`fieldDepreciablesName.${i}.Unit_of_Measure`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Unit_of_Measure");
                                                },
                                              })}
                                            >
                                              <option value="">Select</option>
                                              <option value="kg">Kg</option>
                                              <option value="gm">Gms</option>
                                              <option value="lt">Litre</option>
                                              <option value="ml">Millilitre</option>
                                              <option value="Millilitre">Cm</option>
                                              <option value="Metre">Metre</option>
                                              <option value="Lot">Lot</option>
                                              <option value="Set">Set</option>
                                              <option value="Batch">Batch</option>
                                              <option value="Month">Month</option>
                                              <option value="Dozen">Dozen</option>
                                              <option value="Person">Person</option>
                                              <option value="Piece">Piece</option>
                                              <option value="kWh">kWh</option>
<option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
<option value="event">Event</option>
<option value="service">Service</option>
                                            </select>
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Rate per unit ₹</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Rate_per_unit_Rupees`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Rate_per_unit_Rupees", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Quantity</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Quantity`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Quantity", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>GST % for Item</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.GST_percent_for_Product`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "GST_percent_for_Product", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Transport & Installation</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Transport_Installation`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Transport_Installation", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>GST % for Transport & Installation</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.GST_for_Transport_Installation`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "GST_for_Transport_Installation", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Total</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input tabIndex={i} type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={x.Total ? getNumericFormat(x.Total) : 0} readOnly disabled {...register(getNumericFormat(`fieldDepreciablesName.${i}.Total`))} />
                                          </td>
                                        );
                                      })}
                                    </tr>

                                    <tr>
                                      <td>Depreciable Life In Years</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Depriciable_Life`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Depriciable_Life", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Monthly Depreciation</td>
                                      {fieldDepreciables &&
                                        fieldDepreciables.map((x, i) => {
                                          return (
                                            <td>
                                              <input tabIndex={i} type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={x.Monthly_Depreciation ? getNumericFormatNodecimal(x.Monthly_Depreciation) : 0} disabled readOnly {...register(getNumericFormatNodecimal(`fieldDepreciablesName.${i}.Monthly_Depreciation`))} />
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <td>Lead Time (Months)</td>
                                      {fieldDepreciables &&
                                        fieldDepreciables.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldDepreciablesName.${i}.Lead_Time`, {
                                                  onChange: (e) => {
                                                    triggerDepreciableChange(e, i, x, "Lead_Time", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <td>Installation Time (Months)</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Installation_Time`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Installation_Time", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Rated Capacity</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            <input
                                              tabIndex={i}
                                              type="number"
                                              step="any"
                                              numformat="numberRight"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              {...register(`fieldDepreciablesName.${i}.Rated_Capacity`, {
                                                onChange: (e) => {
                                                  triggerDepreciableChange(e, i, x, "Rated_Capacity", "number");
                                                },
                                              })}
                                            />
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Unit of Measure for Capacity</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            {fieldDepreciables && fieldDepreciables[i].Rated_Capacity > 0 ? (
                                              <select
                                                className={`form-select `}
                                                id="exampleFormControlSelect1"
                                                aria-label="Default select example"
                                                {...register(`fieldDepreciablesName.${i}.unit_of_measure`, {
                                                  onChange: (e) => {
                                                    triggerDepreciableChange(e, i, x, "unit_of_measure", "number");
                                                  },
                                                })}
                                              >
                                                <option value="">Select</option>
                                                <option value="kg">Kg</option>
                                                <option value="gm">Gms</option>
                                                <option value="lt">Litre</option>
                                                <option value="ml">Millilitre</option>
                                                <option value="Millilitre">Cm</option>
                                                <option value="Metre">Metre</option>
                                                <option value="Lot">Lot</option>
                                                <option value="Set">Set</option>
                                                <option value="Batch">Batch</option>
                                                <option value="Month">Month</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Person">Person</option>
                                                <option value="Piece">Piece</option>
                                                <option value="kWh">kWh</option>
<option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
<option value="event">Event</option>
<option value="service">Service</option>
                                              </select>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td>Unit of Time for Capacity</td>
                                      {fieldDepreciables.map((x, i) => {
                                        return (
                                          <td>
                                            {fieldDepreciables && fieldDepreciables[i].Rated_Capacity > 0 ? (
                                              <select
                                                className="form-select"
                                                id="exampleFormControlSelect1"
                                                aria-label="Default select example"
                                                {...register(`fieldDepreciablesName.${i}.time`, {
                                                  onChange: (e) => {
                                                    triggerDepreciableChange(e, i, x, "time", "number");
                                                  },
                                                })}
                                              >
                                                <option value="">Select</option>
                                                <option value="1">Hour</option>
                                                <option value="2">Day</option>
                                                <option value="3">Shift</option>
                                                <option value="4">Month</option>
                                              </select>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td colSpan="2">
                                        <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                          Total Depreciable Assets
                                        </label>
                                        <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldDepreciables ? getNumericFormat(fieldDepreciables.reduce((sum, item) => sum + (((item.Rate_per_unit_Rupees * item.Quantity)+ item.Transport_Installation)), 0)) : 0} />
                                      </td>
                                     
                                      <td colspan="2">
                                        <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            Total GST on Depreciable Assets
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldDepreciables ? getNumericFormat(fieldDepreciables.reduce((sum, item) => sum + (item.Total-((item.Rate_per_unit_Rupees * item.Quantity)+item.Transport_Installation)), 0)) : 0} />
                                        </td>
                  
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <div className="row">
                      <div className="col-md-9 mt-4 mb-4">
                        <p className="float-end">Total Tangible Assets (A)</p>
                      </div>
                      <div className="col-md-3 mt-4 mb-4">
                        <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(totalDepreciableAssets + totalNonDepreciableAssets)} />
                      </div>
                    </div>
                    <Accordion>
                      <Card key={3}>
                        <CustomAccordian eventKey={3}>Intangible Assets (B)</CustomAccordian>
                        <Accordion.Collapse eventKey={3}>
                          <Card.Body>
                            <div className="row">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 float-end"
                                  onClick={() => {
                                    handleAddIntangibles();
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                      <tr>
                                        <td>Sl No</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td className="text-center">
                                              <label>{i + 1}</label>
                                              {i != 0 && <MdDelete style={{ cursor: "pointer", float: "right" }} color="red" size={25} onClick={() => handleRemoveIntangibles(i)}></MdDelete>}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Description</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.Description`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Description");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Unit of Measure</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <select
                                                tabIndex={i}
                                                className={`form-select`}
                                                id={"unit_of_measure" + x.id}
                                                aria-label="Default select example"
                                                {...register(`fieldIntangiblesName.${i}.Unit_of_Measure`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Unit_of_Measure");
                                                  },
                                                })}
                                              >
                                                <option value="">Select</option>
                                                <option value="kg">Kg</option>
                                                <option value="gm">Gms</option>
                                                <option value="lt">Litre</option>
                                                <option value="ml">Millilitre</option>
                                                <option value="Millilitre">Cm</option>
                                                <option value="Metre">Metre</option>
                                                <option value="Lot">Lot</option>
                                                <option value="Set">Set</option>
                                                <option value="Batch">Batch</option>
                                                <option value="Month">Month</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Person">Person</option>
                                                <option value="Piece">Piece</option>
                                                <option value="kWh">kWh</option>
<option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
<option value="event">Event</option>
<option value="service">Service</option>
                                              </select>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Rate per unit ₹</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.Rate_per_unit_Rupees`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Rate_per_unit_Rupees", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Quantity</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.Quantity`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Quantity", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>GST % for Item</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.GST_percent_for_Product`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "GST_percent_for_Product", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Transport & Installation</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.Transport_Installation`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Transport_Installation", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>GST % for Transport & Installation</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.GST_for_Transport_Installation`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "GST_for_Transport_Installation", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Total</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={x.Total ? getNumericFormat(x.Total) : 0} readOnly disabled {...register(getNumericFormat(`fieldIntangiblesName.${i}.Total`))} />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Amortization Life in years</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldIntangiblesName.${i}.Amortization_Life`, {
                                                  onChange: (e) => {
                                                    triggerIntangiblesChange(e, i, x, "Amortization_Life", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Monthly Amortization</td>
                                        {fieldIntangibles.map((x, i) => {
                                          return (
                                            <td>
                                              <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" disabled value={x.Monthly_Amortization ? getNumericFormat(x.Monthly_Amortization) : 0} {...register(getNumericFormat(`fieldIntangiblesName.${i}.Monthly_Amortization`))} />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>
                                          <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            TOTAL INTANGIBLE ASSETS
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldIntangibles  ? getNumericFormat(fieldIntangibles.reduce((sum, item) => sum + ((item.Rate_per_unit_Rupees * item.Quantity+item.Transport_Installation)), 0)) : 0} />
                                        </td>
                                        <td>
                                         <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            TOTAL GST for INTANGIBLE  ASSETS
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fieldIntangibles  ? getNumericFormat(fieldIntangibles.reduce((sum, item) => sum + (item.Total - (item.Rate_per_unit_Rupees * item.Quantity+item.Transport_Installation)), 0)) : 0} />
                                        
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            TOTAL  INTANGIBLE  ASSETS (B)
                                          </label>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalIntangiblesAssets ? getNumericFormat(totalIntangiblesAssets) : 0} />
                                        
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <div className="row">
                      <div className="col-md-9 mt-4">
                        <p className="float-end">Total Assets A+B</p>
                      </div>
                      <div className="col-md-3 mt-4">
                        <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(totalDepreciableAssets + totalNonDepreciableAssets + totalIntangiblesAssets)} />
                      </div>
                    </div>
                    <Accordion>
                      <Card key={4}>
                        <CustomAccordian eventKey={4}>Deposits & Advances (C)</CustomAccordian>
                        <Accordion.Collapse eventKey={4}>
                          <Card.Body>
                            <div className="row">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 float-end"
                                  onClick={() => {
                                    handleAddDepositsAndAdvances();
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                      <tr>
                                        <td>Sl No</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td className="text-center">
                                              <label>{i + 1}</label>
                                              {i != 0 && <MdDelete style={{ cursor: "pointer", float: "right" }} color="red" size={25} onClick={() => handleRemoveDepositsAndAdvances(i)}></MdDelete>}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Description</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldDepositsAndAdvancesName.${i}.Description`, {
                                                  onChange: (e) => {
                                                    triggerDepositsAndAdvancesChange(e, i, x, "Description");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Unit of Measure</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td>
                                              <select
                                                tabIndex={i}
                                                className={`form-select`}
                                                id={"unit_of_measure" + x.id}
                                                aria-label="Default select example"
                                                {...register(`fieldDepositsAndAdvancesName.${i}.Unit_of_Measure`, {
                                                  onChange: (e) => {
                                                    triggerDepositsAndAdvancesChange(e, i, x, "Unit_of_Measure");
                                                  },
                                                })}
                                              >
                                                <option value="">Select</option>
                                                <option value="kg">Kg</option>
                                                <option value="gm">Gms</option>
                                                <option value="lt">Litre</option>
                                                <option value="ml">Millilitre</option>
                                                <option value="Millilitre">Cm</option>
                                                <option value="Metre">Metre</option>
                                                <option value="Lot">Lot</option>
                                                <option value="Set">Set</option>
                                                <option value="Batch">Batch</option>
                                                <option value="Month">Month</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Person">Person</option>
                                                <option value="Piece">Piece</option>
                                                <option value="kWh">kWh</option>
<option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
<option value="event">Event</option>
<option value="service">Service</option>
                                              </select>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Rate per unit ₹</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldDepositsAndAdvancesName.${i}.Rate_per_unit_Rupees`, {
                                                  onChange: (e) => {
                                                    triggerDepositsAndAdvancesChange(e, i, x, "Rate_per_unit_Rupees", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Quantity</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td>
                                              <input
                                                tabIndex={i}
                                                type="number"
                                                step="any"
                                                numformat="numberRight"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                {...register(`fieldDepositsAndAdvancesName.${i}.Quantity`, {
                                                  onChange: (e) => {
                                                    triggerDepositsAndAdvancesChange(e, i, x, "Quantity", "number");
                                                  },
                                                })}
                                              />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td>Total</td>
                                        {fieldDepositsAndAdvances.map((x, i) => {
                                          return (
                                            <td>
                                              <input tabIndex={i} type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={x.Total ? getNumericFormat(x.Total) : 0} readOnly disabled {...register(`fieldDepositsAndAdvancesName.${i}.Total`)} />
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <td colSpan="2">
                                          <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                            TOTAL DEPOSITS (C)
                                          </label>
                                        </td>
                                        <td>
                                          <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalDepositsAndAdvancesAssets ? getNumericFormat(totalDepositsAndAdvancesAssets) : 0} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <div className="col-md-12 mt-4 d-flex">
                      <div className="col-md-3">
                        <p className="float-start">Initial Input Cost (D)</p>
                      </div>
                      <div className="col-md-3">
                        <input type="number" step="any" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={initialRawMaterialPurchase} onChange={(e) => onChangeOfValue(e.target.value, fixedCostDuringGestPeriod, initialCash)} />
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 d-flex">
                      <div className="col-md-3">
                        <p className="float-start">Fixed Cost During Gestation (E)</p>
                      </div>
                      <div className="col-md-3">
                        <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fixedCostDuringGestPeriod ? getNumericFormat(fixedCostDuringGestPeriod) : 0} />
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 d-flex">
                      <div className="col-md-3">
                        <p className="float-start">Initial Cash Needed (F)</p>
                      </div>
                      <div className="col-md-3">
                        <input type="number" step="any" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={initialCash} onChange={(e) => onChangeOfValue(initialRawMaterialPurchase, fixedCostDuringGestPeriod, e.target.value)} />
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 d-flex">
                      <div className="col-md-3">
                        <p className="float-start">Total Funds Required (G)</p>
                      </div>
                      <div className="col-md-3 mb-3">
                        <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalFundsRequired ? getNumericFormat(totalFundsRequired) : 0} />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <h5>Start Up Cost Summary</h5>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive mb-3">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Description</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            <tr>
                              <td colSpan="2">Tangible Assests (A)</td>
                            </tr>
                            <tr>
                              <td>Non Depreciable</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalNonDepreciableAssets ? getNumericFormat(totalNonDepreciableAssets) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Depreciable</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalDepreciableAssets ? getNumericFormat(totalDepreciableAssets) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Intangible Assets (B)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalIntangiblesAssets ? getNumericFormat(totalIntangiblesAssets) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Deposits & Advances (C)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalDepositsAndAdvancesAssets ? getNumericFormat(totalDepositsAndAdvancesAssets) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Initial Input Cost (D)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={initialRawMaterialPurchase ? getNumericFormat(initialRawMaterialPurchase) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Fixed Cost During Gestation (E)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={fixedCostDuringGestPeriod ? getNumericFormat(fixedCostDuringGestPeriod) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Initial Cash Needed (F)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={initialCash ? getNumericFormat(initialCash) : 0} />
                              </td>
                            </tr>
                            <tr>
                              <td>Total Funds Required (G)</td>
                              <td>
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={totalFundsRequired ? getNumericFormat(totalFundsRequired) : 0} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <div className="mb-3 col-md-6 ">
                            <label htmlFor="nameBackdrop" className="form-label">
                              Will you be adding any other machine/additional during the period ?
                            </label>
                            <br />
                            <div className="form-check form-check-inline mt-3">
                              <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" value={true} checked={isAddingAdditionalMachine} onChange={() => setIsAddingAdditionalMachine(true)} />
                              <label className="form-check-label" htmlFor="inlineRadio1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions1"
                                id="inlineRadio2"
                                value={false}
                                checked={!isAddingAdditionalMachine}
                                onChange={() => {
                                  setIsAddingAdditionalMachine(false);
                                }}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2">
                                No
                              </label>
                            </div>
                          </div>
                          {isAddingAdditionalMachine && (
                            <div className=" row col-6 d-flex justify-content-between">
                              <div className="mb-3  col-md-6">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Which Machine ?
                                </label>
                                <input style={{ overflow: "hidden" }} type="text" className={`form-control`} id="exampleFormControlInput1" placeholder="Machine" {...register("whichMachine")} />
                              </div>
                              <div className="mb-3  col-md-6">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Which Month ?
                                </label>
                                <select className={`form-select`} aria-label="Default select example" {...register(`whichMonth`)}>
                                  <option value="">Select</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>
                                  <option value="32">32</option>
                                  <option value="33">33</option>
                                  <option value="34">34</option>
                                  <option value="35">35</option>
                                  <option value="36">36</option>
                                </select>
                              </div>
                              <div className="mb-3  col-md-6">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Cost of Equipment
                                </label>
                                <input type="number" step="any" numformat="numberRight" className={`form-control`} id="exampleFormControlInput1" {...register("totalCost")} />
                              </div>
                              <div className="mb-3  col-md-6">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Source
                                </label>
                                <select className={`form-select`} aria-label="Default select example" {...register(`source`)}>
                                  <option value="">Select</option>
                                  <option value="internal_cash">Internal Cash</option>
                                  <option value="equity">Equity</option>
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                    <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default StartUpCost;
