import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav
} from "react-bootstrap";
import Profile from "./profile";
import ChangePassword from "./changePassword";
import Layout from "common/components/layout";
function ProfilePage() {
  return (
    <Layout>
      <h4 className="fw-bold py-3">My Profile</h4>
      <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
        <div className="nav-align-top mb-4">
          <Nav variant="pills" className="mb-3 ">
            <Nav.Item>
              <Nav.Link eventKey="profile">
                <span className="bx bx-user me-1"></span>
                <span className="bx-profile">Profile Details</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="changePassword">
                <span className="bx bx-key me-1"></span>
                <span className="bx-profile">Change Password</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <Profile />
            </Tab.Pane>
            <Tab.Pane eventKey="changePassword">
              <ChangePassword />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
      <hr className="my-5" />
      <div className="content-backdrop fade"></div>
    </Layout>
  );
}
export default ProfilePage;
