import {ApiUrl} from "API/config";
import axios from "axios";
import Layout from "common/components/layout";
import {useEffect, useMemo, useState} from "react";
import moment from "moment";
import {useForm} from "react-hook-form";
import {useParams, useNavigate, useLocation} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {IsoLangs} from "../common/language";
import {useRef} from "react";
import Multiselect from "multiselect-react-dropdown";
import Loader from "common/components/loader";

function PersonalInfo() {
  const textRef = useRef();
  const op = useParams().op;
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [allUser, setAllUser] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [languageData, setLanagugeData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: {errors},
  } = useForm({mode: "onBlur"});
  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  useEffect(() => {
    if (op == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${location.state.id}`)
        .then((data) => {
          setData(data.data[0].personalInfoId);
          setSelectedValue(data.data[0].personalInfoId.language);
          setIsLoader(false);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log(err);
        });
    } else {
      // setValue([{mentor:userObj._id}])
    }
    getAllUserdata();
  }, []);
  useEffect(() => {
    // reset form with user data
    if (op == "Edit") {
      reset({
        address: data && data.address,
        adharCard: data && data.adharCard,
        age: data && data.age,
        education: data && data.education,
        email: data && data.email,
        familyBackground: data && data.familyBackground,
        language: data && data.language,
        mentor: data && data.mentor,
        mentoringDate: data && moment(data.mentoringDate).format("YYYY-MM-DD"),
        name: data && data.name,
        phone: data && data.phone,
        landline: data && data.landline,
        // recordId: data && data.recordId,
      });
      setLanagugeData(data && data.language);
    } else {
      if (userObj.role == "mentor") {
        reset({
          mentor: userObj._id,
        });
      }
    }
  }, [data]);

  const getAllUserdata = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}user/all?page=-1`)
      .then((data) => {
        setAllUser(data.data);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  };

  const generateRecordId = () => {
    let trimmedCenterCode = Math.floor(10 + Math.random() * 90);
    let formattedDate = moment(Date.now()).format("DD-MM");
    let randomNumber = Math.floor(10 + Math.random() * 90);
    return trimmedCenterCode + "-" + formattedDate + "-" + randomNumber;
  };

  const onCreatePernalInfo = (formdata) => {
    setIsLoader(true);
    let totalNonumberOfField = 12;
    let existingFields = Object.keys(formdata).filter((x) => formdata[x]);
    // console.log(existingFields);
    let existingCount = existingFields ? existingFields.length : 0;

    if (formdata.language) {
      totalNonumberOfField = totalNonumberOfField;
    }
    if (languageData.length == 0) {
      existingCount = existingCount - 1;
    }
    let progressScore = (existingCount / totalNonumberOfField) * 100;
    if (op == "Edit") {
      axios
        .post(`${ApiUrl}personalInfo/updatePersonalInfo/${data._id}`, {
          ...formdata,
          userId: userObj._id,
          progressScore: progressScore,
          language: languageData,
        })
        .then((resoponse) => {
          toast("Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          console.log(error);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoader(false);
        });
    } else {
      axios
        .post(`${ApiUrl}personalInfo/createPersonalInfo`, {
          ...formdata,
          userId: userObj._id,
          progressScore: progressScore,
          // recordId: generateRecordId(),
          language: languageData,
        })
        .then((resoponse) => {
          toast("Created Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });

          navigate(`/ManageProtege/Edit/${resoponse.data.personalInfo._id}`, {
            state: {id: resoponse.data.personalInfo._id},
          });
        })
        .catch((error) => {
          console.log(error);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoader(false);
        });
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    setLanagugeData(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setLanagugeData(selectedList);
  };

  const autoResize = function (e) {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  let isFormRendered = op == "Edit" ? data : true;

  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Personal Information
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="mb-4">
            <div className="card-body">
              {isFormRendered && (
                <form id="formAccountSettings" onSubmit={handleSubmit(onCreatePernalInfo)} method="POST">
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Name
                      </label>
                      <input type="text" className={`form-control ${errors.name ? "errorBorder" : ""}`} id="exampleFormControlInput1" placeholder="Enter Full Name here" defaultValue={data && data.name} autoFocus="" {...register("name", {required: true})} />
                      {errors.name && (
                        <span className="textRed" style={{color: "red"}}>
                          *Name is required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Address
                      </label>
                      <textarea
                        style={{overflow: "hidden"}}
                        ref={textRef}
                        type="text"
                        className={`form-control ${errors.address ? "errorBorder" : ""}`}
                        id="exampleFormControlInput1"
                        defaultValue={data && data.address}
                        placeholder="Address line1, line 2"
                        {...register("address", {
                          required: true,
                          onChange: (e) => autoResize(e),
                        })}
                      />
                      {errors.address && (
                        <span className="textRed" style={{color: "red"}}>
                          *Address is required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Phone
                      </label>

                      <input
                        type="number"
                        numformat="numberLeft"
                        className={`form-control ${errors.phone ? "errorBorder" : ""}`}
                        defaultValue={data && data.phone}
                        id="exampleFormControlInput1"
                        placeholder="xxxx xxxx xx"
                        {...register("phone", {
                          required: true,
                          maxLength: 10,
                          minLength: 10,
                        })}
                      />
                      {errors.phone && errors.phone.type == "required" && (
                        <span className="textRed" style={{color: "red"}}>
                          *Phone Number is required{" "}
                        </span>
                      )}
                      {errors.phone && errors.phone.type === "maxLength" && <span className="text-danger">Phone No should be of 10 digits</span>}
                      {errors.phone && errors.phone.type === "minLength" && <span className="text-danger">Phone No should be of 10 digits</span>}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Alternate Contact
                      </label>
                      <input
                        type="number"
                        numformat="numberLeft"
                        className={`form-control ${errors.landline ? "errorBorder" : ""}`}
                        defaultValue={data && data.landline}
                        id="exampleFormControlInput1"
                        placeholder="xxxx xxxx xx"
                        {...register("landline", {
                          maxLength: 10,
                          minLength: 10,
                        })}
                      />
                      {errors.landline && errors.landline.type == "required" && (
                        <span className="textRed" style={{color: "red"}}>
                          *Alternate Contact is required{" "}
                        </span>
                      )}
                      {errors.landline && errors.landline.type === "maxLength" && <span className="text-danger">Alternate Contact No should be of 10 digits</span>}
                      {errors.landline && errors.landline.type === "minLength" && <span className="text-danger">Alternate Contact No should be of 10 digits</span>}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Email ID
                      </label>
                      <input style={{textTransform: "lowercase"}} type="email" disabled={op == "Edit" ? true : false} className={`form-control ${errors.email ? "errorBorder" : ""}`} defaultValue={data && data.email} id="exampleFormControlInput1" placeholder="abc@abc.com" {...register("email", {required: true})} />
                      {errors.email && (
                        <span className="textRed" style={{color: "red"}}>
                          *Email ID is required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Aadhar Number
                      </label>
                      <input
                        type="number"
                        numformat="numberLeft"
                        defaultValue={data && data.adharCard}
                        className={`form-control ${errors.adharCard ? "errorBorder" : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="xxxx xxxx xxxx"
                        {...register("adharCard", {
                          maxLength: 12,
                          minLength: 12,
                        })}
                      />
                      {errors.adharCard && errors.adharCard.type == "required" && (
                        <span className="textRed" style={{color: "red"}}>
                          * Aadhar Number is required{" "}
                        </span>
                      )}
                      {errors.adharCard && errors.adharCard.type === "maxLength" && <span className="text-danger">Adhaar No should be of 12 digits</span>}
                      {errors.adharCard && errors.adharCard.type === "minLength" && <span className="text-danger">Adhaar No should be of 12 digits</span>}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Education
                      </label>
                      <input type="text" className={`form-control ${errors.education ? "errorBorder" : ""}`} defaultValue={data && data.education} id="exampleFormControlInput1" placeholder="Enter education details" {...register("education", {required: true})} />
                      {errors.education && (
                        <span className="textRed" style={{color: "red"}}>
                          *Education details are required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Age
                      </label>
                      <input type="number" step="any" numformat="numberLeft" className={`form-control ${errors.age ? "errorBorder" : ""}`} id="exampleFormControlInput1" placeholder="Enter your age" defaultValue={data && data.age} {...register("age", {required: true})} />
                      {errors.age && (
                        <span className="textRed" style={{color: "red"}}>
                          *Age is required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Family Background
                      </label>
                      <textarea
                        style={{overflow: "hidden"}}
                        id="autoResize"
                        type="text"
                        className={`form-control ${errors.familyBackground ? "errorBorder" : ""}`}
                        //  id="exampleFormControlInput1"
                        placeholder="Enter Family Background"
                        defaultValue={data && data.familyBackground}
                        {...register("familyBackground", {
                          required: true,
                          onChange: (e) => autoResize(e),
                        })}
                      />
                      {errors.familyBackground && (
                        <span className="textRed" style={{color: "red"}}>
                          *Family Background is required{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="nameBackdrop" className="form-label">
                        Languages Known
                      </label>
                      <Multiselect
                        options={IsoLangs && IsoLangs} // Options to display in the dropdown
                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                      />
                      {errors.language && (
                        <span className="textRed" style={{color: "red"}}>
                          *Language Known is required{" "}
                        </span>
                      )}
                    </div>

                    {userObj.role == "mentor" && (
                      <div className="mb-3 col-md-6">
                        <label htmlFor="nameBackdrop" className="form-label">
                          Mentor Name
                        </label>
                        <select className={`form-select ${errors.mentor ? "errorBorder" : ""}`} value={data && data.mentor} id="exampleFormControlSelect1" aria-label="Default select example" disabled {...register("mentor", {required: true})}>
                          <option value={userObj._id} selected>
                            {userObj.name}
                          </option>
                        </select>
                      </div>
                    )}
                    {userObj.role != "mentor" && (
                      <div className="mb-3 col-md-6">
                        <label htmlFor="nameBackdrop" className="form-label required">
                          Mentor Name
                        </label>
                        <select
                          className={`form-select ${errors.mentor ? "errorBorder" : ""}`}
                          defaultValue={data && data.mentor}
                          // className="form-select"
                          id="exampleFormControlSelect1"
                          aria-label="Default select example"
                          {...register("mentor", {required: true})}
                        >
                          <option value="">Select Mentor</option>
                          {allUser &&
                            allUser
                              .filter((x) => x.role == "mentor")
                              .map((X) => {
                                return (
                                  <option value={X._id}>
                                    {X.name} ({X.email})
                                  </option>
                                );
                              })}
                        </select>
                        {errors.mentor && (
                          <span className="textRed" style={{color: "red"}}>
                            *Mentor is required{" "}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label required">
                        Enrolled for Mentoring on
                      </label>
                      <input type="date" max={new Date().toISOString().split("T")[0]} className={`form-control ${errors.mentoringDate ? "errorBorder" : ""}`} defaultValue={data && moment(data.mentoringDate).format("YYYY-MM-DD")} id="exampleFormControlInput1" {...register("mentoringDate", {required: true})} />
                      {errors.mentoringDate && (
                        <span className="textRed" style={{color: "red"}}>
                          *Mentoring date is required{" "}
                        </span>
                      )}
                    </div>
                    {/* <div className="mb-3 col-md-6">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Record Id
                      </label>
                      <input type="text" defaultValue={data && data.recordId} {...register("recordId")} className={`form-control ${errors.recordId ? "errorBorder" : ""}`} />
                      {errors.recordId && (
                        <span className="textRed" style={{color: "red"}}>
                          *RecordId is required{" "}
                        </span>
                      )}
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <span className="asterisk">*</span> Required Fields
                  </div>
                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      // onClick={onCreatePernalInfo}
                    >
                      {op == "Edit" ? "Save Changes" : " Save changes"}
                    </button>
                    <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default PersonalInfo;
