import logo from "../../../assets/img/icreate_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
function ForgotPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const onForgotPasswordSubmit = (data) => {
    axios
      .put(`${ApiUrl}forgot-password`, data)
      .then((response) => {
        console.log(response.data);
        toast("Please Follow the mail Instructions to reset password", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onclose:()=>navigate("/login")
        });
        // navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        toast("Error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center">
                  <img src={logo} />
                </div>
                <p className="mb-4">
                  Enter your email and we'll send you instructions to reset your
                  password
                </p>
                <form
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={handleSubmit(onForgotPasswordSubmit)}
                >
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      autoFocus
                      {...register("email", { required: true })}
                    />
                  </div>
                  <button className="btn btn-primary d-grid w-100">
                    Send Reset Link
                  </button>
                </form>
                <div className="text-center">
                  <Link
                    to={"/login"}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                    Back to login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
