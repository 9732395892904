import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {useParams, useNavigate, useLocation} from "react-router";
import {set, useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import {ApiUrl} from "API/config";
import {toast, ToastContainer} from "react-toastify";
import CustomAccordian from "common/components/CustomAccordian";
import Loader from "common/components/loader";

function SourcesOfFund() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const {register, control, handleSubmit, reset, formState, setValue} = useForm();
  const [isLoader, setIsLoader] = useState(false);
  const {fields: fieldLineItems, append: appendLineItem, update: updateLineItem, remove: removeLineItem, setValue: setValueLineItem} = useFieldArray({name: "fieldLineItemsName", control});
  const [totalFundsRequired, setTotalFundsRequired] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);
  const [isAddingAdditionalMachine, setIsAddingAdditionalMachine] = useState(false);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,    });
    return num ? numberFormatter.format(num) : 0;
  };
  const [bankFinancial, setBankFinancial] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [others, setOthers] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [machinarySuppliers, setMachinarySuppliers] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [mfi, setMfi] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [familyFriends, setFamilyFriends] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const [rawMaterialSup, setRawMaterialSup] = useState({
    details: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
      },
    },
    aggregate: {
      interest: {
        1: 0,
        2: 0,
        3: 0,
      },
      principle: {
        1: 0,
        2: 0,
        3: 0,
      },
    },
  });
  const onSubmit = (data) => {
    setIsLoader(true);
    console.log("save data", {
      ...data,
      mfi: mfi,
      machinarySuppliers: machinarySuppliers,
      others: others,
      bankFinancial: bankFinancial,
      familyFriends: familyFriends,
      rawMaterialSup: rawMaterialSup,
      totalAmount: totalAmount,
      isAddingAdditionalMachine: isAddingAdditionalMachine,
    });
    axios
      .post(`${ApiUrl}sourcesOfFund/sourcesOfFund/create`, {
        ...data,
        mfi: mfi,
        machinarySuppliers: machinarySuppliers,
        others: others,
        bankFinancial: bankFinancial,
        familyFriends: familyFriends,
        rawMaterialSup: rawMaterialSup,
        protegeId: location.state.data.protegeId,
        totalAmount: totalAmount,
        isAddingAdditionalMachine: isAddingAdditionalMachine,
      })
      .then((result) => {
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onUpdate = (data) => {
    setIsLoader(true);
    axios
      .put(`${ApiUrl}sourcesOfFund/sourcesOfFund/update`, {
        ...data,
        _id: sourcesOfFundId,
        mfi: mfi,
        machinarySuppliers: machinarySuppliers,
        others: others,
        bankFinancial: bankFinancial,
        familyFriends: familyFriends,
        rawMaterialSup: rawMaterialSup,
        protegeId: location.state.data.protegeId,
        totalAmount: totalAmount,
        isAddingAdditionalMachine: isAddingAdditionalMachine,
      })
      .then((result) => {
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let sourcesOfFundId = location.state.data.sourcesOfFundId && location.state.data.sourcesOfFundId;
  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (sourcesOfFundId) {
        await getAllSourcesOfFounds();
      } else {
        let defaultLineItems = [
          "Own Fund",
          "Family/friends/relatives",
          "Bank/Financial Institution",
          "Machinery Supplier Credit",
          "Raw Material Supplier Credit",
          // "Others",
          "MFI",
        ];
        let slno = 1;
        defaultLineItems.forEach((x, i) => {
          appendLineItem({
            lineItemName: x,
            default: true,
            "Sl No": slno++,
            Description: x,
            Amount: 0,
            "Interest/Service Fee": 0,
            "Repayment Terms (Months)": 0,
            "Moratorium in months": 0,
          });
        });
      }
      await getStartupCost();
      setTimeout(() => setIsLoader(false), 400);
    }
    fetchData();
  }, []);

  useEffect(() => {}, []);
  const getAllSourcesOfFounds = () => {
    setIsLoader(true);
    return new Promise(function (resolve, reject) {
      axios
        .get(`${ApiUrl}sourcesOfFund/sourcesOfFund/${sourcesOfFundId}`)
        .then((resultRM) => {
          console.log(resultRM);
          reset(resultRM.data);
          setTotalAmount(resultRM.data.totalAmount);
          setBankFinancial(resultRM.data.bankFinancial);
          setIsAddingAdditionalMachine(resultRM.data.isAddingAdditionalMachine);
          setMachinarySuppliers(resultRM.data.machinarySuppliers);
          setFamilyFriends(resultRM.data.familyFriends ? resultRM.data.familyFriends : familyFriends);
          setRawMaterialSup(resultRM.data.rawMaterialSup ? resultRM.data.rawMaterialSup : rawMaterialSup);
          setMfi(resultRM.data.mfi);
          setOthers(resultRM.data.others);
          resolve(resultRM.data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    });
  };
  const getStartupCost = () => {
    setIsLoader(true);
    return new Promise(function (resolve, reject) {
      axios.get(`${ApiUrl}protege/${location.state.data.protegeId}`).then((result) => {

        setTotalFundsRequired(result.data[0].startupCost && result.data[0].startupCost._id ? result.data[0].startupCost.totalFundsRequired : 0);
        resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    });
  };

  const handleAddLineItem = () => {
    let sno = fieldLineItems.length > 0 ? fieldLineItems.length + 1 : 1;
    let lineItem = {
      lineItemName: "",
      default: false,
      "Sl No": sno,
      Description: "",
      Amount: 0,
      "Interest/Service Fee": 0,
      "Repayment Terms (Months)": 0,
      "Moratorium in months": 0,
    };

    appendLineItem(lineItem);
  };

  const triggerfieldChange = (e, i, x, field, type) => {
    let value = e.target.value;
    if (type === "number") {
      value = parseFloat(e.target.value);
    }
    x[field] = value;
    setValue("fieldLineItemsName", fieldLineItems);
    triggerInterestCalculation(x);
  };

  const triggerAggregateCalculation = (row, year) => {
    let qsum = 0;
    let psum = 0;
    let twelvers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    for (const month in twelvers) {
      let actualMonth = year > 1 ? twelvers[month] + (year - 1) * 12 : twelvers[month];
      qsum += parseFloat(row.details.interest[actualMonth] === "" ? 0 : row.details.interest[actualMonth]);
    }

    row.aggregate.interest[year] = qsum.toFixed(2);

    for (const month in twelvers) {
      let actualMonth = year > 1 ? twelvers[month] + (year - 1) * 12 : twelvers[month];
      psum += parseFloat(row.details.principle[actualMonth] === "" ? 0 : row.details.principle[actualMonth]);
    }
    row.aggregate.principle[year] = psum.toFixed(2);
  };

  const triggerfieldChangeAndAmount = (e, i, x, field, type) => {
    let value = e.target.value || 0;
    if (type === "number") {
      value = parseFloat(e.target.value);
    }
    x[field] = value;
    setValue("fieldLineItemsName", fieldLineItems);
    triggerInterestCalculation(x);
    setTotalAmount(fieldLineItems.reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0));
  };

  const triggerInterestCalculation = (lineItem) => {
    switch (lineItem.lineItemName) {
      case "Bank/Financial Institution":
        triggerMonthCalculation(bankFinancial, lineItem);
        break;

      case "Machinery Supplier Credit":
        triggerMonthCalculation(machinarySuppliers, lineItem);

        break;
      case "MFI":
        triggerMonthCalculation(mfi, lineItem);
        break;
      case "Family/friends/relatives":
        triggerMonthCalculation(familyFriends, lineItem);
        break;
      case "Raw Material Supplier Credit":
        triggerMonthCalculation(rawMaterialSup, lineItem);
        break;
      default:
        break;
    }
  };

  const triggerMonthCalculation = (obj, lineItem) => {
    let balanceDue4 = lineItem["Amount"];
    let repaymentTerms = parseFloat(lineItem["Repayment Terms (Months)"]);
    let moratorium = parseFloat(lineItem["Moratorium in months"]);
    for (var month in obj.details.interest) {
      month = parseFloat(month);
      let interest = balanceDue4 * (lineItem["Interest/Service Fee"] / 12 / 100);
      let rpayment = repaymentTerms > 0 ? lineItem["Amount"] / repaymentTerms : 0;
      rpayment = moratorium > 0 && month <= moratorium && repaymentTerms + moratorium > month ? 0 : month <= repaymentTerms + moratorium ? rpayment : 0;
      balanceDue4 = balanceDue4 - rpayment;
      let principle = month <= repaymentTerms + moratorium ? rpayment : 0;
      obj.details.interest[month] = interest <= 0 ? 0 : interest.toFixed(2);
      obj.details.principle[month] = principle.toFixed(2);
    }
    triggerAggregateCalculation(obj, 1);
    triggerAggregateCalculation(obj, 2);
    triggerAggregateCalculation(obj, 3);
  };

  return (
    <>
      <Layout>
        <ToastContainer />
        <div className="row">
          <div className="col-6">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-2)}>Protégé</a> /
              </span>
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-1)}>{op} Protégé</a> /
              </span>{" "}
              Sources Of Funds
            </h4>
          </div>
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="mb-4">
              <div className="card-body">
                <form id="formAccountSettings" onSubmit={handleSubmit(sourcesOfFundId ? onUpdate : onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <button
                        type="button"
                        className="btn btn-primary me-2 float-end"
                        onClick={() => {
                          handleAddLineItem();
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Sl No</th>
                              <th>Description</th>
                              <th>Amount ₹</th>
                              <th>Interest per Annum %</th>
                              <th>Repayment Terms (Months)</th>
                              <th>Moratorium in months beyond gestation</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldLineItems.map((x, i) => {
                              return (
                                <tr>
                                  <td>
                                    <input type="text" className="form-control sl-width text-center" id="exampleFormControlInput1" disabled readOnly value={x["Sl No"]} />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control lg-width"
                                      id="exampleFormControlInput1"
                                      disabled={x.default}
                                      readOnly={x.default}
                                      value={x["Description"]}
                                      {...register(`fieldRolesName.${i}.details[${"Description"}]`, {
                                        onChange: (e) => {
                                          triggerfieldChange(e, i, x, "Description");
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      className="form-control md-width"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.details[${"Amount "}]`, {
                                        onChange: (e) => {
                                          triggerfieldChangeAndAmount(e, i, x, "Amount", "number");
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      numformat="numberRight"
                                      className="form-control md-width"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.details[${"Interest/Service Fee"}]`, {
                                        onChange: (e) => {
                                          triggerfieldChange(e, i, x, "Interest/Service Fee");
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      numformat="numberRight"
                                      className="form-control md-width"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.details[${"Repayment Terms (Months)"}]`, {
                                        onChange: (e) => {
                                          triggerfieldChange(e, i, x, "Repayment Terms (Months)");
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      numformat="numberRight"
                                      className="form-control md-width"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.details[${"Moratorium in months"}]`, {
                                        onChange: (e) => {
                                          triggerfieldChange(e, i, x, "Moratorium in months");
                                        },
                                      })}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan="2">
                                Total Funds Required: {getNumericFormat(totalFundsRequired)}
                                <label htmlFor="exampleFormControlInput1" className="form-label float-end">
                                  Total
                                </label>
                              </td>

                              <td colSpan="1">
                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalAmount)} disabled readOnly />
                              </td>
                              <td colSpan="3">
                                {(totalFundsRequired - totalAmount).toFixed(2) != 0 && (
                                  <span className="form-text" style={{color: "red"}}>
                                    Requirement and Source don't match ({getNumericFormat(totalFundsRequired - totalAmount)})
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex mt-5 mb-2">
                      <div className="mb-3 col-md-6 ">
                        <label htmlFor="nameBackdrop" className="form-label">
                          Is any subsidy scheme available ?
                        </label>
                        <br />
                        <div className="form-check form-check-inline mt-3">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" value={true} checked={isAddingAdditionalMachine} onChange={() => setIsAddingAdditionalMachine(true)} />
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio2"
                            value={false}
                            checked={!isAddingAdditionalMachine}
                            onChange={() => {
                              setIsAddingAdditionalMachine(false);
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            No
                          </label>
                        </div>
                      </div>
                      {!!isAddingAdditionalMachine && (
                        <div className=" col-6 d-flex justify-content-between">
                          <div className="mb-3  col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              How Much ?
                            </label>
                            <input
                              style={{overflow: "hidden"}}
                              // ref={textRef}
                              type="text"
                              numformat="numberRight"
                              className={`form-control`}
                              id="exampleFormControlInput1"
                              // defaultValue={data && data.address}
                              placeholder=""
                              // {...register("address", {
                              //   required: true,
                              //   onChange: e => autoResize(e)
                              // })}
                              {...register("whichMachine")}
                            />
                          </div>
                          <div className="mb-3  col-md-5">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              After No. of Months
                            </label>
                            <select className={`form-select`} aria-label="Default select example" {...register(`whichMonth`)}>
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-12 col-xl-12">
                      <div>
                        <h5>Repayment Calculation</h5>
                      </div>
                      <Accordion>
                        <Card key="bank">
                          <CustomAccordian eventKey="bank">Bank/Financial Institution</CustomAccordian>
                          <Accordion.Collapse eventKey="bank">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                          <th>5</th>
                                          <th>6</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[6])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Principal</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[6])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>7</th>
                                          <th>8</th>
                                          <th>9</th>
                                          <th>10</th>
                                          <th>11</th>
                                          <th>12</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.interest[12])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Principal</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.details.principle[12])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>Year 1</th>
                                          <th>Year 2</th>
                                          <th>Year 3</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.interest[3])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Principal</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(bankFinancial.aggregate.principle[3])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                        <Card key="MachinerySupply">
                          <CustomAccordian eventKey="MachinerySupply">Machinery Supplier</CustomAccordian>
                          <Accordion.Collapse eventKey="MachinerySupply">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                          <th>5</th>
                                          <th>6</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[6])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[6])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>7</th>
                                          <th>8</th>
                                          <th>9</th>
                                          <th>10</th>
                                          <th>11</th>
                                          <th>12</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.interest[12])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.details.principle[12])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>Year 1</th>
                                          <th>Year 2</th>
                                          <th>Year 3</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.interest[3])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(machinarySuppliers.aggregate.principle[3])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card key="MFI">
                          <CustomAccordian eventKey="MFI">MFI</CustomAccordian>
                          <Accordion.Collapse eventKey="MFI">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                          <th>5</th>
                                          <th>6</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[6])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[6])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>7</th>
                                          <th>8</th>
                                          <th>9</th>
                                          <th>10</th>
                                          <th>11</th>
                                          <th>12</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.interest[12])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.details.principle[12])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>Year 1</th>
                                          <th>Year 2</th>
                                          <th>Year 3</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.interest[3])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(mfi.aggregate.principle[3])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card key="Family/friends/relatives">
                          <CustomAccordian eventKey="Family/friends/relatives">Family/friends/relatives</CustomAccordian>
                          <Accordion.Collapse eventKey="Family/friends/relatives">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                          <th>5</th>
                                          <th>6</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[6])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[6])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>7</th>
                                          <th>8</th>
                                          <th>9</th>
                                          <th>10</th>
                                          <th>11</th>
                                          <th>12</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.interest[12])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.details.principle[12])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>Year 1</th>
                                          <th>Year 2</th>
                                          <th>Year 3</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.interest[3])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(familyFriends.aggregate.principle[3])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card key="Raw Material Supplier Credit">
                          <CustomAccordian eventKey="Raw Material Supplier Credit">Raw Material Supplier Credit</CustomAccordian>
                          <Accordion.Collapse eventKey="Raw Material Supplier Credit">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                          <th>5</th>
                                          <th>6</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[6])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[3])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[4])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[5])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[6])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>7</th>
                                          <th>8</th>
                                          <th>9</th>
                                          <th>10</th>
                                          <th>11</th>
                                          <th>12</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.interest[12])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[7])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[8])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[9])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[10])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[11])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.details.principle[12])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="table-responsive mt-4">
                                    <table className="table table-bordered">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Description</th>
                                          <th>Year 1</th>
                                          <th>Year 2</th>
                                          <th>Year 3</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table-border-bottom-0">
                                        <tr>
                                          <td>Interest</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.interest[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.interest[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.interest[3])} />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Repayment</td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.principle[1])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.principle[2])} />
                                          </td>
                                          <td>
                                            <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" readOnly disabled value={getNumericFormat(rawMaterialSup.aggregate.principle[3])} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                    <button type="reset" onClick={() => navigate(-1)} className="btn btn-outline-secondary">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default SourcesOfFund;
