import { Modal } from "react-bootstrap";
function CustomModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName={props.isModalXl ? "modal-xl" : ""}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <h5 className="modal-title" id="backDropModalTitle">
          {props.title}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <props.Body props={props}></props.Body>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
