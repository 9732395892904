import { Link } from "react-router-dom";
import logo from "assets/img/icreate_logo.png";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import cryptoJs from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import "./login.css";
function Login(props) {
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const onSignInHandler = (data) => {
    console.log(data);
    let encryptedPassword = cryptoJs.AES.encrypt(
      data.password,
      process.env.REACT_APP_ENCRYPTION_SECRET_KEY
    ).toString();

    axios
      .post(`${ApiUrl}/signin`, {
        email: data.email,
        password: encryptedPassword
      })
      .then((loginRes) => {
        console.log(loginRes.data);
        if (loginRes.data.user.status == "active") {
          localStorage.setItem("auth_token", loginRes.data.token);
          localStorage.setItem("user_data", JSON.stringify(loginRes.data));
          toast("Logged in Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          navigate("/");
        } else {
          toast("User ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.data === undefined) {
          toast("Internal Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      });
  };
  const element = (
    <div className="container-xxl">
      <ToastContainer />
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <img src={logo} />
              </div>
              <form
                id="formAuthentication"
                onSubmit={handleSubmit(onSignInHandler)}
                className="mb-3"
                action="4-dashboard.html"
                method="POST"
              >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>

                  <input
                    type="email"
                    className={`form-control ${
                      errors.email ? "errorBorder" : ""
                    }`}
                    id="email"
                    name="email-username"
                    placeholder="Enter your email"
                    autoFocus
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="textRed" style={{ color: "red" }}>
                      *Email is required{" "}
                    </span>
                  )}
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link to={"/forgotPassword"}>
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className={`form-control ${
                        errors.password ? "errorBorder" : ""
                      }`}
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      {...register("password", { required: true })}
                    />

                    <span
                      className={`input-group-text cursor-pointer ${
                        errors.password ? "errorBorder" : ""
                      }`}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={showPassword ? "bx bx-show" : "bx bx-hide"}
                      ></i>
                    </span>
                  </div>
                  {errors.password && (
                    <span className="textRed" style={{ color: "red" }}>
                      *Password is required{" "}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember-me"
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      {" "}
                      Remember Me{" "}
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary d-grid w-100"
                    type="submit"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return element;
}

export default Login;
