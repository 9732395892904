import { ApiUrl } from 'API/config'
import defaultAvatar from 'assets/img/avatars/placeholder.jpg'
import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import EventEmitter from 'reactjs-eventemitter'
import Loader from 'common/components/loader'
function Profile() {
  const [userData, setUserData] = useState({})
  const [src, setSource] = useState(null)
  const [avatar, setAvatar] = useState('')
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 25,
    aspect: 1 / 4,
  })
  const imageRef = useRef(null)
  const [croppedImageUrl, setcroppedImageUrl] = useState(undefined)
  const [isLoader, setIsLoader] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    getProfileData()
  }, [])
  console.log('errors in profile page', errors)
  const handleFile = (e) => {
    let file_size = e.target.files[0].size
    console.log('file_size', file_size)
    if (file_size > 80000) {
      return toast('Please select a file below 800kb', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setSource(fileReader.result)
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  let addPhotoToUser = () => {}
  const onPhotoReset = () => {
    setAvatar(defaultAvatar)
  }
  const handlePhotoUpload = async (e) => {
    e.preventDefault()
    // const user = this.props.currentUser
    const formData = new FormData()

    //formData.append('user[id]', user.id)
    formData.append('file', croppedImageUrl)
    let uploadResult = await axios.post(`${ApiUrl}filestos3`, formData)
    setAvatar(uploadResult.data.urls[0].url)
    setSource('')
    setcroppedImageUrl('')
    console.log('upload result', avatar)
    //  addPhotoToUser(user, formData)
  }
  const onImageLoaded = (image) => {
    ///imageRef = image
  }
  const onCropChange = (crop) => {
    setCrop(crop)
  }
  const onCropComplete = (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = getCroppedImg(imageRef, crop)
      setcroppedImageUrl(croppedImageUrl)
    }
  }

  const getCroppedImg = (image, crop) => {
    console.log('image reference', image)
    let currentImage = image.current
    const canvas = document.createElement('canvas')
    const scaleX = currentImage.naturalWidth / currentImage.width
    const scaleY = currentImage.naturalHeight / currentImage.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      currentImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    const reader = new FileReader()
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        dataURLtoFile(reader.result, `cropped-${Date.now()}.jpg`)
      }
    })
  }
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    let croppedImage = new File([u8arr], filename, { type: mime })
    console.log('image file', croppedImage)
    // if(croppedImage.size>800){
    //   alert("file size exceeded")
    // }
    setcroppedImageUrl(croppedImage)
  }
  const getProfileData = () => {
    setIsLoader(true)
    let userId = JSON.parse(localStorage.getItem('user_data')).user._id
    axios
      .get(`${ApiUrl}user/${userId}`)
      .then((res) => {
        console.log(res.data)
        setUserData(res.data)
        setAvatar(res.data.avatar)
        const timer = setTimeout(() => {
          setIsLoader(false)
        }, 500)
        return () => clearTimeout(timer)
      })
      .catch((err) => {
        console.log(err)
        const timer = setTimeout(() => {
          setIsLoader(false)
        }, 500)
        return () => clearTimeout(timer)
      })
  }
  useEffect(() => {
    // reset form with user data
    reset(userData)
  }, [userData])
  const onSaveChanges = (data) => {
    console.log('data', data)
    axios
      .post(`${ApiUrl}user/${userData._id}`, { ...data, avatar })
      .then((response) => {
        console.log(response)
        getProfileData()
        EventEmitter.dispatch('profileupdated', avatar)
        toast('Updated Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
      .catch((error) => {
        console.log(error)
        toast('Error While Updating', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }
  console.log('imagesource', src)
  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="mb-4">
          {(src || croppedImageUrl) && (
            <ReactCrop
              src={src}
              crop={crop}
              // onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              keepSelection={true}
            >
              <img
                ref={imageRef}
                alt="Crop me"
                src={src}
                // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoaded}
              />
            </ReactCrop>
          )}
          <ToastContainer />
          <div className="card-body">
            <div className="d-flex align-items-start align-items-sm-center gap-4">
              {!(src || croppedImageUrl) && (
                <img
                  src={avatar ? avatar : defaultAvatar}
                  alt="user-avatar"
                  className="d-block rounded"
                  height="100"
                  width="100"
                  id="uploadedAvatar"
                />
              )}
              <div>
                {!croppedImageUrl ? (
                  <div className="button-wrapper">
                    <label
                      htmlFor="upload"
                      className="btn btn-primary me-2 mb-4"
                      tabIndex="0"
                    >
                      <span className="d-none d-sm-block">
                        Upload new photo
                      </span>
                      <i className="bx bx-upload d-block d-sm-none"></i>
                      <input
                        type="file"
                        onChange={handleFile}
                        id="upload"
                        className="account-file-input"
                        hidden
                        accept=".png, .jpg, .jpeg .gif"
                      />
                    </label>
                    <button
                      type="button"
                      onClick={onPhotoReset}
                      className="btn btn-outline-secondary account-image-reset mb-4"
                    >
                      <i className="bx bx-reset d-block d-sm-none"></i>
                      <span className="d-none d-sm-block">Reset</span>
                    </button>

                    <p className="text-muted mb-0">
                      Allowed JPG, GIF or PNG. Max size of 800K
                    </p>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={handlePhotoUpload}
                      className="btn btn-primary md"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="my-0" />
          <div className="card-body">
            <form
              id="formAccountSettings"
              onSubmit={handleSubmit(onSaveChanges)}
            >
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="firstName"
                    name="firstName"
                    disabled
                    defaultValue={userData.name}
                    autoFocus=""
                  />
                </div>
                {/* <div className="mb-3 col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                className="form-control"
                type="text"
                name="lastName"
                id="lastName"
                defaultValue="Doe"
              />
            </div> */}
                <div className="mb-3 col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email ID
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    disabled
                    name="email"
                    defaultValue={userData.email}
                    autoFocus=""
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="mobile" className="form-label">
                    Mobile No
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="mobile"
                    id="mobile"
                    maxLength={10}
                    minLength={9}
                    //  onInput={(e) => {e.target.value = Math.max( 0,parseInt(e.target.value)).toString().slice(0, 10);}}
                    min={9}
                    defaultValue={userData.mobile}
                    {...register('mobile', {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                    })}
                  />
                  {errors.mobile && errors.mobile.type === 'maxLength' && (
                    <span className="text-danger">
                      Mobile No should be of 10 digits
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type === 'minLength' && (
                    <span className="text-danger">
                      Mobile No should be of 10 digits
                    </span>
                  )}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="role" className="form-label">
                    Role
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="role"
                    disabled
                    id="role"
                    defaultValue={userData.role}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="lastName" className="form-label">
                    Center
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="center"
                    disabled
                    id="center"
                    defaultValue={
                      userData.center && userData.center.name
                        ? userData.center && userData.center.name
                        : 'NA'
                    }
                  />
                </div>
              </div>
              <div className="mt-2">
                <button type="submit" className="btn btn-primary me-2">
                  Save changes
                </button>
                {/* <button type="reset" className="btn btn-outline-secondary">
              Cancel
            </button> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default Profile
