import logo from "../../../assets/img/icreate_logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onForgotPasswordSubmit = (data) => {
    if (data.password != data.newPassword) {
      return toast("Confirm Password Not matched", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    axios
      .put(`${ApiUrl}reset-password`, {
        resetPasswordLink: token,
        newPassword: data.newPassword,
      })
      .then((response) => {
        console.log(response.data);
        toast("Great! Now you can login with your new password.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onclose: () => navigate("/login"),
        });
      })
      .catch((error) => {
        console.log(error);
        toast("Error occured", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center">
                  <img src={logo} />
                </div>
                <p className="mb-4">
                  Enter your password and confirm password to reset your
                  password
                </p>
                <form
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={handleSubmit(onForgotPasswordSubmit)}
                >
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      autoFocus
                      {...register("password", { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter your Confirm Password"
                      autoFocus
                      {...register("newPassword", { required: true })}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary d-grid w-100"
                  >
                    Reset
                  </button>
                </form>
                <div className="text-center">
                  <Link
                    to={"/login"}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                    Back to login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
