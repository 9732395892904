import { ApiUrl } from "API/config";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

function UpdateUser(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
const [allCenters,setAllCenters]=useState([])

useEffect(()=>{
  getAllCenter()
},[])


const getAllCenter = ()=>{
  axios.get(`${ApiUrl}center/all`).then((data)=>{
    setAllCenters(data.data)
  })
}


  const element = (
    <form onSubmit={handleSubmit((data)=>props.props.onUserUpdate(data))}>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label">
            Full Name
          </label>
          <input
            type="text"
            id="nameBackdrop"
            className="form-control"
            disabled
            placeholder="Enter Full Name"
            defaultValue={props.props.data.name}
        //    {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="emailBackdrop" className="form-label">
            Email ID
          </label>
          <input
            type="text"
            id="emailBackdrop"
            className="form-control"
            placeholder="xxxx@xxx.xx"
            disabled
            defaultValue={props.props.data.email}
          //  {...register("email", { required: true })}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col mb-3">
          <label htmlFor="emailBackdrop" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="emailBackdrop"
            className="form-control"
            placeholder="xxxxxxxx"
            disabled
          //  {...register("password", { required: true })}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label">
            Mobile No
          </label>
          <input
            type="number"
            id="nameBackdrop"
             numformat="numberLeft"
              className={`form-control ${errors.mobile ? "errorBorder" : ""}`}
            placeholder="xxxx-xxxx-xx"
            onInput={(e) => {e.target.value = Math.max( 0,parseInt(e.target.value)).toString().slice(0, 10);}}
            pattern="/^-?\d+\.?\d*$/" 
            defaultValue={props.props.data.mobile}
            {...register("mobile", { required: true , maxLength: 10, minLength:10})}
          />
        {errors.mobile && errors.mobile.type == "required" && (
                        <span className="textRed" style={{color: "red"}}>
                          *Mobile Number is required{" "}
                        </span>
                      )}
          {errors.mobile && errors.mobile.type === "maxLength" && <span className="textRed">Mobile No should be of 10 digits</span>}
          {errors.mobile && errors.mobile.type === "minLength" && <span className="textRed">Mobile No should be of 10 digits</span>}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label">
            Roles
          </label>
          <select
            className="form-select"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            defaultValue={props.props.data.role}
            disabled
           // {...register("role", { required: true })}
          >
            <option >Select Role</option>
            <option value="admin">Admin</option>
            <option value="excecutive">Executive Assistant</option>
            <option value="mentor">Mentor</option>
            <option value="supervisor">Supervisor</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label">
            Centre
          </label>
          <input
            type="text"
            id="nameBackdrop"
            className="form-control"
            placeholder="xxxx-xxxx-12"
           disabled
            defaultValue={props.props.data&&props.props.data.center&&props.props.data.center.name&&props.props.data.center.name}
          // {...register("center", { required: true })}
          />
          {/* <select
            className="form-select"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            disabled
            
defaultValue={props.props.data.center}
           // {...register("center", { required: true })}
          >
            <option defaultValue="">Select Centre</option>
            {allCenters.map((x)=>{
              return(
                <option value={x._id}>{x.name}</option>
              )
            })

            }
           
          </select> */}
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Status
          </label>
          <select
          defaultValue={props.props.data.status}
         {...register("status", { required: true })}
            className={`form-select ${errors.status ? "errorBorder" : ""}`}
            id="exampleFormControlSelect1"
            aria-label="Default select example"
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
           {errors.status && <span className="textRed">*Status is required</span>}
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Save</button>

    </form>
  );

  return element;
}

export default UpdateUser;
