import Layout from "common/components/layout";
import { useParams, useNavigate, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { set, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "API/config";
import CustomAccordian from "common/components/CustomAccordian";
import { Accordion, Card } from "react-bootstrap";
import Loader from "common/components/loader";
function RawMaterialProcurement() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [totalOpeningStock, setTotalOpeningStock] = useState(0);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
  } = useForm();
  let rawMaterialProcurementId =
    location.state.data.rawMaterialProcurementId &&
    location.state.data.rawMaterialProcurementId;
  const {
    fields: fieldProductRM,
    update: updateProductRM,
    setValue: setValueRM,
  } = useFieldArray({ name: "fieldProductRMName", control });
  const {
    fields: fieldProductRMConsumption,
    update: updateProductRMConsumption,
    setValue: setValueRMConsumption,
  } = useFieldArray({ name: "fieldProductRMConsumption", control });
  const [routePath, setRoutePath] = useState(
    location.state.data.businessType == "manufacturing"
      ? "rawMaterialProcurement"
      : location.state.data.businessType == "animalhusbandry"
      ? "animalhusbandry/rawMaterialProcurement"
      : location.state.data.businessType == "agro"
      ? "agro/rawMaterialProcurement"
      : location.state.data.businessType == "trading"
      ? "trading/rawMaterialProcurement"
      : location.state.data.businessType == "service"
      ? "service/rawMaterialProcurement"
      : ""
  );
  const [monthlyInputPurchaseB4GST, setMonthlyInputPurchaseB4GST] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });

  const [yearlyInputPurchaseB4GST, setYearlyInputPurchaseB4GST] = useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const [monthlyInputGSTPaid, setMonthlyInputGSTPaid] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  });

  const [yearlyInputGSTPaid, setYearlyInputGSTPaid] = useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };

  function calculateGstTotal() {
    const objArray = getValues('fieldProductRMName');
    

    let totalSum = 0;
  
    // Iterate through each object in the array
    for (const obj of objArray) {
      const openingStock = parseFloat(obj.ReceiptPlan.details.openingStock["1"]);
      const gstInTotal = parseFloat(obj.gst_in_total);
      if(routePath==='trading/rawMaterialProcurement' && !obj?.numberOfItemsLessThan){
        let gstPercent = (parseFloat(obj.gst_in_total)/(parseFloat(obj.per_unit_cost_of_raw_material)-parseFloat(obj.gst_in_total)))*100

        const openingStock = parseFloat(obj.ReceiptPlan.details.openingStock["1"]);
        const gstInTotal = openingStock* gstPercent/(100+gstPercent); 
        
        totalSum += gstInTotal;
}
      else{
        totalSum += openingStock * gstInTotal;

    }
    }
    return totalSum;
  }

  
  var protegeData;
 

  const triggerAggregateCalculation = (row, year, field) => {
    let fieldProductRMName = getValues("fieldProductRMName");
   let qsum = 0;

      let monthCount = 0;

      for (const month in row.details[field]) {
        if (monthCount < 12) {
          qsum += parseFloat(row.details[field][month] === "" ? 0 : row.details[field][month]);
          monthCount++;
        } else {
          break;
        }
      }
      row.aggregate[field][year] = parseFloat(qsum);
  
  };

  const aggregateFields = (obj, i) => {
    triggerAggregateCalculation(obj.ReceiptPlan, 1, "receipt");
  };
  const triggerYearCalculation = (year, obj, i) => {
    let rmConsumption = getValues("fieldProductRMConsumption");
    let rm=getValues('fieldProductRMName')
    if (year === 1) {
      obj.ReceiptPlan.aggregate.closingStock[year] =
        obj.ReceiptPlan.details.closingStock[12];
      obj.ReceiptPlan.aggregate.openingStock[year] =
        obj.ReceiptPlan.details.openingStock[1];
      obj.ReceiptPlan.aggregate.closingStockValue[year] =
        obj.ReceiptPlan.details.closingStockValue[12];  
      }


    for (let singleYear = 1; singleYear <= 3; singleYear++) {

      if(singleYear===1){
      
        obj.CashOutflow.aggregate.rm_cost_exl_gst[year] = parseFloat(
          Object.values(obj.CashOutflow.details.rm_cost_exl_gst)
              .slice(obj.no_of_cash_in_months > 0 ? 0 : undefined, obj.no_of_cash_in_months > 0 ? -obj.no_of_cash_in_months : undefined)
              .reduce((sum, total) => sum + total, 0)
      );
     
      
      obj.CashOutflow.aggregate.gst_component[year] = parseFloat(
        Object.values(obj.CashOutflow.details.gst_component)
            .slice(obj.no_of_cash_in_months > 0 ? 0 : undefined, obj.no_of_cash_in_months > 0 ? -obj.no_of_cash_in_months : undefined)
            .reduce((sum, total) => sum + total, 0)
    );
    
    // rmConsumption[i].aggregate[1] = Object.values(rmConsumption[i].details)
    // .reduce((sum, total) => sum + parseFloat(total), 0);
      }

      if (singleYear != 1) {
        obj.ReceiptPlan.aggregate.openingStock[singleYear] =
          obj.ReceiptPlan.aggregate.closingStock[singleYear - 1];
        obj.ReceiptPlan.aggregate.closingStock[singleYear] = parseFloat(
          parseFloat(obj.ReceiptPlan.aggregate.openingStock[singleYear]) +
            parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) -
            parseFloat(
              rmConsumption[i] ? rmConsumption[i].aggregate[singleYear] : 0
            )
        );
        obj.OrderPlan.aggregate.quantity[singleYear] = parseFloat(
          obj.ReceiptPlan.aggregate.receipt[singleYear]
        );

              if(routePath==='trading/rawMaterialProcurement' && !obj?.numberOfItemsLessThan){
                let gstPercent = (parseFloat(obj.gst_in_total)/(parseFloat(obj.per_unit_cost_of_raw_material)-parseFloat(obj.gst_in_total)))*100

             
                if(singleYear==2){
                  obj.CashOutflow.aggregate.rm_cost_exl_gst[singleYear] =
                  
                  (
                    parseFloat(
                      parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                    ) +
                    Object.values(obj.ReceiptPlan.details.receipt)
                .slice(12 - obj.no_of_cash_in_months, 12)
                .map(value => parseFloat(value)  )
                .reduce((sum, total) => sum + total, 0) -
                    (parseFloat(
                      parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                    ) *
                      (obj.no_of_cash_in_months / 12 || 0)))-(
                  parseFloat(
                    parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                  ) +
                  Object.values(obj.ReceiptPlan.details.receipt)
              .slice(12 - obj.no_of_cash_in_months, 12)
              .map(value => parseFloat(value)  )
              .reduce((sum, total) => sum + total, 0) -
                  (parseFloat(
                    parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                  ) *
                    (obj.no_of_cash_in_months / 12 || 0)))*gstPercent/(100+gstPercent);
         

                    obj.CashOutflow.aggregate.gst_component[singleYear] =
                    (
                      parseFloat(
                        parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                      ) +
                      Object.values(obj.ReceiptPlan.details.receipt)
                  .slice(12 - obj.no_of_cash_in_months, 12)
                  .map(value => parseFloat(value)  )
                  .reduce((sum, total) => sum + total, 0) -
                      (parseFloat(
                        parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) 
                      ) *
                        (obj.no_of_cash_in_months / 12 || 0)))*gstPercent/(100+gstPercent)
                } 
                if(singleYear==3){
                  obj.CashOutflow.aggregate.rm_cost_exl_gst[singleYear] = 
                  
                  parseFloat(
                    obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      -((parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      )) *(12-obj.no_of_cash_in_months)/12)
                      + parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear]
                      )-(parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear] 
                      )*(obj.no_of_cash_in_months)/12)
                  )  -  parseFloat(
                    obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      -((parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      )) *(12-obj.no_of_cash_in_months)/12)
                      + parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear]
                      )-(parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear] 
                      )*(obj.no_of_cash_in_months)/12)
                  )  *gstPercent /(100+gstPercent);
                  
                  obj.CashOutflow.aggregate.gst_component[singleYear] = 
                  
                  parseFloat(
                    obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      -((parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear-1] 
                      )) *(12-obj.no_of_cash_in_months)/12)
                      + parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear]
                      )-(parseFloat(
                        obj.OrderPlan.aggregate.quantity[singleYear] 
                      )*(obj.no_of_cash_in_months)/12)
                  )  *gstPercent/(100+gstPercent)
                }
              }
              else{      
        if(singleYear==2){
          obj.CashOutflow.aggregate.rm_cost_exl_gst[singleYear] =
          parseFloat(
            parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) *
              (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
          ) +
          Object.values(obj.ReceiptPlan.details.receipt)
      .slice(12 - obj.no_of_cash_in_months, 12)
      .map(value => parseFloat(value) * (obj.per_unit_cost_of_raw_material-obj.gst_in_total))
      .reduce((sum, total) => sum + total, 0) -
          (parseFloat(
            parseFloat(obj.ReceiptPlan.aggregate.receipt[singleYear]) *
              (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
          ) *
            (obj.no_of_cash_in_months / 12 || 0));
 
            obj.CashOutflow.aggregate.gst_component[singleYear] =
            parseFloat(
              obj.OrderPlan.aggregate.quantity[singleYear] * obj.gst_in_total
            ) +
            Object.values(obj.ReceiptPlan.details.receipt)
            .slice(12 - obj.no_of_cash_in_months, 12)
            .map(value => parseFloat(value) * (obj.gst_in_total))
            .reduce((sum, total) => sum + total, 0)-
            (parseFloat(
              obj.OrderPlan.aggregate.quantity[singleYear] * obj.gst_in_total
            ) *
              (obj.no_of_cash_in_months / 12 || 0));
        }
        if(singleYear==3){
          obj.CashOutflow.aggregate.rm_cost_exl_gst[singleYear] = 
          
          parseFloat(
            obj.OrderPlan.aggregate.quantity[singleYear-1] *
              (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
              -((parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear-1] *
                  (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
              )) *(12-obj.no_of_cash_in_months)/12)
              + parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear] *
                  (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
              )-(parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear] *
                  (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
              )*(obj.no_of_cash_in_months)/12)
          )  
          
          obj.CashOutflow.aggregate.gst_component[singleYear] = 
          
          parseFloat(
            obj.OrderPlan.aggregate.quantity[singleYear-1] *
              (obj.gst_in_total)
              -((parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear-1] *
                  (obj.gst_in_total)
              )) *(12-obj.no_of_cash_in_months)/12)
              + parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear] *
                  (obj.gst_in_total)
              )-(parseFloat(
                obj.OrderPlan.aggregate.quantity[singleYear] *
                  (obj.gst_in_total)
              )*(obj.no_of_cash_in_months)/12)
          ) 
        }
      }
      if(routePath==='trading/rawMaterialProcurement' && !obj?.numberOfItemsLessThan){
        obj.ReceiptPlan.aggregate.closingStockValue[singleYear] = parseFloat(
          obj.ReceiptPlan.aggregate.closingStock[singleYear]
        );
      }else{
        obj.ReceiptPlan.aggregate.closingStockValue[singleYear] = parseFloat(
          obj.ReceiptPlan.aggregate.closingStock[singleYear] *
            parseFloat(obj.per_unit_cost_of_raw_material)
        );
      }
        
      }
    }
    updateProductRM(i, obj);
  };


    
  const calculateClosingStock = (obj, index, i, value) => {
    let rmConsumption = getValues("fieldProductRMConsumption");
    let fieldProductRMName = getValues("fieldProductRMName");
    if (obj.ReceiptPlan && obj.ReceiptPlan.details.closingStock) {
      for (let month = 1; month <= 12; month++) {
        obj.ReceiptPlan.details.openingStock[month] = obj.ReceiptPlan.details
          .openingStock[month]
          ? obj.ReceiptPlan.details.openingStock[month]
          : 0;
        obj.ReceiptPlan.details.receipt[month] = obj.ReceiptPlan.details
          .receipt[month]
          ? obj.ReceiptPlan.details.receipt[month]
          : 0;
        obj.ReceiptPlan.details.closingStock[month] = parseFloat(
          parseFloat(obj.ReceiptPlan.details.openingStock[month]) +
            parseFloat(obj.ReceiptPlan.details.receipt[month]) -
            parseFloat(rmConsumption[i] ? rmConsumption[i].details[month] : 0)
        );
        obj.ReceiptPlan.details.openingStock[parseFloat(month) + 1] =
          parseFloat(obj.ReceiptPlan.details.closingStock[month]);
        obj.ReceiptPlan.details.closingStockValue[parseFloat(month)] =
          parseFloat(
            obj.ReceiptPlan.details.closingStock[month] *
              parseFloat(obj.per_unit_cost_of_raw_material)
          );
        calculateOrderPlan(
          obj,
          month,
          obj.ReceiptPlan.details.receipt[month],
          i
        );
      }
      triggerYearCalculation(1, obj, i);
      calculateOpeningStock();
      aggregateFields(obj, i);
      updateProductRM(i, obj);
    }
    sumOfInputCalculationMonthly(fieldProductRMName);
  };
  const sumOfInputCalculationMonthly = (product) => {
    if (product.length) {
      product.filter((item) => {
        for (let monthData = 1; monthData <= 12; monthData++) {
          let totalMonthlyInputValue = product.reduce(
            (acc, obj) =>
              parseFloat(acc) +
              parseFloat(obj.CashOutflow.details.rm_cost_exl_gst[monthData]),
            0
          );
          let objM = monthlyInputPurchaseB4GST;
          objM[monthData] = totalMonthlyInputValue;
          setMonthlyInputPurchaseB4GST(objM);
        }
        for (let yearData = 1; yearData <= 12; yearData++) {
          let totalYearlyInputValue = product.reduce(
            (acc, obj) =>
              parseFloat(acc) +
              parseFloat(obj.CashOutflow.aggregate.rm_cost_exl_gst[yearData]),
            0
          );
          let objM = yearlyInputPurchaseB4GST;
          objM[yearData] = totalYearlyInputValue;
          setYearlyInputPurchaseB4GST(objM);

        }
        for (let monthData = 1; monthData <= 12; monthData++) {
          let totalMonthlyGSTValue = product.reduce(
            (acc, obj) =>
              parseFloat(acc) +
              parseFloat(obj.CashOutflow.details.gst_component[monthData]),
            0
          );
          let objM = monthlyInputGSTPaid;
          objM[monthData] = totalMonthlyGSTValue;
          setMonthlyInputGSTPaid(objM);
        }
        for (let yearData = 1; yearData <= 12; yearData++) {
          let totalYearlyGSTValue = product.reduce(
            (acc, obj) =>
              parseFloat(acc) +
              parseFloat(obj.CashOutflow.aggregate.gst_component[yearData]),
            0
          );
          let objM = yearlyInputGSTPaid;
          objM[yearData] = totalYearlyGSTValue;
          setYearlyInputGSTPaid(objM);
        }
      });
    }
  };
  const calculateOpeningStock = () => {
    let totalOpeningStock = 0;
    let rmConsumption = getValues("fieldProductRMName");
    rmConsumption.filter((item) => {
      if(routePath==='trading/rawMaterialProcurement' && !item?.numberOfItemsLessThan){
        totalOpeningStock +=
        parseFloat(item.ReceiptPlan.details.openingStock[1])  
        
      }
      else{
      totalOpeningStock +=
        parseFloat(item.ReceiptPlan.details.openingStock[1]) *
        parseFloat(
          item.per_unit_cost_of_raw_material
            ? item.per_unit_cost_of_raw_material
            : 0
        );
    }});
    setTotalOpeningStock(totalOpeningStock);
  };
  const calculateOrderPlan = (obj, index, value, i) => {
    if (value >= 0) {
      let leadTime = obj.Lead_Time ? parseFloat(obj.Lead_Time) : 0;
      if (index > leadTime) {
        calculateClaseFlow(obj, index, value);
      }
      CalculateClosingStockValue(obj, index);
      triggerAggregateCalculation(obj.OrderPlan, 1, "quantity");
      updateProductRM(i, obj);
    }
  };
  const calculateClaseFlow = (obj, index, value) => {
    let cashFlow = getValues("fieldProductRMName");
    let leadTime = obj.Lead_Time ? parseFloat(obj.Lead_Time) : 0;
    let paymentTerms = obj.payment_terms;
    let no_of_months = obj.no_of_cash_in_months
      ? parseFloat(obj.no_of_cash_in_months)
      : 0;
    let orderMonth = 0;
    let cashMonth = 0;
    if (paymentTerms == "credit") {
      orderMonth = parseFloat(index) - leadTime;
      cashMonth = parseFloat(index) + parseFloat(no_of_months);
    } else if (paymentTerms == "cash") {
      orderMonth = parseFloat(index) - leadTime;
      cashMonth = parseFloat(index);
    } else if (paymentTerms == "advance") {
      orderMonth = parseFloat(index) - leadTime;
      cashMonth = parseFloat(index) - leadTime;
    } else {
      orderMonth = parseFloat(index);
      cashMonth = parseFloat(index);
    }
    obj.OrderPlan.details.quantity[orderMonth] = parseFloat(value);
    obj.CashOutflow.details.rm_cost_exl_gst[cashMonth] = parseFloat(
      value * (obj.per_unit_cost_of_raw_material - obj.gst_in_total)
    );
    obj.CashOutflow.details.gst_component[cashMonth] = parseFloat(
      value * obj.gst_in_total
    );

    if(routePath==='trading/rawMaterialProcurement' && !obj?.numberOfItemsLessThan){

      let gstPercent = (parseFloat(obj.gst_in_total)/(parseFloat(obj.per_unit_cost_of_raw_material)-parseFloat(obj.gst_in_total)))*100
      obj.CashOutflow.details.rm_cost_exl_gst[cashMonth] = parseFloat(value)-( parseFloat(
        value)*gstPercent/(100+gstPercent))
      obj.CashOutflow.details.gst_component[cashMonth] = parseFloat(value) -  obj.CashOutflow.details.rm_cost_exl_gst[cashMonth]
      
    // obj.CashOutflow.details.rm_cost_exl_gst[1] = parseFloat(obj.ReceiptPlan.details.openingStock[1])-( parseFloat(
    //   obj.ReceiptPlan.details.openingStock[1])*gstPercent/(100+gstPercent))
    //   obj.CashOutflow.details.gst_component[1] = parseFloat(obj.ReceiptPlan.details.openingStock[1]) -  obj.CashOutflow.details.rm_cost_exl_gst[1]
    }

    // cashFlow[obj].CashOutflow.aggregate.rm_cost_exl_gst[0]= triggerAggregateCalculation(cashFlow[obj].CashOutflow, 1, "rm_cost_exl_gst")
    // cashFlow.CashOutflow.aggregate.rm_cost_exl_gst[1]= triggerAggregateCalculationAgg()
    // cashFlow.CashOutflow.aggregate.rm_cost_exl_gst[2]= triggerAggregateCalculationAgg()
    
    
  };
  const CalculateClosingStockValue = (obj, index) => {
    
    obj.ReceiptPlan.details.closingStockValue[index] = parseFloat(
      obj.ReceiptPlan.details.closingStock[index] *
        parseFloat(obj.per_unit_cost_of_raw_material)
    );
    if(routePath==='trading/rawMaterialProcurement' && !obj?.numberOfItemsLessThan){
      obj.ReceiptPlan.details.closingStockValue[index] = parseFloat(
        obj.ReceiptPlan.details.closingStock[index] 
      );
    }
  };
  const onSubmit = (data) => {
    setIsLoader(true);
    axios
      .post(`${ApiUrl}rawMaterialProcurement/${routePath}/create`, {
        ...data,
        monthlyInputPurchaseB4GST,
        yearlyInputPurchaseB4GST,
        monthlyInputGSTPaid,
        yearlyInputGSTPaid,
        initialInputCost:parseFloat(totalOpeningStock-calculateGstTotal()),
        initialInputCostGST: calculateGstTotal(),
        businessId: location.state.data.businessId,
      })
      .then((result) => {
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onUpdate = (data) => {
    setIsLoader(true);
    axios
      .put(`${ApiUrl}rawMaterialProcurement/${routePath}/update`, {
        ...data,
        monthlyInputPurchaseB4GST,
        yearlyInputPurchaseB4GST,
        monthlyInputGSTPaid,
        yearlyInputGSTPaid,
        initialInputCost:parseFloat(totalOpeningStock-calculateGstTotal()),
        initialInputCostGST: calculateGstTotal(),
        _id: rawMaterialProcurementId,
      })
      .then((result) => {
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  useEffect(() => {
    getRawMaterialProcurementNew();
  }, []);
  const getRawMaterialProcurementNew = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}protege/${location.state.data.protegeId}`)
      .then(async (result) => {
       protegeData = result?.data[0]?.trading;
        if (
          location.state.data.businessType == "manufacturing" ||
          location.state.data.businessType == "service"
        ) {
          let businessData =
            location.state.data.businessType == "manufacturing"
              ? result.data[0].manufacturingIds
              : result.data[0].service;
          let consolidatedRMConsumption = consolidatedRM(businessData);
          let fieldProductRMTotalConsumptionName =
            businessData.salesProjection &&
            businessData.salesProjection.fieldProductSkuName &&
            consolidatedRMConsumption.length
              ? getConsumptionQuantity(consolidatedRMConsumption, businessData)
              : [];
          let fieldProductRMName = setRawMaterialProcurement(
            businessData,
            fieldProductRMTotalConsumptionName
          );
          reset({
            fieldProductRMName,
            fieldProductRMConsumption: fieldProductRMTotalConsumptionName,
          });
          if (rawMaterialProcurementId) {
            fieldProductRMName.map((x, index) => {
              calculateClosingStock(x, 1, index, "");
            });
          }
        } else if (
          location.state.data.businessType == "animalhusbandry" ||
          location.state.data.businessType == "agro"
        ) {
          let businessData =
            location.state.data.businessType == "animalhusbandry"
              ? result.data[0].animalhusbandry
              : location.state.data.businessType == "agro"
              ? result.data[0].agro
              : [];
          let consolidatedRMData = businessData.productList
            .map((x, i) =>
              x.rawMaterialData.rawMaterial.map((y) => {
                return getConsolidatedRawMaterial(x, y, "");
              })
            )
            .flat();
          let fieldProductRMTotalConsumptionName =
            businessData && consolidatedRMData.length
              ? getConsumptionQuantity(consolidatedRMData, businessData)
              : [];
          let fieldProductRMName = setRawMaterialProcurement(
            businessData,
            fieldProductRMTotalConsumptionName
          );
          reset({
            fieldProductRMName,
            fieldProductRMConsumption: fieldProductRMTotalConsumptionName,
          });
          if (rawMaterialProcurementId) {
            fieldProductRMName.map((x, index) => {
              calculateClosingStock(x, 1, index, "");
            });
          }
        } else if (location.state.data.businessType == "trading") {
          let businessData = result.data[0].trading;
          let consolidatedRMData = businessData.productList
            .map((x, i) =>
              x.rawMaterialData.rawMaterial.map((y) => {
                return getConsolidatedRawMaterial(x, y, "");
              })
            )
            .flat();
          let fieldProductRMTotalConsumptionName =
            businessData && consolidatedRMData.length
              ? getConsumptionQuantity(consolidatedRMData, businessData)
              : [];
          let fieldProductRMName = setRawMaterialProcurement(
            businessData,
            fieldProductRMTotalConsumptionName
          );
          reset({
            fieldProductRMName,
            fieldProductRMConsumption: fieldProductRMTotalConsumptionName,
          });
          if (rawMaterialProcurementId) {
            fieldProductRMName.map((x, index) => {
              calculateClosingStock(x, 1, index, "");
            });
          }
          let gstPercent = businessData?.productList[0]?.rawMaterialData?.rawMaterial?.map((item=>item?.gst_on_raw_material));
        } else {
        }
       
        setTimeout(() => setIsLoader(false), 400);
      });
  };
  const setRawMaterialProcurement = (
    businessData,
    fieldProductRMTotalConsumptionName
  ) => {
    let fieldRawMaterialProcurement = businessData.rawMaterialProcurement
      ? businessData.rawMaterialProcurement.fieldProductRMName
      : [];
    let requiredData = fieldProductRMTotalConsumptionName
      .map((x) => {
        let rawMaterialData =
          businessData.rawMaterialConsumption.rawMaterial.find(
            (y) => y.raw_material_description === x.raw_material_description
          );
        let orderingFrequency = businessData.orderingFrequency
          ? businessData.orderingFrequency.fieldOrderingFrequencyName.find(
              (y) => y.Raw_Material === x.raw_material_description
            )
          : [];
        let procurementData = fieldRawMaterialProcurement.find(
          (y) => y.raw_material_description === x.raw_material_description
        );
        if (rawMaterialData && orderingFrequency) {
          if (rawMaterialProcurementId) {
            return procurementData
              ? updatedNewRawMaterial(
                  procurementData,
                  rawMaterialData,
                  orderingFrequency
                )
              : getNewRawMaterial(x, rawMaterialData, orderingFrequency);
          } else {
            return getNewRawMaterial(x, rawMaterialData, orderingFrequency);
          }
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);
    return requiredData;
  };

  const consolidatedRM = (result) => {
    let eachSkuPacking = result.productList.map((x, i) =>
      x.skuData.map((y) => y.packing).flat()
    );
    let consolidatedRM = result.productList
      .map((x, i) => {
        return x.skuData
          .map((z, sIndex) => {
            return [
              ...x.rawMaterialData.rawMaterial.filter((rm) => {
                return !eachSkuPacking[i]
                  .map((mr) => mr.level)
                  .includes(rm.raw_material_description);
              }),
              ...x.skuData[sIndex].packing.map((pk, pi) => {
                return {
                  raw_material_description: pk.level,
                  sku_quantity: parseFloat(pk.sku_quantity),
                  unit_cost: parseFloat(pk.unit_cost),
                };
              }),
            ].map((y) => {
              return getConsolidatedRawMaterial(x, y, z);
            });
          })
          .flat();
      })
      .flat();
    return consolidatedRM;
  };
  const getConsumptionQuantity = (requiredFieldProductRMName, businessData) => {
    let consumptionQuantity = [];
    requiredFieldProductRMName.map((a, i) => {
      let detailValue = [];
      if (
        location.state.data.businessType == "manufacturing" ||
        location.state.data.businessType == "service"
      ) {
        detailValue =
          businessData.salesProjection &&
          businessData.salesProjection.fieldProductSkuName
            ? businessData.salesProjection.fieldProductSkuName.filter(
                (x) => x.name == a.sku_description
              )
            : [];

      } else if (location.state.data.businessType == "trading") {
        detailValue =
          businessData.salesProjection &&
          businessData.salesProjection.fieldProductSkuName
            ? businessData.salesProjection.fieldProductSkuName.filter(
                (x) => x.name == a.raw_material_description
              )
            : [];
      } else if (
        location.state.data.businessType == "animalhusbandry" ||
        location.state.data.businessType == "agro"
      ) {
        detailValue =
          businessData.productList && businessData.productList
            ? businessData.productList.find((x) => x._id == a.product_id)
                .basicInformation
            : [];
           
      }

      let productionQuantity = 0;
      let quantityRequired = a.quantity_required_for_batch_size
        ? parseFloat(a.quantity_required_for_batch_size)
        : 0;
      let requiredCalculatedData = 0;
      for (let month = 1; month <= 12; month++) {
        if (
          location.state.data.businessType == "manufacturing" 
        ) {
          productionQuantity =
            detailValue.length && detailValue[0].details[month]
              ? detailValue[0].details[month].prod_quantity
              : 0;
          if (quantityRequired) {
            requiredCalculatedData =
              ((productionQuantity * parseFloat(a.sku_quantity)) /
                (parseFloat(a.batch_size) / parseFloat(a.sku_size))) *
              quantityRequired;           
             
           

             
            a.details[month] = parseFloat(requiredCalculatedData);
           
          } else {
            a.details[month] = productionQuantity;
           
          }
        } 
        else if (
          location.state.data.businessType == "service"
        ) {
          productionQuantity =
            detailValue.length && detailValue[0].details[month]
              ? detailValue[0].details[month].prod_quantity
              : 0;

              // debugger
          if (quantityRequired) {
              requiredCalculatedData=parseFloat(productionQuantity) *parseFloat(quantityRequired)/ parseFloat(a.batch_size);
            a.details[month] = parseFloat(requiredCalculatedData);
          } 
        } 
        else if (location.state.data.businessType == "trading") {
          productionQuantity =
            detailValue.length && detailValue[0].details[month]
              ? detailValue[0].details[month].prod_quantity
              : 0;
          if (quantityRequired) {
            requiredCalculatedData = productionQuantity;
            a.details[month] = parseFloat(requiredCalculatedData);
          } else {
            a.details[month] = productionQuantity;
          }
        } else if (
          location.state.data.businessType == "animalhusbandry" ||
          location.state.data.businessType == "agro"
        ) {
          productionQuantity =
            detailValue.length && detailValue[0].details[month]
              ? detailValue[0].details[month].qty_to_be_fed
              : 0;
          if (quantityRequired) {
            requiredCalculatedData =
              parseFloat(productionQuantity) * quantityRequired;
            a.details[month] = parseFloat(requiredCalculatedData);
          } else {
            a.details[month] = productionQuantity;
          }
        }
      }

      let requiredAgg = 0;
      let productionQuantityYear = 0;
      for (let year = 1; year <= 3; year++) {
        if (
          location.state.data.businessType == "manufacturing" 
        ) {
          productionQuantityYear =
            detailValue.length && detailValue[0].aggregate[year]
              ? parseFloat(detailValue[0].aggregate[year].prod_quantity)
              : 0;
          if (quantityRequired) {
            requiredAgg =
              ((productionQuantityYear * parseFloat(a.sku_quantity)) /
                (parseFloat(a.batch_size) / parseFloat(a.sku_size))) *
              quantityRequired;
            a.aggregate[year] = parseFloat(requiredAgg);
          } else {
            a.aggregate[year] = productionQuantityYear;
          }
        }
        if (
          location.state.data.businessType == "service"
        ) {
          productionQuantityYear =
            detailValue.length && detailValue[0].aggregate[year]
              ? parseFloat(detailValue[0].aggregate[year].prod_quantity)
              : 0;
          if (quantityRequired) {
            requiredAgg=parseFloat(productionQuantityYear) *parseFloat(a.quantity_required_for_batch_size)/ parseFloat(a.batch_size);

            a.aggregate[year] = parseFloat(requiredAgg);
          } else {
            a.aggregate[year] = productionQuantityYear;
          }
        }
        else if (location.state.data.businessType == "trading") {
          productionQuantityYear =
            detailValue.length && detailValue[0].aggregate[year]
              ? parseFloat(detailValue[0].aggregate[year].prod_quantity)
              : 0;
          if (quantityRequired) {
            requiredAgg = productionQuantityYear;
            a.aggregate[year] = parseFloat(requiredAgg);
          } else {
            a.aggregate[year] = productionQuantityYear;
          }
        } else if (
          location.state.data.businessType == "animalhusbandry" ||
          location.state.data.businessType == "agro"
        ) {
          productionQuantityYear =
            detailValue.length && detailValue[0].aggregate[year]
              ? detailValue[0].aggregate[year].qty_to_be_fed
              : 0;
          if (quantityRequired) {
            requiredAgg =
              parseFloat(productionQuantityYear) * quantityRequired * 12;
            a.aggregate[year] = parseFloat(requiredAgg);
          } else {
            a.aggregate[year] = productionQuantityYear;
          }
        }
      }
      return a;
    });

    requiredFieldProductRMName.forEach((x) => {
      let obj = consumptionQuantity.find(
        (o) => o.raw_material_description == x.raw_material_description
      );
      
      if (obj) {
        console.log(obj);
        let requiredMonth = 0;
        for (let item = 1; item <= 12; item++) {
          requiredMonth =
          parseFloat(obj.details[item]) + parseFloat(x.details[item]);
          obj.details[item] = parseFloat(requiredMonth);
        }
        let requiredAggregate = 0;
        for (let itemYear = 1; itemYear <= 3; itemYear++) {

       
              requiredAggregate =
              parseFloat(obj.aggregate[itemYear]) +
              parseFloat(x.aggregate[itemYear]);
              console.log(requiredAggregate)
              obj.aggregate[itemYear] = requiredAggregate;
              console.log(obj)
            
        }
      } else {
        consumptionQuantity.push(x);
       
      }

      


    });
    console.log(consumptionQuantity)
    return consumptionQuantity;
  };
  const getConsolidatedRawMaterial = (x, y, z) => {
    return {
      product_id: x._id,
      sku_description: z ? z.sku_description : "",
      per_unit_cost_of_raw_material: y.per_unit_cost_of_raw_material,
      sku_quantity: parseFloat(z.sku_quantity),
      sku_size: z.sku_size,
      batch_size: x.batch_size,
      details: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
      raw_material_description: y.raw_material_description,
      unit_of_measure: y.unit_of_measure,
      quantity_required_for_batch_size: y.quantity_required_for_batch_size,
      unit_cost: y.unit_cost,
      numberOfItemsLessThan: x.numberOfItemsLessThan,
    };
  };
  const getNewRawMaterial = (x, y, z) => {
    return {
      raw_material_description: y.raw_material_description
        ? y.raw_material_description
        : "",
      unit_of_measure: y.unit_of_measure,
      minimum_order_quantity: y.minimum_order_quantity,
      Lead_Time: y.lead_time,
      numberOfItemsLessThan: x.numberOfItemsLessThan,
      Ordering_Frequency: z && z.Ordering_Frequency ? z.Ordering_Frequency : 0,
      per_unit_cost_of_raw_material: y.per_unit_cost_of_raw_material,
      gst_in_total: y.gst_in_total,
      payment_terms: y.payment_terms,
      no_of_cash_in_months: y.no_of_cash_in_months,
      Safety_Stock: y.Safety_Stock,
      CashOutflow: {
        details: {
          rm_cost_exl_gst: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          gst_component: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
        },
        aggregate: {
          rm_cost_exl_gst: {
            1: 0,
            2: 0,
            3: 0,
          },
          gst_component: {
            1: 0,
            2: 0,
            3: 0,
          },
        },
      },
      OrderPlan: {
        details: {
          quantity: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
        },
        aggregate: {
          quantity: {
            1: 0,
            2: 0,
            3: 0,
          },
        },
      },
      ReceiptPlan: {
        details: {
          openingStock: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          receipt: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          consumption: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          closingStock: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          closingStockValue: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
        },
        aggregate: {
          openingStock: {
            1: 0,
            2: 0,
            3: 0,
          },
          receipt: {
            1: 0,
            2: 0,
            3: 0,
          },
          consumption: {
            1: 0,
            2: 0,
            3: 0,
          },
          closingStock: {
            1: 0,
            2: 0,
            3: 0,
          },
          closingStockValue: {
            1: 0,
            2: 0,
            3: 0,
          },
        },
      },
    };
  };
  const updatedNewRawMaterial = (x, y, z) => {
    return {
      ...x,
      raw_material_description: y.raw_material_description,
      unit_of_measure: y.unit_of_measure,
      minimum_order_quantity: y.minimum_order_quantity,
      Lead_Time: y.lead_time,
      Ordering_Frequency: z.Ordering_Frequency ? z.Ordering_Frequency : 0,
      per_unit_cost_of_raw_material: y.per_unit_cost_of_raw_material,
      gst_in_total: y.gst_in_total,
      payment_terms: y.payment_terms,
      no_of_cash_in_months: y.no_of_cash_in_months,
      Safety_Stock: y.Safety_Stock,
    };
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Procurement Plan
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="mb-4">
            <div className="card-body">
              {fieldProductRM.length != 0 && (
                <div
                  className="mb-4"
                  style={{ display: "flex", alignItems: "center",flexWrap:'wrap', justifyContent:'space-between' }}
                >
                  <div>
                  <label className="col-md-12">Initial Input Cost Without GST: </label>
                  <input
                    type="text"
                    className="form-control sl-width mb-4"
                    disabled
                    readOnly
                    value={getNumericFormat(totalOpeningStock-calculateGstTotal())}
                  />
                  </div>
                  <div>
                  <label className="col-md-12">GST on Initial Input cost:  </label>
                  <input
                    type="text"
                    className="form-control sl-width mb-4"
                    disabled
                    readOnly
                    value={getNumericFormat(calculateGstTotal())}
                  />
                  </div>
                  <div>
                  <label className="col-md-12">Total Initial Input Cost: </label>
                  <input
                    type="text"
                    className="form-control sl-width mb-4"
                    disabled
                    readOnly
                    value={getNumericFormat(totalOpeningStock)}
                  />
                  </div>
                 
                 
                </div>
              )}
              {!fieldProductRM.length && (
                <div className="row">
                  No data found! Please update the input details and ordering
                  frequency!
                </div>
              )}
              <form
                id="formAccountSettings"
                onSubmit={handleSubmit(
                  rawMaterialProcurementId ? onUpdate : onSubmit
                )}
              >
                <div className="row">
                  {fieldProductRM.map((x, i) => {
                    return (
                      <div className="card mb-4" key={i}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 col-xl-12">
                              <h5>{x.raw_material_description}</h5>
                              <div className="col-md-12 mb-4">
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead className="table-light">
                                      <tr>
                                        <th>Input Name</th>
                                        <th>Unit Measure</th>
                                        <th>Total Unit Cost </th>
                                        <th>Min Order Quantity</th>
                                        <th>Lead Time</th>
                                        <th>Payment Term</th>
                                        <th>No of Month</th>
                                        <th>Frequency of Order</th>
                                        <th>Safety Stock in Month</th>
                                      </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                      <tr>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control xl-width"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={x.raw_material_description}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            readOnly
                                            value={x.unit_of_measure}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.per_unit_cost_of_raw_material
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.minimum_order_quantity
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control sl-width"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.Lead_Time
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={x.payment_terms}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.no_of_cash_in_months
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.Ordering_Frequency
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            disabled
                                            readOnly
                                            value={getNumericFormat(
                                              x.Safety_Stock
                                            )}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <Accordion>
                                <Card key={"receiptPlan"}>
                                  <CustomAccordian eventKey={"receiptPlan"}>
                                    Receipt Plan
                                  </CustomAccordian>
                                  <Accordion.Collapse eventKey={"receiptPlan"}>
                                    <Card.Body>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="table-responsive">
                                            <table className="table table-bordered">
                                              <thead className="table-light">
                                                <tr>
                                                  <th>Description</th>
                                                  <th>1</th>
                                                  <th>2</th>
                                                  <th>3</th>
                                                  <th>4</th>
                                                  <th>5</th>
                                                  <th>6</th>
                                                </tr>
                                              </thead>
                                              <tbody className="table-border-bottom-0">
                                                <tr>
                                                  <td>Opening Stock</td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[1]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              1,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[2]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[3]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[3]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[4]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[4]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[5]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[5]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[6]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[6]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Receipt</td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 1
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[1]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              1,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 2
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[2]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              2,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 3
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[3]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              3,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 4
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[4]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              4,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 5
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[5]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              5,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 6
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[6]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              6,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Consumption</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[1]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[2]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[3]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[4]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[5]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[6]
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[1] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[2] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[2]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[3] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[3]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[3]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[4] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[4]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[4]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[5] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[5]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[5]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[6] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[6]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[6]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock Value</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[2]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[3]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[3]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[4]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[4]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[5]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[5]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[6]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[6]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="table-responsive mt-4">
                                            <table className="table table-bordered">
                                              <thead className="table-light">
                                                <tr>
                                                  <th>Description</th>
                                                  <th>7</th>
                                                  <th>8</th>
                                                  <th>9</th>
                                                  <th>10</th>
                                                  <th>11</th>
                                                  <th>12</th>
                                                </tr>
                                              </thead>
                                              <tbody className="table-border-bottom-0">
                                                <tr>
                                                  <td>Opening Stock</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[7]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[7]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[8]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[8]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[9]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[9]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[10]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[10]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[11]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[11]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .openingStock[12]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.openingStock[12]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Receipt</td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 7
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[7]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              7,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 8
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[8]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              8,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 9
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[9]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              9,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 10
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[10]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              10,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 11
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[11]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              11,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled={
                                                        x.Lead_Time >= 12
                                                      }
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.details.receipt[12]`,
                                                        {
                                                          onChange: (e) => {
                                                            calculateClosingStock(
                                                              x,
                                                              12,
                                                              i,
                                                              e.target.value
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Consumption</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[7]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[8]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[9]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[10]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[11]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].details[12]
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[7] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[7]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[7]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[8] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[8]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[8]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[9] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[9]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[9]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[10] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[10]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[10]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[11] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[11]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[11]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.details
                                                          .closingStock[12] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      disabled
                                                      readOnly
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStock[12]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStock[12]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock Value</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[7]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[7]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[8]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[8]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[9]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[9]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[10]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[10]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[11]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[11]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.details
                                                          .closingStockValue[12]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.details.closingStockValue[12]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="table-responsive mt-4">
                                            <table className="table table-bordered">
                                              <thead className="table-light">
                                                <tr>
                                                  <th>Description</th>
                                                  <th>Year 1</th>
                                                  <th>Year 2</th>
                                                  <th>Year 3</th>
                                                </tr>
                                              </thead>
                                              <tbody className="table-border-bottom-0">
                                                <tr>
                                                  <td>Opening Stock</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .openingStock[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.openingStock[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .openingStock[2]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.openingStock[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStock[2]
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Receipt</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .receipt[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.receipt[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.aggregate.receipt[2]`,
                                                        {
                                                          onChange: (e) => {
                                                            triggerYearCalculation(
                                                              2,
                                                              x,
                                                              i
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(
                                                        `fieldProductRMName.${i}.ReceiptPlan.aggregate.receipt[3]`,
                                                        {
                                                          onChange: (e) => {
                                                            triggerYearCalculation(
                                                              3,
                                                              x,
                                                              i
                                                            );
                                                          },
                                                        }
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Consumption</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].aggregate[1]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].aggregate[2]
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        fieldProductRMConsumption[
                                                          i
                                                        ] &&
                                                          fieldProductRMConsumption[
                                                            i
                                                          ].aggregate[3]
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className={`form-control ${
                                                        x.ReceiptPlan.aggregate
                                                          .closingStock[12] < 0
                                                          ? "errorBorder"
                                                          : ""
                                                      }`}
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStock[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStock[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      readOnly
                                                      disabled
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      value={getNumericFormat(
                                                        parseFloat(
                                                          x.ReceiptPlan
                                                            .aggregate
                                                            .closingStock[2]
                                                        )
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStock[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      readOnly
                                                      disabled
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStock[3]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStock[3]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Closing Stock Value</td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStockValue[1]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStockValue[1]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStockValue[2]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStockValue[2]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      readOnly
                                                      disabled
                                                      value={getNumericFormat(
                                                        x.ReceiptPlan.aggregate
                                                          .closingStockValue[3]
                                                      )}
                                                      {...register(
                                                        getNumericFormat(
                                                          `fieldProductRMName.${i}.ReceiptPlan.aggregate.closingStockValue[3]`
                                                        )
                                                      )}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                <Card key={"OrderPlan"}>
                                  <CustomAccordian eventKey={"OrderPlan"}>
                                    Order Plan
                                  </CustomAccordian>
                                  <Accordion.Collapse eventKey={"OrderPlan"}>
                                    <Card.Body>
                                      <div className="row">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="table-responsive">
                                              <table className="table table-bordered">
                                                <thead className="table-light">
                                                  <tr>
                                                    <th>Description</th>
                                                    <th>1</th>
                                                    <th>2</th>
                                                    <th>3</th>
                                                    <th>4</th>
                                                    <th>5</th>
                                                    <th>6</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                  <tr>
                                                    <td>Quantity</td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[1]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[1]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[2]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[2]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[3]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[3]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[4]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[4]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[5]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[5]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[6]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[6]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="table-responsive mt-4">
                                              <table className="table table-bordered">
                                                <thead className="table-light">
                                                  <tr>
                                                    <th>Description</th>
                                                    <th>7</th>
                                                    <th>8</th>
                                                    <th>9</th>
                                                    <th>10</th>
                                                    <th>11</th>
                                                    <th>12</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                  <tr>
                                                    <td>Quantity</td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[7]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[7]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[8]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[8]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[9]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[9]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[10]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[10]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[11]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[11]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        value={getNumericFormat(
                                                          x.OrderPlan.details
                                                            .quantity[12]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.details.quantity[12]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="table-responsive mt-4">
                                              <table className="table table-bordered">
                                                <thead className="table-light">
                                                  <tr>
                                                    <th>Description</th>
                                                    <th>Year 1</th>
                                                    <th>Year 2</th>
                                                    <th>Year 3</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                  <tr>
                                                    <td>Quantity</td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        disabled
                                                        readOnly
                                                        value={getNumericFormat(
                                                          x.OrderPlan.aggregate
                                                            .quantity[1]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.aggregate.quantity[1]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        disabled
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        value={getNumericFormat(
                                                          x.OrderPlan.aggregate
                                                            .quantity[2]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.aggregate.quantity[2]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        numformat="numberRight"
                                                        disabled
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        value={getNumericFormat(
                                                          x.OrderPlan.aggregate
                                                            .quantity[3]
                                                        )}
                                                        {...register(
                                                          getNumericFormat(
                                                            `fieldProductRMName.${i}.OrderPlan.aggregate.quantity[3]`
                                                          )
                                                        )}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                <Card key={"CashOutflow"}>
                                  <CustomAccordian eventKey={"CashOutflow"}>
                                    Cash Outflow
                                  </CustomAccordian>
                                  <Accordion.Collapse eventKey={"CashOutflow"}>
                                    <Card.Body>
                                      <div className="row">
                                        <div className="row">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="table-responsive">
                                                <table className="table table-bordered">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th>Description</th>
                                                      <th>1</th>
                                                      <th>2</th>
                                                      <th>3</th>
                                                      <th>4</th>
                                                      <th>5</th>
                                                      <th>6</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="table-border-bottom-0">
                                                    <tr>
                                                      <td>
                                                        Input Cost Excluding GST
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[1]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[1]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[2]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[2]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[3]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[3]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[4]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[4]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[5]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[5]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[6]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[6]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>GST Component</td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[1]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[1]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[2]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[2]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[3]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[3]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[4]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[4]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[5]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[5]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[6]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[6]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="table-responsive mt-4">
                                                <table className="table table-bordered">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th>Description</th>
                                                      <th>7</th>
                                                      <th>8</th>
                                                      <th>9</th>
                                                      <th>10</th>
                                                      <th>11</th>
                                                      <th>12</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="table-border-bottom-0">
                                                    <tr>
                                                      <td>
                                                        Input Cost Excluding GST
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[7]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[7]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[8]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[8]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[9]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[9]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[10]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[10]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[11]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[11]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .rm_cost_exl_gst[12]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.rm_cost_exl_gst[12]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>GST Component</td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[7]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[7]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[8]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[8]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[9]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[9]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[10]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[10]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[11]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[11]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .details
                                                              .gst_component[12]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.details.gst_component[12]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="table-responsive mt-4">
                                                <table className="table table-bordered">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th>Description</th>
                                                      <th>Year 1</th>
                                                      <th>Year 2</th>
                                                      <th>Year 3</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="table-border-bottom-0">
                                                    <tr>
                                                      <td>
                                                        Input Cost Excluding GST
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .rm_cost_exl_gst[1]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.rm_cost_exl_gst[1]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .rm_cost_exl_gst[2]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.rm_cost_exl_gst[2]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .rm_cost_exl_gst[3]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.rm_cost_exl_gst[3]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>GST Component</td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .gst_component[1]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.gst_component[1]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .gst_component[2]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.gst_component[2]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          numformat="numberRight"
                                                          className="form-control"
                                                          id="exampleFormControlInput1"
                                                          disabled
                                                          readOnly
                                                          value={getNumericFormat(
                                                            x.CashOutflow
                                                              .aggregate
                                                              .gst_component[3]
                                                          )}
                                                          {...register(
                                                            getNumericFormat(
                                                              `fieldProductRMName.${i}.CashOutflow.aggregate.gst_component[3]`
                                                            )
                                                          )}
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr />
                {fieldProductRM.length != 0 && (
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      {rawMaterialProcurementId
                        ? "Update Changes"
                        : "Save changes"}
                    </button>
                    <button
                      type="reset"
                      onClick={() => navigate(-1)}
                      className="btn btn-outline-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
export default RawMaterialProcurement;
