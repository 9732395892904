import { ApiUrl } from "API/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";

function ChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const navigate =useNavigate()
  const onPasswordChange = (data) => {
    let userEmail = JSON.parse(localStorage.getItem("user_data")).user.email;
    if (data.confirmPassword != data.newPassword) {
      toast("Confirm Password Not matched", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    } else {
      axios
        .put(`${ApiUrl}changePassword`, { ...data, email: userEmail })
        .then((response) => {
          console.log(response);
          toast( "Password Changed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          localStorage.clear()
          navigate('/login')
        })
        .catch((err) => {
          console.log(err.response.data.error);
          toast( err.response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        });
    }
  };

  return (
    <div className="card-body">
      <ToastContainer />
      <form id="formChangePassword" onSubmit={handleSubmit(onPasswordChange)}>
        <div className="col-md-6">
          <div className="mb-3 col-md-12">
            <label htmlFor="firstName" className="form-label">
              Current Password
            </label>
            <input
              type="password"
              id="password"
              className="form-control"
              name="password"
              placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
              aria-describedby="password"
              {...register("oldPassword", { required: true })}
            />
          </div>
          <div className="mb-3 col-md-12">
            <label htmlFor="newpassword" className="form-label">
              New Password
            </label>
            <input
              type="password"
              id="newpassword"
              className="form-control"
              name="newpassword"
              placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
              aria-describedby="newpassword"
              {...register("newPassword", { required: true })}
            />
          </div>
          <div className="mb-3 col-md-12">
            <label htmlFor="confirmpassword" className="form-label">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmpassword"
              className="form-control"
              name="confirmpassword"
              placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
              aria-describedby="confirmpassword"
              {...register("confirmPassword", { required: true })}
            />
          </div>
        </div>
        <div className="mt-2">
          <button type="submit" className="btn btn-primary me-2">
            Save changes
          </button>
          <button type="reset" className="btn btn-outline-secondary">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
