import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import Loader from "common/components/loader";
import React, {useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {useParams, useNavigate, useLocation} from "react-router";
import {MdDelete} from "react-icons/md";
import SkuModel from "./skuModel";
import {set, useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import {ApiUrl} from "API/config";
import {toast, ToastContainer} from "react-toastify";
import "screens/protégé/style/manufacturing.css";
function ManufacturingProductDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const op = useParams().op;
  const productId = useParams().id;
  const [isLoader, setIsLoader] = useState(false);
  const {register, control, handleSubmit, getValues, setValue, setError, reset, formState, watch, setFocus} = useForm();
  const {errors} = formState;
  const {fields: fieldsRawMaterial, append: appendRawMaterial, remove: removeRawMaterial, update: updateRawMaterial} = useFieldArray({name: "rawMaterial", control});
  const {fields: fieldsCapacity, append: appendCapacity, remove: removeCapacity, update: updateCapacity} = useFieldArray({name: "capacity", control});
  const {fields: fieldsSku, append: appendSku, remove: removeSku, update: updateSku, setValue: setValueSku} = useFieldArray({name: "skuData", control});
  const [variableCost, setVariableCost] = useState([]);
  const [basicMaterialCost, setBasicMaterialCost] = useState([]);
  const [rawMaterialName, setRawMaterialName] = useState([]);
  const [batchSize, setBatchSize] = useState(0);
  const [grossProfit, setGrossProfit] = useState([]);
  const [totalCostPerBatchValue, setTotalCostPerBatchValue] = useState(0);
  const [totalGstAmountInThis, setTotalGstAmountInThis] = useState(0);
  const [gstPercOfTotalUnitCost, setGstPercOfTotalUnitCost] = useState(0);
  const [capacityStartMonth, setcapacityStartMonth] = useState(1);
  const [totalRawMaterialCostPerBatch, setTotalRawMaterialCostPerBatch] = useState(0);
  const [totalRawMaterialCostPerUnit, setTotalRawMaterialCostPerUnit] = useState(0);
  const [wastePercentageAfterGst, setWastePercentageAfterGst] = useState(0);
  const [rmAndLabourCost, setRmAndLabourCost] = useState([]);
  const [wasteAmountAfterGst, setWasteAmountAfterGst] = useState(0);
  const [variableCostKeepingUnit, setVariableCostKeepingUnit] = useState();
  const [variableCostKeepingBatch, setVariableCostKeepingBatch] = useState();
  const [packingMaterial, setPackingMaterial] = useState([]);
  const [noOfSku, setNoOfSku] = useState();
  const [skuUnitOfMeasure, setSkuUnitOfMeasure] = useState();
  const [trigger, setTrigger] = useState(0);
  const [errorRawMaterial, setErrorRawMaterial] = useState([]);
  useEffect(() => {
    // if (op == "Edit") {
    //   getEditProductData();
    // }
    if (location.state.serviceId) {
      getManuFactingById();
    }
  }, []);
  const getManuFactingById = () => {
    setIsLoader(true);
    let obj = {
      details: {
        1: {usage: 0, productionPlan: 0, inventory: 0},
        2: {usage: 0, productionPlan: 0, inventory: 0},
        3: {usage: 0, productionPlan: 0, inventory: 0},
        4: {usage: 0, productionPlan: 0, inventory: 0},
        5: {usage: 0, productionPlan: 0, inventory: 0},
        6: {usage: 0, productionPlan: 0, inventory: 0},
        7: {usage: 0, productionPlan: 0, inventory: 0},
        8: {usage: 0, productionPlan: 0, inventory: 0},
        9: {usage: 0, productionPlan: 0, inventory: 0},
        10: {usage: 0, productionPlan: 0, inventory: 0},
        11: {usage: 0, productionPlan: 0, inventory: 0},
        12: {usage: 0, productionPlan: 0, inventory: 0},
      },
      aggregate: {
        1: {usage: 0, productionPlan: 0, inventory: 0},
        2: {usage: 0, productionPlan: 0, inventory: 0},
        3: {usage: 0, productionPlan: 0, inventory: 0},
      },
    };
    axios.get(`${ApiUrl}protege/${location.state.protegeId}`).then((result) => {
      let capacityStartMonth = result.data[0].businessInfoid.ifYesHowLong && result.data[0].businessInfoid.gestationPeriodOnceInstalled == "true" ? parseFloat(result.data[0].businessInfoid.ifYesHowLong + 1) : 1;
      setcapacityStartMonth(capacityStartMonth);
    });
    axios
      .get(`${ApiUrl}service/getservice/${location.state.serviceId}`, {
        _id: location.state.serviceId,
      })
      .then((result) => {
        if (result.data.data.length && result.data.data[0] && result.data.data[0].productList && productId) {
          let productData = result.data.data[0].productList.find((product) => product._id === productId);
          if (productData.basicPlannedProduction) {
            for (const item in obj.details) {
              obj.details[item].productionPlan = productData.basicPlannedProduction.details[item].productionPlan;
            }
            for (const item in obj.aggregate) {
              obj.aggregate[item].productionPlan = productData.basicPlannedProduction.aggregate[item].productionPlan;
            }
          }
          reset({
            basicPlannedProduction: productData.basicPlannedProduction,
            product_name: productData.product_name,
            physical_nature_of_product: productData.physical_nature_of_product,
            brief_manufacture_product: productData.brief_manufacture_product,
            machine_used_in_measure_process: productData.machine_used_in_measure_process,
            installed_capacity: productData.installed_capacity,
            unit_of_time: productData.unit_of_time,
            unit_of_measure: productData.unit_of_measure,
            batch_size: productData.batch_size,
            rawMaterial: productData.rawMaterialData.rawMaterial,
            skuData: productData.skuData,
            capacity: productData.capacity,
          });
          setBatchSize(productData.batch_size);
          setWastePercentageAfterGst(productData.rawMaterialData.waste_percentage);
          setRmAndLabourCost(productData.rawMaterialData.rmAndLabourCost);
          setNoOfSku(productData.skuData.length);
          setSkuUnitOfMeasure(productData.unit_of_measure);
        }
        if (result.data.data.length && result.data.data[0] && result.data.data[0].rawMaterialConsumption) {
          axios
            .get(`${ApiUrl}rawMaterialConsumption/service/rawMaterialConsumption/${result.data.data[0].rawMaterialConsumption}`)
            .then((resultRM) => {
              setRawMaterialName(resultRM.data.rawMaterial);
              let rawMaterial = getValues("rawMaterial");
              rawMaterial.filter((x, i) => setRawMaterialValue(x.raw_material_description, i, x, resultRM.data.rawMaterial));
              if (productId) {
                let productData = result.data.data[0].productList.find((product) => product._id === productId);
                calculateAfterEdit(rawMaterial, productData.rawMaterialData.waste_percentage, productData.batch_size);
              } else {
                calculateAfterEdit(rawMaterial, 0, 0);
              }
              updateErrorRawMaterial();
              setTimeout(() => setIsLoader(false), 400);
            })
            .catch((error) => {
              console.log(error);
              setIsLoader(false);
            });
        } else {
          setTimeout(() => setIsLoader(false), 400);
        }
        if (result.data.data.length && result.data.data[0] && result.data.data[0].salesProjection && productId) {
          axios.get(`${ApiUrl}salesprojection/service/get/${result.data.data[0].salesProjection}`).then((resultSku) => {
            let requiredResultSku = resultSku.data.fieldProductSkuName.filter((y) => y.product_id == productId);
            let skuDetails = getValues("skuData");
            requiredResultSku.map((skuItem, skuIndex) => {
              calculatePlannedProductionUsage(obj, skuDetails, skuItem, skuIndex);
            });
            setValue("basicPlannedProduction", obj);
            for (var month = 1; month <= 12; month++) {
              triggerMasterCalculation(month);
            }
            for (var year = 1; year <= 3; year++) {
              tirggerSubsequentYearCalculation(year);
            }
          });
        } else {
          setValue("basicPlannedProduction", obj);
          setTimeout(() => setIsLoader(false), 400);
        }
      })
      .catch((error) => {
        setIsLoader(false);
      });
  };
  const getConvertedPrimaryUnit = (unitType, value) => {
    let result;
    let standardScale;
    switch (unitType) {
      case "gm":
        standardScale = 1000;
        break;
      case "cm":
        standardScale = 100;
      default:
        standardScale = 1;
        break;
    }
    result = value / standardScale;
    return result;
  };
  const calculatePlannedProductionUsage = (obj, skuDetails, productionDetails, skuIndex) => {
    let requiredUsage = 0;
    let requiredAggregateUsage = 0;
    for (const month in obj.details) {
      requiredUsage = parseFloat(obj.details[month].usage);
      let prod_quantity = productionDetails.details[month].prod_quantity ? productionDetails.details[month].prod_quantity : 0;
      let skuValue = getConvertedPrimaryUnit(skuDetails[skuIndex] && skuDetails[skuIndex].sku_unit_of_measure ? skuDetails[skuIndex].sku_unit_of_measure : "", parseFloat(skuDetails[skuIndex].sku_size) * parseFloat(skuDetails[skuIndex].sku_quantity));
      requiredUsage += skuValue * parseFloat(prod_quantity);
      obj.details[month].usage = requiredUsage;
    }
    for (const year in obj.aggregate) {
      requiredAggregateUsage = parseFloat(obj.aggregate[year].usage);
      let aggregate_prod_quantity = productionDetails.aggregate[year].prod_quantity ? productionDetails.aggregate[year].prod_quantity : 0;
      requiredAggregateUsage += getConvertedPrimaryUnit(skuDetails[skuIndex] && skuDetails[skuIndex].sku_unit_of_measure ? skuDetails[skuIndex].sku_unit_of_measure : "", parseFloat(skuDetails[skuIndex].sku_size) * parseFloat(skuDetails[skuIndex].sku_quantity)) * parseFloat(aggregate_prod_quantity);
      obj.aggregate[year].usage = requiredAggregateUsage;
    }
    setValue("basicPlannedProduction", obj);
  };
  const triggerMasterCalculation = (month) => {
    let obj = getValues("basicPlannedProduction");
    for (const item in obj.details) {
      calculateInventory(obj, item, 1);
    }
    for (const year in obj.aggregate) {
      if (year != 1) {
        obj.aggregate[year].inventory = parseFloat(obj.aggregate[year - 1].inventory) + parseFloat(obj.aggregate[year].productionPlan) - parseFloat(obj.aggregate[year].usage);
      }
    }
    setValue("basicPlannedProduction", obj);
  };
  const calculateInventory = (obj, month, year) => {
    if (year == 1) {
      let requiredCloneObj = Object.assign({}, obj.details[month]);
      let result = requiredCloneObj.productionPlan == "" ? 0 : parseFloat(requiredCloneObj.productionPlan) - parseFloat(requiredCloneObj.usage);
      let requiredMonthInventory = parseFloat(obj.details[month].inventory);
      if (month > 1 && month <= 12) {
        requiredMonthInventory = parseFloat(result) + parseFloat(obj.details[month - 1].inventory);
        obj.details[month].inventory = requiredMonthInventory;
      } else {
        obj.details[month].inventory = result;
      }
    }
    let requiredPlannedUsage = 0;
    let requiredPlannedProd = 0;
    for (const month in obj.details) {
      requiredPlannedProd += obj.details[month].productionPlan == "" ? 0 : parseFloat(obj.details[month].productionPlan);
      requiredPlannedUsage += parseFloat(obj.details[month].usage);
    }
    obj.aggregate[1].usage = parseFloat(requiredPlannedUsage);
    obj.aggregate[1].productionPlan = parseFloat(requiredPlannedProd);
    obj.aggregate[1].inventory = parseFloat(requiredPlannedProd) - parseFloat(requiredPlannedUsage);
  };
  const tirggerSubsequentYearCalculation = (year) => {
    let obj = getValues("basicPlannedProduction");
    obj.aggregate[year].inventory = parseFloat(obj.aggregate[year - 1].inventory) + parseFloat(obj.aggregate[year].productionPlan) - parseFloat(obj.aggregate[year].usage);
    setValue("basicPlannedProduction", obj);
  };
  useEffect(() => {
    if (op == "Add") {
      reset({
        basicPlannedProduction: {
          details: {
            1: {usage: 0, productionPlan: 0, inventory: 0},
            2: {usage: 0, productionPlan: 0, inventory: 0},
            3: {usage: 0, productionPlan: 0, inventory: 0},
            4: {usage: 0, productionPlan: 0, inventory: 0},
            5: {usage: 0, productionPlan: 0, inventory: 0},
            6: {usage: 0, productionPlan: 0, inventory: 0},
            7: {usage: 0, productionPlan: 0, inventory: 0},
            8: {usage: 0, productionPlan: 0, inventory: 0},
            9: {usage: 0, productionPlan: 0, inventory: 0},
            10: {usage: 0, productionPlan: 0, inventory: 0},
            11: {usage: 0, productionPlan: 0, inventory: 0},
            12: {usage: 0, productionPlan: 0, inventory: 0},
          },
          aggregate: {
            1: {usage: 0, productionPlan: 0, inventory: 0},
            2: {usage: 0, productionPlan: 0, inventory: 0},
            3: {usage: 0, productionPlan: 0, inventory: 0},
          },
        },
      });
      appendRawMaterial({
        raw_material_description: "",
        unit_of_measure: "",
        unit_rate: 0,
        gst_on_raw_material: 0,
        transpost_cost_on_raw_material: 0,
        gst_transport: 0,
        gst_in_total: 0,
        per_unit_cost_of_raw_material: 0,
        quantity_required_for_batch_size: 0,
        cost_per_batch: 0,
        gst_amount_batch: 0,
      });
      appendCapacity({
        installed_capacity: "",
        unit_of_time: "Hour",
        days_months: "",
        hours_days: "",
        start_month: "",
      });
      appendSku(
        {
          type_of_packing_required: "",
          cost_of_packing: 0,
          unit_of_sale: 0,
          sku_capacity_per_hour: 0,
          sku_unit_of_measure: "",
          sku_unit_of_measure1: "",
          unit_price: 0,
          sku_description: "",
          sku_size: 0,
          sku_quantity: 0,
          packing: [
            {
              level: "",
              quantity: "0",
              total_packing_cost: "0",
              unit_cost: "0",
              gst_in_total: "0",
            },
          ],
          sku_type_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_inner_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_outer_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_transport_packing: {
            quantity: 0,
            unit_cost: 0,
            total_packing_cost: 0,
          },
          sku_other: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_none: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_total: 0,
          sku_sales_price: 0,
          sku_rm_labor: 0,
          sku_rmValue: 0,
          sku_packing_cost: 0,
          sku_commission: 0,
          sku_labor: 0,
          sku_transport: 0,
          sku_gst_amount: 0,
          sku_gst_percent: 0,
          sku_commission_percent: 0,
          sku_gross_profit: 0,
        },
        {shouldFocus: false}
      );
    }
  }, []);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    });
    return num ? numberFormatter.format(num) : 0;
  };

  const calculateRawMaterialFields = (field, value, index, item, isNumber) => {
    var obj = item;
    value = isNumber ? (value.target ? parseFloat(value.target.value.replace(/,/g, "")) : parseFloat(value.replace(/,/g, ""))) : value;
    obj[field] = value;
     obj.cost_per_batch = parseFloat(obj.per_unit_cost_of_raw_material) * parseFloat(obj.quantity_required_for_batch_size);
    let gst_total_unit_cost = parseFloat(obj.gst_in_total) / parseFloat(obj.per_unit_cost_of_raw_material);
    obj.gst_amount_batch = parseFloat(obj.cost_per_batch) * parseFloat(gst_total_unit_cost);
    updateRawMaterial(index, obj);
    calculateAfterEdit(getValues("rawMaterial"), wastePercentageAfterGst, batchSize);
    setTrigger((trigger) => trigger + 1);
  };
  const setFieldArrayValue = (value, field, index, item, isNumber) => {
    let requiredRmValue = rmAndLabourCost.length ? [...rmAndLabourCost] : [];
    var obj = item;
    if (value && field) {
      obj[field] = isNumber ? parseFloat(value) : value;
    }
    if (isNumber) {
      let rmLabourValue = 0;
      rmLabourValue = totalRawMaterialCostPerUnit.toFixed(2) * parseFloat(obj.sku_size) * parseFloat(obj.sku_quantity);
      requiredRmValue[index] = rmLabourValue.toFixed(2);
      setRmAndLabourCost(requiredRmValue);
    }
    updateSku(index, obj);
    setTrigger((trigger) => trigger + 1);
  };
  const setRawMaterialValue = (value, index, x, rawMaterial) => {
    rawMaterial.filter((item) => {
      if (item.raw_material_description === value) {
        x.raw_material_description = item.raw_material_description;
        x.unit_of_measure = item.unit_of_measure;
        x.unit_rate = item.unit_rate;
        x.gst_on_raw_material = item.gst_on_raw_material;
        x.transpost_cost_on_raw_material = item.transpost_cost_on_raw_material;
        x.gst_transport = item.gst_transport;
        x.gst_in_total = item.gst_in_total;
        x.per_unit_cost_of_raw_material = item.per_unit_cost_of_raw_material;
        x.cost_per_batch = parseFloat(x.per_unit_cost_of_raw_material) * parseFloat(x.quantity_required_for_batch_size);
        x.gst_amount_batch = parseFloat(x.cost_per_batch) * parseFloat(parseFloat(x.gst_in_total) / parseFloat(x.per_unit_cost_of_raw_material));
        updateRawMaterial(index, x);
      }
    });
  };
  const autoResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  const handleAddRawMaterial = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      raw_material_description: "",
      unit_of_measure: "",
      unit_rate: 0,
      gst_on_raw_material: 0,
      transpost_cost_on_raw_material: 0,
      gst_transport: 0,
      gst_in_total: 0,
      per_unit_cost_of_raw_material: 0,
      quantity_required_for_batch_size: 0,
      cost_per_batch: 0,
    };
    appendRawMaterial(rawMaterialItem);
    updateErrorRawMaterial();
  };
  const handleAddCapacity = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      installed_capacity: "",
      unit_of_time: "Hour",
      days_months: "",
      hours_days: "",
      start_month: "",
    };
    appendCapacity(rawMaterialItem);
  };
  const handleAddSku = (e) => {
    e.preventDefault();
    const skuItem = {
      type_of_packing_required: "",
      cost_of_packing: 0,
      unit_of_sale: 0,
      sku_capacity_per_hour: 0,
      sku_unit_of_measure: skuUnitOfMeasure ? skuUnitOfMeasure : "",
      sku_unit_of_measure1: "",
      unit_price: 0,
      sku_description: "",
      packing: [
        {
          level: "",
          quantity: "0",
          total_packing_cost: "0",
          unit_cost: "0",
          gst_in_total: "0",
        },
      ],
      sku_size: 0,
      sku_quantity: 0,
      sku_type_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_inner_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_outer_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_transport_packing: {
        quantity: 0,
        unit_cost: 0,
        total_packing_cost: 0,
      },
      sku_other: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_none: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_total: 0,
      sku_sales_price: 0,
      sku_rm_labor: 0,
      sku_rmValue: 0,
      sku_packing_cost: 0,
      sku_commission: 0,
      sku_labor: 0,
      sku_transport: 0,
      sku_gst_amount: 0,
      sku_gst_percent: 0,
      sku_commission_percent: 0,
      sku_gross_profit: 0,
    };
    appendSku(skuItem);
    setNoOfSku(parseInt(fieldsSku.length) + 1);
  };
  const onRemoveRawMaterial = (i) => {
    removeRawMaterial(i);
    calculateAfterEdit(getValues("rawMaterial"), wastePercentageAfterGst, batchSize);
    updateErrorRawMaterial();
    setTrigger((trigger) => trigger + 1);
  };
  const updateErrorRawMaterial = () => {
    let rawMaterial = [];
    var valueArr = getValues("rawMaterial").map(function (item) {
      return item.raw_material_description;
    });
    valueArr.filter((item, idx) => {
      if (valueArr.indexOf(item) != idx) {
        rawMaterial[idx] = true;
      } else {
        rawMaterial[idx] = false;
      }
    });

    setErrorRawMaterial(rawMaterial);
  };
  const onRemoveSKU = (i) => {
    removeSku(i);
    setNoOfSku(parseInt(fieldsSku.length) - 1);
  };
  const calculateAfterEdit = (array, waste, batch) => {
    let wasteAmount = 0;
    let total_cost_per_batch_value = array
      .map((x, i) => {
        return parseFloat(x.cost_per_batch);
      })
      .reduce((a, b) => a + b, 0);
    let total_gst = array.map((x, i) => parseFloat(x.cost_per_batch) * parseFloat(parseFloat(x.gst_in_total) / parseFloat(x.per_unit_cost_of_raw_material))).reduce((a, b) => a + b, 0);
   
    waste = waste ? waste : 0;
    setWastePercentageAfterGst(parseFloat(waste));
    wasteAmount = (parseFloat(waste) * total_cost_per_batch_value) / 100;
   
    let totalRawMaterialCostPerBatchValue = parseFloat(wasteAmount) + parseFloat(total_cost_per_batch_value);
    let totalRawMaterialCostPerUnitValue = totalRawMaterialCostPerBatchValue ? parseFloat(totalRawMaterialCostPerBatchValue) / parseFloat(batch) : 0;
    setTotalGstAmountInThis(totalRawMaterialCostPerUnitValue ? totalRawMaterialCostPerUnitValue * (total_gst / total_cost_per_batch_value) : total_gst / total_cost_per_batch_value);
      setGstPercOfTotalUnitCost(isNaN(total_gst / total_cost_per_batch_value)?0:total_gst / total_cost_per_batch_value);
    setWasteAmountAfterGst(wasteAmount);
    setTotalRawMaterialCostPerBatch(totalRawMaterialCostPerBatchValue);
    setTotalRawMaterialCostPerUnit(totalRawMaterialCostPerUnitValue);
    setTotalCostPerBatchValue(total_cost_per_batch_value);
  };
  const onBatchSizeChange = (value, i, x) => {
    value = value ? parseFloat(value) : 0;
    setBatchSize(value);
    calculateAfterEdit(fieldsRawMaterial, wastePercentageAfterGst, value);
    setTrigger((trigger) => trigger + 1);
  };
  const handleRemoveCapacity = (i) => {
    removeCapacity(i);
    calculateAfterEdit(fieldsRawMaterial, wastePercentageAfterGst, batchSize);
  };
  const onSubmit = (data) => {
    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    setIsLoader(true);
    if (location.state.serviceId) {
      axios
        .post(`${ApiUrl}service/create`, {
          protegeId: location.state.id,
          serviceId: location.state.serviceId,
          productList: [
            {
              basicPlannedProduction: data.basicPlannedProduction,
              product_name: data.product_name,
              physical_nature_of_product: data.physical_nature_of_product,
              brief_manufacture_product: data.brief_manufacture_product,
              machine_used_in_measure_process: data.machine_used_in_measure_process,
              installed_capacity: data.installed_capacity,
              unit_of_time: data.unit_of_time,
              unit_of_measure: data.unit_of_measure,
              batch_size: batchSize,
              rawMaterialData: {
                total_raw_material_cost_per_batch: totalRawMaterialCostPerBatch,
                rmAndLabourCost: rmAndLabourCost,
                total_raw_material_cost_per_unit: totalRawMaterialCostPerUnit,
                waste_percentage: wastePercentageAfterGst,
                basic_material_variable_coast: variableCostKeepingBatch,
                basic_material_variable_cost_per_unit: variableCostKeepingUnit,
                rawMaterial: data.rawMaterial,
              },
              capacity: data.capacity,
              skuData: data.skuData.map((x, i) => {
                return {
                  type_of_packing_required: x.type_of_packing_required,
                  cost_of_packing: x.cost_of_packing,
                  unit_of_sale: x.unit_of_sale,
                  sku_unit_of_measure: x.sku_unit_of_measure,
                  unit_price: x.unit_price,
                  sku_description: x.sku_description,
                  sku_size: x.sku_size,
                  sku_quantity: x.sku_quantity,
                  packing: x.packing,
                  sku_type_packing: {
                    quantity: x.sku_type_packing.quantity,
                    unit_cost: x.sku_type_packing.unit_cost,
                    total_packing_cost: x.sku_type_packing.total_packing_cost,
                  },
                  sku_inner_packing: {
                    quantity: x.sku_inner_packing.quantity,
                    unit_cost: x.sku_inner_packing.unit_cost,
                    total_packing_cost: x.sku_inner_packing.total_packing_cost,
                  },
                  sku_outer_packing: {
                    quantity: x.sku_outer_packing.quantity,
                    unit_cost: x.sku_outer_packing.unit_cost,
                    total_packing_cost: x.sku_outer_packing.total_packing_cost,
                  },
                  sku_transport_packing: {
                    quantity: x.sku_transport_packing.quantity,
                    unit_cost: x.sku_transport_packing.unit_cost,
                    total_packing_cost: x.sku_transport_packing.total_packing_cost,
                  },
                  sku_other: {
                    quantity: x.sku_other.quantity,
                    unit_cost: x.sku_other.unit_cost,
                    total_packing_cost: x.sku_other.total_packing_cost,
                  },
                  sku_none: {
                    quantity: x.sku_none.quantity,
                    unit_cost: x.sku_none.unit_cost,
                    total_packing_cost: x.sku_none.total_packing_cost,
                  },
                  sku_total: x.sku_total,
                  sku_sales_price: x.sku_sales_price,
                  sku_rm_labor: x.sku_rm_labor,
                  sku_rmValue: x.sku_rmValue,
                  sku_packing_cost: x.sku_packing_cost,
                  sku_commission: x.sku_commission,
                  sku_labor: x.sku_labor,
                  sku_transport: x.sku_transport,
                  sku_gst_amount: x.sku_gst_amount,
                  sku_gst_percent: x.sku_gst_percent,
                  sku_commission_percent: x.sku_commission_percent,
                  skuTotalVariableCostExcludingGST : x.skuTotalVariableCostExcludingGST,
                  skuGstAmtOnVariableCost : x.skuGstAmtOnVariableCost,
                  sku_gross_profit: x.sku_gross_profit,
                  sku_capacity_per_hour: x.sku_capacity_per_hour,
                  sku_unit_of_measure1: x.sku_unit_of_measure1,
                };
              }),
            },
          ],
        })
        .then((response) => {
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          // console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      axios
        .post(`${ApiUrl}service/create`, {
          protegeId: location.state.id,
          productList: [
            {
              basicPlannedProduction: data.basicPlannedProduction,
              product_name: data.product_name,
              physical_nature_of_product: data.physical_nature_of_product,
              brief_manufacture_product: data.brief_manufacture_product,
              machine_used_in_measure_process: data.machine_used_in_measure_process,
              installed_capacity: data.installed_capacity,
              unit_of_time: data.unit_of_time,
              unit_of_measure: data.unit_of_measure,
              batch_size: batchSize,
              rawMaterialData: {
                total_raw_material_cost_per_batch: totalRawMaterialCostPerBatch,
                rmAndLabourCost: rmAndLabourCost,
                total_raw_material_cost_per_unit: totalRawMaterialCostPerUnit,
                waste_percentage: wastePercentageAfterGst,
                basic_material_variable_coast: variableCostKeepingBatch,
                basic_material_variable_cost_per_unit: variableCostKeepingUnit,
                rawMaterial: data.rawMaterial,
              },
              capacity: data.capacity,
              skuData: data.skuData.map((x, i) => {
                return {
                  type_of_packing_required: x.type_of_packing_required,
                  cost_of_packing: x.cost_of_packing,
                  unit_of_sale: x.unit_of_sale,
                  sku_unit_of_measure: x.sku_unit_of_measure,
                  unit_price: x.unit_price,
                  sku_description: x.sku_description,
                  sku_size: x.sku_size,
                  sku_quantity: x.sku_quantity,
                  packing: x.packing,
                  sku_type_packing: {
                    quantity: x.sku_type_packing.quantity,
                    unit_cost: x.sku_type_packing.unit_cost,
                    total_packing_cost: x.sku_type_packing.total_packing_cost,
                  },
                  sku_inner_packing: {
                    quantity: x.sku_inner_packing.quantity,
                    unit_cost: x.sku_inner_packing.unit_cost,
                    total_packing_cost: x.sku_inner_packing.total_packing_cost,
                  },
                  sku_outer_packing: {
                    quantity: x.sku_outer_packing.quantity,
                    unit_cost: x.sku_outer_packing.unit_cost,
                    total_packing_cost: x.sku_outer_packing.total_packing_cost,
                  },
                  sku_transport_packing: {
                    quantity: x.sku_transport_packing.quantity,
                    unit_cost: x.sku_transport_packing.unit_cost,
                    total_packing_cost: x.sku_transport_packing.total_packing_cost,
                  },
                  sku_other: {
                    quantity: x.sku_other.quantity,
                    unit_cost: x.sku_other.unit_cost,
                    total_packing_cost: x.sku_other.total_packing_cost,
                  },
                  sku_none: {
                    quantity: x.sku_none.quantity,
                    unit_cost: x.sku_none.unit_cost,
                    total_packing_cost: x.sku_none.total_packing_cost,
                  },
                  sku_total: x.sku_total,
                  sku_sales_price: x.sku_sales_price,
                  sku_rm_labor: x.sku_rm_labor,
                  sku_rmValue: x.sku_rmValue,
                  sku_packing_cost: x.sku_packing_cost,
                  sku_commission: x.sku_commission,
                  sku_labor: x.sku_labor,
                  sku_transport: x.sku_transport,
                  sku_gst_amount: x.sku_gst_amount,
                  sku_gst_percent: x.sku_gst_percent,
                  sku_commission_percent: x.sku_commission_percent,
                  skuTotalVariableCostExcludingGST : x.skuTotalVariableCostExcludingGST,
                  skuGstAmtOnVariableCost : x.skuGstAmtOnVariableCost,
                  sku_gross_profit: x.sku_gross_profit,
                  sku_capacity_per_hour: x.sku_capacity_per_hour,
                  sku_unit_of_measure1: x.sku_unit_of_measure1,
                };
              }),
            },
          ],
        })
        .then((response) => {
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          // console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const onUpdateSubmit = (data) => {
    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    setIsLoader(true);
    axios
      .put(`${ApiUrl}service/update/${productId}/${location.state.serviceId}`, {
        basicPlannedProduction: data.basicPlannedProduction,
        product_name: data.product_name,
        physical_nature_of_product: data.physical_nature_of_product,
        brief_manufacture_product: data.brief_manufacture_product,
        machine_used_in_measure_process: data.machine_used_in_measure_process,
        installed_capacity: data.installed_capacity,
        unit_of_time: data.unit_of_time,
        unit_of_measure: data.unit_of_measure,
        batch_size: batchSize,
        rawMaterialData: {
          rmAndLabourCost: rmAndLabourCost,
          total_raw_material_cost_per_batch: totalRawMaterialCostPerBatch,
          total_raw_material_cost_per_unit: totalRawMaterialCostPerUnit,
          waste_percentage: wastePercentageAfterGst,
          basic_material_variable_coast: variableCostKeepingBatch,
          basic_material_variable_cost_per_unit: variableCostKeepingUnit,
          rawMaterial: data.rawMaterial,
        },
        capacity: data.capacity,
        skuData: data.skuData.map((x, i) => {
          return {
            packing: x.packing,
            type_of_packing_required: x.type_of_packing_required,
            cost_of_packing: x.cost_of_packing,
            unit_of_sale: x.unit_of_sale,
            sku_unit_of_measure: x.sku_unit_of_measure,
            unit_price: x.unit_price,
            sku_description: x.sku_description,
            sku_size: x.sku_size,
            sku_quantity: x.sku_quantity,
            sku_type_packing: {
              quantity: x.sku_type_packing.quantity,
              unit_cost: x.sku_type_packing.unit_cost,
              total_packing_cost: x.sku_type_packing.total_packing_cost,
            },
            sku_inner_packing: {
              quantity: x.sku_inner_packing.quantity,
              unit_cost: x.sku_inner_packing.unit_cost,
              total_packing_cost: x.sku_inner_packing.total_packing_cost,
            },
            sku_outer_packing: {
              quantity: x.sku_outer_packing.quantity,
              unit_cost: x.sku_outer_packing.unit_cost,
              total_packing_cost: x.sku_outer_packing.total_packing_cost,
            },
            sku_transport_packing: {
              quantity: x.sku_transport_packing.quantity,
              unit_cost: x.sku_transport_packing.unit_cost,
              total_packing_cost: x.sku_transport_packing.total_packing_cost,
            },
            sku_other: {
              quantity: x.sku_other.quantity,
              unit_cost: x.sku_other.unit_cost,
              total_packing_cost: x.sku_other.total_packing_cost,
            },
            sku_none: {
              quantity: x.sku_none.quantity,
              unit_cost: x.sku_none.unit_cost,
              total_packing_cost: x.sku_none.total_packing_cost,
            },
            sku_total: x.sku_total,
            sku_sales_price: x.sku_sales_price,
            sku_rm_labor: x.sku_rm_labor,
            sku_rmValue: x.sku_rmValue,
            sku_packing_cost: x.sku_packing_cost,
            sku_commission: x.sku_commission,
            sku_labor: x.sku_labor,
            sku_transport: x.sku_transport,
            sku_gst_amount: x.sku_gst_amount,
            sku_gst_percent: x.sku_gst_percent,
            sku_commission_percent: x.sku_commission_percent,
            skuTotalVariableCostExcludingGST : x.skuTotalVariableCostExcludingGST,
            skuGstAmtOnVariableCost : x.skuGstAmtOnVariableCost,
            sku_gross_profit: x.sku_gross_profit,
            sku_capacity_per_hour: x.sku_capacity_per_hour,
            sku_unit_of_measure1: x.sku_unit_of_measure1,
          };
        }),
      })
      .then((response) => {
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-3)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>{op} Protégé</a> /
            </span>{" "}
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Service</a> /
            </span>{" "}
            Service Information
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form id="productOne" onSubmit={handleSubmit(op == "Edit" ? onUpdateSubmit : onSubmit)} autoComplete="off">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label required">
                  Service Name
                  </label>
                  <input tabIndex={0} autoFocus type="text" className={`form-control ${errors.product_name ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register("product_name", {required: true})} />
                  {errors.product_name && errors.product_name.type == "required" && (
                    <span className="textRed" style={{color: "red"}}>
                      * Service Name is required{" "}
                    </span>
                  )}
                </div>
                {/* <div className="col-md-6  mb-3">
                  <label htmlFor="nameBackdrop" className="form-label">
                    Physical Nature of the service
                  </label>
                  <select className={`form-select ${errors.physical_nature_of_product ? "errorBorder" : ""}`} id="exampleFormControlSelect1" aria-label="Default select example" {...register("physical_nature_of_product")}>
                    <option defaultValue="">Select</option>
                    <option value="1">Solid(fixed form)</option>
                    <option value="2">Solid(free form)</option>
                    <option value="2">Liquid</option>
                    <option value="1">Gas</option>
                  </select>
                </div> */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Briefly describe the process of Service
                  </label>
                  <textarea
                    className={`autoResize form-control ${errors.brief_manufacture_product ? "errorBorder" : ""}`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("brief_manufacture_product", {
                      onChange: (e) => {
                        autoResize(e);
                      },
                    })}
                  ></textarea>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">
                    List Machines, Equipments, Tools Etc needed
                  </label>
                  <textarea
                    className={` autoResize form-control ${errors.machine_used_in_measure_process ? "errorBorder" : ""}`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("machine_used_in_measure_process", {
                      onChange: (e) => {
                        autoResize(e);
                      },
                    })}
                  ></textarea>
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Batch Size
                  </label>
                  <input
                    numformat="numberRight"
                    type="number"
                    step="any"
                    className={`form-control ${errors.batch_size ? "errorBorder" : ""}`}
                    id="exampleFormControlInput1"
                      defaultValue={0}
                    value={batchSize}
                    {...register(`batch_size`, {
                      onChange: (e) => {
                        onBatchSizeChange(e.target.value);
                      },
                    })}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="nameBackdrop" className="form-label">
                    Unit of Measure
                  </label>
                  <select
                    className={`form-select ${errors.unit_of_measure ? "errorBorder" : ""}`}
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                    {...register(`unit_of_measure`, {
                      onChange: (e) => {
                        setSkuUnitOfMeasure(e.target.value);
                      },
                    })}
                  >
                    <option value="">Select</option>
                    <option value="kg">Kg</option>
                    <option value="gm">Gms</option>
                    <option value="lt">Litre</option>
                    <option value="ml">Millilitre</option>
                    <option value="cm">Cm</option>
                    <option value="Metre">Metre</option>
                    <option value="Lot">Lot</option>
                    <option value="Set">Set</option>
                    <option value="Batch">Batch</option>
                    <option value="Month">Month</option>
                    <option value="Dozen">Dozen</option>
                    <option value="Person">Person</option>
                    <option value="Piece">Piece</option>
                    <option value="kWh">kWh</option>
                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                    <option value="event">Event</option>
<option value="service">Service</option>
                  </select>
                </div>
                <strong>Capacity</strong>
                {fieldsCapacity &&
                  fieldsCapacity.map((x, i) => {
                    return (
                      <div className="col-md-12" key={i}>
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Service Installed Capacity
                            </label>
                            <input type="number" step="any" numformat="numberRight" className={` form-control ${errors.installed_capacity ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register(`capacity.${i}.installed_capacity`)} />
                          </div>
                          <div className="col-md-3  mb-3">
                            <label htmlFor="nameBackdrop" className="form-label">
                              Unit of Time
                            </label>
                            <input type="text" disabled id="exampleFormControlInput1" className={`form-control ${errors.unit_of_time ? "errorBorder" : ""}`} value="Hour" {...register(`capacity.${i}.unit_of_time`)} />
                          </div>
                          {/* <div className="col-md-2 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Days/Months
                            </label>
                            <input type="number" step="any" numformat="numberRight" className={`form-control ${errors.days_months ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register(`capacity.${i}.days_months`)} />
                          </div>
                          <div className="col-md-2 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Hours/Days
                            </label>
                            <input type="number" step="any" numformat="numberRight" className={`form-control ${errors.hours_days ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register(`capacity.${i}.hours_days`)} />
                          </div> */}
                          <div className="col-md-1 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Start Month
                            </label>
                            {i == 0 && <input type="number" step="any" numformat="numberRight" disabled={true} className={`form-control ${errors.start_month ? "errorBorder" : ""}`} id="exampleFormControlInput1" value={parseFloat(capacityStartMonth)} {...register(`capacity.${i}.start_month`)} />}
                            {i != 0 && <input type="number" step="any" numformat="numberRight" className={`form-control ${errors.start_month ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register(`capacity.${i}.start_month`)} />}
                          </div>
                          {i != 0 && (
                            <div className="col-md-1 mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                Remove
                              </label>
                              <div className="">
                                <MdDelete style={{cursor: "pointer"}} color="red" size={25} onClick={() => handleRemoveCapacity(i)}></MdDelete>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div className="col-md-12 mb-5">
                  <button onClick={(e) => handleAddCapacity(e)} className="btn btn-primary me-2 mb-2 float-end">
                    Add Capacity
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <strong>Variable Cost Service</strong>
                  <button onClick={(e) => handleAddRawMaterial(e)} className="float-right btn btn-primary me-2 mb-2 float-end">
                    Add Input
                  </button>
                </div>
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>S No</th>
                      <th>Material</th>
                      <th>Total Unit Cost</th>
                      <th>Unit of Measure</th>
                      <th>Quantity Per batch size</th>
                      <th>Cost Per batch </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {fieldsRawMaterial &&
                      fieldsRawMaterial.map((x, i) => {
                        // if (x.cost_per_batch) {
                        return (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <select
                                  className={`form-select lg-width col-md-2 ${errorRawMaterial[i] ? "errorBorder" : ""}`}
                                  id="exampleFormControlSelect1"
                                  aria-label="Default select example"
                                  value={fieldsRawMaterial[i].raw_material_description}
                                required
                                  {...register(`rawMaterial.${i}.raw_material_description`, {
                                    onChange: (e) => {
                                      autoResize(e, i);
                                      setRawMaterialValue(e.target.value, i, x, rawMaterialName);
                                      calculateRawMaterialFields("raw_material_description", e.target.value, i, x, false);
                                      updateErrorRawMaterial();
                                    }
                                  })}
                                >
                                  <option value="">Select</option>
                                  {rawMaterialName.map((y) => {
                                    return <option value={y.raw_material_description}>{y.raw_material_description}</option>;
                                  })}
                                </select>
                                {errorRawMaterial[i] && (
                                        <span className="textRed" style={{color: "red"}}>
                                          *Already the material is added.{" "}
                                        </span>
                                      )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  numformat="numberRight"
                                  className={`form-control`}
                                  disabled
                                  value={rawMaterialName.filter((y) => y.raw_material_description === x.raw_material_description).map((item) => getNumericFormat(item.per_unit_cost_of_raw_material))}
                                  id="exampleFormControlInput1"
                                />
                              </td>
                              <td>
                                <select className="form-select md-width" id="exampleFormControlSelect1" disabled value={fieldsRawMaterial[i].unit_of_measure} aria-label="Default select example" {...register(`rawMaterial.${i}.unit_of_measure`)}>
                                  <option value="">Select</option>
                                  <option value="kg">Kg</option>
                                  <option value="gm">Gms</option>
                                  <option value="lt">Litre</option>
                                  <option value="ml">Millilitre</option>
                                  <option value="Millilitre">Cm</option>
                                  <option value="Metre">Metre</option>
                                  <option value="Lot">Lot</option>
                                  <option value="Set">Set</option>
                                  <option value="Batch">Batch</option>
                                  <option value="Month">Month</option>
                                  <option value="Dozen">Dozen</option>
                                  <option value="Person">Person</option>
                                  <option value="Piece">Piece</option>
                                  <option value="kWh">kWh</option>
                                  <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                  <option value="event">Event</option>
<option value="service">Service</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  numformat="numberRight"
                                  className={`form-control ${errors.rawMaterial && errors.rawMaterial[i] && errors.rawMaterial[i].quantity_required_for_batch_size ? "errorBorder" : ""}`}
                                  id="exampleFormControlInput1"
                                  {...register(`rawMaterial.${i}.quantity_required_for_batch_size`, {
                                    onChange: (e) => {
                                      calculateRawMaterialFields("quantity_required_for_batch_size", e.target.value, i, x, true);
                                    },
                                  })}
                                />
                              </td>
                              <td style={{textAlign: "right"}}>{getNumericFormat(x.cost_per_batch)}</td>
                              <td className="text-center cursor-pointer">
                                <MdDelete
                                  size={20}
                                  color="red"
                                  onClick={() => {
                                    onRemoveRawMaterial(i, x);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        );
                        // }
                      })}
                    <tr>
                      <td colSpan="5" style={{textAlign: "right"}}>
                        <strong> Total</strong>
                      </td>
                      <td style={{textAlign: "right"}}> {totalCostPerBatchValue ? getNumericFormat(totalCostPerBatchValue) : ""}</td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <label>Waste Percentage</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          step="any"
                          numformat="numberRight"
                          className="form-control"
                          id="wastePercentageAfterGst"
                          defaultValue={0}
                          value={wastePercentageAfterGst}
                          onChange={(e) => {
                            calculateAfterEdit(getValues("rawMaterial"), e.target.value, batchSize);
                          }}
                        />
                      </td>
                      <td>
                        <input disabled numformat="numberRight" type="text" className="form-control" id="exampleFormControlInput1" value={wasteAmountAfterGst ? getNumericFormat(wasteAmountAfterGst) : 0} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <label>Variable Cost/Batch</label>
                      </td>
                      <td>
                        <input disabled numformat="numberRight" type="text" className="form-control" id="exampleFormControlInput1" value={totalRawMaterialCostPerBatch ? getNumericFormat(totalRawMaterialCostPerBatch) : 0} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <label>Unit Variable Cost</label>
                      </td>
                      <td>
                        <input disabled numformat="numberRight" type="text" className="form-control" id="exampleFormControlInput1" value={totalRawMaterialCostPerUnit ? getNumericFormat(totalRawMaterialCostPerUnit) : 0} {...register(getNumericFormat(`total_raw_material_cost_per_unit`))} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <label>GST amount in Variable Cost</label>
                      </td>
                      <td>
                        <input disabled numformat="numberRight" type="text" className="form-control" id="exampleFormControlInput1" value={totalGstAmountInThis ? getNumericFormat(totalGstAmountInThis) : 0} {...register(getNumericFormat(`totalGstAmountInThis`))} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <label>Total Variable Cost Excluding GST</label>
                      </td>
                      <td>
                        <input disabled numformat="numberRight" type="text" className="form-control" id="exampleFormControlInput1" value={totalGstAmountInThis ? getNumericFormat(totalRawMaterialCostPerUnit - totalGstAmountInThis) : 0} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <br></br>
                  <br></br>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <strong>How Many SKU's?</strong>
                    </div>
                    <div className="col-md-3 mb-3">
                      <input numformat="numberRight" disabled type="text" id="noOfSKU" value={noOfSku} className="form-control" />
                    </div>
                    <div className="col-md-6 mb-3 float-right">
                      <button onClick={(e) => handleAddSku(e)} className=" btn btn-primary me-2  float-end">
                        Add SKU's
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* sku Accordion */}
              <div className="row">
                <div className="col-md-12 table-responsive">
                  <table className="table1 table table-bordered">
                    <thead className="table-light text-center">
                      <tr>
                        <th>Description</th>
                        <th>Size/Qty (Per Unit Pack)</th>
                        <th>Unit Of Measure </th>
                        <th>No. of Units per SKU</th>
                        {/* <th>Packing Capacity/Hour </th>
                         <th>Unit of Measure Packing</th>
                        <th>Packaging Type</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {fieldsSku &&
                        fieldsSku.map((x, i) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <input
                                    autoFocus={false}
                                    type="text"
                                    className="form-control lg-width"
                                    {...register(`skuData.${i}.sku_description`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_description", i, x, false);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    autoFocus={false}
                                    numformat="numberRight"
                                    type="number"
                                    step="any"
                                    className="form-control sl-width"
                                    {...register(`skuData.${i}.sku_size`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_size", i, x, true);
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <select
                                    className={`form-select`}
                                    aria-label="Default select example"
                                    disabled
                                    value={skuUnitOfMeasure}
                                    {...register(`skuData.${i}.sku_unit_of_measure`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_unit_of_measure", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="kg">Kg</option>
                                    <option value="gm">Gms</option>
                                    <option value="lt">Litre</option>
                                    <option value="ml">Millilitre</option>
                                    <option value="Millilitre">Cm</option>
                                    <option value="Metre">Metre</option>
                                    <option value="Lot">Lot</option>
                                    <option value="Set">Set</option>
                                    <option value="Batch">Batch</option>
                                    <option value="Month">Month</option>
                                    <option value="Dozen">Dozen</option>
                                    <option value="Person">Person</option>
                                    <option value="Piece">Piece</option>
                                    <option value="kWh">kWh</option>
                                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                    <option value="event">Event</option>
<option value="service">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    numformat="numberRight"
                                    type="number"
                                    step="any"
                                    className="form-control sl-width"
                                    id={"sku_quantity" + x.id}
                                    value={x.sku_quantity}
                                    {...register(`skuData.${i}.sku_quantity`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_quantity", i, x, true);
                                      },
                                    })}
                                  />
                                </td>
                                {/* <td>
                                  <input
                                    autoFocus={false}
                                    numformat="numberRight"
                                    type="number"
                                    step="any"
                                    className="form-control sl-width"
                                    {...register(`skuData.${i}.sku_capacity_per_hour`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_capacity_per_hour", i, x, true);
                                      },
                                    })}
                                  />
                                </td> 
                                <td>
                                  <select
                                    className={`form-select`}
                                    id={"unit_of_measure" + x.id}
                                    aria-label="Default select example"
                                    {...register(`skuData.${i}.sku_unit_of_measure1`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "sku_unit_of_measure1", i, x, false);
                                      },
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="kg">Kg</option>
                                    <option value="gm">Gms</option>
                                    <option value="lt">Litre</option>
                                    <option value="ml">Millilitre</option>
                                    <option value="Millilitre">Cm</option>
                                    <option value="Metre">Metre</option>
                                    <option value="Lot">Lot</option>
                                    <option value="Set">Set</option>
                                    <option value="Batch">Batch</option>
                                    <option value="Month">Month</option>
                                    <option value="Dozen">Dozen</option>
                                    <option value="Person">Person</option>
                                    <option value="Piece">Piece</option>
                                    <option value="kWh">kWh</option>
                                    <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                    <option value="event">Event</option>
<option value="service">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={"type_of_packing_required" + x.id}
                                    value={x.type_of_packing_required}
                                    {...register(`skuData.${i}.type_of_packing_required`, {
                                      onChange: (e) => {
                                        setFieldArrayValue(e.target.value, "type_of_packing_required", i, x, false);
                                      },
                                    })}
                                  />
                                </td> */}
                                <td className="text-center cursor-pointer">
                                  <MdDelete
                                    size={20}
                                    color="red"
                                    onClick={() => {
                                      onRemoveSKU(i, x);
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <br></br>
              <br></br>
              <Accordion>
                {fieldsSku &&
                  fieldsSku.map((x, index) => {
                    return (
                      <Card key={index}>
                        <CustomAccordian eventKey={index}>{x.sku_description}</CustomAccordian>
                        <Accordion.Collapse eventKey={index}>
                          <Card.Body>
                            <SkuModel
                              trigger={trigger}
                              removeSku={() => removeSku(index)}
                              value={x}
                              setValueSku={setValueSku}
                              setValue={setValue}
                              data={fieldsSku}
                              control={control}
                              index={index}
                              batchSize={batchSize}
                              grossProfit={grossProfit}
                              basicMaterialCost={basicMaterialCost}
                              variableCostKeepingUnit={variableCostKeepingUnit}
                              packingMaterial={packingMaterial}
                              variableCost={variableCost}
                              errors={errors}
                              updateSku={updateSku}
                              getValues={getValues}
                              setError={setError}
                              reset={reset}
                              fieldsRawMaterial={fieldsRawMaterial}
                              setGrossProfit={setGrossProfit}
                              setBasicMaterialCost={setBasicMaterialCost}
                              setPackingMaterial={setPackingMaterial}
                              setVariableCost={setVariableCost}
                              register={register}
                              totalRawMaterialCostPerBatch={totalRawMaterialCostPerBatch}
                              totalRawMaterialCostPerUnit={totalRawMaterialCostPerUnit}
                              gstPercOfTotalUnitCost={gstPercOfTotalUnitCost}
                              rmAndLabourCost={rmAndLabourCost}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
              </Accordion>
              
              {/* sku Accordion end */}
              <hr />
              <div className="mt-2">
                <button type="submit" className="btn btn-primary me-2">
                  {op == "Edit" ? "Update Changes" : "Save changes"}
                </button>
                <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
}
export default ManufacturingProductDetails;
