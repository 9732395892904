import "screens/protégé/style/protégéCard.css";
import {Dropdown} from "react-bootstrap";
import React from "react";
import {useParams, useNavigate} from "react-router";
import {createBrowserHistory} from "history";
function ProtégéCard(props) {
  const route = props.tile.route;
  const op = useParams().op;
  // console.log("props.tile._id", props.tile);
  const id = props.tile.id;
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const navigateToCardDetails = () => {
    // navigate(["/ProtegeDetailsRouter", op, route].join("/"),{state:{id:1}});
    navigate("/Protege/" + route + "/" + op, {state: {id: id, data: props.tile}}, {replace: true});
  };
  // console.log("props", props);

  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <button
      type="button"
      className="btn p-0 dropdown-toggle hide-arrow"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  const className = props.tile.progress === "100%" ? "bg-label-primary " + "border-primary" : props.tile.progress === "0%" ? "bg-not-started " : "bg-label-warning " + "border-warning";
  const classProgress = props.tile.progress === "100%" ? "bg-primary " : props.tile.progress === "0%" ? "bg-secondary " : "bg-warning";
  //  style={{pointerEvents:"none" ,opacity:"0.5"}}
  const classActive = props.disbaled || (props.tile.title == "Business Idea and Model" && props.tile.personalInfoPorgressScore == undefined) || props.tile.personalInfoPorgressScore < 61 ? "bg-disabled" : "";

  return (
    <div className={`col-md-6 col-xl-4 ${classActive}`}>
      <div className={"card shadow-none  border  mb-3 " + className}>
        <div className="card-body">
          <div className="row">
            <div className="col-10">
              <h5 className="card-title">{props.tile.title == "Manufacturing" ? "Product Information" : props.tile.title == "Service" ? "Service Information" : props.tile.title}</h5>
            </div>
            <div className="col-2">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <i className="bx bx-dots-vertical-rounded"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={navigateToCardDetails}>
                    {" "}
                    <i className="bx bx-edit-alt me-1"></i> {props.operation}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5">
              <span className={"badge " + classProgress}>{props.tile.status.toUpperCase()}</span>
            </div>
            <div className="col-7 mt-2">
              <div className="progress">
                <div className={"progress-bar " + classProgress} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                  {props.tile.progress}
                </div>
              </div>
            </div>
            <div className="col-6 mt-4">
              <span>{props.tile.lastEditedOn}</span>
              <br />
              <span className="font-12">
                <i className="card-icon tf-icons bx bx-calendar-alt"></i>Last Edited On
              </span>
            </div>
            <div className="col-6 mt-4">
              <span>{props.tile.lastEditedBy}</span>
              <br />
              <span className="font-12">
                <i className="card-icon tf-icons bx bx-user"></i>Last Edited By
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtégéCard;
