import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router";
import { set, useFieldArray, useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Loader from "common/components/loader";

function Manpower() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const { register, control, handleSubmit, getValues, setValue, setError, reset, formState, watch, setFocus } = useForm();
  const [isLoader, setIsLoader] = useState(false);
  const { fields: fieldRoles, append: appendRole, update: updateRole, remove: removeRole, setValue: setValueRole } = useFieldArray({ name: "fieldRolesName", control });

  const { fields: fieldManPowerNeeded, append: appendManPowerNeeded, update: updateManPowerNeeded, remove: removeManPowerNeeded, setValue: setValueManPowerNeeded } = useFieldArray({ name: "fieldManPowerNeededName", control });

  const { fields: fieldManPowerCost, append: appendManPowerCost, update: updateManPowerCost, remove: removeManPowerCost, setValue: setValueManPowerCost } = useFieldArray({ name: "fieldManPowerCostName", control });
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,    });
    return num ? numberFormatter.format(num) : 0;
  };
  
  const [miscellaneous, setMiscellaneous] = useState({
    details: {
      1: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      2: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      3: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      4: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      5: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      6: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      7: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      8: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      9: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      10: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      11: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      12: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
    },
    aggregate: {
      1: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      2: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      3: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
    }
  });

  const [totalManPowerNeeded, setTotalManPowerNeeded] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    },
  });

  const [totalManPowerCost, setTotalManPowerCost] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    },
  });

  const months = ["gestPeriod", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const onSubmit = (data) => {
    setIsLoader(true);
    axios
      .post(`${ApiUrl}manpower/manpower/create`, {
        ...data,
        // manufacturId: location.state.data.manufacturingId,
        protegeId: location.state.data.protegeId,
        miscellaneous: data.miscellaneous,
        totalManPowerNeeded: totalManPowerNeeded,
        totalManPowerCost: totalManPowerCost,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onUpdate = (data) => {
    setIsLoader(true);
    console.log("data from update==", data);
    axios
      .put(`${ApiUrl}manpower/manpower/update`, {
        ...data,
        _id: manpowerId,
        // manufacturId: location.state.data.manufacturingId,
        miscellaneous: data.miscellaneous,
        protegeId: location.state.data.protegeId,
        totalManPowerNeeded: totalManPowerNeeded,
        totalManPowerCost: totalManPowerCost,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let manpowerId = location.state.data.manpowerId && location.state.data.manpowerId;
  useEffect(() => {
    reset({
      miscellaneous: {
      details: {
        1: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        2: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        3: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        4: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        5: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        6: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        7: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        8: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        9: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        10: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        11: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        12: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      },
      aggregate: {
        1: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        2: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
        3: { comissionPaidonSales: 0, finishedGoodsTransport: 0, taxPaid: 0 },
      }
    }
  })
    if (manpowerId) {
      getManPowerById();
    }
  }, []);

  const getManPowerById = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}manpower/manpower/get/${manpowerId}`)
      .then((res) => {
        reset(res.data);
        setMiscellaneous(res.data.miscellaneous);
        setTotalManPowerCost(res.data.totalManPowerCost);
        setTotalManPowerNeeded(res.data.totalManPowerNeeded);
        setTimeout(() => setIsLoader(false), 400);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const handleAddRole = () => {
    let newRole = {
      roleName: "",
      costPerMonth: 0,
    };
    appendRole(newRole);
    appendManPowerNeeded({
      roleName: "",
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    });

    appendManPowerCost({
      roleName: "",
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    });
  };

  useEffect(() => {
    let defaultRoles = ["Supervisor", "Manager", "Assitant", "Receptionist"];
    defaultRoles.forEach((x, i) => {
      appendRole({ roleName: x, costPerMonth: 0 });

      appendManPowerNeeded({
        roleName: x,
        details: {
          gestPeriod: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      });

      appendManPowerCost({
        roleName: x,
        details: {
          gestPeriod: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        aggregate: {
          1: 0,
          2: 0,
          3: 0,
        },
      });
    });
  }, []);

  const onRemoveRole = (i) => {
    var roleName = fieldRoles[i].roleName;
    removeRole(i);
    removeManPowerNeeded(i);
    removeManPowerCost(i);

    fieldRoles.splice(i, 1);
    fieldManPowerNeeded.splice(i, 1);
    fieldManPowerCost.splice(i, 1);

    setValue("fieldRolesName", fieldRoles);
    setValue("fieldManPowerNeededName", fieldManPowerNeeded);
    setValue("fieldManPowerCostName", fieldManPowerCost);
    handleRemoveRoleCalculation();
  };

  const handleRemoveRoleCalculation = () => {
    for (const month in months) {
      calculateTotalManPowerNeeded(months[month]);
    }

    for (const month in months) {
      calculateTotalManpowerCost(months[month]);
    }

    fieldManPowerNeeded.forEach((mpn, i) => {
      triggerAggregateCalculation(mpn, 1);
    });

    fieldManPowerCost.forEach((mpc, i) => {
      triggerAggregateCalculation(mpc, 1);
    });
  };

  const calculateTotalManPowerNeeded = (month) => {
    let totalMPN = totalManPowerNeeded;

    totalMPN.details[month] = fieldManPowerNeeded.reduce((acc, mp) => acc + parseFloat(mp.details[month]), 0);

    triggerAggregateCalculation(totalMPN, 1);
    setTotalManPowerNeeded(totalMPN);
  };

  const calculateTotalManpowerCost = (month) => {
    let totalMPC = totalManPowerCost;
    console.log(fieldManPowerCost)
    totalMPC.details[month] = fieldManPowerCost.reduce((acc, mp) => acc + parseFloat(mp.details[month]), 0);

    triggerAggregateCalculation(totalMPC, 1);
    setTotalManPowerCost(totalMPC);
  };

  const triggerMasterCalculation = (e, i, x, month) => {
    let mpn = fieldManPowerNeeded.filter((mp, i) => {
      return x.roleName === mp.roleName;
    })[0];

    mpn.details[month] = parseFloat(e.target.value);
    triggerAggregateCalculation(x, 1);
    setValue("fieldManPowerNeededName", fieldManPowerNeeded);

    calculateTotalManPowerNeeded(month);

    triggerManpowerCostCalculation(x.roleName, month, mpn, i);
  };

  const triggerCostPerMonthChange = (e, index, x) => {
    let role = x;
    role.costPerMonth = parseFloat(e.target.value);
    updateRole(role, index);
    for (var i = 1; i <= 12; i++) {
      triggerManpowerCostCalculation(role.roleName, i, fieldManPowerNeeded[index], index);
    }
  };

  const triggerRoleNameChange = (e, index, x) => {
    let role = x;
    let previousRole = role.roleName;
    role.roleName = e.target.value;
    updateRole(role, index);

    let obj = fieldManPowerNeeded.filter((mp, i) => {
      return mp.roleName === previousRole;
    })[0];

    obj.roleName = role.roleName;

    setValue("fieldManPowerNeededName", fieldManPowerNeeded);

    let obj1 = fieldManPowerCost.filter((mp, i) => {
      return mp.roleName === previousRole;
    })[0];

    obj1.roleName = role.roleName;
    setValue("fieldManPowerCostName", fieldManPowerCost);
  };
 
  const triggerSubsequentYearCalculation = (e, i, x, year) => {
    x.aggregate[year] = parseFloat(e.target.value);
    setValue("fieldManPowerNeededName", fieldManPowerNeeded);
    let totalMPN = totalManPowerNeeded;
    totalMPN.aggregate[year] = fieldManPowerNeeded.reduce((acc, mp) => acc + parseFloat(mp.aggregate[year]), 0);
    setTotalManPowerNeeded(totalMPN);
  };

  const triggerSubsequentYearCalculationForCost = (e, i, x, year) => {
    x.aggregate[year] = parseFloat(e.target.value);
    setValue("fieldManPowerCostName", fieldManPowerCost);
    let totalMPC = totalManPowerCost;
    totalMPC.aggregate[year] = fieldManPowerCost.reduce((acc, mp) => acc + parseFloat(mp.aggregate[year]), 0);
    setTotalManPowerCost(totalMPC);
  };

  const triggerAggregateCalculation = (row, year) => {
    let qsum = 0;
    for (var i = 1; i <= 12; i++) {
      qsum += parseFloat(row.details[i]);
    }
    row.aggregate[year] = qsum;
  };

  const triggerManpowerCostCalculation = (roleName, month, mpn, i) => {
    let mpc = fieldManPowerCost.filter((mp, i) => {
      return roleName === mp.roleName;
    })[0];

    let role = fieldRoles.filter((x, i) => {
      return roleName === x.roleName;
    })[0];
    mpc.details[month] = parseFloat(mpn.details[month] * role.costPerMonth);
    triggerAggregateCalculation(mpc, 1);
    setValue("fieldManPowerCostName", fieldManPowerCost);

    calculateTotalManpowerCost(month);
  };

  return (
    <>
      <Layout>
        <ToastContainer />
        <div className="row">
          <div className="col-6">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-2)}>Protégé</a> /
              </span>
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-1)}>{op} Protégé</a> /
              </span>{" "}
              Manpower
            </h4>
          </div>
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="mb-4">
              <div className="card-body">
                <form id="formAccountSettings" onSubmit={handleSubmit(manpowerId ? onUpdate : onSubmit)}>
                  <div className="row">
                    <div className="col-md-9 mb-3">
                      Identify the Manpower required by category for each month based on Production and Sales
                      <br />
                      Monthly Salary for each type of Job
                    </div>
                    <div className="col-md-3 mb-3">
                      <button
                        type="button"
                        className="btn btn-primary me-2 float-end"
                        onClick={() => {
                          handleAddRole();
                        }}
                      >
                        Add Role
                      </button>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Role</th>
                              <th>Cost per Month</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldRoles.map((x, i) => {
                              return (
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.roleName`, {
                                        onChange: (e) => {
                                          triggerRoleNameChange(e, i, x);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      step="any"
                                      numformat="numberRight"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      {...register(`fieldRolesName.${i}.costPerMonth`, {
                                        onChange: (e) => {
                                          triggerCostPerMonthChange(e, i, x);
                                        },
                                      })}
                                    />
                                  </td>
                                  <td className="text-center cursor-pointer">
                                    <MdDelete
                                      size={20}
                                      color="red"
                                      onClick={() => {
                                        onRemoveRole(i);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <Accordion>
                          <Card key={1}>
                            <CustomAccordian eventKey={1}>Manpower Needed</CustomAccordian>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <div className="row">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="table-responsive">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>Gestation Period</th>
                                              <th>1</th>
                                              <th>2</th>
                                              <th>3</th>
                                              <th>4</th>
                                              <th>5</th>
                                              <th>6</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerNeeded.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[${"gestPeriod"}]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, "gestPeriod");
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[1]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 1);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[2]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 2);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[3]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 3);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[4]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 4);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[5]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 5);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[6]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 6);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            })}

                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details["gestPeriod"] && totalManPowerNeeded.details["gestPeriod"])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[1])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[2])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[3])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[4])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[5])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[6])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>7</th>
                                              <th>8</th>
                                              <th>9</th>
                                              <th>10</th>
                                              <th>11</th>
                                              <th>12</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerNeeded.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[7]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 7);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[8]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 8);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[9]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 9);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[10]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 10);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[11]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 11);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.details[12]`, {
                                                        onChange: (e) => {
                                                          triggerMasterCalculation(e, i, x, 12);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                            <tr>
                                              <td>Total</td>

                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[7])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[8])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[9])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[10])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[11])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.details && totalManPowerNeeded.details[12])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>Year 1</th>
                                              <th>Year 2</th>
                                              <th>Year 3</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerNeeded.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1"  {...register(`fieldManPowerNeededName.${i}.aggregate[1]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculation(e, i, x, 1);
                                                        },
                                                      })}   />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number" step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.aggregate[2]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculation(e, i, x, 2);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number" step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerNeededName.${i}.aggregate[3]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculation(e, i, x, 3);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.aggregate && totalManPowerNeeded.aggregate[1])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.aggregate && totalManPowerNeeded.aggregate[2])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerNeeded.aggregate && totalManPowerNeeded.aggregate[3])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card key={2}>
                            <CustomAccordian eventKey={2}>Manpower Cost</CustomAccordian>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <div className="row">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="table-responsive">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>Gestation Period</th>
                                              <th>1</th>
                                              <th>2</th>
                                              <th>3</th>
                                              <th>4</th>
                                              <th>5</th>
                                              <th>6</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerCost.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details["gestPeriod"])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[1])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[2])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[3])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[4])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[5])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[6])} disabled readOnly />
                                                  </td>
                                                </tr>
                                              );
                                            })}

                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details["gestPeriod"])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[1])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[2])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[3])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[4])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[5])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[6])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>7</th>
                                              <th>8</th>
                                              <th>9</th>
                                              <th>10</th>
                                              <th>11</th>
                                              <th>12</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerCost.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[7])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[8])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[9])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[10])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[11])} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(x.details[12])} disabled readOnly />
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                            <tr>
                                              <td>Total</td>

                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[7])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[8])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[9])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[10])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[11])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.details && totalManPowerCost.details[12])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                          <thead className="table-light">
                                            <tr>
                                              <th> </th>
                                              <th>Year 1</th>
                                              <th>Year 2</th>
                                              <th>Year 3</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {fieldManPowerCost.map((x, i) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={x.roleName} disabled readOnly />
                                                  </td>
                                                  <td>
                                                    <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" {...register(`fieldManPowerCostName.${i}.aggregate[1]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculationForCost(e, i, x, 1);
                                                        },
                                                      })}  />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerCostName.${i}.aggregate[2]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculationForCost(e, i, x, 2);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="number"
                                                      step="any"
                                                      numformat="numberRight"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      {...register(`fieldManPowerCostName.${i}.aggregate[3]`, {
                                                        onChange: (e) => {
                                                          triggerSubsequentYearCalculationForCost(e, i, x, 3);
                                                        },
                                                      })}
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.aggregate && totalManPowerCost.aggregate[1])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.aggregate && totalManPowerCost.aggregate[2])} disabled readOnly />
                                              </td>
                                              <td>
                                                <input type="text" numformat="numberRight" className="form-control" id="exampleFormControlInput1" value={getNumericFormat(totalManPowerCost.aggregate && totalManPowerCost.aggregate[3])} disabled readOnly />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* <Card key={3}>
                            <CustomAccordian eventKey={3}>Miscellaneous </CustomAccordian>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead className="table-light text-center">
                                          <tr>
                                            <th>Months</th>
                                            <th>1</th>
                                            <th>2</th>
                                            <th>3</th>
                                            <th>4</th>
                                            <th>5</th>
                                            <th>6</th>
                                          </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                          <tr>
                                            <td>Comission Paid on Sales</td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[1].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[2].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[3].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[4].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[5].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[6].comissionPaidonSales`)} />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Finished Goods Transport</td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[1].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[2].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[3].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[4].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[5].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[6].finishedGoodsTransport`)}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Tax Paid</td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[1].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[2].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[3].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[4].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[5].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[6].taxPaid`)} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="table-responsive mt-4">
                                      <table className="table table-bordered">
                                        <thead className="table-light text-center">
                                          <tr>
                                            <th>Months</th>
                                            <th>7</th>
                                            <th>8</th>
                                            <th>9</th>
                                            <th>10</th>
                                            <th>11</th>
                                            <th>12</th>
                                          </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                          <tr>
                                            <td>Comission Paid on Sales</td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[7].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[8].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[9].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[10].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[11].comissionPaidonSales`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[12].comissionPaidonSales`)} />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Finished Goods Transport</td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[7].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[8].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[9].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[10].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[11].finishedGoodsTransport`)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number" step="any" className="form-control" {...register(`miscellaneous.details[12].finishedGoodsTransport`)}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Tax Paid</td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[7].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[8].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[9].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[10].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[11].taxPaid`)} />
                                            </td>
                                            <td>
                                              <input type="number" step="any" className="form-control" {...register(`miscellaneous.details[12].taxPaid`)} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="table-responsive mt-4">
                                      <table className="table table-bordered">
                                        <thead className="table-light text-center">
                                          <tr>
                                            <th>Months</th>
                                            <th>Year 1</th>
                                            <th>Year 2</th>
                                            <th>Year 3</th>
                                          </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                          <tr>
                                            <td>Comission Paid on Sales</td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[1].comissionPaidonSales`)} type="number" step="any" className="form-control" />
                                            </td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[2].comissionPaidonSales`)} type="number" step="any" className="form-control" />
                                            </td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[3].comissionPaidonSales`)} type="number" step="any" className="form-control" />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Finished Goods Transport</td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[1].finishedGoodsTransport`)} type="number" step="any" className="form-control" />
                                            </td>
                                            <td>
                                              <input
                                                {...register(`miscellaneous.aggregate[2].finishedGoodsTransport`)} type="number" step="any" className="form-control"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                {...register(`miscellaneous.aggregate[3].finishedGoodsTransport`)} type="number" step="any" className="form-control"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Tax Paid</td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[1].taxPaid`)} type="number" step="any" className="form-control" />
                                            </td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[2].taxPaid`)} type="number" step="any" className="form-control" />
                                            </td>
                                            <td>
                                              <input {...register(`miscellaneous.aggregate[3].taxPaid`)} type="number" step="any" className="form-control" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card> */}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      {manpowerId ? "Update Changes" : "Save changes"}
                    </button>
                    <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
export default Manpower;