function Hamburger(props) {
  const element = (
    <div className="align-items-xl-center me-xl-0 d-xl-none">
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div
          className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
          onClick={props.onHamburgerClick}
        >
          <a className="nav-item nav-link px-0 me-xl-4" href="#">
            <i className="bx bx-menu bx-sm"></i>
          </a>
        </div>
      </nav>
    </div>
  );
  return element;
}

export default Hamburger;
