import React from "react";
import Spreadsheet from "x-data-spreadsheet";
import XSpreadsheet from "../../../../spreadsheet/src";
function SpreadsheetCommon(props) {
  const sheetEl = React.useRef(null);
  const sheetRef = React.useRef(null);
  const [state, setState] = React.useState(props.data || {});
  React.useEffect(() => {
    const element = sheetEl.current;
    const sheet = new Spreadsheet("#x-spreadsheet-demo", {
      view: {
        height: () =>
          (element && element.clientHeight) ||
          document.documentElement.clientHeight,
        width: () =>
          (element && element.clientWidth) ||
          document.documentElement.clientWidth
      },
      ...props.options
    })
      .loadData(state) // load data
      .change((data) => {
        setState(data);
        props.setExcelSheetData(data);
        console.log(data);
      });
    sheetRef.current = sheet;
    return () => {
      element.innerHTML = "";
    };
  }, [props.options]);

  return (
    <div
      style={{ height: props.height || "100%", width: props.width || "100%" }}
      id="x-spreadsheet-demo"
      ref={sheetEl}
    ></div>
  );
}

export default SpreadsheetCommon;
