import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import {useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {useParams, useNavigate, useLocation} from "react-router";
import {set, useFieldArray, useForm, Controller} from "react-hook-form";
import axios from "axios";
import {ApiUrl} from "API/config";
import {toast, ToastContainer} from "react-toastify";
import {MdDelete} from "react-icons/md";
import Loader from "common/components/loader";

function FixedCost() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const {register, control, handleSubmit, reset, formState, error, getValues, setValue} = useForm();
  const [isLoader, setIsLoader] = useState(false);
  const {fields: fieldLineItems, append: appendLineItem, update: updateLineItem, remove: removeLineItem, setValue: setValueLineItem} = useFieldArray({name: "fieldLineItemsName", control});
  
  let fixedCostId = location.state.data.fixedCostId && location.state.data.fixedCostId;
  const [gestationMonth, setGestationMonth] = useState(0);

  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (fixedCostId) {
      getAllFixedCost();
    } else {
      getManuFactingById([]);
    }
  }, []);
  const onRemoveLineItem = (i) => {
    removeLineItem(i);
  };

  const [yearlyFixedCost, setYearlyFixedCost] = useState(
    {
      1:0,
      2:0,
      3:0
    }
  )

  const getStartupCost = (startupCostId) => {
    axios
      .get(`${ApiUrl}startupCost/startupCost/${startupCostId}`)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllFixedCost = async () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}fixedCost/fixedCost/${fixedCostId}`)
      .then(async (res) => {
        await getManuFactingById(res.data.fieldLineItemsName);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getManuFactingById = (fieldLineItemsName) => {
    setIsLoader(true);
    let defaultLineItems = ["Salary", "Interest", "Dep + Amort (Non Cash)", "Addtnl Dep (Non Cash)"];
    axios.get(`${ApiUrl}protege/${location.state.data.protegeId}`).then((result) => {
      console.log(result)
      let gestationPeriod = result.data[0].businessInfoid.gestationPeriodOnceInstalled == "true" ? result.data[0].businessInfoid.ifYesHowLong : 0;
      setGestationMonth(gestationPeriod);
      // getStartupCost();
          let MonthlyDepreciation = 0;
          let detailsMonth2 = {
            gestPeriod: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          };
          let aggregateYear = {
            1: 0,
            2: 0,
            3:0
          }
          let MonthlyAmortization = 0;
          defaultLineItems.forEach((x, i) => {
            if (x === "Salary") {
              appendLineItem({
                lineItemName: x,
                default: true,
                details: result.data[0].manpower ? result.data[0].manpower.totalManPowerCost.details : 0,
                aggregate: result.data[0].manpower ? result.data[0].manpower.totalManPowerCost.aggregate : 0,
              });
            } else if (x === "Interest") {
              let lineItemName = ["bankFinancial", "machinarySuppliers", "mfi", "familyFriends", "rawMaterialSup"]
              for (var i = 0; i < lineItemName.length; i++) {
                for (var month = 1; month <= 12; month++) {
                  let sourceOfFund = result.data[0].sourcesOfFund ? result.data[0].sourcesOfFund[lineItemName[i]].details.interest[month] : 0;
                  detailsMonth2[month] += parseFloat(sourceOfFund);
                }
                for (var year = 1; year <= 3; year++) {
                  let sourceOfFund_Year = result.data[0].sourcesOfFund ? result.data[0].sourcesOfFund[lineItemName[i]].aggregate.interest[year] : 0;
                  aggregateYear[year] += parseFloat(sourceOfFund_Year);
                }
                
              }
  
              // detailsMonth2["gestPeriod"] = parseFloat(detailsMonth2[1] * gestationPeriod);
              detailsMonth2["gestPeriod"] = 0;
              appendLineItem({
                lineItemName: x,
                default: true,
                details: detailsMonth2,
                aggregate: aggregateYear,
              });
            } else if (x === "Dep + Amort (Non Cash)" && result.data[0].startupCost) {
                let detailsMonth = {
                  gestPeriod: 0,
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                };
                console.log(result);
              for (var j = 0; j <= result.data[0].startupCost.fieldDepreciablesName.length; j++) {
                MonthlyDepreciation += result.data[0].startupCost.fieldDepreciablesName[j] ? parseFloat(result.data[0].startupCost.fieldDepreciablesName[j].Monthly_Depreciation) : 0;
              }
              console.log(MonthlyDepreciation)
              for (var j = 0; j <= result.data[0].startupCost.fieldIntangiblesName.length; j++) {
                MonthlyAmortization += result.data[0].startupCost.fieldIntangiblesName[j]?.Monthly_Amortization ? parseFloat(result.data[0].startupCost.fieldIntangiblesName[j].Monthly_Amortization) : 0;
              }
              console.log(MonthlyAmortization)

              for (var j = 1; j <= 12; j++) {
                detailsMonth[j] = MonthlyDepreciation + MonthlyAmortization;
              }
  
              appendLineItem({
                lineItemName: x,
                default: true,
                details: detailsMonth,
                aggregate: {
                  1: (MonthlyDepreciation + MonthlyAmortization) * 12,
                  2: (MonthlyDepreciation + MonthlyAmortization) * 12,
                  3: (MonthlyDepreciation + MonthlyAmortization) * 12,
                },
              });
            } else if (x === "Addtnl Dep (Non Cash)" && result.data[0].startupCost) {
              console.log(result.data[0].startupCost)
              let yearAggregate = 0;
              let year2Aggregate = 0;
              let year3Aggregate = 0;
              let detailsMonth =  {
                gestPeriod: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              };
              let startMonth = parseFloat(result.data[0].startupCost.whichMonth)?parseFloat(result.data[0].startupCost.whichMonth):0;
              let costOfEquipment = result.data[0].startupCost.totalCost / 60;
              console.log(costOfEquipment);
              for (var j = 1; j <= 12; j++) {
                if (j >=startMonth) {
                  detailsMonth[j] = costOfEquipment;
                  yearAggregate += costOfEquipment;
                  // console.log(costOfEquipment, yearAggregate)
                }  else {
                  detailsMonth[j] = 0;
                }
              }
              for (var j = 13; j <= 24 ; j++) {
              if (j >= startMonth) {
                  year2Aggregate += costOfEquipment;
                }
              } 
              for (var j = 25; j <= 36; j++) {
              if (j >= startMonth) {
                   year3Aggregate += costOfEquipment;
                }
              } 
              appendLineItem({
                lineItemName: x,
                default: true,
                details: detailsMonth,
                aggregate: {
                  1: yearAggregate,
                  2: year2Aggregate,
                  3: year3Aggregate,
                },
              });
            } else {
              appendLineItem({
                lineItemName: x,
                default: true,
                details: {
                  gestPeriod: 0,
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                aggregate: {
                  1: 0,
                  2: 0,
                  3: 0,
                },
              });
            }
          });
          let fieldLineData = getValues("fieldLineItemsName");
          fieldLineItemsName.filter((x, index) => {
            if (x.lineItemName !== "Salary" && x.lineItemName !== "Interest" && x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)") {
              appendLineItem(x);
            }
            //  if (x.lineItemName === "Addtnl Dep (Non Cash)") {
            //   let indexvalue = fieldLineData.findIndex((y) => y.lineItemName === "Addtnl Dep (Non Cash)");
            //   updateLineItem(indexvalue, x);
            // }
          });
          // console.log( result.data[0].fixedCost.fieldLineItemsName)
          calculateTotalYearlyFixedCost(result?.data?.[0]?.fixedCost?.fieldLineItemsName)
          setTimeout(() => setIsLoader(false), 400);
      
    });

  };

  function  calculateTotalYearlyFixedCost(data){
        console.log(data)

    const obj =  {
      1:0,
      2:0,
      3:0
    }
    obj[1] = data?.reduce((accumulator, currentItem) => 
    accumulator + (currentItem ? (isNaN(parseFloat(currentItem?.aggregate?.[1]))?0:(parseFloat(currentItem?.aggregate?.[1]))) : 0),
    0
) ?? 0;

obj[2] = data?.reduce((accumulator, currentItem) =>
accumulator + (currentItem ? (isNaN(parseFloat(currentItem?.aggregate?.[2]))?0:(parseFloat(currentItem?.aggregate?.[2]))) : 0),
0
) ?? 0;

obj[3] = data?.reduce((accumulator, currentItem) => 
accumulator + (currentItem ? (isNaN(parseFloat(currentItem?.aggregate?.[3]))?0:(parseFloat(currentItem?.aggregate?.[3]))) : 0),
0
) ?? 0;

console.log(obj)
    setYearlyFixedCost(obj);
  }
  const onSubmitForm = (data) => {
    // console.log(data)
    // return;
    setIsLoader(true);
    if (fixedCostId) {
      axios
        .put(`${ApiUrl}fixedCost/fixedCost/update`, {
          ...data,
          _id: fixedCostId,
          protegeId: location.state.data.protegeId,
        })
        .then((result) => {
          toast("Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          setIsLoader(false);
          console.log(error);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      axios
        .post(`${ApiUrl}fixedCost/fixedCost/create`, {
          ...data,
          // manufacturId: location.state.data.manufacturingId,
          protegeId: location.state.data.protegeId,
        })
        .then((result) => {
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          setIsLoader(false);
          console.log(error);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const handleAddLineItem = () => {
    let pos = fieldLineItems.length - 4;
    let lineItem = {
      lineItemName: "",
      default: false,
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      },
    };
    fieldLineItems.splice(pos, 0, lineItem);
    reset({fieldLineItemsName: fieldLineItems});
  };

  const triggerLineItemNameChange = (e, i, x) => {
    let value = e.target.value;
    x.lineItemName = value;
    setValue("fieldLineItemsName", fieldLineItems);
  };

  const triggerfieldChange = (e, i, x, month, type) => {
    let value = e.target ? e.target.value : e;
    if (type === "number") {
      value = parseFloat(value);
    }
    x.details[month] = value;

    triggerAggregateCalculation(x, 1, fieldLineItems);
    setValue("fieldLineItemsName", fieldLineItems);
    calculateTotalYearlyFixedCost(fieldLineItems);

  };
  const triggerfieldChangeAnnual = (e, i, x, year, type) => {
    let value = e.target ? e.target.value : e;
    if (type === "number") {
      value = parseFloat(value);
    }
    x.aggregate[year] = value;

    triggerAggregateCalculation(x, 1, fieldLineItems);
    // setValue("fieldLineItemsName", fieldLineItems);
    // calculateTotalYearlyFixedCost(fieldLineItems);

  };

  const triggerAggregateCalculation = (row, year,fieldLineItems) => {
    let qsum = 0;
    for (var i = 1; i <= 12; i++) {
      qsum += parseFloat(row.details[i]);
    }
    row.aggregate[year] = qsum;
        calculateTotalYearlyFixedCost(fieldLineItems);

  };

  const triggerSubsequentYearCalculation = (e, i, x, year) => {
    x.aggregate[year] = parseFloat(e.target.value);
    setValue("fieldLineItemsName", fieldLineItems);
  };

  return (
    <>
      <Layout>
        <ToastContainer />
        <div className="row">
          <div className="col-6">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-2)}>Protégé</a> /
              </span>
              <span className="text-muted fw-light">
                <a onClick={() => navigate(-1)}>{op} Protégé</a> /
              </span>{" "}
              Fixed Cost
            </h4>
          </div>
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="mb-4">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <button type="button" className="btn btn-primary me-2 float-end" onClick={() => handleAddLineItem()}>
                        Add
                      </button>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Description</th>
                              <th>Gestation Period</th>
                              <th>Month 1</th>
                              <th>Month 2</th>
                              <th>Month 3</th>
                              <th>Month 4</th>
                              <th>Month 5</th>
                              <th>Month 6</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldLineItems.map((x, i) => {
                              // console.log(x)
                              if (x.lineItemName != "Salary" && x.lineItemName != "Interest" && x.lineItemName != "Dep + Amort (Non Cash)" && x.lineItemName != "Addtnl Dep (Non Cash)") {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control lg-width"
                                        id="exampleFormControlInput1"
                                        value={x.lineItemName}
                                        disabled={x.lineItemName == "Addtnl Dep (Non Cash)"}
                                        {...register(`fieldLineItemsName.${i}.lineItemName`, {
                                          onChange: (e) => {
                                            triggerLineItemNameChange(e, i, x);
                                          },
                                        })}
                                      />
                                    </td>
                                   
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["gestPeriod"]}
                                        {...register(`fieldLineItemsName.${i}.details[gestPeriod]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, "gestPeriod", "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["1"]}
                                        {...register(`fieldLineItemsName.${i}.details[1]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 1, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["2"]}
                                        {...register(`fieldLineItemsName.${i}.details[2]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 2, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["3"]}
                                        {...register(`fieldLineItemsName.${i}.details[3]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 3, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["4"]}
                                        {...register(`fieldLineItemsName.${i}.details[4]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 4, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["5"]}
                                        {...register(`fieldLineItemsName.${i}.details[5]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 5, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["6"]}
                                        {...register(`fieldLineItemsName.${i}.details[6]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 6, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>{x.lineItemName == "Addtnl Dep (Non Cash)" ? <MdDelete></MdDelete> : <MdDelete size={20} color="red" onClick={() => onRemoveLineItem(i)}></MdDelete>}</td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <input type="text" className="form-control lg-width" id="exampleFormControlInput1" disabled readOnly value={x.lineItemName} {...register(`fieldLineItemsName.${i}.lineItemName`)} />
                                    </td>
                                   
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["gestPeriod"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[gestPeriod]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled value={getNumericFormat(fieldLineItems[i].details["1"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[1]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["2"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[2]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["3"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[3]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["4"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[4]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["5"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[5]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" value={getNumericFormat(fieldLineItems[i].details["6"])} disabled readOnly {...register(getNumericFormat(`fieldLineItemsName.${i}.details[6]`))} />
                                    </td>
                                    <td>
                                      <MdDelete></MdDelete>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Description</th>
                              <th>Month 7</th>
                              <th>Month 8</th>
                              <th>Month 9</th>
                              <th>Month 10</th>
                              <th>Month 11</th>
                              <th>Month 12</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldLineItems.map((x, i) => {
                              // console.log(x)
                              if (x.lineItemName != "Salary" && x.lineItemName != "Interest" && x.lineItemName != "Dep + Amort (Non Cash)" && x.lineItemName != "Addtnl Dep (Non Cash)") {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control lg-width"
                                        id="exampleFormControlInput1"
                                        disabled
                                        value={x.lineItemName}
                                        {...register(`fieldLineItemsName.${i}.lineItemName`, {
                                          onChange: (e) => {
                                            triggerLineItemNameChange(e, i, x);
                                          },
                                        })}
                                      />
                                    </td>
                                  
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["7"]}
                                        {...register(`fieldLineItemsName.${i}.details[7]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 7, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["8"]}
                                        {...register(`fieldLineItemsName.${i}.details[8]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 8, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["9"]}
                                        {...register(`fieldLineItemsName.${i}.details[9]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 9, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["10"]}
                                        {...register(`fieldLineItemsName.${i}.details[10]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 10, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["11"]}
                                        {...register(`fieldLineItemsName.${i}.details[11]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 11, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control md-width"
                                        id="exampleFormControlInput1"
                                        value={fieldLineItems[i].details["12"]}
                                        {...register(`fieldLineItemsName.${i}.details[12]`, {
                                          onChange: (e) => {
                                            triggerfieldChange(e, i, x, 12, "number");
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>{x.lineItemName == "Addtnl Dep (Non Cash)" ? <MdDelete></MdDelete> : <MdDelete size={20} color="red" onClick={() => onRemoveLineItem(i)}></MdDelete>}</td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <input type="text" className="form-control lg-width" id="exampleFormControlInput1" disabled readOnly value={x.lineItemName} {...register(`fieldLineItemsName.${i}.lineItemName`)} />
                                    </td>
                                   
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled value={getNumericFormat(fieldLineItems[i].details["7"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[7]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["8"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[8]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["9"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[9]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["10"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[10]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(fieldLineItems[i].details["11"])} {...register(getNumericFormat(`fieldLineItemsName.${i}.details[11]`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" value={getNumericFormat(fieldLineItems[i].details["12"])} disabled readOnly {...register(getNumericFormat(`fieldLineItemsName.${i}.details[12]`))} />
                                    </td>
                                    <td>
                                      <MdDelete></MdDelete>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Description</th>
                              <th>Year 1</th>
                              <th>Year 2</th>
                              <th>Year 3</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {fieldLineItems.map((x, i) => {
                              if (x.lineItemName != "Salary" && x.lineItemName != "Interest" && x.lineItemName != "Dep + Amort (Non Cash)" && x.lineItemName != "Addtnl Dep (Non Cash)") {
                                // console.log(x);
                                
                                return (
                                  <tr>
                                     
                                    <td>
                                      <input type="text" className="form-control lg-width" id="exampleFormControlInput1" disabled readOnly value={x.lineItemName} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(x.aggregate[1])} />
                                    </td>
                                    <td>
                                      <input type="number" step="any" className="form-control md-width" id="exampleFormControlInput1" {...register(`fieldLineItemsName.${i}.aggregate[2]`, 
                                      { onChange: (e) => {
                                            triggerfieldChangeAnnual(e, i, x, 2, "number");
                                      },
                                          })} />
                                    </td>
                                    <td>
                                      <input type="number" step="any" className="form-control md-width" id="exampleFormControlInput1" {...register(`fieldLineItemsName.${i}.aggregate[3]`,
                                      { onChange: (e) => {
                                        triggerfieldChangeAnnual(e, i, x, 3, "number");
                                  },
                                      })}
                                       />
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr>
                                    <td>
                                      <input type="text" className="form-control lg-width" id="exampleFormControlInput1" disabled readOnly value={x.lineItemName} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(x.aggregate[1])} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(x.aggregate[2])} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" className="form-control md-width" id="exampleFormControlInput1" disabled readOnly value={getNumericFormat(x.aggregate[3])} />
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                            <tr>
                            
                                    <td>
                                      <div className="text-left"><strong>Total</strong></div>
                                    </td>
                                    <td>
                                    <div className="text-right"><strong>{yearlyFixedCost[1]?getNumericFormat(yearlyFixedCost[1]):0}</strong></div>

                                    </td>
                                    <td>
                                    <div className="text-right"><strong>{yearlyFixedCost[2]?getNumericFormat(yearlyFixedCost[2]):0}</strong></div>

                                    </td>
                                    <td>
                                    <div className="text-right"><strong>{yearlyFixedCost[3]?getNumericFormat(yearlyFixedCost[3]):0}</strong></div>

                                    </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                    <button type="reset" onClick={() => navigate(-1)} className="btn btn-outline-secondary">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default FixedCost;
