import Layout from "common/components/layout";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
function HusbandryProductDetails() {
  const navigate = useNavigate();
  const op = useParams().op;
  return (
    <Layout>
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Husbandary
          </h4>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form id="productOne" method="POST">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Product Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="col-md-6  mb-3">
                <label htmlFor="nameBackdrop" className="form-label">
                  Are there any by-products
                </label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Identify the relationship with Main Product and explain
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Fields
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  How many SKU's
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="col-md-12 mb-3 mt-4">
                Animal Husbandry details
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  BRIEFLY DESCRIBE THE PROCESS OF Growing the animal including
                  the Mortality Rate
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-md-12 mb-3 mt-4">
                Provide Information about the Feed For each Animal (for each
                product)
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Period
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Period Unit
                </label>
                <select className="form-select">
                  <option>Week</option>
                  <option>Day</option>
                  <option>Month</option>
                </select>
              </div>
              <div className="col-md-12 mb-3 mt-4">
                For each product mentioned, the below feed item details are
                captured. Example for milk (i.e., Cow milk), the feed provided
                for cow animal is captured. Each feed table will have details
                for each animal based product.
              </div>
              <div className="col-md-6 mb-3 mt-4">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Product Name</th>
                        <th>Animal Type</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      <tr>
                        <td>
                          <select className="form-select">
                            <option>Milk</option>
                            <option>Meat</option>
                            <option>Egg</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Cow"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mb-3 mt-4">Feed Details</div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Feed/Item Description</th>
                        <th>Unit of Measure</th>
                        <th>Unit Rate</th>
                        <th>GST % on Feed/Item</th>
                        <th>Transport Cost on Feed/Item</th>
                        <th>GST% On transport</th>
                        <th>Per unit cost of Feed/Item</th>
                        <th>Quantity required for one Animal</th>
                        <th>Feed/Item Cost per Animal </th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Grass"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label float-end"
                          >
                            Total Feed/Item Cost before waste
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label float-end"
                          >
                            Waste Percentage
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label float-end"
                          >
                            Per Animal Feed Cost
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mb-3 mt-4">Feed Cost Details</div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>No of Animals</th>
                        <th>Quarter 1</th>
                        <th>Quarter 2</th>
                        <th>Quarter 3</th>
                        <th>Quarter 4</th>
                        <th>Year 1</th>
                        <th>Year 2</th>
                        <th>Year 3</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Sheep"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Goat"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Hen"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mb-3 mt-4">
                Cost of feed per animal details
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Cost of feed</th>
                        <th>Quarter 1</th>
                        <th>Quarter 2</th>
                        <th>Quarter 3</th>
                        <th>Quarter 4</th>
                        <th>Year 1</th>
                        <th>Year 2</th>
                        <th>Year 3</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Animal type 1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Animal type 2"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value="Animal type 3"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div className="mt-2">
            <button type="submit" className="btn btn-primary me-2">
              Save changes
            </button>
            <button
              type="reset"
              className="btn btn-outline-secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HusbandryProductDetails;
