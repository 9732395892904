import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import { set, useFieldArray, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
function SalesRevenewReadOnly(props) {
  console.log(props);
  const [compositionOfSales, setCompositionOfSales] = useState(
    props.props.compositionOfSales
  );
  const [totalMonthlyCashInflowOfSales, setTotalMonthlyCashInflowOfSales] = useState(
    props.props.totalMonthlyCashInflowOfSales
  );
  const [totalMonthlyCashInflowOfSalesGST, setTotalMonthlyCashInflowOfSalesGST] = useState(
    props.props.totalMonthlyCashInflowOfSalesGST
  );
  const [totalMonthlySalesRevenue, setTotalMonthlySalesRevenue] = useState(
    props.props.totalMonthlySalesRevenue
  );
  const [totalMonthlyGSTCollectOnSale, setTotalMonthlyGSTCollectOnSale] = useState(
    props.props.totalMonthlyGSTCollectOnSale
  );
  const [totalYearlyGSTCollectOnSale, setTotalYearlyGSTCollectOnSale] = useState(
    props.props.totalYearlyGSTCollectOnSale
  );

  const [totalYearlySalesRevenue, setTotalYearlySalesRevenue] = useState(
    props.props.totalYearlySalesRevenue
  );
  const [totalYearlyCashInFlowOfSales, setTotalYearlyCashInFlowOfSales] = useState(
    props.props.totalYearlyCashInFlowOfSales
  );
  const [totalYearlyCashInFlowOfSalesGST, setTotalYearlyCashInFlowOfSalesGST] = useState(
    props.props.totalYearlyCashInFlowOfSalesGST
  );
   
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });
       return num ? numberFormatter.format(num) : 0;
  };
  


  
  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="">
          <form id="productOne">
            <div className="">
              <div className="row">
                <div className="col-md-12 col-xl-12">
                  <div className="card shadow-none bg-not-started border mb-3">
                    <div className="card-body">
                      <div className="row">
                        <h5 className="card-title">Sales Revenue (₹)</h5>
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th>Months</th>
                                  <th>1</th>
                                  <th>2</th>
                                  <th>3</th>
                                  <th>4</th>
                                  <th>5</th>
                                  <th>6</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {props.props.fieldProducts.map((x, index) => {
                                  console.log(props.props);
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{x.productId}</td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_1" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[1])}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_2" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[2])}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_3" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[3])}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_4" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[4])}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_5" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[5])}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_6" + index}
                                            disabled
                                            readOnly
                                            value={getNumericFormat(x.details[6])}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>GST Collected on Sales</td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            value={getNumericFormat(x.sales && x.sales[1])}
                                            readOnly
                                            id={"salesrevenue_1" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[2])}
                                            value={getNumericFormat(x.sales && x.sales[2])}
                                            readOnly
                                            id={"salesrevenue_2" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            //value={getNumericFormat(totalGstCollectedOnSales[3])}
                                            value={getNumericFormat(x.sales && x.sales[3])}
                                            readOnly
                                            id={"salesrevenue_3" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[4])}
                                            value={getNumericFormat(x.sales && x.sales[4])}
                                            readOnly
                                            id={"salesrevenue_4" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            //  value={getNumericFormat(totalGstCollectedOnSales[5])}
                                            value={getNumericFormat(x.sales && x.sales[5])}
                                            readOnly
                                            id={"salesrevenue_5" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            //value={getNumericFormat(totalGstCollectedOnSales[6])}
                                            // value={getNumericFormat(props.props.fieldProductSku[index].details[6].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[6])}
                                            readOnly
                                            id={"salesrevenue_6" + index}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                <tr>
                                  <td>Total Sales Revenue B4 GST</td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_1"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[1])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_2"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[2])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_3"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[3])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_4"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[4])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_5"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[5])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_6"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[6])}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total GST Collected On Sales</td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_1"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[1])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_2"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[2])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_3"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[3])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_4"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[4])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_5"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[5])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_6"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[6])}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="table-responsive mt-4">
                            <table className="table table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th>Months</th>
                                  <th>7</th>
                                  <th>8</th>
                                  <th>9</th>
                                  <th>10</th>
                                  <th>11</th>
                                  <th>12</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {props.props.fieldProducts.map((x, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{x.productId}</td>

                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_7" + index}
                                            disabled
                                            value={getNumericFormat(x.details[7])}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_8" + index}
                                            disabled
                                            value={getNumericFormat(x.details[8])}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={"Sales_Revenue_Total_9" + index}
                                            disabled
                                            value={getNumericFormat(x.details[9])}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={
                                              "Sales_Revenue_Total_10" + index
                                            }
                                            disabled
                                            value={getNumericFormat(x.details[10])}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={
                                              "Sales_Revenue_Total_11" + index
                                            }
                                            disabled
                                            value={getNumericFormat(x.details[11])}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            id={
                                              "Sales_Revenue_Total_12" + index
                                            }
                                            disabled
                                            value={getNumericFormat(x.details[12])}
                                            readOnly
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>GST Collected on Sales</td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            value={getNumericFormat(x.sales && x.sales[7])}
                                            readOnly
                                            id={"salesrevenue_1" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[8])}
                                            //  value={getNumericFormat(props.props.fieldProductSku[index].details[8].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[8])}
                                            readOnly
                                            id={"salesrevenue_2" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[9])}
                                            // value={getNumericFormat(props.props.fieldProductSku[index].details[9].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[9])}
                                            readOnly
                                            id={"salesrevenue_3" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[10])}
                                            // value={getNumericFormat(props.props.fieldProductSku[index].details[10].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[10])}
                                            readOnly
                                            id={"salesrevenue_4" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[11])}
                                            // value={getNumericFormat(props.props.fieldProductSku[index].details[11].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[11])}
                                            readOnly
                                            id={"salesrevenue_5" + index}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            numformat="numberRight"
                                            className="form-control"
                                            // value={getNumericFormat(totalGstCollectedOnSales[12])}
                                            // value={getNumericFormat(props.props.fieldProductSku[index].details[12].gst_collected_on_sales)}
                                            value={getNumericFormat(x.sales && x.sales[12])}
                                            readOnly
                                            id={"salesrevenue_6" + index}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                <tr>
                                  <td>Total Sales Revenue B4 GST</td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_7"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[7])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_8"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[8])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_9"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[9])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_10"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[10])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_11"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[11])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlySalesRevenue_12"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlySalesRevenue[12])}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total GST Collected On Sales</td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_7"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[7])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_8"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[8])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_9"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[9])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_10"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[10])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_11"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[11])}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={"totalMonthlyGSTCollectOnSale_12"}
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalMonthlyGSTCollectOnSale[12])}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="table-responsive mt-4">
                            <table className="table table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th>Months</th>
                                  <th>Year 1</th>
                                  <th>Year 2</th>
                                  <th>Year 3</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {props.props.fieldProducts.map((x, index) => { 
                                  return (
                                    <tr key={index}>
                                      <td>{x.productId}</td>
                                      <td>
                                        <input
                                          disabled
                                          readOnly
                                          value={getNumericFormat(x.aggregate[1])}
                                          type="text"
                                          numformat="numberRight"
                                          className="form-control"
                                          id={
                                            "aggregate_sales_revenue_total_1" +
                                            index
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled
                                          value={getNumericFormat(x.aggregate[2])}
                                          type="text"
                                          numformat="numberRight"
                                          className="form-control"
                                          id={
                                            "aggregate_sales_revenue_total_2" +
                                            index
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled
                                          value={getNumericFormat(x.aggregate[3])}
                                          type="text"
                                          numformat="numberRight"
                                          className="form-control"
                                          id={
                                            "aggregate_sales_revenue_total_3" +
                                            index
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td>Total Sales Revenue B4 GST</td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlySalesRevenue[1])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_1"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlySalesRevenue[2])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_2"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlySalesRevenue[3])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_3"
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total GST Collected On Sales</td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlyGSTCollectOnSale[1])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_1"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlyGSTCollectOnSale[2])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_2"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      readOnly
                                      value={getNumericFormat(totalYearlyGSTCollectOnSale[3])}
                                      type="text"
                                      numformat="numberRight"
                                      className="form-control"
                                      id={
                                        "overall_aggregate_sales_revenue_total_3"
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card shadow-none bg-not-started border mb-3">
        <div className="card-body">
          <div className="row">
            <h5 className="card-title">Composition of Total Sale Revenue B4 GST</h5>
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Cash</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[6])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Credit</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[6])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Card</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[6])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Advance</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[6])}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Cash</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.details[12])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Credit</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.details[12])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Card</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.details[12])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Advance</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.details[12])}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Cash</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.aggregate[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.aggregate[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Cash.aggregate[3])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Credit</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.aggregate[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.aggregate[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Credit.aggregate[3])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Card</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.aggregate[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.aggregate[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Card_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Card.aggregate[3])}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Advance</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.aggregate[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.aggregate[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Advance_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(compositionOfSales.Advance.aggregate[3])}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow-none bg-not-started border mb-3">
        <div className="card-body">
          <div className="row">
            <h5 className="card-title">Sales Cash Inflow</h5>
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Total Sales Cash B4 GST</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[6])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Total GST Collected</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_2"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_3"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[3])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_4"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[4])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_5"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[5])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_6"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[6])}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Total Sales Cash B4 GST</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSales[12])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Total GST Collected</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_7"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[7])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_8"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[8])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_9"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[9])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_10"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[10])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_11"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[11])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_12"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalMonthlyCashInflowOfSalesGST[12])}
                        />
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Total Sales Cash B4 GST</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlySalesRevenue[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlySalesRevenue[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlySalesRevenue[3])}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Total GST Collected</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlyGSTCollectOnSale[1])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlyGSTCollectOnSale[2])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(totalYearlyGSTCollectOnSale[3])}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Months</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>Total Sales Cash B4 GST Cash Inflow</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSales?totalYearlyCashInFlowOfSales[1]:0))}
                          />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSales?totalYearlyCashInFlowOfSales[2]:0))}
                            // (getNumericFormat(totalYearlySalesRevenue[1]) - getNumericFormat(Object.values(totalMonthlyCashInflowOfSales)).reduce((sum, value) => sum + value, 0))}
                          />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Cash_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSales?totalYearlyCashInFlowOfSales[3]:0))}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Total GST Collected Cash Inflow</td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSalesGST?totalYearlyCashInFlowOfSalesGST[1]:0))}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSalesGST?totalYearlyCashInFlowOfSalesGST[2]:0))}
                        /> 
                      </td>
                      <td>
                        <input
                          type="text"
                          numformat="numberRight"
                          className="form-control"
                          id={"Credit_yearly_1"}
                          disabled
                          readOnly
                          value={getNumericFormat(parseFloat(totalYearlyCashInFlowOfSalesGST?totalYearlyCashInFlowOfSalesGST[3]:0))}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesRevenewReadOnly;
