import { Link } from "react-router-dom";
function ProductItem(props) {
  console.log("props", props);
  return (
    <div className="col-md-6 col-xl-6" id={props.product.id} key={props.i}>
      <div className="card shadow-none bg-not-started border mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-9">
              <h5 className="card-title">{props.product.title}</h5>
            </div>
            <div className="col-3">
              <span className="float-end">
                {/* <a className="cursor-pointer">
                  <i
                    className="bx bx-show"
                    onClick={() => {
                      props.setProductId(props.product.id);
                      props.showProduct();
                    }}
                  ></i>
                </a> */}
                {"   "}{" "}
                <Link
                  to={`/Protege/${props.productName}Details/Edit/${props.product.id}`}
                  state={{
                    id: props.product.id,
                    manufacturingId: props.manufacturingId,
                    animalHusbandryId: props.animalHusbandryId,
                    agroId: props.agroId,
                    tradingId: props.tradingId,
                    serviceId: props.serviceId,
                    protegeId: props.protegeId,
                    
                  }}
                >
                  <i className="bx bx-edit-alt"></i>
                </Link>
                {"   "}
                <a
                  className="cursor-pointer"
                  onClick={() => props.handleDelete(props.productName,props.product.id)}
                >
                  <i className="bx bx-trash"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
