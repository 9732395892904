import Hamburger from "navigation/components/hamburger";
import { useState } from "react";
import NavigationBar from "navigation/components/navBar";
import { useLocation } from "react-router";
function Layout(props) {
  const location = useLocation();
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const toggleHamburgerMenu = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };
  const element = (
    <div
      className={
        showHamburgerMenu
          ? "layout-wrapper layout-content-navbar layout-menu-expanded"
          : "layout-wrapper layout-content-navbar"
      }
    >
      <div className="layout-container">
        {!(
          location.pathname === "/login" ||
          location.pathname === "/forgotPassword"
        ) && (
          <NavigationBar
            toggleHamburgerMenu={toggleHamburgerMenu}
          ></NavigationBar>
        )}
        <div className="layout-page">
          <Hamburger onHamburgerClick={toggleHamburgerMenu}></Hamburger>
          <div className="content-wrapper layout-menu-fixed ">
            <div className="container-xxl flex-grow-1 container-p-y">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return element;
}
export default Layout;
