import { useEffect, useState } from "react";
import React from "react";
import CustomModal from "common/components/CustomModal";
import NewCenter from "./newCenter";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import { ToastContainer, toast } from "react-toastify";
import UpdateCenter from "./updateCenter";
import Loader from "common/components/loader";
function CenterList() {
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [allData, setALLData] = useState([]);
  const [allCenterData, setAllCenterData] = useState([]);
  const [allCenters, setSearchData] = useState([]);
  const [centerData, setCenterData] = useState("");
  const [pager, setPager] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    getAllcenter();
    getAllCenterData();
  }, []);
  let page = 1;
  let currentPage;

  const addCentreHandler = () => {
    setShow(true);
  };
  const UpdateCentreHandler = () => {
    setShowUpdate(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseCenter = () => {
    setShowUpdate(false);
  };
  const getAllCenterData = () => {

    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}center/all?page=${-1}`)
        .then((data) => {
          setSearchData(data.data);

          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 500);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          setIsLoader(false);
        });
      }
  }
  const getAllcenter = () => {
    // axios
    //   .get(`${ApiUrl}center/all`)
    //   .then((centerdata) => {
    //     console.log(centerdata.data);
    //     setAllCenterData(centerdata.data);
    //     setAllCenter(centerdata.data)
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setIsLoader(true);

    if (page !== pager.currentPage) {
      axios
        .get(`${ApiUrl}center/all?page=${page}`)
        .then((data) => {
          setAllCenterData(data.data.pageOfItems);
          setPager(data.data.pager);
          setALLData(data.data.pageOfItems);
          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 500);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log(err);
        });
    } else {
      axios
        .get(`${ApiUrl}center/all?page=${page}`)
        .then((data) => {
          setAllCenterData(data.data.pageOfItems);
          setPager(data.data.pager);
          setALLData(data.data.pageOfItems);
          const timer = setTimeout(() => {
            setIsLoader(false);
          }, 500);
          return () => clearTimeout(timer);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log(err);
        });
    }
  };
  const createCenter = (data) => {
    axios
      .post(`${ApiUrl}center/create`, data)
      .then((createres) => {
        toast("Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleClose();
        getAllcenter();
      })
      .catch((err) => {
        console.log(err);
        toast("Error While Creating", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const udapteCenter = (data) => {
    let userId = JSON.parse(localStorage.getItem("user_data")).user._id;
    axios
      .put(`${ApiUrl}center/update/${centerData._id}`, data)
      .then((response) => {
        handleCloseCenter();
        getAllcenter();
        toast("Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .catch((error) => {
        console.log(error);
        toast("Error While Updating", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const onCenterSearch = (e) => {
      if (!e.target.value) {
      setALLData(allCenterData);
    } else {
      setALLData(
      allCenters.filter((x) =>
        x.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    }
  };

  const onChangePage = (data) => {
    page = data.page;
    getAllcenter();
  };
  return (
    <Layout>
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 cursor-pointer">Centres</h4>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary float-end"
            data-bs-toggle="modal"
            data-bs-target="#backDropModal"
            onClick={addCentreHandler}
          >
            {" "}
            Add Centre{" "}
          </button>
        </div>
      </div>
      {isLoader ? (
<Loader/>
      ) : (
        <div className="card">
          <div className="user-search">
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Centre name"
                onChange={onCenterSearch}
                aria-describedby="defaultFormControlHelp"
              />
            </div>
          </div>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead className="table-light">
                <tr>
                  <th>Centre Name</th>
                  <th>Centre Code</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {allData.map((x, index) => {
                  return (
                    <tr>
                      <td>{x.name}</td>
                      <td>{x.code}</td>
                      {x.status == "active" ? (
                        <td>
                          <span className="badge bg-label-success me-1">
                            Active
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span className="badge bg-label-warning me-1">
                            Inactive
                          </span>
                        </td>
                      )}

                      <td>
                        <div
                          onClick={() => {
                            UpdateCentreHandler();
                            setCenterData(x);
                          }}
                        >
                          <i className="bx bx-edit-alt me-1"></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}

                {/* <tr>
                  <td>ASC &amp; College - Bangalore</td>
                  <td>05</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr>
                <tr>
                  <td>ACC &amp; S - Ahmednagar </td>
                  <td>02</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr>
                <tr>
                  <td>AMC - Lucknow</td>
                  <td>03</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr>
                <tr>
                  <td>BEG - Pune</td>
                  <td>04</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr>
                <tr>
                  <td>KRC - Ranikhet</td>
                  <td>06</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr>
                <tr>
                  <td>ASCE - Roorkie</td>
                  <td>07</td>
                  <td><span className="badge bg-label-success me-1">Active</span></td>
                  <td><a href="#"><i className="bx bx-edit-alt me-1"></i></a></td>
                </tr> */}
              </tbody>
            </table>

            <nav aria-label="Page navigation" className="paginationSpacing">
              <ul className="pagination justify-content-end">
                {pager.pages && pager.pages.length && (
                  <ul className="pagination">
                    {/* <li
                    className={`page-item first-item ${
                      pager.currentPage === 1 ? "disabled" : ""
                    }`}
                  ></li> */}
                    <li
                      style={{ cursor: "pointer" }}
                      className={`page-item first-item ${
                        pager.currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <div
                        // to={{ search: `?page=1` }}
                        onClick={() => {
                          onChangePage({ page: 1 });
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </div>
                    </li>

                    {pager.pages.map((page) => (
                      <li
                        key={page}
                        style={{ cursor: "pointer" }}
                        className={`page-item number-item ${
                          pager.currentPage === page ? "active" : ""
                        }`}
                      >
                        <div
                          /// to={{ search: `?page=${page}` }}
                          onClick={() => {
                            onChangePage({ page: page });
                          }}
                          className="page-link"
                        >
                          {page}
                        </div>
                      </li>
                    ))}
                    <li
                      style={{ cursor: "pointer" }}
                      className={`page-item previous-item ${
                        pager.currentPage === pager.endPage ? "disabled" : ""
                      }`}
                    >
                      <div
                        // to={{ search: `?page=${pager.currentPage - 1}` }}
                        onClick={() => {
                          onChangePage({ page: pager.endPage });
                        }}
                        className="page-link"
                      >
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </div>
                    </li>
                  </ul>
                )}
                {/* <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li> */}
                {/* <li className="page-item next">
                <div className="page-link">
                 
                </div>
              </li> */}
              </ul>
            </nav>
          </div>
        </div>
      )}

      {/* <hr className="my-5" /> */}

      <div className="content-backdrop fade"></div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        title="New Centre"
        createCenter={createCenter}
        submitBtnTitle="Save"
        cancelBtnTitle="Close"
        Body={NewCenter}
      ></CustomModal>
      <CustomModal
        show={showUpdate}
        data={centerData}
        handleClose={handleCloseCenter}
        title="New Centre"
        udapteCenter={udapteCenter}
        submitBtnTitle="Save"
        cancelBtnTitle="Close"
        Body={UpdateCenter}
      ></CustomModal>
    </Layout>
  );
}

export default CenterList;
