import CustomAccordian from "common/components/CustomAccordian";
import Layout from "common/components/layout";
import Loader from "common/components/loader";
import React, {useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {useParams, useNavigate, useLocation} from "react-router";
import {MdDelete} from "react-icons/md";
import SkuModel from "./skuModel";
import {set, useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import {ApiUrl} from "API/config";
import {toast, ToastContainer} from "react-toastify";
import "screens/protégé/style/manufacturing.css";
function TradingProductDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const op = useParams().op;
  const productId = useParams().id;
  const [isLoader, setIsLoader] = useState(false);
  const {register, control, handleSubmit, getValues, setValue, setError, reset, formState, watch, setFocus} = useForm();
  const {errors} = formState;
  const {fields: fieldsRawMaterial, append: appendRawMaterial, remove: removeRawMaterial, update: updateRawMaterial} = useFieldArray({name: "rawMaterial", control});
  const {fields: fieldsCapacity, append: appendCapacity, remove: removeCapacity, update: updateCapacity} = useFieldArray({name: "capacity", control});
  const {fields: fieldsSku, append: appendSku, remove: removeSku, update: updateSku, setValue: setValueSku} = useFieldArray({name: "skuData", control});
  const [variableCost, setVariableCost] = useState([]);
  const [basicMaterialCost, setBasicMaterialCost] = useState([]);
  const [rawMaterialName, setRawMaterialName] = useState([]);
  const [batchSize, setBatchSize] = useState(0);
  const [grossProfit, setGrossProfit] = useState([]);
  const [totalCostPerBatchValue, setTotalCostPerBatchValue] = useState(0);
  const [totalGstAmountInThis, setTotalGstAmountInThis] = useState(0);
  const [gstPercOfTotalUnitCost, setGstPercOfTotalUnitCost] = useState(0);
  const [capacityStartMonth, setcapacityStartMonth] = useState(1);
  const [totalRawMaterialCostPerBatch, setTotalRawMaterialCostPerBatch] = useState(0);
  const [totalRawMaterialCostPerUnit, setTotalRawMaterialCostPerUnit] = useState(0);
  const [wastePercentageAfterGst, setWastePercentageAfterGst] = useState(0);
  const [rmAndLabourCost, setRmAndLabourCost] = useState([]);
  const [wasteAmountAfterGst, setWasteAmountAfterGst] = useState(0);
  const [variableCostKeepingUnit, setVariableCostKeepingUnit] = useState();
  const [variableCostKeepingBatch, setVariableCostKeepingBatch] = useState();
  const [packingMaterial, setPackingMaterial] = useState([]);
  const [noOfSku, setNoOfSku] = useState();
  const [skuUnitOfMeasure, setSkuUnitOfMeasure] = useState();
  const [trigger, setTrigger] = useState(0);
  const [errorRawMaterial, setErrorRawMaterial] = useState([]);
  const [numberOfItemsLessThan, setNumberOfItemsLessThan] = useState(true);
  useEffect(() => {
    // if (op == "Edit") {
    //   getEditProductData();
    // }
    if (location.state.tradingId) {
      getManuFactingById();
    }

  }, []);
  const getManuFactingById = () => {
    setIsLoader(true);
    let obj = {
      details: {
        1: {usage: 0, productionPlan: 0, inventory: 0},
        2: {usage: 0, productionPlan: 0, inventory: 0},
        3: {usage: 0, productionPlan: 0, inventory: 0},
        4: {usage: 0, productionPlan: 0, inventory: 0},
        5: {usage: 0, productionPlan: 0, inventory: 0},
        6: {usage: 0, productionPlan: 0, inventory: 0},
        7: {usage: 0, productionPlan: 0, inventory: 0},
        8: {usage: 0, productionPlan: 0, inventory: 0},
        9: {usage: 0, productionPlan: 0, inventory: 0},
        10: {usage: 0, productionPlan: 0, inventory: 0},
        11: {usage: 0, productionPlan: 0, inventory: 0},
        12: {usage: 0, productionPlan: 0, inventory: 0},
      },
      aggregate: {
        1: {usage: 0, productionPlan: 0, inventory: 0},
        2: {usage: 0, productionPlan: 0, inventory: 0},
        3: {usage: 0, productionPlan: 0, inventory: 0},
      },
    };
    axios.get(`${ApiUrl}protege/${location.state.protegeId}`).then((result) => {
      let capacityStartMonth = result.data[0].businessInfoid.ifYesHowLong && result.data[0].businessInfoid.gestationPeriodOnceInstalled == "true" ? parseFloat(result.data[0].businessInfoid.ifYesHowLong + 1) : 1;
      setcapacityStartMonth(capacityStartMonth);
    });
    axios
      .get(`${ApiUrl}trading/gettrading/${location.state.tradingId}`, {
        _id: location.state.tradingId,
      })
      .then((result) => {
        if (result.data.data.length && result.data.data[0] && result.data.data[0].productList && productId) {
          let productData = result.data.data[0].productList.find((product) => product._id === productId);

          reset({
            product_name: productData.product_name,
            physical_nature_of_product: productData.physical_nature_of_product,
            brief_manufacture_product: productData.brief_manufacture_product,
            machine_used_in_measure_process: productData.machine_used_in_measure_process,
            numberOfItemsLessThan: productData.numberOfItemsLessThan,
            rawMaterial: productData.rawMaterialData.rawMaterial,
          });

          setNumberOfItemsLessThan(productData.numberOfItemsLessThan);
        }

        if (result.data.data.length && result.data.data[0] && result.data.data[0].rawMaterialConsumption) {
          axios
            .get(`${ApiUrl}rawMaterialConsumption/trading/rawMaterialConsumption/${result.data.data[0].rawMaterialConsumption}`)
            .then((resultRM) => {
              setRawMaterialName(resultRM.data.rawMaterial);
              let rawMaterial = getValues("rawMaterial");
              rawMaterial.filter((x, i) => setRawMaterialValue(x.raw_material_description, i, x, resultRM.data.rawMaterial));
              if (productId) {
                let productData = result.data.data[0].productList.find((product) => product._id === productId);
                calculateAfterEdit(rawMaterial, productData.rawMaterialData.waste_percentage, productData.batch_size);
              } else {
                calculateAfterEdit(rawMaterial, 0, 0);
              }
              updateErrorRawMaterial();
              setTimeout(() => setIsLoader(false), 400);
            })
            .catch((error) => {
              console.log(error);
              setIsLoader(false);
            });
        } else {
          setTimeout(() => setIsLoader(false), 400);
        }
        if (result.data.data.length && result.data.data[0] && result.data.data[0].salesProjection && productId) {
          axios.get(`${ApiUrl}salesprojection/get/${result.data.data[0].salesProjection}`).then((resultSku) => {
            let requiredResultSku = resultSku.data.fieldProductSkuName.filter((y) => y.product_id == productId);
            let skuDetails = getValues("skuData");
            requiredResultSku.map((skuItem, skuIndex) => {
              calculatePlannedProductionUsage(obj, skuDetails, skuItem, skuIndex);
            });
            setValue("basicPlannedProduction", obj);
            for (var month = 1; month <= 12; month++) {
              triggerMasterCalculation(month);
            }
            for (var year = 1; year <= 3; year++) {
              tirggerSubsequentYearCalculation(year);
            }
          });
        } else {
          setValue("basicPlannedProduction", obj);
          setTimeout(() => setIsLoader(false), 400);
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoader(false);
      });
  };
  const getConvertedPrimaryUnit = (unitType, value) => {
    let result;
    let standardScale;
    switch (unitType) {
      case "gm":
        standardScale = 1000;
        break;
      case "cm":
        standardScale = 100;
      default:
        standardScale = 1;
        break;
    }
    result = value / standardScale;
    return result;
  };
  const calculatePlannedProductionUsage = (obj, skuDetails, productionDetails, skuIndex) => {
    let requiredUsage = 0;
    let requiredAggregateUsage = 0;
    for (const month in obj.details) {
      requiredUsage = parseFloat(obj.details[month].usage);
      let prod_quantity = productionDetails.details[month].prod_quantity ? productionDetails.details[month].prod_quantity : 0;
      let skuValue = getConvertedPrimaryUnit(skuDetails[skuIndex] && skuDetails[skuIndex].sku_unit_of_measure ? skuDetails[skuIndex].sku_unit_of_measure : "", parseFloat(skuDetails[skuIndex].sku_size) * parseFloat(skuDetails[skuIndex].sku_quantity));
      requiredUsage += skuValue * parseFloat(prod_quantity);
      obj.details[month].usage = requiredUsage;
    }
    for (const year in obj.aggregate) {
      requiredAggregateUsage = parseFloat(obj.aggregate[year].usage);
      let aggregate_prod_quantity = productionDetails.aggregate[year].prod_quantity ? productionDetails.aggregate[year].prod_quantity : 0;
      requiredAggregateUsage += getConvertedPrimaryUnit(skuDetails[skuIndex] && skuDetails[skuIndex].sku_unit_of_measure ? skuDetails[skuIndex].sku_unit_of_measure : "", parseFloat(skuDetails[skuIndex].sku_size) * parseFloat(skuDetails[skuIndex].sku_quantity)) * parseFloat(aggregate_prod_quantity);
      obj.aggregate[year].usage = requiredAggregateUsage;
    }
    setValue("basicPlannedProduction", obj);
  };
  const triggerMasterCalculation = (month) => {
    let obj = getValues("basicPlannedProduction");
    for (const item in obj.details) {
      calculateInventory(obj, item, 1);
    }
    for (const year in obj.aggregate) {
      if (year != 1) {
        obj.aggregate[year].inventory = parseFloat(obj.aggregate[year - 1].inventory) + parseFloat(obj.aggregate[year].productionPlan) - parseFloat(obj.aggregate[year].usage);
      }
    }
    setValue("basicPlannedProduction", obj);
  };
  const calculateInventory = (obj, month, year) => {
    if (year == 1) {
      let requiredCloneObj = Object.assign({}, obj.details[month]);
      let result = requiredCloneObj.productionPlan == "" ? 0 : parseFloat(requiredCloneObj.productionPlan) - parseFloat(requiredCloneObj.usage);
      let requiredMonthInventory = parseFloat(obj.details[month].inventory);
      if (month > 1 && month <= 12) {
        requiredMonthInventory = parseFloat(result) + parseFloat(obj.details[month - 1].inventory);
        obj.details[month].inventory = requiredMonthInventory;
      } else {
        obj.details[month].inventory = result;
      }
    }
    let requiredPlannedUsage = 0;
    let requiredPlannedProd = 0;
    for (const month in obj.details) {
      requiredPlannedProd += obj.details[month].productionPlan == "" ? 0 : parseFloat(obj.details[month].productionPlan);
      requiredPlannedUsage += parseFloat(obj.details[month].usage);
    }
    obj.aggregate[1].usage = parseFloat(requiredPlannedUsage);
    obj.aggregate[1].productionPlan = parseFloat(requiredPlannedProd);
    obj.aggregate[1].inventory = parseFloat(requiredPlannedProd) - parseFloat(requiredPlannedUsage);
  };
  const tirggerSubsequentYearCalculation = (year) => {
    let obj = getValues("basicPlannedProduction");
    obj.aggregate[year].inventory = parseFloat(obj.aggregate[year - 1].inventory) + parseFloat(obj.aggregate[year].productionPlan) - parseFloat(obj.aggregate[year].usage);
    setValue("basicPlannedProduction", obj);
  };
  useEffect(() => {
    if (op == "Add") {
      reset({
        basicPlannedProduction: {
          details: {
            1: {usage: 0, productionPlan: 0, inventory: 0},
            2: {usage: 0, productionPlan: 0, inventory: 0},
            3: {usage: 0, productionPlan: 0, inventory: 0},
            4: {usage: 0, productionPlan: 0, inventory: 0},
            5: {usage: 0, productionPlan: 0, inventory: 0},
            6: {usage: 0, productionPlan: 0, inventory: 0},
            7: {usage: 0, productionPlan: 0, inventory: 0},
            8: {usage: 0, productionPlan: 0, inventory: 0},
            9: {usage: 0, productionPlan: 0, inventory: 0},
            10: {usage: 0, productionPlan: 0, inventory: 0},
            11: {usage: 0, productionPlan: 0, inventory: 0},
            12: {usage: 0, productionPlan: 0, inventory: 0},
          },
          aggregate: {
            1: {usage: 0, productionPlan: 0, inventory: 0},
            2: {usage: 0, productionPlan: 0, inventory: 0},
            3: {usage: 0, productionPlan: 0, inventory: 0},
          },
        },
      });
      appendRawMaterial({
        raw_material_description: "",
        unit_of_measure: "",
        unit_rate: 0,
        gst_on_raw_material: 0,
        transpost_cost_on_raw_material: 0,
        gst_transport: 0,
        gst_in_total: 0,
        per_unit_cost_of_raw_material: 0,
        cost_per_batch: 0,
        gst_amount_batch: 0,
      });
      appendCapacity({
        installed_capacity: "",
        unit_of_time: "Hour",
        days_months: "",
        hours_days: "",
        start_month: "",
      });
      appendSku(
        {
          type_of_packing_required: "",
          cost_of_packing: 0,
          unit_of_sale: 0,
          sku_capacity_per_hour: 0,
          sku_unit_of_measure: "",
          sku_unit_of_measure1: "",
          unit_price: 0,
          sku_description: "",
          sku_size: 0,
          sku_quantity: 0,
          packing: [
            {
              level: "",
              quantity: "0",
              total_packing_cost: "0",
              unit_cost: "0",
              gst_in_total: "0",
            },
          ],
          sku_type_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_inner_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_outer_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_transport_packing: {
            quantity: 0,
            unit_cost: 0,
            total_packing_cost: 0,
          },
          sku_other: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_none: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
          sku_total: 0,
          sku_sales_price: 0,
          sku_rm_labor: 0,
          sku_rmValue: 0,
          sku_packing_cost: 0,
          sku_commission: 0,
          sku_labor: 0,
          sku_transport: 0,
          sku_gst_amount: 0,
          sku_gst_percent: 0,
          sku_commission_percent: 0,
          sku_gross_profit: 0,
        },
        {shouldFocus: false}
      );
    }
  }, []);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    });
    return num ? numberFormatter.format(num) : 0;
  };

  const calculateRawMaterialFields = (field, value, index, item, isNumber) => {
    console.log(item);
    var obj = item;
    value = isNumber ? (value.target ? parseFloat(value.target.value.replace(/,/g, "")) : parseFloat(value.replace(/,/g, ""))) : value;
    obj[field] = value;
     obj.cost_per_batch = parseFloat(obj.per_unit_cost_of_raw_material);
    let gst_total_unit_cost = parseFloat(obj.gst_in_total) / parseFloat(obj.per_unit_cost_of_raw_material);
    obj.gst_amount_batch = parseFloat(obj.cost_per_batch) * parseFloat(gst_total_unit_cost);
    updateRawMaterial(index, obj);
    calculateAfterEdit(getValues("rawMaterial"), wastePercentageAfterGst, batchSize);
    setTrigger((trigger) => trigger + 1);
  };
  const setFieldArrayValue = (value, field, index, item, isNumber) => {
    let requiredRmValue = rmAndLabourCost.length ? [...rmAndLabourCost] : [];
    var obj = item;
    if (value && field) {
      obj[field] = isNumber ? parseFloat(value) : value;
    }
    if (isNumber) {
      let rmLabourValue = 0;
      rmLabourValue = totalRawMaterialCostPerUnit.toFixed(2) * parseFloat(obj.sku_size) * parseFloat(obj.sku_quantity);
      requiredRmValue[index] = rmLabourValue.toFixed(2);
      setRmAndLabourCost(requiredRmValue);
    }
    updateSku(index, obj);
    setTrigger((trigger) => trigger + 1);
  };
  const setRawMaterialValue = (value, index, x, rawMaterial) => {
    rawMaterial.filter((item) => {
      console.log(item)
      if (item.raw_material_description === value) {
        x.raw_material_description = item.raw_material_description;
        x.unit_of_measure = item.unit_of_measure;
        x.unit_rate = item.unit_rate;
        x.gst_on_raw_material = item.gst_on_raw_material;
        x.transpost_cost_on_raw_material = item.transpost_cost_on_raw_material;
        x.gst_transport = item.gst_transport;
        x.gst_in_total = item.gst_in_total;
        x.per_unit_cost_of_raw_material = item.per_unit_cost_of_raw_material;
        x.cost_per_batch = parseFloat(x.per_unit_cost_of_raw_material);
        x.gst_amount_batch = parseFloat(x.cost_per_batch) * parseFloat(parseFloat(x.gst_in_total) / parseFloat(x.per_unit_cost_of_raw_material));
        updateRawMaterial(index, x);
      }
    });
  };
  const autoResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  const handleAddRawMaterial = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      raw_material_description: "",
      unit_of_measure: "",
      unit_rate: 0,
      gst_on_raw_material: 0,
      transpost_cost_on_raw_material: 0,
      gst_transport: 0,
      gst_in_total: 0,
      per_unit_cost_of_raw_material: 0,
      cost_per_batch: 0,
    };
    appendRawMaterial(rawMaterialItem);
    updateErrorRawMaterial();
  };
  const handleAddCapacity = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      installed_capacity: "",
      unit_of_time: "Hour",
      days_months: "",
      hours_days: "",
      start_month: "",
    };
    appendCapacity(rawMaterialItem);
  };
  const handleAddSku = (e) => {
    e.preventDefault();
    const skuItem = {
      type_of_packing_required: "",
      cost_of_packing: 0,
      unit_of_sale: 0,
      sku_capacity_per_hour: 0,
      sku_unit_of_measure: skuUnitOfMeasure ? skuUnitOfMeasure : "",
      sku_unit_of_measure1: "",
      unit_price: 0,
      sku_description: "",
      packing: [
        {
          level: "",
          quantity: "0",
          total_packing_cost: "0",
          unit_cost: "0",
          gst_in_total: "0",
        },
      ],
      sku_size: 0,
      sku_quantity: 0,
      sku_type_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_inner_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_outer_packing: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_transport_packing: {
        quantity: 0,
        unit_cost: 0,
        total_packing_cost: 0,
      },
      sku_other: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_none: {quantity: 0, unit_cost: 0, total_packing_cost: 0},
      sku_total: 0,
      sku_sales_price: 0,
      sku_rm_labor: 0,
      sku_rmValue: 0,
      sku_packing_cost: 0,
      sku_commission: 0,
      sku_labor: 0,
      sku_transport: 0,
      sku_gst_amount: 0,
      sku_gst_percent: 0,
      sku_commission_percent: 0,
      sku_gross_profit: 0,
    };
    appendSku(skuItem);
    setNoOfSku(parseInt(fieldsSku.length) + 1);
  };
  const onRemoveRawMaterial = (i) => {
    removeRawMaterial(i);
    calculateAfterEdit(getValues("rawMaterial"), wastePercentageAfterGst, batchSize);
    updateErrorRawMaterial();
    setTrigger((trigger) => trigger + 1);
  };
  const updateErrorRawMaterial = () => {
    let rawMaterial = [];
    var valueArr = getValues("rawMaterial").map(function (item) {
      return item.raw_material_description;
    });
    valueArr.filter((item, idx) => {
      if (valueArr.indexOf(item) != idx) {
        rawMaterial[idx] = true;
      } else {
        rawMaterial[idx] = false;
      }
    });

    setErrorRawMaterial(rawMaterial);
  };
  const onRemoveSKU = (i) => {
    removeSku(i);
    setNoOfSku(parseInt(fieldsSku.length) - 1);
  };
  const calculateAfterEdit = (array, waste, batch) => {
    let wasteAmount = 0;
    let total_cost_per_batch_value = array
      .map((x, i) => {
        return parseFloat(x.cost_per_batch);
      })
      .reduce((a, b) => a + b, 0);
    let total_gst = array.map((x, i) => parseFloat(x.cost_per_batch) * parseFloat(parseFloat(x.gst_in_total) / parseFloat(x.per_unit_cost_of_raw_material))).reduce((a, b) => a + b, 0);
   
    waste = waste ? waste : 0;
    setWastePercentageAfterGst(parseFloat(waste));
    wasteAmount = (parseFloat(waste) * total_cost_per_batch_value) / 100;
   
    let totalRawMaterialCostPerBatchValue = parseFloat(wasteAmount) + parseFloat(total_cost_per_batch_value);
    let totalRawMaterialCostPerUnitValue = totalRawMaterialCostPerBatchValue ? parseFloat(totalRawMaterialCostPerBatchValue) / parseFloat(batch) : 0;
    setTotalGstAmountInThis(totalRawMaterialCostPerUnitValue ? totalRawMaterialCostPerUnitValue * (total_gst / total_cost_per_batch_value) : total_gst / total_cost_per_batch_value);
      setGstPercOfTotalUnitCost(isNaN(total_gst / total_cost_per_batch_value)?0:total_gst / total_cost_per_batch_value);
    setWasteAmountAfterGst(wasteAmount);
    setTotalRawMaterialCostPerBatch(totalRawMaterialCostPerBatchValue);
    setTotalRawMaterialCostPerUnit(totalRawMaterialCostPerUnitValue);
    setTotalCostPerBatchValue(total_cost_per_batch_value);
  };
  const onBatchSizeChange = (value, i, x) => {
    value = value ? parseFloat(value) : 0;
    setBatchSize(value);
    calculateAfterEdit(fieldsRawMaterial, wastePercentageAfterGst, value);
    setTrigger((trigger) => trigger + 1);
  };
  const handleRemoveCapacity = (i) => {
    removeCapacity(i);
    calculateAfterEdit(fieldsRawMaterial, wastePercentageAfterGst, batchSize);
  };
  const onSubmit = (data) => {
    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    setIsLoader(true);
    if (location.state.tradingId) {
      axios
        .post(`${ApiUrl}trading/create`, {
          protegeId: location.state.id,
          tradingId: location.state.tradingId,
          productList: [
            {
              basicPlannedProduction: data.basicPlannedProduction,
              product_name: data.product_name,
              physical_nature_of_product: data.physical_nature_of_product,
              brief_manufacture_product: data.brief_manufacture_product,
              machine_used_in_measure_process: data.machine_used_in_measure_process,
              numberOfItemsLessThan: data.numberOfItemsLessThan,
              rawMaterialData: {
                rawMaterial: data.rawMaterial,
              },
            },
          ],
        })
        .then((response) => {
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          // console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      axios
        .post(`${ApiUrl}trading/create`, {
          protegeId: location.state.id,
          productList: [
            {
              product_name: data.product_name,
              physical_nature_of_product: data.physical_nature_of_product,
              brief_manufacture_product: data.brief_manufacture_product,
              machine_used_in_measure_process: data.machine_used_in_measure_process,
              numberOfItemsLessThan: data.numberOfItemsLessThan,
              rawMaterialData: {
                rawMaterial: data.rawMaterial,
              },
            }
          ],
        })
        .then((response) => {
          toast("Saved Successfully ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate(-1);
            },
          });
        })
        .catch((error) => {
          // console.log(error);
          setIsLoader(false);
          toast(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const onUpdateSubmit = (data) => {
    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    setIsLoader(true);
    axios
      .put(`${ApiUrl}trading/update/${productId}/${location.state.tradingId}`, {
        basicPlannedProduction: data.basicPlannedProduction,
        product_name: data.product_name,
        physical_nature_of_product: data.physical_nature_of_product,
        brief_manufacture_product: data.brief_manufacture_product,
        machine_used_in_measure_process: data.machine_used_in_measure_process,
        numberOfItemsLessThan: data.numberOfItemsLessThan,
        rawMaterialData: {
          rawMaterial: data.rawMaterial,
        },
      })
      .then((response) => {
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-3)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>{op} Protégé</a> /
            </span>{" "}
            Trading
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form id="productOne" onSubmit={handleSubmit(op == "Edit" ? onUpdateSubmit : onSubmit)} autoComplete="off">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label required">
                  Type of Trading
                  </label>
                  <input tabIndex={0} autoFocus type="text" className={`form-control ${errors.product_name ? "errorBorder" : ""}`} id="exampleFormControlInput1" {...register("product_name", {required: true})} />
                  {errors.product_name && errors.product_name.type == "required" && (
                    <span className="textRed" style={{color: "red"}}>
                      * Product Name is required{" "}
                    </span>
                  )}
                </div>
                {/* <div className="col-md-6  mb-3">
                  <label htmlFor="nameBackdrop" className="form-label">
                    Physical Nature of the product
                  </label>
                  <select className={`form-select ${errors.physical_nature_of_product ? "errorBorder" : ""}`} id="exampleFormControlSelect1" aria-label="Default select example" {...register("physical_nature_of_product")}>
                    <option defaultValue="">Select</option>
                    <option value="1">Solid(fixed form)</option>
                    <option value="2">Solid(free form)</option>
                    <option value="2">Liquid</option>
                    <option value="1">Gas</option>
                  </select>
                </div> */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Briefly describe sources & other process involved
                  </label>
                  <textarea
                    className={`autoResize form-control ${errors.brief_manufacture_product ? "errorBorder" : ""}`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("brief_manufacture_product", {
                      onChange: (e) => {
                        autoResize(e);
                      },
                    })}
                  ></textarea>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  List needed Equipments Etc needed
                  </label>
                  <textarea
                    className={` autoResize form-control ${errors.machine_used_in_measure_process ? "errorBorder" : ""}`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    {...register("machine_used_in_measure_process", {
                      onChange: (e) => {
                        autoResize(e);
                      },
                    })}
                  ></textarea>
                </div>
                <div class="mb-3 col-md-3">
                <label for="nameBackdrop" class="form-label">
                  Number of Items
                </label>
                <br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="NumberOfItems"
                    id="inlineRadio1"
                    value={true}
                    checked={numberOfItemsLessThan}
                    {...register("numberOfItemsLessThan")}
                    onClick={() => setNumberOfItemsLessThan(true)}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Less than 10
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="NumberOfItems"
                    id="inlineRadio2"
                    value={false}
                    checked={!numberOfItemsLessThan}
                    {...register("numberOfItemsLessThan")}
                    onClick={() => setNumberOfItemsLessThan(false)}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    More than 10
                  </label>
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <strong>Item/Category</strong>
                  <button onClick={(e) => handleAddRawMaterial(e)} className="float-right btn btn-primary me-2 mb-2 float-end">
                  Add Item/Category
                  </button>
                </div>
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>S No</th>
                      <th>Name</th>
                      <th>Total Unit Cost</th>
                      <th>Gross Profit Percentage</th>
                      <th>Avg Sale Price per item/Transaction</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {fieldsRawMaterial &&
                      fieldsRawMaterial.map((x, i) => {
                        // if (x.cost_per_batch) {
                        return (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <select
                                  className={`form-select lg-width col-md-2 ${errorRawMaterial[i] ? "errorBorder" : ""}`}
                                  id="exampleFormControlSelect1"
                                  aria-label="Default select example"
                                  value={fieldsRawMaterial[i].raw_material_description}
                                required
                                  {...register(`rawMaterial.${i}.raw_material_description`, {
                                    onChange: (e) => {
                                      autoResize(e, i);
                                      setRawMaterialValue(e.target.value, i, x, rawMaterialName);
                                      calculateRawMaterialFields("raw_material_description", e.target.value, i, x, false);
                                      updateErrorRawMaterial();
                                    }
                                  })}
                                >
                                  <option value="">Select</option>
                                  {rawMaterialName.map((y) => {
                                    return <option value={y.raw_material_description}>{y.raw_material_description}</option>;
                                  })}
                                </select>
                                {errorRawMaterial[i] && (
                                        <span className="textRed" style={{color: "red"}}>
                                          *Already the material is added.{" "}
                                        </span>
                                      )}
                              </td>
                              <td>
                                <input
                                 type="text"
                                  numformat="numberRight"
                                  className={`form-control`}
                                  disabled
                                  value={rawMaterialName.filter((y) => y.raw_material_description === x.raw_material_description).map((item) => getNumericFormat(item.per_unit_cost_of_raw_material))}
                                  id="exampleFormControlInput1"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  numformat="numberRight"
                                  disabled={numberOfItemsLessThan}
                                  className={`form-control ${errors.rawMaterial && errors.rawMaterial[i] && errors.rawMaterial[i].gross_profit_percentage ? "errorBorder" : ""}`}
                                  id="exampleFormControlInput1"
                                  {...register(`rawMaterial.${i}.gross_profit_percentage`, {
                                    onChange: (e) => {
                                      calculateRawMaterialFields("gross_profit_percentage", e.target.value, i, x, true);
                                    },
                                  })}
                                />
                              </td> 
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  numformat="numberRight"
                                  className={`form-control ${errors.rawMaterial && errors.rawMaterial[i] && errors.rawMaterial[i].sales_price ? "errorBorder" : ""}`}
                                  id="exampleFormControlInput1"
                                  {...register(`rawMaterial.${i}.sales_price`, {
                                    onChange: (e) => {
                                      calculateRawMaterialFields("sales_price", e.target.value, i, x, true);
                                    },
                                  })}
                                />
                              </td> 
                              {/* <td style={{textAlign: "right"}}>{getNumericFormat(x.cost_per_batch)}</td> */}
                              <td className="text-center cursor-pointer">
                                <MdDelete
                                  size={20}
                                  color="red"
                                  onClick={() => {
                                    onRemoveRawMaterial(i, x);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        );
                        // }
                      })}
                   
                  </tbody>
                </table>
                
              </div>
            
              <hr />
              <div className="mt-2">
                <button type="submit" className="btn btn-primary me-2">
                  {op == "Edit" ? "Update Changes" : "Save changes"}
                </button>
                <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
}
export default TradingProductDetails;
