import React from 'react'
import Spinner from '../../assets/img/spinner.gif'
function Loader() {
    return (
        <div className="card">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          
          <img
            src={
                Spinner
            }
          />
        </div>
      </div>
    )
}

export default Loader
