import { useNavigate } from "react-router";
import { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Layout from "common/components/layout";
import CashFlowPage from "./CashFlowPage";
import IncomeStatementPage from "./IncomeStatementPage";
import BalanceSheet from "./balanceSheet";
import RatioAnalysis from "./ratioAnalysis";
import Narrative from "./narrative/narrative";
function ProtegeReports(props) {
  const [active, setActive] = useState('cashflow');
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light" onClick={() => navigate(-1)}>
              Protégé /
            </span>{" "}
            Reports
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="col-md-12">
          <Tabs
            defaultActiveKey="cashflow"
            id="justify-tab-example"
            className="mb-3"
            justify
            onSelect={(index) => setActive(index)}
          >
            <Tab eventKey="cashflow" title="Cash Flow">
              {active === "cashflow" ? <CashFlowPage /> : ""}
            </Tab>
            <Tab eventKey="incomestatement" title="Income Statement">
              {active === "incomestatement" ? <IncomeStatementPage /> : ""}
            </Tab>
            <Tab eventKey="balancesheet" title="Balance Sheet">
              {active === "balancesheet" ? <BalanceSheet /> : ""}
            </Tab>
            <Tab eventKey="ratioanalysis" title="Ratio Analysis">
              {active === "ratioanalysis" ? <RatioAnalysis /> : ""}
            </Tab>
            <Tab eventKey="narrative" title="Narrative">
              {active === "narrative" ? <Narrative /> : ""}
            </Tab>
          </Tabs>
        </div>
      </div>

    </Layout>
  );
}

export default ProtegeReports;
