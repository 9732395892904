import {
  Tab,
  Nav
} from "react-bootstrap";
import CashFlow from "./CashFlow";
import RepaymentBackUp from "./RepaymentBackUp";
import { useState } from "react";
import StartUpCostReport from "./StartUpCostReport";
function CashFlowPage() {
  const [active, setActive] = useState('cashflow');
  return (
    <>
      {/* <h4 className="fw-bold py-3">Cash flow</h4> */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="cashflow" onSelect={(index) => setActive(index)}>
        <div className="nav-align-top mb-4">
          <Nav variant="pills" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="cashflow">
                <span className="bx-profile">Cash Flow</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="repayment">
                <span className="bx-profile">Repayment Back Up</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="startup">
                <span className="bx-profile">Startup Cost and Sources Of Fund</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="cashflow">
              {active === "cashflow" ? <CashFlow /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="repayment">
              {active === "repayment" ? <RepaymentBackUp /> : ""}
            </Tab.Pane>
            <Tab.Pane eventKey="startup">
              {active === "startup" ? <StartUpCostReport /> : ""}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
      <hr className="my-5" />
      <div className="content-backdrop fade"></div>
    </>
  );
}
export default CashFlowPage;
