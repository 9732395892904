import { ApiUrl } from "API/config";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
function NewCenter(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const [user, setUser] = useState()

useEffect(()=>{
  setUser()
},[])

  return (
    <>
        <ToastContainer />
   
    <form
     onSubmit={handleSubmit((data)=>props.props.createCenter(data))}
     >
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Centre Name
          </label>
          <input
            type="text"
            id="nameBackdrop"
            className={`form-control ${errors.name ? "errorBorder" : ""}`}
            placeholder="Enter Centre Name"
            {...register("name", { required: true })}
            />
        {errors.name && <span className="textRed">*Centre name is required</span>}
            
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="emailBackdrop" className="form-label required">
            Centre Code
          </label>
          <input
            type="text"
            id="emailBackdrop"
            className={`form-control ${errors.code ? "errorBorder" : ""}`}
            placeholder="Enter Centre Code"
            {...register("code", { required: true })}
            />
        {errors.code && <span className="textRed">*Centre code is required</span>}
            
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <label htmlFor="nameBackdrop" className="form-label required">
            Status
          </label>
          <select
           className={`form-select ${errors.status ? "errorBorder" : ""}`}
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            defaultValue={user && user.status}
            {...register("status", { required: true })}
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            </select>
            
        {errors.status && <span className="textRed">*Centre status is required</span>}
          </div>
          
      </div>
      <div className="row">
      <div className="col-sm-12 text-center">
      <button type="submit" className="btn btn-md btn-primary me-2">Save</button>
      <button onClick={ ()=> props.props.handleClose()} className="btn btn-md btn-secondary btn-outline-secondary">Cancel</button>
      </div>
      </div>
    </form>
    </>
  );
}

export default NewCenter;
