import logo from "../../../assets/img/icreate_logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl } from "API/config";
import { toast, ToastContainer } from "react-toastify";
function ActiveAccount() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const { token } = useParams();
  console.log(token);
  const onForgotPasswordSubmit = (data) => {
    console.log(data.password);
    if (data.password != data.newPassword) {
      toast("Password not matched", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    axios
      .post(`${ApiUrl}updateFirstPassword`, {
        ...data,
        createAccountPasswordLink: token
      })
      .then((response) => {
        console.log(response);
        toast("Acount activated please login", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
                toast("Error occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
 
      })
  };

  return (
    <div className="container-xxl">
      <ToastContainer />
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <img src={logo} />
              </div>
              <p className="mb-4">Enter a password to activate your account</p>
              <form
                id="formAuthentication"
                className="mb-3"
                onSubmit={handleSubmit(onForgotPasswordSubmit)}
              >
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    autoFocus
                    {...register("password", { required: true })}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newpassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="newpassword"
                    name="newpassword"
                    placeholder="Confirm Password"
                    autoFocus
                    {...register("newPassword", { required: true })}
                  />
                </div>
                <button className="btn btn-primary d-grid w-100">
                  Activate
                </button>
              </form>
              <div className="text-center">
                <Link
                  to={"/login"}
                  className="d-flex align-items-center justify-content-center"
                >
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveAccount;
