import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function StartUpCostReport(props) {
  const operation = useParams().op;
  const params = useParams();
  const [sourcesOfFundData, setSourcesOfFundData] = useState({});
  const [startUpCostData, setStartUpCostData] = useState({});
  const [fixedCostData, setFixedCostData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => { 
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setSourcesOfFundData(data.data[0].sourcesOfFund ? data.data[0].sourcesOfFund : {});
          setStartUpCostData(data.data[0].startupCost ? data.data[0].startupCost : {});
          setFixedCostData(data.data[0].fixedCost.fieldLineItemsName.reduce((total, item) => (item.details && item.details.gestPeriod ? total + item.details.gestPeriod : total), 0))
          // console.log(data.data[0].fixedCost.fieldLineItemsName.reduce((total, item) => (item.details && item.details.gestPeriod ? total + item.details.gestPeriod : total), 0))
          setIsLoader(false);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  if (isLoader) {
    return (
      <Loader></Loader>
    );
  }
  // console.log(sourcesOfFundData.fieldLineItemsName)
  console.log(startUpCostData)
  console.log()
  if (startUpCostData) {
    return (
      <>
        <h5>Start Up Cost </h5>
        <div className="table-responsive" style={{maxWidth:'700px'}}>
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th className="text-center">Sl No</th>
                <th className="text-center md-width">Description </th>
                <th className="text-center md-width">Amount</th>
            
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td className="text-center">
                  1
                </td>
                <td className="text-right md-width">
                
                  Tangible Assets
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && startUpCostData?.totalNonDepreciableAssets + startUpCostData?.totalDepreciableAssets)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  2
                </td>
                <td className="text-right md-width">
                
                  Intangible Assets
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && startUpCostData?.totalIntangiblesAssets)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  3
                </td>
                <td className="text-right md-width">
                
                  Deposits and Advances
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && startUpCostData?.totalDepositsAndAdvancesAssets)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  4
                </td>
                <td className="text-right md-width">
                
                  Initial Raw Material Purchases
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && startUpCostData?.initialRawMaterialPurchase)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  5
                </td>
                <td className="text-right md-width">
                
                Fixed Cost During Gestation 
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && fixedCostData)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  6
                </td>
                <td className="text-right md-width">
                
                Intial Cash
                </td> 
                <td className="text-right">
                  {getNumericFormat(startUpCostData && startUpCostData?.initialCash)}
                </td>
              </tr>
              <tr>
                <td className="">
                  
                </td>
                <td className="text-right md-width bold">
                <strong>
                Total Funds Required
                </strong>
                </td> 
                <td className="text-right">
                  <strong>
                  {getNumericFormat(startUpCostData && startUpCostData?.totalFundsRequired)}
                  </strong>
                </td>
              </tr>
             
            </tbody>
          </table>
        </div>
        <h5>Sources Of Fund </h5>
        <div className="table-responsive" style={{maxWidth:'700px'}}>
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th className="text-center">Sl No</th>
                <th className="text-center md-width">Description </th>
                <th className="text-center md-width">Amount</th>
            
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td className="text-center">
                  1
                </td>
                <td className="text-right md-width">
                
                Own Fund

                </td> 
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[0]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  2
                </td>
                <td className="text-right md-width">
                
                Family/friends/relatives
                </td> 
                <td className="text-right">
                {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[1]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  3
                </td>
                <td className="text-right md-width">
                
                Bank/Financial Institution
                </td> 
                <td className="text-right">
                {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[2]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  4
                </td>
                <td className="text-right md-width">
                
                Machinery Supplier Credit
                </td> 
                <td className="text-right">
                {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[3]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  5
                </td>
                <td className="text-right md-width">
                
                Raw Material Supplier Credit
                </td> 
                <td className="text-right">
                {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[4]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  6
                </td>
                <td className="text-right md-width">
                
                MFI
                </td> 
                <td className="text-right">
                {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[5]?.Amount)}
                </td>
              </tr>
              <tr>
                <td className="">
                  
                </td>
                <td className="text-right md-width bold">
                <strong>
                Total Sources of Funds
                </strong>
                </td> 
                <td className="text-right">
                  <strong>
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData?.fieldLineItemsName?.[0]?.Amount + sourcesOfFundData?.fieldLineItemsName?.[1]?.Amount +sourcesOfFundData?.fieldLineItemsName?.[2]?.Amount +sourcesOfFundData?.fieldLineItemsName?.[3]?.Amount +sourcesOfFundData?.fieldLineItemsName?.[4]?.Amount +sourcesOfFundData?.fieldLineItemsName?.[5]?.Amount)}
                  </strong>
                </td>
              </tr>
            
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
export default StartUpCostReport;
