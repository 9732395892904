import logo from "assets/img/icreate_logo.png";
import { ApiUrl } from "API/config";
import defaultAvatar from "assets/img/avatars/placeholder.jpg";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useRoutes } from "react-router";
import { useEffect, useState } from "react";
import EventEmitter from "reactjs-eventemitter";
function NavigationBar(props) {
  const [avatar, setAvatar] = useState("");
  const [isProtegeTab, setIsProtegeTab] = useState(false);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  let userData = localStorage.getItem("user_data");
  let userObj = userData
    ? JSON.parse(localStorage.getItem("user_data")).user
    : undefined;
  useEffect(() => {
    getProfileData();
    EventEmitter.subscribe("profileupdated", (event) => {
      setAvatar(event);
    });
  }, []);
  const getProfileData = () => {
    let userId = JSON.parse(localStorage.getItem("user_data")).user._id;
    axios
      .get(`${ApiUrl}user/${userId}`)
      .then((res) => {
        setAvatar(res.data.avatar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

useEffect(()=>{
  if(path==='/'|| path ==='Protege'){
    setIsProtegeTab(true);
  }
},[path])

  const element = (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <Link to={"/"} className="app-brand-link">
          <img src={logo} />
        </Link>
        <a
          onClick={props.toggleHamburgerMenu}
          href="#"
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1">
        {/* <li className={"menu-item " + (path === "/dashboard" ? "active" : "")}>
          <Link to={"/dashboard"} className="menu-link">
            <i className="menu-icon tf-icons bx bx-home-circle"></i>
            <div data-i18n="Tables">Dashboard</div>
          </Link>
        </li> */}
         <li
          className={
            "menu-item " + (path.indexOf("Protege") > -1 ? "active" : isProtegeTab? "active" : '')
          }
        >
          <Link to={"/Protege"} className="menu-link">
            <i className="menu-icon tf-icons bx bx-group"></i>
            <div data-i18n="Tables">Protégé</div>
          </Link>
        </li>
        {userObj && userObj.role == "admin" && (
          <li className={"menu-item " + (path === "/users" ? "active" : "")}>
            <Link to={"/users"} className="menu-link">
              <i className="menu-icon tf-icons bx bx-group"></i>
              <div data-i18n="Tables">Users</div>
            </Link>
          </li>
        )}

        {userObj && userObj.role == "admin" && (
          <li className={"menu-item " + (path === "/centres" ? "active" : "")}>
            <Link to={"/centres"} className="menu-link">
              <i className="menu-icon tf-icons bx bx-buildings"></i>
              <div data-i18n="Tables">Centres</div>
            </Link>
          </li>
        )}

       
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text">Account Settings</span>
        </li>
        <li className={"menu-item " + (path === "/profile" ? "active" : "")}>
          <Link to={"/profile"} className="menu-link">
            <i className="menu-icon tf-icons bx bx-user"></i>
            <div data-i18n="Tables">My Profile</div>
          </Link>
        </li>
        <li className="menu-item">
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
            className="menu-link"
          >
            <i className="menu-icon tf-icons bx bx-exit"></i>
            <div data-i18n="Tables">Logout</div>
          </div>
        </li>
        <li className="menu-item mar-auto">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={avatar ? avatar : defaultAvatar}
                  className="w-px-40  rounded"
                  height={55}
                  width={55}
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <span className="fw-semibold d-block">
                {userObj && userObj.name}
              </span>
              <small className="text-muted">{userObj && userObj.role}</small>
            </div>
          </div>
        </li>
      </ul>
    </aside>
  );

  return element;
}

export default NavigationBar;
