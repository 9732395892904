import { useAccordionButton } from "react-bootstrap/AccordionButton";
function CustomAccordian({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!", eventKey)
  );
  return (
    <>
      <h2 className="accordion-header" id="headingOne">
        <button
          onClick={decoratedOnClick}
          type="button"
          className="accordion-button collapsed"
          aria-expanded="false"
          aria-controls="accordionOne"
        >
          {children}
        </button>
      </h2>
    </>
  );
}

export default CustomAccordian;
