import { useParams, useNavigate, useLocation } from "react-router";
import { useEffect } from "react";
const ProtégéDetailsRouter = () => {

    const route = useParams().route;
    const op = useParams().op;
    const navigate = useNavigate();
    const location =useLocation()

    useEffect(() => {
        if (route) {
            navigate(("/Protege/" + route + "/" + op),{state:{id:location.state.id}}, { replace: true });
        }
    });
}

export default ProtégéDetailsRouter;