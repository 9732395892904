import Layout from "common/components/layout";
import {useParams, useNavigate, useLocation} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {set, useFieldArray, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import axios from "axios";
import {ApiUrl} from "API/config";
import {MdDelete} from "react-icons/md";
import Loader from "common/components/loader";
function RawMaterialConsumption() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  // console.log("location", location);
  
  const [isLoader, setIsLoader] = useState(false);
  const [routePath, setRoutePath] = useState(location.state.data.businessType == "manufacturing" ? "rawMaterialConsumption" : location.state.data.businessType == "animalhusbandry" ? "animalhusbandry/rawMaterialConsumption" : location.state.data.businessType == "agro" ? "agro/rawMaterialConsumption" : location.state.data.businessType == "trading" ? "trading/rawMaterialConsumption" : location.state.data.businessType == "service" ? "service/rawMaterialConsumption" : "");
  const [errorRawMaterial, setErrorRawMaterial] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm();

  const {fields: fieldsRawMaterial, append: appendRawMaterial, remove: removeRawMaterial, update: updateRawMaterial} = useFieldArray({name: "rawMaterial", control});

  let rawMaterialConsumptionId = location.state && location.state.data && location.state.data.rawMaterialConsumptionId && location.state.data.rawMaterialConsumptionId;
  const [totalObjectData, setTotalObj] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    },
  });
  useEffect(() => {

    // setRoutePath();
console.log(location.state.data)
    if (location.state.data.businessId) {
      getRawMaterialConsumptionById();
    }
    if (!rawMaterialConsumptionId) {
      appendRawMaterial({
        raw_material_description: "",
        unit_of_measure: "",
        unit_rate: 0,
        gst_on_raw_material: 0,
        transpost_cost_on_raw_material: 0,
        gst_transport: 0,
        gst_in_total: 0,
        per_unit_cost_of_raw_material: 0,
      });
    }
  }, []);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,    });
    return num ? numberFormatter.format(num) : 0;
  };

  const getRawMaterialConsumptionById = async() => {
    setIsLoader(true);
      const rawMaterialList = location.state.data.rawMaterialConsumptionId ? await rawMaterialData(location.state.data.rawMaterialConsumptionId) : [];
      reset({
        rawMaterial: rawMaterialList,
      });
        updateErrorRawMaterial();
        setTimeout(() => setIsLoader(false), 400);

  };

  const rawMaterialData = (data) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${ApiUrl}rawMaterialConsumption/${routePath}/${data}`)
        .then((result) => {
          resolve(result.data.rawMaterial);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleAddRawMaterial = (e) => {
    e.preventDefault();
    const rawMaterialItem = {
      raw_material_description: "",
      unit_of_measure: "",
      unit_rate: 0,
      gst_on_raw_material: 0,
      transpost_cost_on_raw_material: 0,
      gst_transport: 0,
      gst_in_total: 0,
      per_unit_cost_of_raw_material: 0,
    };
    appendRawMaterial(rawMaterialItem);
    updateErrorRawMaterial();
  };
  const onRemoveRawMaterial = (i) => {
    removeRawMaterial(i);
    updateErrorRawMaterial();
  };
  const updateErrorRawMaterial = () => {
    let rawMaterial = [];
    var valueArr = getValues("rawMaterial").map(function (item) {
      return item.raw_material_description;
    });
    valueArr.filter((item, idx) => {
      if (valueArr.indexOf(item) != idx) {
        rawMaterial[idx] = true;
      } else {
        rawMaterial[idx] = false;
      }
    });

    setErrorRawMaterial(rawMaterial);
  };
  const calculateRawMaterialFields = (field, value, index, item, isNumber) => {
    var obj = item;
    value = isNumber ? (value.target ? parseFloat(value.target.value.replace(/,/g, "")) : parseFloat(value.replace(/,/g, ""))) : value;
    obj[field] = value;
    obj.per_unit_cost_of_raw_material = parseFloat(obj.unit_rate) + parseFloat(obj.transpost_cost_on_raw_material) + (parseFloat(obj.gst_on_raw_material) * parseFloat(obj.unit_rate)) / 100 + (parseFloat(obj.gst_transport) * parseFloat(obj.transpost_cost_on_raw_material)) / 100;
    obj.gst_in_total = (parseFloat(obj.gst_on_raw_material) * parseFloat(obj.unit_rate)) / 100 + (parseFloat(obj.gst_transport) * parseFloat(obj.transpost_cost_on_raw_material)) / 100;
    updateRawMaterial(index, obj);
  };

  const onSubmit = (data) => {
    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    
    setIsLoader(true);

    
    
    axios
      .post(`${ApiUrl}rawMaterialConsumption/${routePath}/create`, {
        ...data,
        businessId: location.state.data.businessId,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onUpdate = (data) => {

    if (errorRawMaterial.indexOf(true) !== -1) {
      return false;
    }
    setIsLoader(true);
      axios
      .put(`${ApiUrl}rawMaterialConsumption/${routePath}/update`, {
        ...data,
        _id: rawMaterialConsumptionId,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Details of Inputs
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        (!location.state.data.businessId && (
          <div className="card">
            <div className="card-body">
              <p>No data found! Please Create the product under Product Information.</p>
            </div>
          </div>
        )) ||
        (location.state.data.businessId && (
          <div className="card">
            <div className="mb-4">
              <div className="card-body">
                <form id="formAccountSettings" onSubmit={handleSubmit(rawMaterialConsumptionId ? onUpdate : onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-3 mt-4">
                      <strong>Material/Packing Material/Consumables/Labor Details</strong>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        {!fieldsRawMaterial.length && <div className="text-center">No data found! Please add Input.</div>}
                        {fieldsRawMaterial.map((x, i) => {
                          return (
                            <>
                              <table className="table table-bordered">
                                <thead className="table-light">
                                  <tr>
                                    <th>Input</th>
                                    <th>
                                      <input
                                        title=""
                                        required
                                        id="exampleFormControlInput1"
                                        key={i}
                                        className={`form-control sl-width ${errorRawMaterial[i] ? "errorBorder" : ""}`}
                                        {...register(`rawMaterial.${i}.raw_material_description`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("raw_material_description", e.target.value, i, x, false);
                                            updateErrorRawMaterial();
                                          },
                                        })}
                                      />
                                      {errorRawMaterial[i] && (
                                        <span className="textRed" style={{color: "red"}}>
                                          *Already the material is added.{" "}
                                        </span>
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                              <table className="table table-bordered">
                                <thead className="table-light text-center">
                                  <tr>
                                    <th>Unit of Measure</th>
                                    <th>Unit Rate</th>
                                    <th>GST %</th>
                                    <th>Transport Cost </th>
                                    <th>GST% On transport</th>
                                    <th>Total Unit Cost</th>
                                    <th>GST Component in Total</th>
                                    <th>Minimum Order Quantity</th>
                                    <th>Lead Time in Month</th>
                                    <th>Payment Terms</th>
                                    <th>No. of Months</th>
                                    <th>Safety Stock in Month</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                  <tr key={i}>
                                    <td>
                                      <select
                                        className="form-select md-width"
                                        id="exampleFormControlSelect1"
                                        aria-label="Default select example"
                                        {...register(`rawMaterial.${i}.unit_of_measure`, {
                                          onChange: (e) => calculateRawMaterialFields("unit_of_measure", e.target.value, i, x, false),
                                        })}
                                      >
                                        <option value="">Select</option>
                                        <option value="kg">Kg</option>
                                        <option value="gm">Gms</option>
                                        <option value="lt">Litre</option>
                                        <option value="ml">Millilitre</option>
                                        <option value="Millilitre">Cm</option>
                                        <option value="Metre">Metre</option>
                                        <option value="Lot">Lot</option>
                                        <option value="Set">Set</option>
                                        <option value="Batch">Batch</option>
                                        <option value="Month">Month</option>
                                        <option value="Dozen">Dozen</option>
                                        <option value="Person">Person</option>
                                        <option value="Piece">Piece</option>
                                        <option value="kWh">kWh</option>
                                        <option value="Kilowatt">Kilo watt</option>
                                      <option value="Sqft">Sqft</option>
                                      <option value="Sqmt">Sqmt</option>
                                      <option value="Acre">Acre</option>
                                      <option value="Feet">Feet</option>
                                      <option value="Inch">Inch</option>
                                      <option value="Gunta">Gunta</option>
                                      <option value="Pair">Pair</option>
                                      <option value="Quintal">Quintal</option>
                                      <option value="Ton">Ton</option>
                                        <option value="event">Event</option>
<option value="service">Service</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        numformat="numberRight"
                                        className="form-control sl-width"
                                        value={fieldsRawMaterial[i].unit_rate}
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.unit_rate`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("unit_rate", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        numformat="numberRight"
                                        className="form-control sl-width"
                                        value={fieldsRawMaterial[i].gst_on_raw_material}
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.gst_on_raw_material`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("gst_on_raw_material", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        numformat="numberRight"
                                        className="form-control sl-width"
                                        value={fieldsRawMaterial[i].transpost_cost_on_raw_material}
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.transpost_cost_on_raw_material`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("transpost_cost_on_raw_material", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        numformat="numberRight"
                                        type="number"
                                        step="any"
                                        className="form-control sl-width"
                                        value={fieldsRawMaterial[i].gst_transport}
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.gst_transport`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("gst_transport", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input numformat="numberRight" type="text" disabled className="form-control md-width" title={getNumericFormat(x.per_unit_cost_of_raw_material)} id="exampleFormControlInput1" value={getNumericFormat(x.per_unit_cost_of_raw_material)} {...register(getNumericFormat(`rawMaterial.${i}.per_unit_cost_of_raw_material`))} />
                                    </td>
                                    <td>
                                      <input type="text" numformat="numberRight" title={getNumericFormat(x.gst_in_total)} disabled className="form-control md-width" value={getNumericFormat(x.gst_in_total)} id="exampleFormControlInput1" {...register(getNumericFormat(`rawMaterial.${i}.gst_in_total`))} />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        numformat="numberRight"
                                        className="form-control sl-width"
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.minimum_order_quantity`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("minimum_order_quantity", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        numformat="numberRight"
                                        className="form-control sl-width"
                                        id="exampleFormControlInput1"
                                        {...register(`rawMaterial.${i}.lead_time`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("lead_time", e.target.value, i, x, true);
                                          },
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        className={`form-select`}
                                        aria-label="Default select example"
                                        {...register(`rawMaterial.${i}.payment_terms`, {
                                          onChange: (e) => {
                                            calculateRawMaterialFields("payment_terms", e.target.value, i, x, false);
                                          },
                                        })}
                                      >
                                        <option value="">Select</option>
                                        <option value="cash">Cash</option>
                                        <option value="credit">Credit</option>
                                        <option value="creditCard">Credit Card</option>
                                        <option value="advance">Advance</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input type="number" step="any" disabled={fieldsRawMaterial[i].payment_terms == "credit" ? false : true} numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" {...register(`rawMaterial.${i}.no_of_cash_in_months`)} />
                                    </td>
                                    <td>
                                      <input type="number" step="any" numformat="numberRight" className="form-control sl-width" id="exampleFormControlInput1" {...register(`rawMaterial.${i}.Safety_Stock`)} />
                                    </td>
                                    <td className="text-center cursor-pointer">
                                      <MdDelete
                                        size={20}
                                        color="red"
                                        onClick={() => {
                                          onRemoveRawMaterial(i, x);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          );
                        })}
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-3 mt-4">
                          <button onClick={(e) => handleAddRawMaterial(e)} className="btn btn-primary me-2 mb-2 float-end">
                          Add Input
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <hr />
                  <div className="mt-2">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                    <button type="reset" onClick={() => navigate(-1)} className="btn btn-outline-secondary">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ))
      )}
    </Layout>
  );
}
export default RawMaterialConsumption;
